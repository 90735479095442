import React from 'react';

import PropTypes from 'prop-types';

import { Alert, Box } from '_shared/designSystem/components';

import { DATE_INCOMPLETE_ALERT_TYPES } from './matchConstants';

export default function DataIncompleteAlert({ messageType }) {
  let message;
  switch (messageType) {
    case DATE_INCOMPLETE_ALERT_TYPES.HIDE_DATA:
      message = 'Sorry, due to missing ball & player tracking data, no data is available for this match';
      break;
    case DATE_INCOMPLETE_ALERT_TYPES.SHOW_DATA:
      message =
        'Please be aware this match is missing ball & player tracking data. Please scroll down to see the completeness score at the bottom of the page';
      break;
    default:
  }

  return (
    <Box py={5}>
      <Alert status="warning" message={message} />
    </Box>
  );
}

DataIncompleteAlert.propTypes = {
  messageType: PropTypes.oneOf(['show_data', 'hide_data'])
};
