export function setWimbTvSession() {
  localStorage.setItem('wimbTvSession', 'yes');
}

export function isWimbTvSession() {
  const wimbTvSession = localStorage.getItem('wimbTvSession');
  return wimbTvSession === 'yes';
}

export function isWimbMyWSession() {
  const wimbMyWSession = localStorage.getItem('wimbMyWSession');
  return wimbMyWSession === 'yes';
}

export function removeWimbTvSession() {
  localStorage.removeItem('wimbTvSession');
}

export function removeWimbMyWSession() {
  localStorage.removeItem('wimbMyWSession');
}

export function setMyWAuthTokens(accessToken, wRefreshToken) {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('wRefreshToken', wRefreshToken);
  localStorage.setItem('wimbMyWSession', 'yes');
}

export function getMyWAuthTokens() {
  const accessToken = localStorage.getItem('accessToken');
  const wRefreshToken = localStorage.getItem('wRefreshToken');
  return {
    accessToken,
    wRefreshToken
  };
}

export function removeMyWAuthTokens() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('wRefreshToken');
  localStorage.removeItem('wimbMyWSession');
  localStorage.removeItem('myWRedirectPath');
  localStorage.removeItem('playerId');
}

export function setMyWRedirectPath(pathname) {
  localStorage.setItem('myWRedirectPath', pathname);
}

export function getMyWRedirectPath() {
  return localStorage.getItem('myWRedirectPath');
}

export function removeMyWRedirectPath() {
  localStorage.removeItem('myWRedirectPath');
}

export function setWimbPlayerId(playerId) {
  localStorage.setItem('playerId', playerId);
}

export function geWimbPlayerId() {
  return localStorage.getItem('playerId');
}

export function removeWimbPlayerId() {
  localStorage.removeItem('playerId');
}
