import React from 'react';

import { Box, Flex, Text } from '@ellipsedata/tennis';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { isWimbPortal } from '_shared/utils/environment/currentEnv';

const PlaylistDesktop = ({ playlist, onSelectPoint, currentIndex }) => {
  if (isEmpty(playlist)) return null;

  return (
    <Flex
      direction="column"
      maxH="670px"
      overflowY="auto"
      overflowX="hidden"
      gap={2}
      sx={{
        '&::-webkit-scrollbar': {
          width: '12px',
          borderRadius: '8px',
          backgroundColor: 'grey.200'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'primary.600',
          borderRadius: '8px'
        }
      }}
    >
      {playlist.map((item, index) => (
        <Box
          key={index}
          onClick={() => onSelectPoint(index)}
          bg={currentIndex === index ? (isWimbPortal() ? 'primary.500' : 'primary.200') : 'none'}
          color={currentIndex === index ? (isWimbPortal() ? 'white' : 'secondary.800') : 'secondary.800'}
          justifyContent="flex-start"
          _hover={{ bg: 'primary.100', color: 'secondary.800' }}
          cursor="pointer"
          data-testid={`playlist-item-${index}`}
          p={1}
          minW="310px"
          borderBottom="1px solid"
          borderColor="grey.200"
          mr={3}
        >
          <Flex gap={1} justify="space-between">
            <Flex gap={2}>
              <Text fontSize="xs" w="55px">
                {index + 1}/{playlist.length}
              </Text>
              <Text fontSize="xs">{item.summary}</Text>
            </Flex>
            <Flex flexDirection="column" w="70px" minW="70px">
              <Text fontSize="xs" textAlign="right">
                Set {item.set}
              </Text>
              <Text fontSize="xs" textAlign="right">
                {item.games} {item.points}
              </Text>
            </Flex>
          </Flex>
        </Box>
      ))}
    </Flex>
  );
};

PlaylistDesktop.propTypes = {
  playlist: PropTypes.arrayOf(PropTypes.object),
  onSelectPoint: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired
};

export default PlaylistDesktop;
