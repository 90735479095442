import { courtGraphicTypes } from 'match/_shared/matchConstants';
export const getServeButtons = () => {
  return [
    {
      label: '1st Serve',
      value: 1,
      position: 'left'
    },
    {
      label: '2nd Serve',
      value: 2,
      position: 'right'
    }
  ];
};

export const getDeuceAdvantageButtons = () => {
  return [
    {
      label: 'Deuce',
      value: 'deuce',
      position: 'left'
    },
    {
      label: 'Advantage',
      value: 'advantage',
      position: 'right'
    }
  ];
};

export const getPressureBreakButtons = () => {
  return [
    {
      label: 'Pressure',
      value: 'pressure',
      position: 'left'
    },
    {
      label: 'Break',
      value: 'break',
      position: 'right'
    }
  ];
};

export const getForehandBackhandButtons = () => {
  return [
    {
      label: 'Forehand',
      value: 'forehand',
      position: 'left'
    },
    {
      label: 'Backhand',
      value: 'backhand',
      position: 'right'
    }
  ];
};

export const getOrientationButtons = () => {
  return [
    {
      label: 'Horizontal',
      value: 'horizontal',
      position: 'left'
    },
    {
      label: 'Vertical',
      value: 'vertical',
      position: 'right'
    }
  ];
};

export const getAveragesTypeButtons = (graphicType) => {
  let data = [];
  switch (graphicType) {
    case courtGraphicTypes.RALLY_PLACEMENT:
      data = [];
      break;
    case courtGraphicTypes.RALLY_CONTACT_POINT:
      data = [];
      break;
    case courtGraphicTypes.RETURN_PLACEMENT:
      data = [
        {
          label: 'Placement',
          value: 'placement',
          position: 'left'
        },
        {
          label: 'Win',
          value: 'win',
          position: 'right'
        }
      ];
      break;
    case courtGraphicTypes.RETURN_CONTACT_POINT:
      data = [
        {
          label: 'Contact',
          value: 'placement',
          position: 'left'
        },
        {
          label: 'Quality',
          value: 'quality',
          position: 'middle'
        },
        {
          label: 'Win',
          value: 'win',
          position: 'right'
        }
      ];
      break;
    default:
      data = [
        {
          label: 'Placement',
          value: 'placement',
          position: 'left'
        },
        {
          label: 'Quality',
          value: 'quality',
          position: 'middle'
        },
        {
          label: 'Win',
          value: 'win',
          position: 'middle'
        },
        {
          label: 'Unreturned',
          value: 'unreturned',
          position: 'right'
        }
      ];
  }
  return data;
};

// Base function to generate serve location buttons
export const generateServeLocationButtons = (labelType = 'verbose') => {
  const locations = [
    { label: 'Wide', abbreviation: 'W', position: 'Deuce', value: 6 },
    { label: 'Body', abbreviation: 'B', position: 'Deuce', value: 5 },
    { label: 'T', abbreviation: 'T', position: 'Deuce', value: 4 },
    { label: 'T', abbreviation: 'T', position: 'Adv', value: 3 },
    { label: 'Body', abbreviation: 'B', position: 'Adv', value: 2 },
    { label: 'Wide', abbreviation: 'W', position: 'Adv', value: 1 }
  ];

  return locations.map((location) => {
    const label =
      labelType === 'verbose'
        ? `${location.position} ${location.label}`
        : `${location.position[0]}${location.abbreviation}`;
    const position =
      location.value === 6 || location.value === 1 ? (location.value === 6 ? 'left' : 'right') : 'middle';

    return { label, value: location.value, position };
  });
};

export const getServeLocationButtons = () => {
  return generateServeLocationButtons('verbose');
};

export const getServeLocationMobileButtons = () => {
  return generateServeLocationButtons('abbreviated');
};

export function getBallDotCoordinates3d(shot, viewBoxWidth, viewBoxHeight, widthOfBall) {
  const feetToMetres = 0.3048;
  const actualHalfCourtWidth = (27 / 2) * feetToMetres; // in metres
  const actualHalfCourtLength = 39 * feetToMetres; // in metres

  // These percentages have been manually calculated from Figma, but are static
  const startingX = 0.5 * viewBoxWidth;
  const startingY = 0.2133 * viewBoxHeight;
  const pxHalfCourtWidthAtNet = (0.37306 / 2) * viewBoxWidth;
  const pxCourtLength = 0.61 * viewBoxHeight;

  const pxPerMWidthAtNet = pxHalfCourtWidthAtNet / actualHalfCourtWidth;
  const pxPerMLength = pxCourtLength / actualHalfCourtLength;

  const xDistanceFromCentrePx = shot?.x * pxPerMWidthAtNet;
  const yDistanceFromNet = shot?.y * -1 * pxPerMLength;

  // We need to stretch the x coordinate because of the 3D court
  const proportionStretch = getProportionToStretchX(yDistanceFromNet, pxHalfCourtWidthAtNet);
  const newX = xDistanceFromCentrePx * proportionStretch;

  const xCoordinateOnScreen = Math.round(startingX + newX - widthOfBall / 2);
  const yCoordinateOnScreen = Math.round(startingY + yDistanceFromNet - widthOfBall / 2);

  return {
    top: yCoordinateOnScreen,
    left: xCoordinateOnScreen
  };
}

function getProportionToStretchX(yDistanceFromNet, pxHalfCourtWidthAtNet) {
  // Method here is to calculate the extra width using trigonemtry
  // This angle is at the net, going outwards
  const angleOfTriangle = 23.2;
  const extraWidth = getTanDeg(angleOfTriangle) * yDistanceFromNet;
  return (extraWidth + pxHalfCourtWidthAtNet) / pxHalfCourtWidthAtNet;
}

function getTanDeg(deg) {
  var rad = (deg * Math.PI) / 180;
  return Math.tan(rad);
}

export function getBallDotCoordinates2dReturn(shot, viewBoxWidth, viewBoxHeight, widthOfBall) {
  const feetToMetres = 0.3048;

  // These percentages have been manually calculated from Figma, but are static
  const startingX = 0.5 * viewBoxWidth;
  const startingY = 0.4094827 * viewBoxHeight;
  const pxFor1m = 0.07887931 * viewBoxHeight;
  const yBaselineMetres = 39 * feetToMetres;

  const xPoint = startingX + shot?.x * pxFor1m;

  const fromY = Math.abs(shot?.y) - yBaselineMetres;
  const yPoint = startingY + fromY * pxFor1m;

  const xCoordinateOnScreen = Math.round(xPoint - widthOfBall / 2);
  const yCoordinateOnScreen = Math.round(yPoint - widthOfBall / 2);

  return {
    top: yCoordinateOnScreen,
    left: xCoordinateOnScreen
  };
}

export function getBallDotCoordinates2dRally(shot, viewBoxWidth, viewBoxHeight, widthOfBall) {
  const feetToMetres = 0.3048;

  // These percentages have been manually calculated from Figma, but are static
  const startingX = 0.5 * viewBoxWidth;
  const startingY = 0.666 * viewBoxHeight;
  const pxFor1m = 0.0548 * viewBoxHeight;
  const yBaselineMetres = 39 * feetToMetres;

  const xPoint = startingX + shot?.x * pxFor1m;

  const fromY = Math.abs(shot?.y) - yBaselineMetres;
  const yPoint = startingY + fromY * pxFor1m;

  const xCoordinateOnScreen = Math.round(xPoint - widthOfBall / 2);
  const yCoordinateOnScreen = Math.round(yPoint - widthOfBall / 2);

  return {
    top: yCoordinateOnScreen,
    left: xCoordinateOnScreen
  };
}

export function isReturnOrRallyPlacement(graphicType) {
  return graphicType === courtGraphicTypes.RETURN_PLACEMENT || graphicType === courtGraphicTypes.RALLY_PLACEMENT;
}

export function isReturnOrRallyPlacementVertical(graphicType, orientation) {
  return (
    (graphicType === courtGraphicTypes.RETURN_PLACEMENT || graphicType === courtGraphicTypes.RALLY_PLACEMENT) &&
    orientation === 'vertical'
  );
}

export function isReturnOrRallyPlacementHorizontal(graphicType, orientation) {
  return (
    (graphicType === courtGraphicTypes.RETURN_PLACEMENT || graphicType === courtGraphicTypes.RALLY_PLACEMENT) &&
    orientation === 'horizontal'
  );
}

export function isServeDirectionOrAccuracy(graphicType) {
  return graphicType === courtGraphicTypes.SERVE_DIRECTION || graphicType === 'serve_accuracy';
}

export function isContactPoint(graphicType) {
  return (
    graphicType === courtGraphicTypes.RETURN_CONTACT_POINT || graphicType === courtGraphicTypes.RALLY_CONTACT_POINT
  );
}

export function getCourtGraphic3d2d(graphicType) {
  switch (graphicType) {
    case courtGraphicTypes.SERVE_DIRECTION:
    case 'serve_accuracy':
    case courtGraphicTypes.RETURN_PLACEMENT:
    case courtGraphicTypes.RALLY_PLACEMENT:
      return '3d';
    case courtGraphicTypes.RETURN_CONTACT_POINT:
    case courtGraphicTypes.RALLY_CONTACT_POINT:
      return '2d';
    default:
      return null;
  }
}
