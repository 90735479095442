export const ACCORDION_TITLE = {
  IN_ATTACK: 'In Attack',
  CONVERSION_SCORE: 'Conversion Score',
  STEAL_SCORE: 'Steal Score',
  SHOT_QUALITY: 'Shot Quality',
  PERFORMANCE_RATING: 'Performance Rating',
  WINNING_AND_LOSING_PLAYS: 'Winning and Losing Plays',
  SERVE_EFFECTIVENESS: 'Serve Effectiveness',
  SHOT_VARIATION: 'Shot Variation',
  BATTLES: 'Battles',
  FAQ_PRESSURE_POINT: 'What is a Pressure Point?',
  FAQ_PLAYER_AND_TOUR_AVERAGES: 'How are the Tour and Player averages calculated?'
};
