import { useRecoilValue } from 'recoil';

import { dataCompletenessStatus } from '_shared/constants/matchTypes';
import { userPermissionTypes } from '_shared/constants/user';
import { userPermissionsState } from '_shared/globalState/atoms';
import { checkPermission } from '_shared/utils/permissions';

import { usePerformanceForMatch } from './matchHooks';

export function useCheckHidePageContent(matchId: string): boolean {
  const { data: performanceData } = usePerformanceForMatch(matchId);
  const { permissions } = useRecoilValue(userPermissionsState);

  const cannotSeeData = !checkPermission(userPermissionTypes.DATA_QUALITY_BANNER_SHOW_DATA, permissions);

  return performanceData?.completeness?.status === dataCompletenessStatus.INCOMPLETE && cannotSeeData;
}
