import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { Box } from '_shared/designSystem/components';
import { isMatchReportState } from '_shared/globalState/atoms';

export default function CourtBallDot(props) {
  const { positionFromTop, positionFromLeft, diameter, surface, courtAngle, displayType } = props;

  const isMatchReport = useRecoilValue(isMatchReportState);

  let background;
  let borderColor;
  let border;
  let shadow = '#667085'; // grey.500
  const innerShadow = '#66708590'; // grey.500
  if (surface === 'clay') shadow = '#9E165F';

  if (courtAngle === '3d') {
    border = '';
    borderColor = '';
    switch (displayType) {
      case 'won':
        background = 'warning.300';
        break;
      case 'lost':
        background = surface === 'clay' ? 'rose.800' : 'rose.500';
        borderColor = surface === 'clay' ? 'rose.900' : 'rose.600';
        break;
      case 'winner':
        background = surface === 'grass' ? 'success.600' : 'success.500';
        break;
      case 'error':
        background = 'white';
        break;
      default:
        background = 'white';
    }
  } else if (courtAngle === '2d') {
    border = '0.5px solid';
    switch (displayType) {
      case 'won':
        background = 'warning.200';
        borderColor = 'warning.300';
        break;
      case 'lost':
        background = surface === 'clay' ? 'rose.800' : 'rose.500';
        borderColor = surface === 'clay' ? 'rose.900' : 'rose.600';
        break;
      case 'winner':
        background = surface === 'grass' ? 'success.700' : 'success.500';
        // success.600 is too prominent, so this is a custom colour approximately equal to 550
        borderColor = surface === 'grass' ? 'success.700' : '#0EB868';
        break;
      case 'error':
        background = 'white';
        borderColor = 'black';
        break;
      default:
        background = 'white';
    }
  }

  if (isMatchReport && courtAngle === '3d') {
    border = '0.5px solid';
    switch (displayType) {
      case 'won':
        background = 'warning.200';
        borderColor = 'warning.300';
        break;
      case 'lost':
        background = surface === 'clay' ? 'rose.800' : 'rose.500';
        borderColor = surface === 'clay' ? 'rose.900' : 'rose.600';
        break;
      case 'winner':
        background = surface === 'grass' ? 'success.700' : 'success.500';
        // success.600 is too prominent, so this is a custom colour approximately equal to 550
        borderColor = surface === 'grass' ? 'success.700' : '#0EB868';
        break;
      case 'error':
        background = 'white';
        borderColor = 'black';
        break;
      default:
        background = 'white';
    }
  }

  return (
    <Box
      data-testid="courtBallDot"
      position="absolute"
      top={`${positionFromTop}px`}
      left={`${positionFromLeft}px`}
      bg={background}
      w={`${diameter}px`}
      h={`${diameter}px`}
      borderRadius="full"
      border={border}
      borderColor={borderColor}
      boxShadow={getBoxShadow(isMatchReport, courtAngle, diameter, shadow, innerShadow)}
      dropShadow={getDropShadow(isMatchReport, courtAngle)}
    />
  );
}

CourtBallDot.propTypes = {
  displayType: PropTypes.string,
  positionFromTop: PropTypes.number.isRequired,
  positionFromLeft: PropTypes.number.isRequired,
  diameter: PropTypes.number.isRequired,
  surface: PropTypes.string
};

CourtBallDot.defaultProps = {
  diameter: 10
};

function getBoxShadow(isMatchReport, courtAngle, diameter, shadow, innerShadow) {
  if (isMatchReport) return null;
  const boxShadow3d = `${0}px ${diameter / 3}px ${diameter / 5}px 0px ${shadow}, inset -${diameter / 8}px -${
    diameter / 8
  }px ${diameter / 5}px ${innerShadow}`;

  const boxShadow2d = ``;

  if (courtAngle === '3d') return boxShadow3d;
  return boxShadow2d;
}

function getDropShadow(isMatchReport, courtAngle) {
  if (isMatchReport && courtAngle === '3d') {
    return;
  }
}
