import isEmpty from 'lodash/isEmpty';

export function getSetButtonData(numberOfSets) {
  const allSetsButtonData = [
    {
      label: '1',
      value: 1,
      position: 'left'
    },
    {
      label: '2',
      value: 2,
      position: 'middle'
    },
    {
      label: '3',
      value: 3,
      position: 'middle'
    },
    {
      label: '4',
      value: 4,
      position: 'middle'
    },
    {
      label: '5',
      value: 5,
      position: 'right'
    }
  ];
  if (numberOfSets <= 0) return null;
  if (numberOfSets === 1)
    return [
      {
        label: '1',
        value: 1,
        position: 'singleButton'
      }
    ];
  const buttonData = allSetsButtonData.slice(0, numberOfSets);
  if (buttonData.length > 0) buttonData[buttonData.length - 1].position = 'right';
  return buttonData;
}

export function filterBySet(data, currentSetFilter) {
  if (isEmpty(data)) return [];
  if (!currentSetFilter || currentSetFilter === 'all') return data;
  return data.filter((item) => item.set === parseInt(currentSetFilter));
}
