import { displayScore, zeroOrMore } from 'match/utils/transformations';
import PropTypes from 'prop-types';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Icon, Text } from '_shared/designSystem/components';
import { Box, Flex, HorizontalBar } from '_shared/designSystem/components';
import { getComponentWithExtraProps } from '_shared/utils/componentUtils';

import { leftColumnContentWidth } from '../performanceMargins';

export const MomentumBattle = ({ data, currentScoreRangeFilter, player1Name, player2Name }) => {
  return (
    <Flex direction="column" gap={4} w={{ base: '100%', sm: `${leftColumnContentWidth}px` }}>
      <Flex justify="space-between" w="100%">
        <Text fontSize="sm" fontWeight="semibold">
          {player1Name}
        </Text>
        <Text fontSize="sm" fontWeight="semibold">
          {player2Name}
        </Text>
      </Flex>
      {getComponentWithExtraProps(customSitesConfig.momentumComponent, {
        frequency: data?.momentum?.frequency,
        leftPercentage: data?.momentum?.player1?.score,
        rightPercentage: data?.momentum?.player2?.score,
        data,
        currentScoreRangeFilter
      })}
    </Flex>
  );
};

export default function Momentum(props) {
  const {
    frequency,
    leftPercentage,
    rightPercentage,
    currentScoreRangeFilter,
    data,
    bgColor,
    bgBorderColor,
    labelColor,
    greaterColor,
    lessColor
  } = props;
  return (
    <Flex
      borderRadius="lg"
      bg={bgColor}
      justify="center"
      pb={6}
      pt={4}
      border={bgBorderColor ? '1px solid' : null}
      borderColor={bgBorderColor}
    >
      <Flex direction="column" w="90%">
        <Box>
          <HorizontalBar
            label="Momentum"
            labelColor={labelColor}
            labelSize="md"
            barHeight="34px"
            frequency={frequency}
            leftPercentage={leftPercentage}
            rightPercentage={rightPercentage}
            greaterColor={greaterColor}
            lessColor={lessColor}
          />
        </Box>
        {!currentScoreRangeFilter && (
          <MomentumChange data={data} currentScoreRangeFilter={currentScoreRangeFilter} labelColor={labelColor} />
        )}
      </Flex>
    </Flex>
  );
}

Momentum.propTypes = {
  frequency: PropTypes.number,
  leftPercentage: PropTypes.number,
  rightPercentage: PropTypes.number,
  currentScoreRangeFilter: PropTypes.object,
  data: PropTypes.object,
  bgColor: PropTypes.string,
  bgBorderColor: PropTypes.string,
  labelColor: PropTypes.string,
  greaterColor: PropTypes.string,
  lessColor: PropTypes.string
};

Momentum.defaultProps = {
  bgColor: 'secondary.800',
  bgBorderColor: 'secondary.800',
  labelColor: 'white',
  greaterColor: 'primary.600',
  lessColor: 'primary.200'
};

export const MomentumChange = ({ data, labelColor }) => {
  if (!zeroOrMore(data?.momentum?.player1?.change?.score)) return null;
  return (
    <Flex alignItems="center" h={6} justify="space-between" pt={2}>
      <MomentumChangeArrow
        alignment="left"
        score={displayScore(data?.momentum?.player1?.change?.score, data?.momentum?.player1?.change?.score_type)}
        direction={data?.momentum?.player1?.change?.direction}
      />
      <Text fontSize="sm" color={labelColor} mt={{ base: 4, sm: 0 }} textAlign="center">
        {data?.momentum?.player1?.change?.type === 'change_from_previous_game'
          ? 'Previous Game Change'
          : 'Current Game Change'}
      </Text>
      <MomentumChangeArrow
        alignment="right"
        score={displayScore(data?.momentum?.player2?.change?.score, data?.momentum?.player2?.change?.score_type)}
        direction={data?.momentum?.player2?.change?.direction}
      />
    </Flex>
  );
};

export const MomentumChangeArrow = ({ score, direction, alignment }) => {
  const up = direction === 'increase';
  let color = up ? 'success.400' : 'error.500';
  if (!direction) color = 'grey.300';
  return (
    <Flex w={20} justify={alignment === 'right' ? 'flex-end' : 'flex-start'}>
      <Flex>
        {direction === 'increase' || direction === 'decrease' ? (
          <Icon color={color} height={4} width={4} name={up ? 'arrowUp' : 'arrowDown'} />
        ) : null}
        <Text color={color} fontSize="sm" lineHeight="16px">
          {score}
        </Text>
      </Flex>
    </Flex>
  );
};
