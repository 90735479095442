import PageHeading from 'matchReport/components/_shared/PageHeading';
import PropTypes from 'prop-types';
import ScoutMatchSelectorTable from 'scout/components/matchSelector/ScoutMatchSelectorTable';
import PageLayout, { scoutReportPageWidth } from 'scoutReport/components/PageLayout';

import { Box } from '_shared/designSystem/components';

export default function MatchesPage({ data, playerName, page }) {
  const columnHeadings = [
    'Date',
    'Tournament',
    'Opponent',
    'Result',
    'Score',
    'Round',
    'Surface',
    'Opp. Rank',
    'Opp. Hand'
  ];

  return (
    <PageLayout playerName={playerName}>
      <Box maxW={scoutReportPageWidth}>
        {page === 0 && <PageHeading>Report Matches</PageHeading>}
        <Box mt={page > 0 ? '40px' : 0}>
          <ScoutMatchSelectorTable data={data} columnHeadings={columnHeadings} />
        </Box>
      </Box>
    </PageLayout>
  );
}

MatchesPage.propTypes = {
  data: PropTypes.array,
  playerName: PropTypes.string,
  page: PropTypes.number
};
