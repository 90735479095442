export const VIEW_OPTION_VALUES = {
  FIFTY_TWO_WEEK_AVERAGES: 'fiftyTwoWeekAverages',
  HEAD_TO_HEAD_MATCHES_AVERAGES: 'headToHeadMatchAverages'
};

export const GENDER_OPTION_VALUES = {
  FEMALE: 'female',
  MALE: 'male'
};

const GENDER_OPTION_LABELS_DEFAULT = {
  WOMEN: 'Women',
  MEN: 'Men'
};

const GENDER_OPTION_LABELS_WIMBLEDON = {
  LADIES: 'Ladies',
  GENTLEMEN: 'Gentlemen'
};

const POSITION = {
  LEFT: 'left',
  RIGHT: 'right'
};

export const PLAYER_OPTION_VALUES = {
  PLAYER1: 'player1',
  PLAYER2: 'player2'
};

export const VIEW_OPTIONS = [
  { value: VIEW_OPTION_VALUES.FIFTY_TWO_WEEK_AVERAGES, label: '52-week Averages', position: POSITION.LEFT },
  {
    value: VIEW_OPTION_VALUES.HEAD_TO_HEAD_MATCHES_AVERAGES,
    label: 'Head-to-head Match Averages',
    position: POSITION.RIGHT
  }
];

export const GENDER_OPTIONS_DEFAULT = [
  { value: GENDER_OPTION_VALUES.FEMALE, label: GENDER_OPTION_LABELS_DEFAULT.WOMEN, position: POSITION.LEFT },
  { value: GENDER_OPTION_VALUES.MALE, label: GENDER_OPTION_LABELS_DEFAULT.MEN, position: POSITION.RIGHT }
];

export const GENDER_OPTIONS_WIMB = [
  { value: GENDER_OPTION_VALUES.FEMALE, label: GENDER_OPTION_LABELS_WIMBLEDON.LADIES, position: POSITION.LEFT },
  { value: GENDER_OPTION_VALUES.MALE, label: GENDER_OPTION_LABELS_WIMBLEDON.GENTLEMEN, position: POSITION.RIGHT }
];

export const ERROR_MESSAGE = 'An error has occured displaying the data. Please try refreshing the page.';

export const PROFILE_POSITION = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right'
};

export const HEAD_TO_HEAD_MATCHES_AVERAGES_TOOLTIP_NO_TRACKING_DATA =
  "We do not have ball and player tracking data for these players' head-to-head matches";
