import { useState } from 'react';

import { matchReportService } from 'matchReport/services/matchReportService';
import { BarLoader } from 'react-spinners';

import { getReportBucketUrl } from '_shared/dataFetching/urlConfig';
import {
  Text,
  Box,
  VStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ErrorMessage
} from '_shared/designSystem/components';
import { logBasicMessage } from '_shared/errors/log';

export const MatchReportModal = ({ isOpen, onClose, heading, matchId, company }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [createReportError, setCreateReportError] = useState(null);
  const [matchReportExists, setMatchReportExists] = useState(false);

  const fileName = `Match_Report_${company?.toUpperCase()}_${matchId}_${getCurrentDateInUtc()}.pdf`;
  const bucketUrl = getReportBucketUrl('match');
  const matchReportUrl = `${bucketUrl}Match_Report_${company?.toUpperCase()}_${matchId}_${getCurrentDateInUtc()}.pdf`;

  async function handleReportFlow() {
    setCreateReportError(false);
    logBasicMessage('INFO', `User started to download match report ${matchId}`);
    let retry = false;
    try {
      setIsDownloading(true);
      await matchReportService.createMatchReport({ matchId, company });
      setIsDownloading(false);
      setMatchReportExists(true);
      openReport();
    } catch (error) {
      retry = true;
    }
    if (retry) {
      logBasicMessage('INFO', `First attempt at report generation failed for ${matchId}, retrying`);
      try {
        setIsDownloading(true);
        await matchReportService.createMatchReport({ matchId, company });
        setIsDownloading(false);
        setMatchReportExists(true);
        openReport();
      } catch (error) {
        setIsDownloading(false);
        setCreateReportError(true);
        let sendingError = `Error downloading match report: ${error} `;
        if (error.request) sendingError += ` | Request: ${JSON.stringify(error.request)} `;
        if (error.response) sendingError += ` | Response: ${JSON.stringify(error.response)} `;
        logBasicMessage('ERROR', sendingError);
      }
    }
  }

  const openReport = () => {
    setCreateReportError(false);
    const link = document.createElement('a');
    link.href = matchReportUrl;
    link.target = '_blank';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    logBasicMessage('INFO', `User successfully downloaded match report ${fileName}`);
  };

  const showDownloadMessage = () => {
    if (matchReportExists) return <Button onClick={() => openReport()}>Download Report</Button>;
    else if (isDownloading) {
      return (
        <VStack justify="center">
          <Text mb={3} fontSize="sm" fontWeight="normal" color="grey.700">
            Generating Match Report
          </Text>
          <BarLoader height="8px" width="300px" color="#96BF32" />
        </VStack>
      );
    } else return <Button onClick={() => handleReportFlow()}>Report</Button>;
  };

  const closeModal = () => {
    setCreateReportError(null);
    setIsDownloading(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} size="lg" onClose={() => closeModal()}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Box p={6}>
          <VStack gap={10}>
            <Text fontSize="xl" fontWeight="semibold" color="grey.700">
              Match Report
            </Text>
            <Text fontSize="md" fontWeight="normal" color="grey.700">
              {heading}
            </Text>
            {createReportError && (
              <ErrorMessage message="An error occured when downloading the report, please try again" />
            )}
            <Box h="70px">{showDownloadMessage()}</Box>
          </VStack>
        </Box>
      </ModalContent>
    </Modal>
  );
};

const getCurrentDateInUtc = () => {
  return new Date().toISOString().split('T')[0];
};
