import { useEffect, useState } from 'react';

import { showScrollUpArrowDepth } from '_layouts/margins';

import { Icon, Center, useBreakpointValue } from '_shared/designSystem/components';

export default function ScrollToTopArrow() {
  const [scrollY, setScrollY] = useState(0);

  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false
    },
    { ssr: false }
  );

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!isMobile || scrollY < showScrollUpArrowDepth) return null;

  return <ScrollIcon />;
}

export const ScrollIcon = () => (
  <Center
    cursor="pointer"
    w="40px"
    h="40px"
    borderRadius="full"
    border="1px solid"
    bg="white"
    borderColor="primary.500"
    position="fixed"
    bottom={7}
    right={5}
    onClick={() => window.scrollTo(0, 0)}
  >
    <Icon height={8} width={8} name="chevronUp" color="primary.500" />
  </Center>
);
