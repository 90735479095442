import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { searchByString } from '_shared/utils/stringUtil';

export function getSeasonsData(data) {
  if (isEmpty(data)) return [];
  const result = [];
  data.forEach((element) => {
    const season = element.season;
    result.push(season?.toString());
  });
  return uniq(result);
}

export const getFilteredTournaments = (tournaments, searchSeason, tournamentName) => {
  const filterSections = (sections) =>
    sections
      .map((section) => ({
        ...section,
        tournaments: searchByString(section.tournaments, tournamentName, 'name')
      }))
      .filter((section) => section.tournaments.length > 0);

  const filterYears = (years) =>
    years
      .map((year) => ({
        ...year,
        sections: filterSections(year.sections)
      }))
      .filter((year) => year.sections.length > 0);

  if (searchSeason === 'all') {
    return filterYears(tournaments);
  } else {
    const seasonTournaments = tournaments.find((tournament) => tournament.season === Number(searchSeason));

    return seasonTournaments ? filterSections(seasonTournaments.sections) : [];
  }
};
