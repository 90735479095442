import { Flex, Icon } from '_shared/designSystem/components';

const PlaylistControlsDesktop = ({ handlePlayPrev, handlePlayNext, disablePrev, disableNext }) => {
  return (
    <Flex gap={2}>
      <ControlButton
        onClick={handlePlayPrev}
        iconName="playPrevious"
        disabled={disablePrev}
        color="black"
        iconLabel="skip-previous"
      />
      <ControlButton
        onClick={handlePlayNext}
        iconName="playNext"
        disabled={disableNext}
        color="black"
        iconLabel="skip-next"
      />
    </Flex>
  );
};

const ControlButton = ({ onClick, iconName, iconLabel, disabled, color }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    borderRadius="3"
    cursor={disabled ? null : 'pointer'}
    opacity={disabled ? 0.5 : 1}
  >
    <Icon
      name={iconName}
      color={color}
      height={{ base: 5, md: 6 }}
      width={{ base: 5, md: 6 }}
      onClick={onClick}
      data-testid={`${iconLabel}-button`}
    />
  </Flex>
);

export default PlaylistControlsDesktop;
