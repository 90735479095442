import { Slider as ChakraSlider, SliderTrack, SliderFilledTrack, SliderThumb } from '@chakra-ui/react';

export default function Slider(props) {
  return (
    <ChakraSlider data-testid="slider" aria-label="slider-1" {...props}>
      <SliderTrack bg="primary.100">
        <SliderFilledTrack bg="primary.600" />
      </SliderTrack>
      <SliderThumb />
    </ChakraSlider>
  );
}

Slider.defaultProps = {
  value: 50,
  onChange: () => console.log('slider changed')
};
