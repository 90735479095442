import React from 'react';

import { BARS_OPACITY } from 'match/constants/winningAndLosingPlaysConstants';
import { displayScore } from 'match/utils/transformations';
import { determineColor, getFontWeight } from 'match/utils/winningAndLosingPlaysUtil.js';
import PropTypes from 'prop-types';

import { Text, VStack, HStack, Flex, useBreakpointValue } from '_shared/designSystem/components';

const barId = {
  WINNING: 'winning',
  LOSING: 'losing',
  EFFECTIVENESS: 'effectiveness'
};

const EffectivenessBarGraphic = ({ data, isMatchPage, volumeType, selectedPlayType }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <VStack spacing={2} width="100%">
      {data.map((item, index) => (
        <HStack key={index} width="100%">
          <Text
            fontSize="sm"
            width="70px"
            whiteSpace="nowrap"
            fontWeight={getFontWeight(item.label, volumeType, selectedPlayType)}
          >
            {item.label === 'Effectiveness' ? 'Impact' : item.label}
          </Text>
          <Flex flex="1" position="relative">
            <Flex width="50%" justify="flex-end">
              {/* Losing bar & negative effectiveness bar */}
              {(item.id === barId.LOSING || (item.id === barId.EFFECTIVENESS && item.score < 0)) && (
                <Flex
                  width={`${Math.abs(item.score)}%`}
                  height={isMatchPage ? '20px' : '40px'}
                  bg={determineColor(item)}
                  aria-label="bar-chart-bar-left"
                  justify="flex-end"
                  opacity={BARS_OPACITY}
                />
              )}
            </Flex>
            <Flex width="50%">
              {/* Winning bar & positive effectiveness bar */}
              {(item.id === barId.WINNING || (item.id === barId.EFFECTIVENESS && item.score > 0)) && (
                <Flex
                  width={`${Math.abs(item.score)}%`}
                  height={isMatchPage ? '20px' : '40px'}
                  bg={determineColor(item)}
                  aria-label="bar-chart-bar-right"
                  opacity={BARS_OPACITY}
                />
              )}
            </Flex>
          </Flex>
          <Flex
            w="80px"
            align="center"
            justifyContent="flex-end"
            gap={2}
            fontWeight={getFontWeight(item.label, volumeType, selectedPlayType)}
          >
            {item.score !== null && (
              <Text fontSize={isMobile ? 'xs' : 'sm'}>
                {item.id === barId.EFFECTIVENESS && item.score > 0 && '+'}
                {displayScore(item.score, item.score_type)}
              </Text>
            )}
            {item.frequency !== null && (
              <Text fontSize="xs" textColor="grey.500">
                ({item.frequency})
              </Text>
            )}
          </Flex>
        </HStack>
      ))}
    </VStack>
  );
};

EffectivenessBarGraphic.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      frequency: PropTypes.number,
      score: PropTypes.number,
      score_type: PropTypes.string
    })
  ).isRequired,
  showLabels: PropTypes.bool
};

export default EffectivenessBarGraphic;
