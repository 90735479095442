import { Flex } from '_shared/designSystem/components';
import { isAtpMediaPortal, isAtpTourPortal, isTvPortal, isWimbPortal } from '_shared/utils/environment/currentEnv';

import Logo from '../logo/Logo';

export default function SideMenuLogo() {
  if (isTvPortal() || isAtpMediaPortal()) {
    return (
      <Flex align="center" pl={3} bg="secondary.800" w="100px">
        <Logo width="110px" height="50px" />
      </Flex>
    );
  }
  if (isWimbPortal()) {
    return (
      <Flex align="center" justify="center" bg="grey.25" pt="20px">
        <Logo width="60px" height="60px" />
      </Flex>
    );
  }
  if (isAtpTourPortal()) {
    return (
      <Flex w="100%" h="40px" bg="darkNavy" pl={3} align="center">
        <Logo width="150px" type="horizontal" color="white" />
      </Flex>
    );
  }
}
