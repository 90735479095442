import { PROFILE_POSITION } from 'headToHead/constants/headToHeadConstants';

import { Box, Flex, Text } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import { capitaliseString } from '_shared/utils/stringUtil';

const ProfileSection = ({ player1, player2 }) => {
  const { ranking, age, handedness } = player1 ?? {};
  const { ranking: ranking2, age: age2, handedness: handedness2 } = player2 ?? {};

  return (
    <Flex
      backgroundColor="white"
      alignItems="center"
      justify="center"
      boxShadow={isWimbPortal() ? 'none' : { base: 'none', md: 'md' }}
      pb="20px"
      w="100%"
    >
      <Box>
        <Text fontWeight="bold" fontSize={{ base: 'md', sm: 'lg', md: 'lg', xl: 'xl' }} textAlign="center">
          Profile
        </Text>
        <Flex justify="space-between" w={{ base: '300px', sm: '320px' }}>
          <Flex display="column" w="100px">
            <ProfileTextSection position={PROFILE_POSITION.LEFT} data={ranking ?? null} />
            <ProfileTextSection position={PROFILE_POSITION.LEFT} data={age ?? null} />
            <ProfileTextSection
              position={PROFILE_POSITION.LEFT}
              data={handedness ? capitaliseString(handedness) : null}
            />
          </Flex>
          <Flex display="column" align="center">
            <ProfileTextSection position={PROFILE_POSITION.CENTER} data="Ranking" />
            <ProfileTextSection position={PROFILE_POSITION.CENTER} data="Age" />
            <ProfileTextSection position={PROFILE_POSITION.CENTER} data="Playing Hand" />
          </Flex>
          <Flex display="column" w="100px">
            <ProfileTextSection position={PROFILE_POSITION.RIGHT} data={ranking2 ?? null} />
            <ProfileTextSection position={PROFILE_POSITION.RIGHT} data={age2 ?? null} />
            <ProfileTextSection
              position={PROFILE_POSITION.RIGHT}
              data={handedness ? capitaliseString(handedness2) : null}
            />
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ProfileSection;

const ProfileTextSection = ({ data, position }) => {
  return (
    <Text pt={3} textAlign={position} fontSize={{ base: 'sm', sm: 'md' }}>
      {data}
    </Text>
  );
};
