import isEmpty from 'lodash/isEmpty';
import AverageBars from 'match/_shared/AverageBars';
import { BREAKDOWN_METRICS_TYPES } from 'match/_shared/matchConstants';
import { Averages } from 'match/components/_shared/Filtering';
import BreakdownMetrics from 'match/components/performance/breakdownMetrics/BreakdownMetrics';
import { convertScoreToPercentage, displayScore } from 'match/utils/transformations';
import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';

import HeadingWithTooltip from '_shared/components/HeadingWithTooltip';
import { metricScoreType } from '_shared/constants/metricTypes';
import {
  Box,
  Flex,
  Text,
  HorizontalBarTriple,
  CircularProgress,
  HorizontalBar,
  Alert,
  Collapse,
  Legend
} from '_shared/designSystem/components';
import useFeature from '_shared/utils/hooks/useFeature';

export default function ScoutInsights(props) {
  const {
    dataNoAverages,
    dataWithAverages,
    isMatchReport,
    showAverages,
    handleSetShowAverages,
    performanceRatingNoAveragesData,
    performanceRatingWithAveragesData,
    performanceRatingNoAveragesIsLoading
  } = props;

  const shotVariationEnabled = useFeature('shotVariation');
  const serveEffectivenessEnabled = useFeature('serveEffectiveness');

  if (isEmpty(dataNoAverages)) return null;

  if (isEmpty(dataNoAverages?.player_card))
    return <Alert status="info" message="No data found, please try broadening your search"></Alert>;

  return (
    <Box pb="50px" px={{ base: 0, lg: '1px' }}>
      {!isMatchReport && (
        <Box py={1}>
          <Averages showAverages={showAverages} setShowAverages={handleSetShowAverages} />
        </Box>
      )}
      {isMatchReport && (
        <Box py="20px">
          <Legend
            fields={[
              {
                label: 'Player Avg',
                color: 'playerAverage'
              },
              {
                label: 'Tour Avg',
                color: 'tourAverage'
              }
            ]}
          />
        </Box>
      )}
      <Flex justify="center">
        <Flex
          mt={4}
          direction="row"
          flexWrap={isMatchReport ? null : 'wrap'}
          gap="100px"
          justify="center"
          flex={1}
          maxW={isMatchReport ? '1000px' : '1250px'}
        >
          <Flex direction="column" maxW="500px" minW="300px" w="100%" gap="50px">
            <Box>
              <HorizontalBarTriple
                heading="In Attack"
                leftPercentage={dataNoAverages.attack.attacking.score}
                middlePercentage={dataNoAverages.attack.neutral.score}
                rightPercentage={dataNoAverages.attack.defending.score}
                leftLabel="Attacking"
                middleLabel="Neutral"
                rightLabel="Defending"
                tooltipText="In Attack shows the percentage of shots played in attack, neutral and defence"
                isMatchReport={isMatchReport}
              />
              <Box mt={2}>
                <AveragesBarsBattles
                  {...props}
                  leftData={dataWithAverages?.attack?.attacking}
                  rightData={dataWithAverages?.attack?.defending}
                  showAverages={showAverages}
                />
              </Box>
              {isMatchReport && (
                <Text textAlign="center" fontSize="xs" mt={7}>
                  In Attack shows the percentage of shots played in attack, neutral and defence
                </Text>
              )}
            </Box>
            {!isMatchReport && serveEffectivenessEnabled && (
              <Box maxW="500px">
                <Flex justify="center">
                  <BreakdownMetrics
                    {...props}
                    graphicType={BREAKDOWN_METRICS_TYPES.SERVE_EFFECTIVENESS}
                    dataNoAverages={dataNoAverages?.serve_effectiveness}
                    dataWithAverages={dataWithAverages?.serve_effectiveness}
                    players={null}
                    showAverages={showAverages}
                    isScout
                  />
                </Flex>
              </Box>
            )}
            <Box>
              <Flex direction="column" gap="30px">
                <HorizontalBar
                  label="Baseline Battle"
                  frequency={dataNoAverages?.baseline?.baseline_battle?.frequency}
                  leftPercentage={dataNoAverages?.baseline?.baseline_battle?.won?.score}
                  rightPercentage={dataNoAverages?.baseline?.baseline_battle?.lost?.score}
                  leftLabel="Won"
                  rightLabel="Lost"
                  greaterColor="primary.500"
                  lessColor="grey.200"
                />
                <AveragesBarsBattles
                  {...props}
                  leftData={dataWithAverages?.baseline?.baseline_battle?.won}
                  rightData={dataWithAverages?.baseline?.baseline_battle?.lost}
                  showAverages={showAverages}
                />
                <HorizontalBar
                  label="Player at Net"
                  frequency={dataNoAverages?.baseline?.player_at_net?.frequency}
                  leftPercentage={dataNoAverages?.baseline?.player_at_net?.won?.score}
                  rightPercentage={dataNoAverages?.baseline?.player_at_net?.lost?.score}
                  leftLabel="Won"
                  rightLabel="Lost"
                  greaterColor="primary.500"
                  lessColor="grey.200"
                />
                <AveragesBarsBattles
                  {...props}
                  leftData={dataWithAverages?.baseline?.player_at_net?.won}
                  rightData={dataWithAverages?.baseline?.player_at_net?.lost}
                  showAverages={showAverages}
                />
                <HorizontalBar
                  label="Opponent at Net"
                  frequency={dataNoAverages?.baseline?.opponent_at_net?.frequency}
                  leftPercentage={dataNoAverages?.baseline?.opponent_at_net?.won?.score}
                  rightPercentage={dataNoAverages?.baseline?.opponent_at_net?.lost?.score}
                  leftLabel="Won"
                  rightLabel="Lost"
                  greaterColor="primary.500"
                  lessColor="grey.200"
                />
                <AveragesBarsBattles
                  {...props}
                  leftData={dataWithAverages?.baseline?.opponent_at_net?.won}
                  rightData={dataWithAverages?.baseline?.opponent_at_net?.lost}
                  showAverages={showAverages}
                />
              </Flex>
              {isMatchReport && (
                <Text textAlign="center" fontSize="xs" mt="30px">
                  Battles show the situation the player was in when the point finished and the percentage of points they
                  won in this situation
                </Text>
              )}
            </Box>
          </Flex>
          <Flex direction="column" maxW="400px" gap={isMatchReport ? 10 : 4}>
            {!isMatchReport && (
              <Box>
                <Box>
                  <HeadingWithTooltip
                    heading="Performance Rating"
                    tooltipText="Performance Rating combines In Attack, Conversion, Steal and Shot Quality to show the overall performance level"
                    isMatchReport={isMatchReport}
                  />
                  {!isMatchReport && performanceRatingNoAveragesIsLoading ? (
                    <Flex justify="center" h="120px" align="center">
                      <BeatLoader size={6} color="grey" />
                    </Flex>
                  ) : (
                    <Flex justify="center">
                      <CircleWithAverage
                        {...props}
                        dataNoAveragesSpecific={performanceRatingNoAveragesData?.performance_rating}
                        dataWithAveragesSpecific={performanceRatingWithAveragesData?.performance_rating}
                        showAverages={showAverages}
                      />
                    </Flex>
                  )}
                </Box>
                {isMatchReport && (
                  <Flex justify="center">
                    <Flex direction="column" gap={3} pt="30px">
                      <Text textAlign="center" fontSize="xs" w="300px">
                        Performance Rating combines In Attack, Conversion, Steal and Shot Quality to show the overall
                        performance level
                      </Text>
                    </Flex>
                  </Flex>
                )}
              </Box>
            )}
            <Box>
              <Box>
                <HeadingWithTooltip
                  heading="Attack and Defence"
                  tooltipText="How often a player has won the point when attacking (Conversion) or defending (Steal)"
                  isMatchReport={isMatchReport}
                />
                <Flex justify="center">
                  <Flex gap="40px">
                    <CircleWithAverage
                      label="Conversion"
                      {...props}
                      dataNoAveragesSpecific={dataNoAverages?.attack_and_defence?.conversion}
                      dataWithAveragesSpecific={dataWithAverages?.attack_and_defence?.conversion}
                      showAverages={showAverages}
                    />
                    <CircleWithAverage
                      label="Steal"
                      {...props}
                      dataNoAveragesSpecific={dataNoAverages?.attack_and_defence?.steal}
                      dataWithAveragesSpecific={dataWithAverages?.attack_and_defence?.steal}
                      showAverages={showAverages}
                    />
                  </Flex>
                </Flex>
              </Box>
              {isMatchReport && (
                <Flex justify="center">
                  <Flex direction="column" gap={3} pt="30px">
                    <Text textAlign="center" fontSize="xs" w="300px">
                      Conversion Score calculates how often a player has won a point when attacking
                    </Text>
                    <Text textAlign="center" fontSize="xs" w="300px">
                      Steal Score calculates how often a player has won the point when defending
                    </Text>
                  </Flex>
                </Flex>
              )}
            </Box>
            <Box>
              <Box>
                <HeadingWithTooltip
                  heading="Shot Quality"
                  tooltipText="The quality of the player's four main shots on a 0-10 scale"
                  isMatchReport={isMatchReport}
                />
                <Flex align="center" direction="column" gap={4}>
                  <Flex gap="40px">
                    <CircleWithAverage
                      label="Serve"
                      {...props}
                      dataNoAveragesSpecific={dataNoAverages?.player_card?.shot_quality?.serve}
                      dataWithAveragesSpecific={dataWithAverages?.player_card?.shot_quality?.serve}
                      showAverages={showAverages}
                    />
                    <CircleWithAverage
                      label="Return"
                      {...props}
                      dataNoAveragesSpecific={dataNoAverages?.player_card?.shot_quality?.return}
                      dataWithAveragesSpecific={dataWithAverages?.player_card?.shot_quality?.return}
                      showAverages={showAverages}
                    />
                  </Flex>
                  {isMatchReport && <Box h="10px" w="1px" />}
                  <Flex gap="40px">
                    <CircleWithAverage
                      label="Forehand"
                      {...props}
                      dataNoAveragesSpecific={dataNoAverages?.player_card?.shot_quality?.forehand}
                      dataWithAveragesSpecific={dataWithAverages?.player_card?.shot_quality?.forehand}
                      showAverages={showAverages}
                    />
                    <CircleWithAverage
                      label="Backhand"
                      {...props}
                      dataNoAveragesSpecific={dataNoAverages?.player_card?.shot_quality?.backhand}
                      dataWithAveragesSpecific={dataWithAverages?.player_card?.shot_quality?.backhand}
                      showAverages={showAverages}
                    />
                  </Flex>
                </Flex>
              </Box>
              {isMatchReport && (
                <Flex justify="center">
                  <Text textAlign="center" fontSize="xs" w="320px" pt="30px">
                    Shot Quality measures the quality of the player's serve, return, forehand and backhand on a 0-10
                    scale. Shot Quality is calculated by analysing the speed, spin, depth and width of every shot
                  </Text>
                </Flex>
              )}
            </Box>
            {!isMatchReport && shotVariationEnabled && (
              <Box mt="50px">
                <BreakdownMetrics
                  {...props}
                  graphicType={BREAKDOWN_METRICS_TYPES.SHOT_VARIATION}
                  dataNoAverages={dataNoAverages?.variation}
                  dataWithAverages={dataWithAverages?.variation}
                  players={null}
                  showAverages={showAverages}
                  isScout
                />
              </Box>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

ScoutInsights.propTypes = {
  dataNoAverages: PropTypes.object,
  dataWithAverages: PropTypes.object,
  averagesLoading: PropTypes.bool,
  isMatchReport: PropTypes.bool,
  showAverages: PropTypes.bool,
  handleSetShowAverages: PropTypes.func,
  performanceRatingNoAveragesData: PropTypes.object,
  performanceRatingWithAveragesData: PropTypes.object,
  performanceRatingNoAveragesIsLoading: PropTypes.bool
};

ScoutInsights.defaultProps = {
  isMatchReport: false
};

const CircleWithAverage = ({
  label,
  dataNoAveragesSpecific,
  dataWithAveragesSpecific,
  showAverages,
  averagesLoading
}) => (
  <Flex direction="column" gap={2}>
    <CircularProgress
      percentage={
        [metricScoreType.OUT_OF_TEN, metricScoreType.DECIMAL_TWO_PLACES].includes(dataNoAveragesSpecific?.score_type)
          ? convertScoreToPercentage(dataNoAveragesSpecific?.score, dataNoAveragesSpecific?.score_type)
          : dataNoAveragesSpecific?.score
      }
      topLabel={label}
      centreLabel={displayScore(dataNoAveragesSpecific?.score, dataNoAveragesSpecific?.score_type)}
    />
    <Collapse in={showAverages} animateOpacity>
      <AverageBars labelPosition="right" data={dataWithAveragesSpecific} isLoading={averagesLoading} />
    </Collapse>
  </Flex>
);

const AveragesBarsBattles = ({ leftData, rightData, averagesLoading, showAverages }) => (
  <Collapse in={showAverages} animateOpacity>
    <Flex justify="space-between" mt={2}>
      <Box w="145px">
        <AverageBars labelPosition="left" data={leftData} isLoading={averagesLoading} />
      </Box>
      <Box w="145px">
        <AverageBars labelPosition="right" data={rightData} isLoading={averagesLoading} />
      </Box>
    </Flex>
  </Collapse>
);
