export const formFieldTypes = {
  TEXT: 'text',
  NUMBER: 'number',
  SELECT_SINGLE: 'selectSingle',
  SELECT_MULTI: 'selectMulti',
  BOOLEAN: 'boolean',
  SUBMIT_BUTTON: 'submitButton',
  DATE: 'date',
  RESET_BUTTON: 'resetButton',
  CUSTOM_COMPONENT: 'customComponent',
  BUTTON_GROUP: 'buttonGroup'
};
