export const getPlayersButtons = (data) => {
  return [
    {
      label: data?.player_details?.player1?.member1?.last_name,
      value: 'player1',
      position: 'left'
    },
    {
      label: data?.player_details?.player2?.member1?.last_name,
      value: 'player2',
      position: 'right'
    }
  ];
};
