import PropTypes from 'prop-types';

import { Box, Flex, Text, Divider, Select, useBreakpointValue } from '_shared/designSystem/components';

export default function SecondaryNavBar(props) {
  const { items, activeItem, onSelectItem } = props;
  const windowSizeIsMd = useBreakpointValue({ md: true });

  const handleChangeSelect = (event) => {
    onSelectItem(event.target.value);
  };

  return (
    <Box>
      {windowSizeIsMd ? (
        <Flex>
          {items.map((item) => (
            <MenuItem
              label={item.label}
              key={item.value}
              value={item.value}
              isSelected={activeItem === item.value}
              {...props}
            />
          ))}
        </Flex>
      ) : (
        <Select defaultValue={activeItem} size="sm" maxW="300px" onChange={(event) => handleChangeSelect(event)}>
          {items.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </Select>
      )}
    </Box>
  );
}

SecondaryNavBar.propTypes = {
  items: PropTypes.array.isRequired,
  activeItem: PropTypes.string,
  onSelectItem: PropTypes.func
};

export function MenuItem(props) {
  const { label, isSelected, value, onSelectItem } = props;

  const color = isSelected ? 'primary.700' : 'grey.500';

  return (
    <Box cursor="pointer" onClick={() => onSelectItem(value)}>
      <Box px={3}>
        <Text mb={3} fontSize="sm" _hover={{ color: 'primary.800' }} fontWeight="medium" color={color}>
          {label}
        </Text>
      </Box>
      <Divider />
      {isSelected && (
        <Box>
          <Divider variant="menuSelected" />
        </Box>
      )}
    </Box>
  );
}
