import sum from 'lodash/sum';
import { convertLabelName } from 'match/utils/transformations';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Box, Flex, Text, PieChart, Legend, AspectRatio } from '_shared/designSystem/components';
import { isMatchReportState } from '_shared/globalState/atoms';

const WinnersAndUnforcedErrors = (props) => {
  const { data } = props;

  return (
    <Box pt={7}>
      <Flex align="center" pb={8}>
        <PlayerMetrics player="player1" data={data} />
        <PlayerMetrics player="player2" data={data} />
      </Flex>
      <Flex justify="center">
        <Legend fields={customSitesConfig.pieChartLegend} direction="horizontal" />
      </Flex>
    </Box>
  );
};

export default WinnersAndUnforcedErrors;

WinnersAndUnforcedErrors.propTypes = {
  data: PropTypes.object.isRequired
};

const PlayerMetrics = ({ data, player }) => {
  const shotTypes = ['serve', 'return', 'forehand', 'backhand', 'volley'];
  const metricTypes = ['winners', 'unforced_errors'];
  const isMatchReport = useRecoilValue(isMatchReportState);
  return (
    <Flex direction="column" key={player} gap={7} width="50%">
      {metricTypes.map((metricType) => {
        let pieChartData = [];
        shotTypes.forEach((shotType) => {
          pieChartData.push(data?.[`${shotType}_${metricType}`]?.[`${player}`]?.score);
        });
        return (
          <Flex direction="column" key={metricType} gap={2}>
            <Flex justify="center">
              <Text fontSize={isMatchReport ? 'lg' : 'sm'} fontWeight="medium" color="grey.700">
                {`${convertLabelName(metricType)}: ${data?.[`total_${metricType}`]?.[`${player}`]?.score}`}
              </Text>
            </Flex>
            {sum(pieChartData) > 0 ? (
              <Flex justify="center">
                <Box maxW="200px">
                  <PieChart
                    colors={customSitesConfig.pieChartColors}
                    data={pieChartData}
                    labels={shotTypes.map((item) => convertLabelName(item))}
                  />
                </Box>
              </Flex>
            ) : (
              <Box px="25px">
                <AspectRatio ratio={1}>
                  <Box bg="primary.50" borderRadius="full" w="100%"></Box>
                </AspectRatio>
              </Box>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};
