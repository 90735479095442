import React from 'react';

import { Box, UnorderedList, Flex } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';

import { ACCORDION_TITLE } from './constants';
import { Paragraph, NormalParagraphText, InsightsListItem } from './helperComponents';

export const generateFaqContent = () => {
  const faqContent = [
    {
      title: ACCORDION_TITLE.FAQ_PRESSURE_POINT,
      content: (
        <Box>
          <Paragraph>
            <NormalParagraphText>
              A Pressure Point is any point leading to a break point, break point itself, and any point in a tie-break.
            </NormalParagraphText>
            <NormalParagraphText>
              Pressure Points = 0-30, 0-40, 15-30, 15-40, 30-30, 30-40, 40-40, 40-Adv
            </NormalParagraphText>
          </Paragraph>
        </Box>
      )
    }
  ];

  if (!isWimbPortal()) {
    faqContent.push({
      title: ACCORDION_TITLE.FAQ_PLAYER_AND_TOUR_AVERAGES,
      content: (
        <Flex direction="column">
          <UnorderedList>
            <InsightsListItem>
              Tour averages are calculated using main draw matches from the last 52 weeks at ATP 250, 500, 1000, United
              Cup, Next-Gen and Tour Finals.
            </InsightsListItem>
            <InsightsListItem>
              Player averages are calculated using all available matches on the ATP Tour in the last 52 weeks.
            </InsightsListItem>
            <InsightsListItem>
              Performance Rating and Shot quality player averages are displayed for players with five or more main-draw
              matches of tracking data available in the last 52 weeks.
            </InsightsListItem>
          </UnorderedList>
        </Flex>
      )
    });
  }

  return faqContent;
};
