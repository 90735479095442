import PropTypes from 'prop-types';

import { Flex, Icon, Text, Tooltip } from '_shared/designSystem/components';

const HeadingWithTooltip = ({ heading, tooltipText, isMatchReport, isDark }) => {
  const InfoIcon = <Icon name="info" color="secondary.800" height={4} width={4} />;
  const InfoIconDark = <Icon name="info" color="white" height={4} width={4} />;
  return (
    <Flex justify="center" mb={5}>
      <Text fontSize="md" fontWeight="semibold" mr={1} color={isDark ? 'white' : 'secondary.800'}>
        {heading}
      </Text>
      {!isMatchReport && tooltipText && (
        <Tooltip tooltipText={tooltipText} elementToHover={isDark ? InfoIconDark : InfoIcon} />
      )}
    </Flex>
  );
};

HeadingWithTooltip.propTypes = {
  heading: PropTypes.string,
  tooltipText: PropTypes.string,
  isMatchReport: PropTypes.bool,
  isDark: PropTypes.bool
};

export default HeadingWithTooltip;
