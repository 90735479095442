import axios from 'axios';

import { getReportLambdaUrl } from '_shared/dataFetching/urlConfig';

export const scoutReportService = {
  async createScoutReport({ playerName, queryParams, company }) {
    const url = `${getReportLambdaUrl('scout')}?random_num=${Math.floor(100000 + Math.random() * 900000)}`;
    const res = await axios({
      method: 'POST',
      url,
      data: JSON.stringify({
        playerName,
        queryParams,
        company
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = res.data;
    return data;
  }
};
