import isEmpty from 'lodash/isEmpty';
import { fieldMappings, videoPlaylistCategories, videoPlaylistFields } from 'match/constants/videoConstants';

import { capitaliseAndPreserveCasing } from '_shared/utils/stringUtil';

export function convertValueToLabel(value, player1Name, player2Name, category) {
  if (Array.isArray(value)) {
    return value.map((val) => convertValueToLabel(val, player1Name, player2Name, category)).join(', ');
  }
  if (isEmpty(value)) return null;

  const mappingsWithoutPlayer = {
    both_baseline: 'Both players at baseline',
    both_net: 'Both players at the net'
  };

  const mappingsForPlayer = {
    win_point: 'wins the point',
    winner: 'hits a winner',
    all_errors: 'all errors',
    unforced_error: 'hits an UE',
    forced_error: 'hits a FE',
    net: 'at net',
    conversion: 'converts',
    steal: 'steals'
  };

  const serveMappingsForPlayer = {
    all: 'all serves',
    '1st': '1st serves',
    '2nd': '2nd serves'
  };

  const returnMappingsForPlayer = {
    all: 'all returns',
    '1st': '1st returns',
    '2nd': '2nd returns'
  };

  if (value === 'player1') return player1Name;
  if (value === 'player2') return player2Name;
  if (value === 'rally player1') return `Rally ${player1Name}`;
  if (value === 'rally player2') return `Rally ${player2Name}`;

  const playerName = getPlayerNameFromValue(value, player1Name, player2Name);
  if (isEmpty(playerName)) {
    if (mappingsWithoutPlayer[value]) {
      return capitaliseAndPreserveCasing(mappingsWithoutPlayer[value]);
    } else {
      return capitaliseAndPreserveCasing(value);
    }
  }

  if (category === videoPlaylistCategories.SERVE && serveMappingsForPlayer[removePlayerFromValue(value)]) {
    return `${playerName} ${capitaliseAndPreserveCasing(serveMappingsForPlayer[removePlayerFromValue(value)])}`;
  }

  if (category === videoPlaylistCategories.RETURN && returnMappingsForPlayer[removePlayerFromValue(value)]) {
    return `${playerName} ${capitaliseAndPreserveCasing(returnMappingsForPlayer[removePlayerFromValue(value)])}`;
  }

  if (mappingsForPlayer[removePlayerFromValue(value)]) {
    return `${playerName} ${capitaliseAndPreserveCasing(mappingsForPlayer[removePlayerFromValue(value)])}`;
  } else {
    return capitaliseAndPreserveCasing(value);
  }
}

export function updateFormFilters(formState, filter, value) {
  if (isEmpty(filter) || isEmpty(value)) return formState;
  if (value === 'all' || value === 'both') {
    const newFilters = { ...formState };
    delete newFilters[filter];
    return newFilters;
  }
  const newFilters = { ...formState };
  if (filter === videoPlaylistFields.SETS) {
    delete newFilters[videoPlaylistFields.FROM_GAME];
    delete newFilters[videoPlaylistFields.TO_GAME];
  } else if (![videoPlaylistFields.SETS, videoPlaylistFields.FROM_GAME, videoPlaylistFields.TO_GAME].includes(filter)) {
    delete newFilters[videoPlaylistFields.CONVERSION_AND_STEAL];
  }
  if (filter === videoPlaylistFields.CONVERSION_AND_STEAL) {
    const newFilters = {};
    if (formState.sets) newFilters.sets = formState.sets;
    if (formState.from_game) newFilters.from_game = formState.from_game;
    if (formState.to_game) newFilters.to_game = formState.to_game;
    newFilters[filter] = value;
    return newFilters;
  }
  newFilters[filter] = value;
  return newFilters;
}

export function removeFormFilter(formState, filter) {
  if (isEmpty(filter)) return formState;
  const newFilters = { ...formState };

  if (filter === videoPlaylistFields.SETS) {
    delete newFilters[videoPlaylistFields.FROM_GAME];
    delete newFilters[videoPlaylistFields.TO_GAME];
  }

  delete newFilters[filter];
  return newFilters;
}

export function removePlayerFromValue(value) {
  if (isEmpty(value)) return null;
  const arr = value.split('_');
  arr.shift();
  return arr.join('_');
}

export function getPlayerNameFromValue(value, player1Name, player2Name) {
  if (isEmpty(value)) return null;
  const arr = value.split('_');
  const first = arr[0];
  let playerName = null;
  if (first === 'player1') playerName = player1Name;
  if (first === 'player2') playerName = player2Name;
  return playerName;
}

export function getVideoPlaylistParamsUrl(_formState) {
  if (isEmpty(_formState)) return null;
  const formState = { ..._formState };

  if (formState.sets) {
    const setsArray = Array.isArray(formState.sets) ? formState.sets : formState.sets.split(',').map(Number);
    formState.sets = setsArray.join(',');

    if (setsArray.length === 1 && formState.from_game && formState.to_game) {
      formState.min_point = `${setsArray[0]}-${formState.from_game}-1`;
      formState.max_point = `${setsArray[0]}-${formState.to_game}-999`;
      delete formState.from_game;
      delete formState.to_game;
    } else {
      delete formState.min_point;
      delete formState.max_point;
    }
  }
  return new URLSearchParams(formState).toString();
}

export function createPlaylistInputData(playlist, matchInfo) {
  if (isEmpty(playlist) || isEmpty(matchInfo)) return null;
  return playlist.map((item) => {
    let outputSet, outputGamesScore, outputPointsScore;
    const pjkParsed = parsePjk(item.pjk, matchInfo.match_id);
    if (isEmpty(pjkParsed)) return null;
    outputSet = pjkParsed.setNumber;
    const setData = matchInfo.match_score.all_scores.find((item) => item.set_number === outputSet);
    const gameData = setData.games.find((item) => item.game_number === pjkParsed.gameNumber);
    outputGamesScore = gameData?.score || null;
    const pointData = gameData?.points.find((item) => item.point_number === pjkParsed.pointNumber);
    outputPointsScore = pointData?.score || null;
    return {
      summary: item.summary,
      url: item.url,
      set: outputSet?.toString(),
      games: outputGamesScore?.toString(),
      points: outputPointsScore?.toString()
    };
  });
}

export function parsePjk(pjk, matchId) {
  if (pjk === null || pjk <= 0) return null;
  try {
    const stringPjk = pjk.toString();
    const matchIdRemoved = stringPjk.replace(matchId, '');
    const result = {
      setNumber: parseInt(matchIdRemoved.substring(0, 1)),
      gameNumber: parseInt(matchIdRemoved.substring(1, 4)),
      pointNumber: parseInt(matchIdRemoved.substring(4, 7))
    };
    return result;
  } catch (error) {
    return null;
  }
}

export function isCategoryPresent(category, formState) {
  if (isEmpty(category) || isEmpty(formState)) return false;
  return fieldMappings[category].some((field) => formState[field]);
}

export function getCategory(field) {
  if (isEmpty(field)) return null;
  const category = Object.keys(fieldMappings).find((category) => fieldMappings[category].includes(field));
  if (isEmpty(category)) return null;
  return category;
}

export function convertChipLabel(
  field,
  value,
  formState = {},
  setGames = [],
  player1Name = '',
  player2Name = '',
  isCategorised
) {
  if (Array.isArray(value)) {
    return value.map((val) => convertChipLabel(field, val, formState, setGames, player1Name, player2Name)).join(', ');
  }
  if (isEmpty(field) || isEmpty(value)) return null;

  const translatedValue = convertValueToLabel(value, player1Name, player2Name, getCategory(field));

  if (getCategory(field) === videoPlaylistCategories.SCORE) {
    switch (field) {
      case videoPlaylistFields.POINT_TYPE:
        return `${translatedValue} point`;
      case videoPlaylistFields.SETS:
        return `Set ${translatedValue}`;
      case videoPlaylistFields.FROM_GAME:
        return `From ${getGameLabel(value, formState.sets, setGames)}`;
      case videoPlaylistFields.TO_GAME:
        return `To ${getGameLabel(value, formState.sets, setGames)}`;
      default:
        return translatedValue;
    }
  }
  if (getCategory(field) === videoPlaylistCategories.POINT_END) {
    switch (field) {
      case videoPlaylistFields.LAST_SHOT:
        return `last shot ${translatedValue}`;
      default:
        return translatedValue;
    }
  }
  if (getCategory(field) === videoPlaylistCategories.SERVE) {
    if (field === videoPlaylistFields.SERVE_TYPE) {
      return translatedValue;
    } else if (field === videoPlaylistFields.SERVE_PLUS_ONE) {
      return `Serve +1 ${translatedValue}`;
    } else {
      return `serve ${translatedValue}`;
    }
  }
  if (getCategory(field) === videoPlaylistCategories.RETURN) {
    if (field === videoPlaylistFields.RETURN_TYPE) {
      return translatedValue;
    } else if (field === videoPlaylistFields.RETURN_POSITION) {
      return isCategorised ? `position ${translatedValue}` : `return position ${translatedValue}`;
    } else if (field === videoPlaylistFields.RETURN_DIRECTION) {
      return isCategorised ? `direction ${translatedValue}` : `return direction ${translatedValue}`;
    } else {
      return isCategorised ? translatedValue : `return ${translatedValue}`;
    }
  }
  if (getCategory(field) === videoPlaylistCategories.RALLY) {
    if (field === videoPlaylistFields.RALLY_PLAYER_HIT)
      return isCategorised ? `hit by ${translatedValue}` : `rally hit by ${translatedValue}`;
    return isCategorised ? translatedValue : `rally ${translatedValue}`;
  }
  return translatedValue;
}

export function getGameLabel(value, set, setGames) {
  if (isEmpty(value) || isEmpty(set) || isEmpty(setGames)) return null;
  return setGames
    .find((item) => item.set_number === parseInt(set))
    .games.find((item) => item.game_number === parseInt(value)).score;
}

export function getUpdatedFilters(formState, filter, value) {
  let newFilters;

  if (filter === videoPlaylistFields.SETS) {
    const currentSets = formState.sets || [];
    if (currentSets.includes(value)) {
      newFilters = {
        ...formState,
        sets: currentSets.filter((set) => set !== value)
      };
    } else {
      newFilters = {
        ...formState,
        sets: [...currentSets, value]
      };
    }

    if (newFilters.sets.length > 1) {
      delete newFilters[videoPlaylistFields.FROM_GAME];
      delete newFilters[videoPlaylistFields.TO_GAME];
    }

    return newFilters;
  } else {
    if (value === 'all' || value === 'both' || value === 'Select') {
      newFilters = removeFormFilter(formState, filter);
    } else {
      newFilters = updateFormFilters(formState, filter, value);
    }

    return newFilters;
  }
}
