import React from 'react';

import { convertScoreToPercentage, displayScore, zeroOrMore } from 'match/utils/transformations';
import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';

import { averageType } from '_shared/constants/metricTypes';
import { Box, Flex, LinearProgress } from '_shared/designSystem/components';

const AverageBars = ({ labelPosition, data, isLoading, opacity }) => {
  if (isLoading)
    return (
      <Flex justify="center" align="center">
        <BeatLoader size={3} color="green" />
      </Flex>
    );

  return (
    <Flex direction="column" gap={0.5}>
      {zeroOrMore(data?.player_average, data?.score_type) ? (
        <LinearProgress
          percentage={convertScoreToPercentage(data?.player_average, data?.score_type)}
          type={averageType.PLAYER_AVERAGE}
          label={displayScore(data?.player_average, data?.score_type)}
          labelPosition={labelPosition}
          opacity={opacity}
        />
      ) : (
        <EmptyLinearProgress />
      )}
      {zeroOrMore(data?.tour_average, data?.score_type) ? (
        <LinearProgress
          percentage={convertScoreToPercentage(data?.tour_average, data?.score_type)}
          type={averageType.TOUR_AVERAGE}
          label={displayScore(data?.tour_average, data?.score_type)}
          labelPosition={labelPosition}
        />
      ) : (
        <EmptyLinearProgress />
      )}
    </Flex>
  );
};

AverageBars.propTypes = {
  labelPosition: PropTypes.oneOf(['left', 'right']),
  data: PropTypes.object,
  isLoading: PropTypes.bool
};

function EmptyLinearProgress() {
  return <Box h="12px"></Box>;
}

export default AverageBars;
