import axios from 'axios';

import { apiClient } from '_shared/dataFetching/apiConfig';
import { logout } from '_shared/dataFetching/auth';
import { getAuthApiUrl, getSiteParam } from '_shared/dataFetching/urlConfig';
import { isTvPortal, isAtpMediaPortal, isAtpTourPortal, isWimbPortal } from '_shared/utils/environment/currentEnv';

export const determineGroup = () => {
  // Implement logic to determine group based on user's role
  if (isTvPortal()) return 'tennis_portal';
  else if (isAtpMediaPortal()) return 'tennis_atpmedia_portal';
  else if (isAtpTourPortal()) return 'tennis_atptour_portal';
  else if (isWimbPortal()) return 'tennis_wimb_portal';
  else return '';
};

export const authenticationService = {
  async validateCredentialsToken({ credentials }) {
    const formData = new URLSearchParams();
    formData.append('grant_type', 'authorisation');
    formData.append('user_name', credentials.userName);
    formData.append('password', credentials.password);
    const url = `${getAuthApiUrl()}token?group=${determineGroup()}`;
    const res = await axios({
      method: 'post',
      url,
      data: formData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    const { data } = res;
    return data;
  },
  async logout() {
    return logout();
  },
  async getUserPermissions() {
    const url = `user/perms?${getSiteParam()}&unique_param=${Math.floor(100000 + Math.random() * 900000)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getTermsAndConditionsAccepted() {
    const url = `user/terms_and_conditions/accepted?${getSiteParam()}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async submitTermsAndConditionsAccepted(versions) {
    const url = `user/terms_and_conditions/accepted`;
    const postBody = {
      ...versions,
      site: process.env.REACT_APP_SITE_NAME
    };
    const res = await apiClient.post(url, postBody);
    const data = res.data;
    return data;
  },
  async getTermsAndConditionsTexts() {
    const url = `user/terms_and_conditions/texts?${getSiteParam()}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getAblyToken() {
    const url = `realtime/ably/access_token`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  }
};
