import React from 'react';

import { Select, FormControl, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function SelectSingle({ fieldId, label, options, setFieldValue, value }) {
  const handleChange = (event) => {
    const selectedOption = options.find((option) => option.id === event.target.value);
    setFieldValue(fieldId, selectedOption);
  };

  return (
    <FormControl id={fieldId} w="180px">
      <Text color="grey.600" fontWeight="medium" fontSize="xs" ml={1}>
        {label}
      </Text>
      <Select
        value={value?.id || ''}
        onChange={handleChange}
        maxW="180px"
        borderColor="grey.300"
        sx={{
          fontSize: 'xs',
          height: '2rem',
          padding: '2'
        }}
      >
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

SelectSingle.propTypes = {
  fieldId: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string
  })
};
