import PropTypes from 'prop-types';

import { Box, Flex } from '_shared/designSystem/components';

import { determineBorderColor, determineHoverColor } from './utils';

const Card = ({ children, disabled, onClick, status }) => (
  <Flex
    w="100%"
    maxW="420px"
    direction="column"
    gap={1}
    border="solid 1px"
    borderColor={determineBorderColor({ status, disabled })}
    cursor={disabled ? 'not-allowed' : 'pointer'}
    borderRadius="md"
    overflow="hidden"
    onClick={onClick}
    _hover={disabled ? null : { bg: determineHoverColor({ status, disabled }) }}
  >
    <Box w="100%" h="7px" bg={determineBorderColor({ status, disabled })} />
    <Box p={3} opacity={disabled ? '0.5' : null}>
      {children}
    </Box>
  </Flex>
);

Card.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func
};

export default Card;
