import PropTypes from 'prop-types';

import { averageType } from '_shared/constants/metricTypes';
import { Box, Flex, Text } from '_shared/designSystem/components';

const LinearProgress = ({ percentage: _percentage, type, label, labelPosition, barStartFrom, opacity }) => {
  let percentage = _percentage;
  if (_percentage === 0 || _percentage === 1) percentage = 2;
  let barColor;
  switch (type) {
    case averageType.MATCH:
      barColor = 'primary.600';
      break;
    case averageType.PLAYER_AVERAGE:
      barColor = 'playerAverage';
      break;
    case averageType.TOUR_AVERAGE:
      barColor = 'tourAverage';
      break;
    default:
      barColor = 'primary.600';
  }

  if (!label) return <Bar percentage={percentage} barColor={barColor} />;

  return (
    <Box>
      {labelPosition === 'right' ? (
        <Box display="flex" alignItems="center">
          <Bar percentage={percentage} barColor={barColor} barStartFrom={barStartFrom} opacity={opacity ?? ''} />
          <NumberLabel label={label} labelPosition={labelPosition} />
        </Box>
      ) : (
        <Box display="flex" alignItems="center">
          <NumberLabel label={label} />
          <Bar percentage={percentage} barColor={barColor} barStartFrom={barStartFrom} opacity={opacity ?? ''} />
        </Box>
      )}
    </Box>
  );
};

const Bar = ({ percentage, barColor, barStartFrom, opacity }) => {
  if (barStartFrom === 'right') {
    return (
      <Flex width="100%" data-testid="average-bar" justify="flex-end" borderLeftRadius={4}>
        <Box height="8px" width={`${percentage}%`} bg={barColor} borderLeftRadius={4} opacity={opacity} />
      </Flex>
    );
  }
  return (
    <Flex width="100%" data-testid="average-bar" borderRightRadius={4}>
      <Box height="8px" width={`${percentage}%`} bg={barColor} borderRightRadius={4} opacity={opacity} />
    </Flex>
  );
};

const NumberLabel = ({ label, labelPosition }) => {
  const alignment = labelPosition === 'right' ? 'flex-end' : 'flex-start';
  return (
    <Flex justify={alignment} w="45px">
      <Text fontSize="xs" lineHeight="12px" fontWeight="normal" color="grey.700">
        {label}
      </Text>
    </Flex>
  );
};

export default LinearProgress;

LinearProgress.propTypes = {
  percentage: PropTypes.number,
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelPosition: PropTypes.string,
  barStartFrom: PropTypes.string
};
