import { courtGraphicTypes } from 'match/_shared/matchConstants';
import { Heading, getScoreValue, tableFields } from 'match/components/court-graphics/serve-plus-one/ServePlusOneStats';
import { useMatchReportCourtGraphicsQuery } from 'matchReport/hooks/matchReportHooks';
import PropTypes from 'prop-types';

import { Box, Flex, Text } from '_shared/designSystem/components';

import PageHeading from '../_shared/PageHeading';

const ServePlusOneSummary = ({ matchId, playerName, player }) => {
  const defaultOptions = {
    set: 'all',
    servePlusOne: false,
    deuceAdvantage: 'all',
    pressureBreak: 'all',
    forehandBackhand: 'all',
    orientation: 'horizontal',
    showAverages: 'false',
    averagesType: 'placement'
  };

  const allServes = useMatchReportCourtGraphicsQuery(matchId, {
    ...defaultOptions,
    graphicType: courtGraphicTypes.SERVE_PLUS_ONE,
    player,
    serve: 'all'
  });
  const firstServe = useMatchReportCourtGraphicsQuery(matchId, {
    ...defaultOptions,
    graphicType: courtGraphicTypes.SERVE_PLUS_ONE,
    player,
    serve: 1
  });
  const secondServe = useMatchReportCourtGraphicsQuery(matchId, {
    ...defaultOptions,
    graphicType: courtGraphicTypes.SERVE_PLUS_ONE,
    player,
    serve: 2
  });

  return (
    <Box>
      <PageHeading>{`${playerName} Serve +1 Information`}</PageHeading>
      <Flex direction="column" gap="80px" mt="70px">
        <Flex justify="center" mb="40px">
          <Box bg="grey.50" borderRadius="md" pb={5}>
            <StatsTable showBackgroundCourt data={allServes.data} heading="Serve +1" showHeadings />
          </Box>
        </Flex>
        <Flex justify="center">
          <Flex gap="40px" bg="grey.50" borderRadius="md" pb={5}>
            <StatsTable data={firstServe.data} heading="1st Serve" showHeadings />
            <StatsTable data={secondServe.data} heading="2nd Serve" />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

ServePlusOneSummary.propTypes = {
  matchId: PropTypes.string.isRequired,
  player: PropTypes.string.isRequired,
  playerName: PropTypes.string.isRequired
};

export const StatsTable = ({ data, heading, showHeadings }) => {
  const showNoDataMessage = data?.forehand?.usage?.score === 0 && data?.backhand?.usage?.score === 0;
  const newTableData = [{ name: 'Usage', value: 'usage' }].concat(tableFields);
  return (
    <Flex direction="column" maxW="600px" gap={2}>
      <Flex justify="center">
        {showHeadings && heading !== 'Serve +1' && <Box w="330px" />}
        <Text textAlign="center" fontSize="lg" fontWeight="bold" bg="primary.200" w="100%">
          {heading}
        </Text>
      </Flex>
      <Flex>
        {showHeadings && <Box w="200px" />}
        <Box w="150px">
          <Text fontWeight="semibold" textAlign="center">
            Forehand
          </Text>
        </Box>
        <Box w="150px">
          <Text fontWeight="bold" textAlign="center">
            Backhand
          </Text>
        </Box>
      </Flex>
      {newTableData.map((item) => (
        <TableRow
          showHeadings={showHeadings}
          key={item.value}
          name={item.name}
          leftValue={getScoreValue(data, item.value, 'forehand', showNoDataMessage)}
          rightValue={getScoreValue(data, item.value, 'backhand', showNoDataMessage)}
        />
      ))}
    </Flex>
  );
};

const TableRow = ({ name, leftValue, rightValue, showHeadings }) => (
  <Flex>
    {showHeadings && (
      <Box w="200px">
        <Heading>{name}</Heading>
      </Box>
    )}
    <Box w="150px">
      <TableStat>{leftValue}</TableStat>
    </Box>
    <Box w="150px">
      <TableStat>{rightValue}</TableStat>
    </Box>
  </Flex>
);

export const TableStat = ({ children }) => (
  <Text fontSize="sm" textAlign="center">
    {children}
  </Text>
);

export default ServePlusOneSummary;
