import BalanceOfPowerWithAverages from 'match/components/performance/battles/BalanceOfPowerWithAverages';
import CircularMetricsPanel from 'match/components/performance/circularMetrics/CircularMetricsPanel';
import { matchStatsOrder } from 'match/components/performance/matchStatistics/matchStatsOrder';
import StatisticsRow from 'match/components/performance/matchStatistics/StatisticsRow';
import WinnersAndUnforcedErrors from 'match/components/performance/matchStatistics/WinnersAndUnforcedErrors';
import { convertLabelName } from 'match/utils/transformations';
import PageHeading from 'matchReport/components/_shared/PageHeading';

import { Box, Flex, Text, VStack } from '_shared/designSystem/components';

import { Definition } from './_shared/Definition';

const Rally = ({ headerData, performanceData, page, player1Name, player2Name }) => {
  const type = 'rally';

  const balanceOfPowerDefinition = `In Attack shows the percentage of shots played in attack and neutral for both players`;
  const conversionDefinition = `Conversion Score calculates how often a player has won the point when attacking`;
  const stealDefinition = `Steal Score calculates how often a player has won the point when defending`;
  const groundstrokeQuality = `Groundstroke quality shows the aggregated score of all the forehands and backhands played in this match. Groundstroke quality is calculated by analysing each shot's speed, spin, depth, width, and the impact it has on the opponent`;

  return page === 1 ? (
    <Box>
      <PageHeading>Rally Information</PageHeading>
      <Box>
        <VStack gap={4}>
          <Box w="450px">
            <BalanceOfPowerWithAverages
              data={performanceData?.performance?.battles}
              showAverages={false}
              player1Name={player1Name}
              player2Name={player2Name}
            />
          </Box>
          <Definition definition={balanceOfPowerDefinition} />
        </VStack>
      </Box>
      <Flex mt={10} justify="center">
        <Flex gap="90px">
          <Box w="50%">
            <VStack>
              <CircularMetricsPanel
                heading="Attack and Defence"
                tooltipText="How often a player has won the point when attacking (Conversion) or defending (Steal)"
                player1Name={player1Name}
                player2Name={player2Name}
                data={performanceData?.performance?.comparison_metrics?.capitalisation}
                type="medium"
                showAverages={false}
                orderOfMetrics={['conversion', 'steal']}
              />
              <Definition definition={conversionDefinition} width="300px" />
              <Definition definition={stealDefinition} width="300px" />
            </VStack>
          </Box>
          <Box>
            <VStack>
              <CircularMetricsPanel
                heading="Groundstroke Quality"
                tooltipText="The quality of the player's four main shots on a 0-10 scale"
                player1Name={player1Name}
                player2Name={player2Name}
                data={performanceData?.performance?.comparison_metrics?.shot_quality}
                type="light"
                showAverages={false}
                orderOfMetrics={['forehand', 'backhand']}
              />
              <Box>
                <Definition definition={groundstrokeQuality} width="300px" />
              </Box>
            </VStack>
          </Box>
        </Flex>
      </Flex>
    </Box>
  ) : (
    <Box pl={8}>
      <Box>
        <Box w="493px" mt={8}>
          <MatchStats
            performanceData={performanceData}
            type={type}
            player1Name={player1Name}
            player2Name={player2Name}
          />
        </Box>
        <Box w="493px" mt={7}>
          <Text fontSize="xs">
            Rally length includes shots that go in the court only. The following point would be classified as a
            three-shot rally:
          </Text>
          <Text fontSize="xs">Shot 1: In</Text>
          <Text fontSize="xs">Shot 2: In</Text>
          <Text fontSize="xs">Shot 3: In</Text>
          <Text fontSize="xs">Shot 4: Out</Text>
        </Box>
        <Box w="493px" mt={10}>
          <WinnersAndUnforcedErrors
            data={performanceData?.performance?.match_statistics?.winners_and_unforced_errors}
          />
        </Box>
      </Box>
    </Box>
  );
};

const MatchStats = ({ performanceData, type, player1Name, player2Name }) => {
  return (
    <Box>
      <Flex justify="space-between" w="100%">
        <Text fontSize="sm" fontWeight="semibold">
          {player1Name}
        </Text>
        <Text fontSize="sm" fontWeight="semibold">
          {player2Name}
        </Text>
      </Flex>
      {matchStatsOrder?.[`${type}`].map((statType) => {
        return performanceData?.performance?.match_statistics?.[`${type}`]?.[statType] ? (
          <StatisticsRow
            data={performanceData?.performance?.match_statistics?.[`${type}`]?.[statType]}
            label={convertLabelName(statType)}
            key={statType}
            showAverages={false}
          />
        ) : null;
      })}
    </Box>
  );
};

export default Rally;
