import { Box, Flex } from '_shared/designSystem/components';

import Header from './Header';

export const scoutReportPageHeight = '1464px';
export const scoutReportPageWidth = '1035px';

const PageLayout = ({ playerName, hideHeader, children }) => (
  <Box h={scoutReportPageHeight} w={scoutReportPageWidth}>
    {!hideHeader && <Header playerName={playerName} />}
    <Flex py={5} justify="center">
      <Box>{children}</Box>
    </Flex>
  </Box>
);

export default PageLayout;
