import { Text, Table, Thead, Tr, Th, Tbody, Td } from '_shared/designSystem/components';

const MatchReportTable = ({ headings, tableData, rowHeight, size }) => {
  return (
    <Table size={size ? size : 'sm'}>
      <Thead>
        <Tr>
          {headings?.map((item) => (
            <Th key={item}>{item}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {tableData?.map((row, index) => {
          return (
            <Tr key={index} h={rowHeight}>
              {row.heading && <Td>{row.heading}</Td>}
              {row.data.map((cellData, index) => (
                <Td key={index} whiteSpace="nowrap">
                  <Text fontSize="sm" color="grey.600">
                    {cellData}
                  </Text>
                </Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default MatchReportTable;
