import { useLocation, useNavigate } from '@tanstack/react-location';
import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import {
  getInitialMatchHeaderForm,
  getNumberSelectedMatches,
  getPlayerHeadshotImage,
  getQueryParams,
  getScoutHeaderForm
} from 'scout/util/scoutHeaderUtil';
import ScoutReportModal from 'scoutReport/components/ScoutReportModal';

import customSitesConfig from '_shared/config/customSitesConfig';
import { userPermissionTypes } from '_shared/constants/user';
import {
  Text,
  Flex,
  NationalityFlag,
  FormStandard,
  Box,
  Image,
  Center,
  Icon,
  useDisclosure
} from '_shared/designSystem/components';
import { userPermissionsState } from '_shared/globalState/atoms';
import useFeature from '_shared/utils/hooks/useFeature';
import { navigateBack } from '_shared/utils/navigationUtils';
import { checkPermission } from '_shared/utils/permissions';

export default function ScoutHeader(props) {
  const {
    playerDetailsData,
    winLoss,
    openMatchSelector,
    handleHeaderFormSubmit,
    handleHeaderFormUpdate,
    scoutFormValues,
    winLossLoading
  } = props;

  const playerId = playerDetailsData?.player_id;
  const playerName = `${playerDetailsData?.player_first_name} ${playerDetailsData?.player_last_name}`;
  const nationality = playerDetailsData?.nationality;
  const headShotUrl = getPlayerHeadshotImage(playerDetailsData);

  const scoutReportEnabled = useFeature('scoutReport');
  const { permissions, company } = useRecoilValue(userPermissionsState);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const navigate = useNavigate();
  const location = useLocation();

  const noOfSelectedMatches = getNumberSelectedMatches(scoutFormValues);

  return (
    <Flex pt={{ base: 2, md: 0 }} justify="space-between">
      <Flex direction="column" gap={2}>
        <Flex justify="space-between">
          <Flex>
            <Box
              cursor="pointer"
              pt={{ base: 0, md: 2 }}
              pr={1}
              onClick={() =>
                navigateBack({
                  navigate
                })
              }
            >
              <Icon name="arrowLeft" color="primary.500" height={{ base: 5, md: 7 }} width={{ base: 5, md: 7 }} />
            </Box>
            <Flex mr={6} display={{ base: 'none', md: 'flex' }}>
              <Flex align="center" mr={4}>
                <NationalityFlag nationality={nationality} width={30} />
              </Flex>
              <Text fontSize="3xl" fontWeight="medium" color="secondary.800">
                {playerName}
              </Text>
            </Flex>
            {winLossLoading ? (
              <Center>
                <BeatLoader size={6} color="grey" />
              </Center>
            ) : (
              <>
                {winLoss && (
                  <>
                    <Flex align="flex-end" pb={1.5}>
                      <Text fontSize="sm" fontWeight="semibold" mr={3} color="grey.500">
                        Win Loss
                      </Text>
                      {customSitesConfig.showScoutWinLossAll && (
                        <>
                          <Text
                            mr={1.5}
                            fontSize="sm"
                            color="grey.500"
                          >{`${winLoss?.all_matches_won}-${winLoss?.all_matches_lost}`}</Text>
                          <Text mr={3} fontSize="xs" color="grey.700" bg="grey.100" px={1.5} borderRadius="md" pb="2px">
                            All
                          </Text>
                        </>
                      )}
                      <Text
                        mr={1.5}
                        fontSize="sm"
                        color="grey.500"
                      >{`${winLoss?.scout_matches_won}-${winLoss?.scout_matches_lost}`}</Text>
                      <Text mr={2} fontSize="xs" color="grey.700" bg="grey.100" px={1.5} borderRadius="md" pb="2px">
                        Scout
                      </Text>
                    </Flex>
                  </>
                )}
              </>
            )}
            {customSitesConfig.showScoutReport &&
              scoutReportEnabled &&
              checkPermission(userPermissionTypes.SCOUT_REPORT, permissions) && (
                <Flex align="center">
                  <Box cursor="pointer" onClick={onOpen} pt={2}>
                    <Icon name="fileText" color="primary.500" height={5} width={5} />
                  </Box>
                  <ScoutReportModal
                    playerId={playerId}
                    heading={playerName}
                    isOpen={isOpen}
                    onClose={onClose}
                    queryParams={getQueryParams(location)}
                    playerName={`${playerDetailsData?.player_first_name}_${playerDetailsData?.player_last_name}`}
                    company={company}
                  />
                </Flex>
              )}
          </Flex>
        </Flex>
        <Flex gap={4} align="flex-start">
          <FormStandard
            formFieldsData={getScoutHeaderForm(
              playerDetailsData?.seasons_filter,
              true,
              openMatchSelector,
              noOfSelectedMatches
            )}
            formInitialValues={scoutFormValues || getInitialMatchHeaderForm()}
            handleFormSubmit={handleHeaderFormSubmit}
            handleFormUpdated={handleHeaderFormUpdate}
            enableReinitialize={true}
          />
          <Box pt="19px"></Box>
        </Flex>
      </Flex>
      {customSitesConfig.showScoutPlayerHeadshot && (
        <Flex px={5} display={{ base: 'none', md: 'flex' }} justify="center" align="flex-end">
          <Image
            borderRadius="full"
            border="1px solid"
            borderColor="grey.300"
            h="92px"
            w="92px"
            minW="92px"
            minH="92px"
            src={headShotUrl}
            alt={playerName}
            fallback={
              <Center
                mt={4}
                overflow="hidden"
                h="92px"
                w="92px"
                border="1px solid"
                borderColor="grey.300"
                borderRadius="full"
              >
                <Icon mt={2} color="grey.300" w="90px" h="90px" name="profileImagePlaceholder" />
              </Center>
            }
          />
        </Flex>
      )}
    </Flex>
  );
}

ScoutHeader.propTypes = {
  playerDetailsData: PropTypes.object,
  winLoss: PropTypes.object,
  handleHeaderFormSubmit: PropTypes.func,
  openMatchSelector: PropTypes.func,
  winLossLoading: PropTypes.bool
};
