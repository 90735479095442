/**
 * Scroll to a specific element smoothly.
 * @param {HTMLElement} element - The element to scroll to.
 * @param {number} offset - Offset from the top of the element.
 */
export const scrollToElement = (element, offset = 0) => {
  if (element) {
    const { top } = element.getBoundingClientRect();
    window.scrollTo({
      top: window.scrollY + top + offset,
      behavior: 'smooth'
    });
  }
};

/**
 * Scroll back to the top of the page smoothly.
 */
export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

/**
 * Scroll vertically to a specific offset from the top of the screen
 */
export const scrollToOffset = (offset) => {
  // delay here is so you can store the current Y position in sessionStorage before scrolling, otherwise
  // it stores the position just after the scroll started
  setTimeout(function () {
    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    });
  }, 2);
};
