export const getApiUrl = () => {
  const localApiUrl = 'http://localhost:3000/';

  // we are running tests, so don't set the api url, otherwise MSW won't work
  if (process.env.NODE_ENV === 'test') return undefined;

  // we are running storybook, so return the storybook url
  if (process.env.STORYBOOK === 'on') return 'http://localhost:6006/';

  // we are running locally, using MSW
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MSW === 'on') return localApiUrl;

  // we are running locally, using the staging API
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_STAGING === 'on')
    return process.env.REACT_APP_API_URL;

  // we are running remotely. get the url from env vars
  if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_API_URL;
  }

  // return local by default
  return localApiUrl;
};

export const getAuthApiUrl = () => {
  const localApiUrl = 'http://localhost:3000/auth/';

  // we are running tests, so don't set the api url, otherwise MSW won't work
  if (process.env.NODE_ENV === 'test') return undefined;

  // we are running locally, using MSW
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MSW === 'on') return localApiUrl;

  // we are running locally, using the staging API
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_STAGING === 'on')
    return process.env.REACT_APP_AUTH_API_URL;

  // we are running remotely. get the url from env vars
  if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_AUTH_API_URL;
  }

  // return local by default
  return localApiUrl;
};

export const getReportBucketUrl = (reportType) => {
  if (process.env.NODE_ENV === 'test') return undefined;
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MSW === 'on')
    return 'dummy_match_report_bucket_url';
  if (
    (process.env.NODE_ENV === 'development' && process.env.REACT_APP_STAGING === 'on') ||
    process.env.NODE_ENV === 'production'
  ) {
    return reportType === 'scout'
      ? process.env.REACT_APP_SCOUT_REPORT_BUCKET_URL
      : process.env.REACT_APP_MATCH_REPORT_BUCKET_URL;
  }
};

export const getReportLambdaUrl = (reportType) => {
  if (process.env.NODE_ENV === 'test') return undefined;
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MSW === 'on')
    return 'dummy_match_report_lambda_url/';
  if (
    (process.env.NODE_ENV === 'development' && process.env.REACT_APP_STAGING === 'on') ||
    process.env.NODE_ENV === 'production'
  ) {
    return reportType === 'scout'
      ? process.env.REACT_APP_SCOUT_REPORT_LAMBDA_URL
      : process.env.REACT_APP_MATCH_REPORT_LAMBDA_URL;
  }
};

export function getSiteParam() {
  return `site=${process.env.REACT_APP_SITE_NAME}`;
}
