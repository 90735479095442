import PropTypes from 'prop-types';

import { Box, Text, Flex } from '_shared/designSystem/components';

import TournamentCard from './TournamentCard';

const CardsGrid = ({ sections, openTournamentPage, navigate }) => {
  return (
    <>
      {sections?.map(({ label, tournaments }, index) => (
        <Box mb={8} key={index}>
          <Text fontSize="md" fontWeight="bold" mb={2}>
            {label}
          </Text>
          <Flex wrap="wrap" gap={6} justify={{ base: 'center', lg: 'normal' }}>
            {tournaments?.map((tournament) => (
              <TournamentCard
                key={tournament.tournament_id}
                data={tournament}
                onClick={() => openTournamentPage(tournament.tournament_id, navigate)}
              />
            ))}
          </Flex>
        </Box>
      ))}
    </>
  );
};

const tournamentPropType = PropTypes.shape({
  tournament_id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  tier: PropTypes.string.isRequired,
  surface: PropTypes.string.isRequired,
  season: PropTypes.number.isRequired,
  start_date: PropTypes.string.isRequired,
  end_date: PropTypes.string.isRequired,
  comp_status: PropTypes.string.isRequired
});

const sectionPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  tournaments: PropTypes.arrayOf(tournamentPropType).isRequired
});

const dataPropType = PropTypes.arrayOf(
  PropTypes.shape({
    season: PropTypes.number.isRequired,
    sections: PropTypes.arrayOf(sectionPropType).isRequired
  })
);

CardsGrid.propTypes = {
  data: dataPropType,
  openTournamentPage: PropTypes.func,
  navigate: PropTypes.func
};

export default CardsGrid;
