export const atptourFontsGuidelines = {
  normal: {
    fontFamily: 'Roboto'
  },

  h5: {
    fontFamily: 'Roboto'
  },

  h4: {
    fontFamily: 'Roboto'
  },

  h3: {
    fontFamily: 'Roboto'
  },

  h2: {
    fontFamily: 'Roboto'
  },

  h1: {
    fontFamily: 'Roboto'
  },

  paragraphText: {
    fontFamily: 'Roboto'
  }
};
