import PropTypes from 'prop-types';

import { Box, Center, Flex, Legend, Text } from '_shared/designSystem/components';

export default function PerformanceGraphShadedLegend({ lineColors, player1Name, player2Name }) {
  const legendFields = [
    {
      label: player1Name,
      color: lineColors.player1Line
    },
    {
      label: player2Name,
      color: lineColors.player2Line
    }
  ];

  return (
    <Flex justify="center">
      <Flex direction="column" gap={4}>
        <Flex justify="center">
          <Legend fields={legendFields} direction="horizontal" />
        </Flex>
        <Flex justify="center">
          <Flex gap={3}>
            <Center>
              <Box h="4px" w="25px" borderTop="4px dotted" borderColor={lineColors.player1Line} strokeDasharray="5,5" />
            </Center>
            <Text fontSize="small" color="grey.700">
              Player Avg
            </Text>
            <Center>
              <Box h="4px" w="25px" borderTop="4px dotted" borderColor={lineColors.player2Line} strokeDasharray="5,5" />
            </Center>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

PerformanceGraphShadedLegend.propTypes = {
  lineColors: PropTypes.object,
  player1Name: PropTypes.string,
  player2Name: PropTypes.string
};
