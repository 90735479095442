import { patternsOfPlayGraphicType } from 'scout/_shared/scoutConstants';

import { Flex, CourtBadgeGroup, ButtonGroup } from '_shared/designSystem/components';

import { QualityCourtBadgeGroupMobile } from './BadgeComponents';

const BadgeMobilePositions = ({
  dataNoAverages,
  dataWithAverages,
  graphicType,
  courtSide,
  setCourtSide,
  showAverages,
  averagesLoading
}) => {
  const handleCourtSideSelect = (prevCourtSide, setCourtSide) => {
    setCourtSide(prevCourtSide === 'to_deuce' ? 'to_advantage' : 'to_deuce');
  };

  const getCourtSideButtons = () => [
    { label: 'Deuce', value: 'to_deuce', position: 'left' },
    { label: 'Advantage', value: 'to_advantage', position: 'right' }
  ];

  const CourtSideFilter = ({ courtSide, setCourtSide }) => (
    <ButtonGroup
      paramName="courtSide"
      getButtonDataFunction={getCourtSideButtons}
      handleButtonClick={() => handleCourtSideSelect(courtSide, setCourtSide)}
      selectedItem={courtSide}
    />
  );

  if (graphicType === patternsOfPlayGraphicType.QUALITY && showAverages) {
    return (
      <>
        <CourtSideFilter courtSide={courtSide} setCourtSide={setCourtSide} />
        <Flex direction="row" gap={5} justifyContent="center" mb={5} data-testid="quality-badge-group-mobile">
          {['speed', 'spin'].map((attribute) => (
            <Flex direction="column" gap={1} key={attribute}>
              {courtSide === 'to_advantage' ? (
                <QualityCourtBadgeGroupMobile
                  dataNoAverages={dataNoAverages}
                  dataWithAverages={dataWithAverages}
                  courtSide="to_advantage"
                  attribute={attribute}
                  averagesLoading={averagesLoading}
                />
              ) : (
                <QualityCourtBadgeGroupMobile
                  dataNoAverages={dataNoAverages}
                  dataWithAverages={dataWithAverages}
                  courtSide="to_deuce"
                  attribute={attribute}
                  averagesLoading={averagesLoading}
                />
              )}
            </Flex>
          ))}
        </Flex>
      </>
    );
  }

  if (graphicType === patternsOfPlayGraphicType.IN) {
    return (
      <>
        <CourtSideFilter courtSide={courtSide} setCourtSide={setCourtSide} />
        <Flex direction="column" gap={5} data-testid="in-badge-group-mobile">
          <CourtBadgeGroup
            dataNoAverages={dataNoAverages?.[courtSide].net}
            dataWithAverages={dataWithAverages?.[courtSide].net}
            direction="row"
            label="Net"
            showAverages={showAverages}
            averagesLoading={averagesLoading}
            matchAverageVariant={courtSide === 'to_advantage' ? 'alternateMatchAverage' : 'matchAverage'}
          />
          <CourtBadgeGroup
            dataNoAverages={dataNoAverages?.[courtSide].long}
            dataWithAverages={dataWithAverages?.[courtSide].long}
            direction="row"
            label="Long"
            showAverages={showAverages}
            averagesLoading={averagesLoading}
            matchAverageVariant={courtSide === 'to_advantage' ? 'alternateMatchAverage' : 'matchAverage'}
          />
          <CourtBadgeGroup
            dataNoAverages={dataNoAverages?.[courtSide].wide}
            dataWithAverages={dataWithAverages?.[courtSide].wide}
            direction="row"
            label="Wide"
            showAverages={showAverages}
            averagesLoading={averagesLoading}
            matchAverageVariant={courtSide === 'to_advantage' ? 'alternateMatchAverage' : 'matchAverage'}
          />
        </Flex>
      </>
    );
  }
};

export default BadgeMobilePositions;
