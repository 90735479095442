import isEmpty from 'lodash/isEmpty';

import { formFieldTypes } from '_shared/designSystem/components/form/formTypes';

function getOptionsAsObjectArray(options, labelName, idName) {
  const result = [];
  options?.forEach((item) =>
    result.push({
      label: labelName ? item[`${labelName}`] : item,
      id: idName ? item[`${idName}`] : item
    })
  );
  return result;
}

export function getScoutMatchSelectorForm(tournaments, opponents, rounds) {
  const tournamentOptions = getOptionsAsObjectArray(tournaments, 'tournament_name', 'tournament_id');
  const opponentOptions = getOptionsAsObjectArray(opponents, 'opponent_name', 'player_id');
  const roundOptions = getOptionsAsObjectArray(rounds, 'round_id', 'round_name');

  return [
    {
      type: formFieldTypes.SELECT_MULTI,
      label: 'Tournaments',
      fieldId: 'tournamentIds',
      options: tournamentOptions
    },
    {
      type: formFieldTypes.SELECT_MULTI,
      label: 'Opponents',
      fieldId: 'opponentPlayerIds',
      options: opponentOptions
    },
    {
      type: formFieldTypes.SELECT_MULTI,
      label: 'Result',
      fieldId: 'result',
      options: [
        {
          label: 'Won',
          id: 'won'
        },
        {
          label: 'Lost',
          id: 'lost'
        }
      ]
    },
    {
      type: formFieldTypes.SELECT_MULTI,
      label: 'Rounds',
      fieldId: 'rounds',
      options: roundOptions
    },
    {
      type: formFieldTypes.SELECT_MULTI,
      label: 'Opponent Rank',
      fieldId: 'opponentRank',
      options: [
        {
          label: 'Top 5',
          id: '5'
        },
        {
          label: 'Top 10',
          id: '10'
        },
        {
          label: 'Top 20',
          id: '20'
        },
        {
          label: 'Top 50',
          id: '50'
        }
      ]
    },
    {
      type: formFieldTypes.SELECT_MULTI,
      label: 'Opponent Hand',
      fieldId: 'opponentHand',
      options: [
        {
          label: 'Right',
          id: 'right'
        },
        {
          label: 'Left',
          id: 'left'
        }
      ]
    },
    {
      type: formFieldTypes.SUBMIT_BUTTON,
      label: 'Apply',
      fieldId: 'submit'
    }
  ];
}

export function getInitialMatchSelectorChoices(scoutFormValues) {
  if (isEmpty(scoutFormValues)) return {};
  return {
    seasons: isEmpty(scoutFormValues.seasons) ? [] : scoutFormValues.seasons,
    last52Weeks: scoutFormValues.last52Weeks,
    surfaces: isEmpty(scoutFormValues.surfaces) ? [] : scoutFormValues.surfaces,
    lastNoMatches: !scoutFormValues.lastNoMatches ? '' : scoutFormValues.lastNoMatches,
    tournamentIds: isEmpty(scoutFormValues.tournamentIds) ? [] : scoutFormValues.tournamentIds,
    opponentPlayerIds: isEmpty(scoutFormValues.opponentPlayerIds) ? [] : scoutFormValues.opponentPlayerIds,
    result: isEmpty(scoutFormValues.result) ? [] : scoutFormValues.result,
    rounds: isEmpty(scoutFormValues.rounds) ? [] : scoutFormValues.rounds,
    opponentRank: isEmpty(scoutFormValues.opponentRank) ? [] : scoutFormValues.opponentRank,
    opponentHand: isEmpty(scoutFormValues.opponentHand) ? [] : scoutFormValues.opponentHand,
    matchIds: isEmpty(scoutFormValues.matchIds) ? [] : scoutFormValues.matchIds
  };
}

export function getVisibleMatchIds(matchSelectorMatches, matchIds) {
  if (isEmpty(matchSelectorMatches) && isEmpty(matchIds)) return [];
  let result = [];
  matchSelectorMatches.forEach((item) => {
    if (matchIds.includes(item.match_id.toString())) {
      result.push(item.match_id.toString());
    }
  });
  return result;
}
