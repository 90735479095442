import { getVideoPlaylistParamsUrl } from 'match/utils/videoUtil';

import { apiClient } from '_shared/dataFetching/apiConfig';
import { getSiteParam } from '_shared/dataFetching/urlConfig';
import { convertBooleanToYesNo } from '_shared/utils/stringUtil';

export const matchService = {
  async getMatchInformation({ matchId }) {
    const url = `match/${matchId}?${getSiteParam()}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getPerformance({ matchId, currentSetFilter, currentScoreRangeFilter, averages }) {
    let url;
    if (currentScoreRangeFilter) {
      url = `match/${matchId}/performance?${getSiteParam()}&min_point=${
        currentScoreRangeFilter.apiMinPoint
      }&max_point=${currentScoreRangeFilter.apiMaxPoint}&averages=${convertBooleanToYesNo(averages)}`;
    } else if (currentSetFilter) {
      url = `match/${matchId}/performance?${getSiteParam()}&set=${currentSetFilter}&averages=${convertBooleanToYesNo(
        averages
      )}`;
    } else {
      url = `match/${matchId}/performance?${getSiteParam()}&averages=${convertBooleanToYesNo(averages)}`;
    }
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getPerformanceRatingGame({ matchId }) {
    const url = `match/${matchId}/performance/performance_rating_game?${getSiteParam()}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getPerformanceRatingPoint({ matchId }) {
    const url = `match/${matchId}/performance/performance_rating_point?${getSiteParam()}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getCourtGraphics({ matchId, queryParams }) {
    const url = `match/${matchId}/court_graphics?${getSiteParam()}&graphic_type=${queryParams.graphicType}&set=${
      queryParams.set
    }&player=${queryParams.player}&serve=${queryParams.serve}&deuce_advantage=${
      queryParams.deuceAdvantage
    }&pressure_break=${queryParams.pressureBreak}&serve_plus_one=${convertBooleanToYesNo(
      queryParams.servePlusOne
    )}&shot_type=${queryParams.forehandBackhand}&out_shots=${convertBooleanToYesNo(
      queryParams.outShots
    )}&averages=no&serve_location=${queryParams.serveLocation}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getCourtGraphicsAverages({ matchId, queryParams }) {
    const url = `match/${matchId}/court_graphics?${getSiteParam()}&graphic_type=${queryParams.graphicType}&set=${
      queryParams.set
    }&player=${queryParams.player}&serve=${queryParams.serve}&deuce_advantage=${
      queryParams.deuceAdvantage
    }&pressure_break=${queryParams.pressureBreak}&serve_plus_one=${convertBooleanToYesNo(
      queryParams.servePlusOne
    )}&shot_type=${queryParams.forehandBackhand}&out_shots=${convertBooleanToYesNo(
      queryParams.outShots
    )}&averages=yes&serve_location=${queryParams.serveLocation}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getWinningAndLosingPlays({ matchId, queryParams }) {
    const url = `match/${matchId}/winning_and_losing_plays?${getSiteParam()}&player=${queryParams.player}&play_type=${
      queryParams.playType
    }`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getWinningAndLosingPlaysV2({ matchId, queryParams, averages }) {
    const url = `match/${matchId}/winning_and_losing_plays_v2?${getSiteParam()}&player=${queryParams.player}&set=${
      queryParams.set
    }&averages=${convertBooleanToYesNo(averages)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getPatternsOfPlay({ matchId, queryParams, averages }) {
    let url = `match/${matchId}/patterns_of_play?${getSiteParam()}&${queryParams}&averages=${convertBooleanToYesNo(
      averages
    )}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getDataQualityFlag({ matchId }) {
    const url = `match/${matchId}/data_quality_flag?${getSiteParam()}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async updateDataQualityFlag({ matchId, dataQualityFlag }) {
    const url = `match/${matchId}/data_quality_flag?${getSiteParam()}&action=${!!dataQualityFlag ? 'add' : 'remove'}`;
    const res = await apiClient.post(url);
    const data = res.data;
    return data;
  },
  async updateVideoQualityFlag({ matchId, action }) {
    const url = `match/${matchId}/video_quality_flag?${getSiteParam()}&action=${action}`;
    const res = await apiClient.post(url);
    const data = res.data;
    return data;
  },
  async getVideoCompleteMatch({ matchId }) {
    const url = `match/${matchId}/video/complete_match?${getSiteParam()}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getVideoStatus({ matchId }) {
    const url = `match/${matchId}/video/status?${getSiteParam()}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getVideoPlaylist({ matchId, formValues }) {
    const url = `match/${matchId}/video/playlist?${getSiteParam()}&${getVideoPlaylistParamsUrl(formValues)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getCsvReportFiles({ matchId, formValues }) {
    const url = `match/${matchId}/csv_report?${getSiteParam()}`;
    const queryParams = new URLSearchParams();

    if (formValues && formValues.length > 0) {
      queryParams.append('csv_type', formValues.join(','));
    }

    const finalUrl = `${url}&${queryParams.toString()}`;

    const res = await apiClient.get(finalUrl);
    const data = res.data;
    return data;
  }
};
