import isEmpty from 'lodash/isEmpty';

import { Flex, Box } from '_shared/designSystem/components';

import FrequencyBadge from './_shared/FrequencyBadge';
import { serveDirectionOrder } from './AveragesBadges';
import { CustomBadge } from './CustomBadge';
import { getAverage } from './util';

export const AveragesServeDirection = ({
  dataNoAverages,
  dataWithAverages,
  showAverages,
  averagesLoading,
  width,
  height
}) => (
  <Box position="absolute" width={width * 0.52} top={height * 0.555} left={width * 0.24} pt={3}>
    <Flex direction="column" gap={2}>
      <Flex justify="space-around">
        {serveDirectionOrder.map((position) => {
          const averageFigure = getAverage(dataNoAverages, position, 'match');
          return (
            <Flex direction="column" gap={1} key={position}>
              <CustomBadge key={position} text={averageFigure} type="matchAverage" />
              {!showAverages && <FrequencyBadge frequency={dataNoAverages?.[position]?.frequency} />}
            </Flex>
          );
        })}
      </Flex>
      {showAverages && averagesLoading && (
        <Flex justify="space-around">
          {[1, 2, 3, 4, 5, 6].map((item, index) => (
            <CustomBadge loading={averagesLoading} key={index} type={`playerAverage`} />
          ))}
        </Flex>
      )}
      {showAverages &&
        !isEmpty(dataWithAverages) &&
        ['player', 'tour'].map((item) => (
          <Flex justify="space-around" key={item}>
            {serveDirectionOrder.map((position) => {
              const averageFigure = getAverage(dataWithAverages, position, item);
              return (
                <CustomBadge loading={averagesLoading} key={position} text={averageFigure} type={`${item}Average`} />
              );
            })}
          </Flex>
        ))}
    </Flex>
  </Box>
);
