import { atom } from 'recoil';

export const isMatchReportState = atom({
  key: 'isMatchReport',
  default: false
});

export const userPermissionsState = atom({
  key: 'userPermissions',
  default: {
    roles: [],
    permissions: [],
    company: '',
    csv_types: []
  }
});

export const mobileHeaderTextState = atom({
  key: 'mobileHeaderText',
  default: null
});

export const matchPageGoBackToState = atom({
  key: 'matchPageGoBackTo',
  default: 'tournaments'
});

export const showTopMenuState = atom({
  key: 'showTopMenu',
  default: true
});

export const insightsExplainedState = atom({
  key: 'insightsExplainedState',
  default: {
    isOpen: false,
    activeAccordionTitle: null
  }
});

export const AISelectedState = atom({
  key: 'AISelectedState',
  default: { found: false, path: null }
});

export const liveMatchRefetchIntervalState = atom({
  key: 'liveMatchRefetchInterval',
  default: false
});
