import { courtGraphicTypes } from 'match/_shared/matchConstants';
import {
  isContactPoint,
  isReturnOrRallyPlacementVertical,
  isReturnOrRallyPlacementHorizontal,
  isReturnOrRallyPlacement
} from 'match/utils/courtGraphicsUtil';
import { useRecoilValue } from 'recoil';

import { Box } from '_shared/designSystem/components';
import { isMatchReportState } from '_shared/globalState/atoms';

import { AveragesHorizontal } from './AveragesHorizontal';
import { AveragesReturnPlacementVertical } from './AveragesReturnPlacement';
import { AveragesServeDirection } from './AveragesServeDirection';
import { coordinatesPlacementHorizontal, coordinatesRallyContactPoint, coordinatesReturnContactPoint } from './util';

export const serveDirectionOrder = [
  'advantage_left',
  'advantage_middle',
  'advantage_right',
  'deuce_left',
  'deuce_middle',
  'deuce_right'
];

export const horizontalOrientationOrder = ['top', 'middle', 'bottom'];

export const verticalOrientationOrder = ['left', 'middle', 'right'];

export default function AveragesBadges(props) {
  const {
    width,
    height,
    graphicType,
    orientation,
    dataNoAverages: _dataNoAverages,
    dataWithAverages: _dataWithAverages
  } = props;

  const isMatchReport = useRecoilValue(isMatchReportState);

  let dataNoAverages;
  let dataWithAverages;
  let coordinates;

  if (isReturnOrRallyPlacement(graphicType) && orientation === 'horizontal') {
    dataNoAverages = _dataNoAverages?.horizontal;
    dataWithAverages = _dataWithAverages?.horizontal;
    coordinates = coordinatesPlacementHorizontal;
  } else if (isReturnOrRallyPlacement(graphicType) && orientation === 'vertical') {
    dataNoAverages = _dataNoAverages?.vertical;
    dataWithAverages = _dataWithAverages?.vertical;
  } else {
    dataNoAverages = _dataNoAverages;
    dataWithAverages = _dataWithAverages;
  }

  if (graphicType === courtGraphicTypes.RETURN_CONTACT_POINT) coordinates = coordinatesReturnContactPoint;
  else if (graphicType === courtGraphicTypes.RALLY_CONTACT_POINT) coordinates = coordinatesRallyContactPoint;

  return (
    <Box
      display={
        isMatchReport
          ? 'initial'
          : {
              base: 'none',
              md: 'initial'
            }
      }
    >
      {graphicType === courtGraphicTypes.SERVE_DIRECTION && (
        <AveragesServeDirection width={width} height={height} {...props} />
      )}
      {isReturnOrRallyPlacementVertical(graphicType, orientation) && (
        <AveragesReturnPlacementVertical
          width={width}
          height={height}
          {...props}
          dataNoAverages={dataNoAverages}
          dataWithAverages={dataWithAverages}
        />
      )}
      {(isReturnOrRallyPlacementHorizontal(graphicType, orientation) || isContactPoint(graphicType)) && (
        <AveragesHorizontal
          coordinates={coordinates}
          width={width}
          height={height}
          {...props}
          dataNoAverages={dataNoAverages}
          dataWithAverages={dataWithAverages}
        />
      )}
    </Box>
  );
}
