import { Alert as ChakraAlert, AlertIcon, AlertDescription } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function Alert(props) {
  const { status, message } = props;
  return (
    <ChakraAlert status={status} variant="left-accent">
      <AlertIcon />
      <AlertDescription>{message}</AlertDescription>
    </ChakraAlert>
  );
}

Alert.propTypes = {
  message: PropTypes.string,
  status: PropTypes.oneOf(['success', 'error', 'warning', 'info'])
};
