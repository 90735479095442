import { Player } from '_mockData/apiResponses/players/playersTypes';
import isEmpty from 'lodash/isEmpty';
import { FormState, Search } from 'players/components/types';

import customSitesConfig from '_shared/config/customSitesConfig';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import { searchByString } from '_shared/utils/stringUtil';

export function getInitialPlayersFormState() {
  return {
    gender: customSitesConfig.playersListDefaultGender
  };
}

export function searchPlayers(data: Player[], formState: FormState, playersListPlayerGender: string) {
  if (isEmpty(data)) return [];
  let result = data;
  if (playersListPlayerGender && playersListPlayerGender !== 'all') {
    result = data.filter((item) => item?.gender === playersListPlayerGender);
  } else if (!isEmpty(formState.gender)) {
    result = data.filter((item) => item?.gender === formState.gender);
  }
  if (formState.playerName) {
    result = searchByString(result, formState.playerName, 'full_name');
  }
  return result;
}

// this is quite fiddly due to dealing with empty params
export function getNewFormState(formState: FormState, search: Search, playersListPlayerGender: string) {
  if (!formState && !search && !playersListPlayerGender) return null;
  const genderExists = search.gender || search.gender === '';
  if (playersListPlayerGender !== 'all') {
    // we aren't showing gender on the page, so only need to deal with player name, but set the gender to config one
    return search.playerName
      ? { ...formState, gender: playersListPlayerGender, playerName: search.playerName }
      : { ...formState, gender: playersListPlayerGender };
  }
  if (genderExists && search.playerName) {
    // both gender and name set
    return { ...formState, gender: search.gender, playerName: search.playerName };
  } else if (genderExists && !search.playerName) {
    // only gender set
    return { ...formState, gender: search.gender };
  } else if (!genderExists && search.playerName) {
    // only name  set
    return { ...formState, playerName: search.playerName };
  }
  return formState;
}

export function getRankingMovementStyle(rankingMovement: string) {
  switch (rankingMovement) {
    case 'up':
      return {
        rankMovementColour: 'success.500',
        rankMovementIcon: 'arrowUp'
      };
    case 'down':
      return {
        rankMovementColour: 'error.500',
        rankMovementIcon: 'arrowDown'
      };
    case 'new':
      return {
        rankMovementColour: 'primary.500',
        rankMovementIcon: 'new'
      };
    default:
      return {
        rankMovementColour: 'gray.500',
        rankMovementIcon: null
      };
  }
}

export function getRankingNumberStyle(playerRank: number) {
  switch (true) {
    case playerRank < 10:
      return {
        rankFontSize: '4xl',
        rankLineHeight: '30px',
        rankWidth: '35px',
        rankFontWeight: 'normal'
      };
    case playerRank < 100:
      return {
        rankFontSize: '2xl',
        rankLineHeight: '20px',
        rankWidth: isWimbPortal() ? '40px' : '35px',
        rankFontWeight: 'semibold'
      };
    default:
      return {
        rankFontSize: 'xl',
        rankLineHeight: '16px',
        rankWidth: '50px',
        rankFontWeight: 'semibold'
      };
  }
}

export function getFormattedPlayerName(firstName: string, lastName: string, initial: string) {
  if (isEmpty(firstName) || isEmpty(lastName)) return '';
  const charLimit = isWimbPortal() ? 15 : 20;
  const adjustedFirstname = firstName.length + lastName.length > charLimit ? initial : firstName;
  if (customSitesConfig.playersListSurnameFirst) {
    return `${lastName}, ${adjustedFirstname}`;
  }
  return firstName.length + lastName.length > charLimit ? `${initial}. ${lastName}` : `${firstName} ${lastName}`;
}
