import React from 'react';

import './index.css';
import { createRoot } from 'react-dom/client';

import { isAtpMediaPortal, isAtpTourPortal, isWimbPortal } from '_shared/utils/environment/currentEnv';

import App from './App';
import reportWebVitals from './reportWebVitals';

// add specific site global css conditionally
if (isWimbPortal()) {
  import('./_customSites/wimb/theme/css/wimb.css');
} else if (isAtpTourPortal()) {
  import('./_customSites/atptour/theme/css/atptour.css');
} else if (isAtpMediaPortal()) {
  import('./_customSites/atpmedia/theme/css/atpmedia.css');
}

// Start mock service worker conditionally
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MSW === 'on') {
  const { worker } = require('./_mockData/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
}

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
