// margins used across the site

export const maxSiteWidth = 1440;
export const mainAreaTop = 16;
export const mainAreaPaddingX = 20;
export const mainAreaWidth = 1137;
export const sideMenuWidth = 195;
export const sideMenuWidthMobile = 210;
export const sideMenuNarrowWidth = 54;
export const topMenuHeight = 51;
export const topMenuMobileHeight = 72;
export const matchHeaderHeight = 121;
export const mainAreaMarginLeft = 20;
export const mainAreaMaxWidth = 1440;
export const mainAreaMaxWidthScout = 1440;
export const mobileHeaderHeight = 40;
export const mobileMatchHeaderHeight = 290;

export const showScrollUpArrowDepth = 400;
