import { Link } from '@tanstack/react-location';
import PropTypes from 'prop-types';
import { FORM_FIELDS, viewingTypes } from 'schedule/constants/scheduleConstants';
import { convertArrayToButtonData } from 'schedule/util/tournamentUtil';

import customSitesConfig from '_shared/config/customSitesConfig';
import {
  Flex,
  ButtonGroup,
  FormStandard,
  Box,
  Text,
  Icon,
  useBreakpointValue,
  Select,
  ButtonGroupSingle
} from '_shared/designSystem/components';
import { formFieldTypes } from '_shared/designSystem/components/form/formTypes';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';

export default function ScheduleSearch(props) {
  const { formState, handleFormStateUpdate, rounds, days, handleWimbledonDayClick, viewingType, tournamentId } = props;

  const DROPDOWN_MIN_ROUNDS = 7;
  const DROPDOWN_MIN_DAYS = 10;

  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false
    },
    { ssr: false }
  );

  const handleDropDownSelect = (event, field) => {
    handleFormStateUpdate(field, event.target.value);
  };

  return (
    <Flex rowGap="16px" flexWrap="wrap" bg="white">
      <Box display={{ base: 'initial', md: 'none' }} pt="19px" pr={1}>
        <Link to={customSitesConfig.tournamentsUrlPath}>
          <Icon name="arrowLeft" color="primary.500" height={7} width={7} />
        </Link>
      </Box>
      <Flex direction="column">
        <Text fontSize="xs" ml={1}>
          Round
        </Text>
        {isMobile && rounds?.length > DROPDOWN_MIN_ROUNDS ? (
          <Select
            mr={5}
            value={formState.round || 'All'}
            size="sm"
            w="100px"
            onChange={(e) => handleDropDownSelect(e, FORM_FIELDS.ROUND)}
          >
            {rounds.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Select>
        ) : (
          <ButtonGroup
            data={convertArrayToButtonData(rounds)}
            paramName={FORM_FIELDS.ROUND}
            handleButtonClick={handleFormStateUpdate}
            selectedItem={formState.round}
          />
        )}
      </Flex>
      <Box mr="20px">
        <FormStandard
          formFieldsData={[
            {
              type: formFieldTypes.TEXT,
              label: 'Search Players',
              fieldId: 'playerName'
            }
          ]}
          formInitialValues={
            formState || {
              playerName: ''
            }
          }
          handleFormUpdated={handleFormStateUpdate}
          enableReinitialize={true}
        />
      </Box>
      {isWimbPortal() && [146375, 146453].includes(tournamentId) && (
        <Box mt="18px">
          <ButtonGroupSingle
            label="Today"
            isSelected={viewingType === viewingTypes.COURT_VIEW}
            onClick={() => handleWimbledonDayClick()}
          />
        </Box>
      )}
      {!isWimbPortal() && (
        <Flex direction="column">
          <Text fontSize="xs" ml={1}>
            Day
          </Text>
          {isMobile && days?.length > DROPDOWN_MIN_DAYS ? (
            <Select
              value={formState.day || ''}
              size="sm"
              w="100px"
              onChange={(e) => handleDropDownSelect(e, FORM_FIELDS.DAY)}
            >
              <option key="Select" value="">
                Select
              </option>
              {days.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Select>
          ) : (
            <ButtonGroup
              data={convertArrayToButtonData(days, true)}
              paramName={FORM_FIELDS.DAY}
              handleButtonClick={handleFormStateUpdate}
              selectedItem={formState.day}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
}

ScheduleSearch.propTypes = {
  formState: PropTypes.object,
  handleFormStateUpdate: PropTypes.func,
  rounds: PropTypes.array,
  days: PropTypes.array
};
