import { useNavigate } from '@tanstack/react-location';
import { courtGraphicTypes } from 'match/_shared/matchConstants';
import { Averages, OutShotsSwitch, ServePlusOneSwitch } from 'match/components/_shared/Filtering';
import ServiceBoxLocationGraphicTooltip from 'match/components/_shared/ServiceBoxLocationGraphicToolTip';
import { getServeLocationButtons, getServeLocationMobileButtons } from 'match/utils/courtGraphicsUtil';
import PropTypes from 'prop-types';
import { getButtonGroup } from 'scout/util/scoutCourtGraphicsUtil';

import { Flex, Alert, Text, ButtonGroup } from '_shared/designSystem/components';
import { useBreakpointValue } from '_shared/designSystem/components';
import { convertBooleanToYesNo } from '_shared/utils/stringUtil';

export default function CourtGraphicsFilters({
  courtGraphicsFiltersValues,
  graphicType,
  handleButtonClick,
  showAverages,
  setShowAverages,
  setServePlusOne,
  servePlusOne,
  surface
}) {
  const navigate = useNavigate();

  if (courtGraphicsFiltersValues?.averagesType !== 'placement' && courtGraphicsFiltersValues?.outShots) {
    handleButtonClick('outShots', !courtGraphicsFiltersValues?.outShots);
    navigate({ search: (old) => ({ ...old, outShots: 'no' }) });
  }

  const handleOutShotsToggle = () => {
    const newOutShotsValue = !courtGraphicsFiltersValues?.outShots;
    handleButtonClick('outShots', newOutShotsValue);
    navigate({ search: (old) => ({ ...old, outShots: convertBooleanToYesNo(newOutShotsValue) }) });
  };

  const getServeLocationButtonDataFunction = useBreakpointValue({
    base: getServeLocationMobileButtons,
    md: getServeLocationButtons
  });
  const showTooltip = useBreakpointValue({ base: true, md: false });

  switch (graphicType) {
    case courtGraphicTypes.SERVE_DIRECTION:
      return (
        <Flex gap="16px" flexWrap="wrap" mb={5}>
          {getButtonGroup(courtGraphicTypes.SERVE_DIRECTION, 'serve', courtGraphicsFiltersValues, handleButtonClick)}
          {getButtonGroup(
            courtGraphicTypes.SERVE_DIRECTION,
            'pressureBreak',
            courtGraphicsFiltersValues,
            handleButtonClick
          )}
          {getButtonGroup(
            courtGraphicTypes.SERVE_DIRECTION,
            'averagesType',
            courtGraphicsFiltersValues,
            handleButtonClick
          )}
          {courtGraphicsFiltersValues.averagesType === 'placement' && (
            <OutShotsSwitch outShots={courtGraphicsFiltersValues?.outShots} setOutShots={handleOutShotsToggle} />
          )}
          <Averages showAverages={showAverages} setShowAverages={setShowAverages} />
          {courtGraphicsFiltersValues.serve === 1 && courtGraphicsFiltersValues.outShots === true && (
            <Alert
              message="Some extreme landing placements are caused by a serve clipping the net and then landing deep in the court"
              status="info"
            />
          )}
        </Flex>
      );
    case courtGraphicTypes.RETURN_PLACEMENT:
      return (
        <Flex gap="16px" flexWrap="wrap" mb={5}>
          {getButtonGroup(courtGraphicTypes.RETURN_PLACEMENT, 'serve', courtGraphicsFiltersValues, handleButtonClick)}
          {getButtonGroup(
            courtGraphicTypes.RETURN_PLACEMENT,
            'orientation',
            courtGraphicsFiltersValues,
            handleButtonClick
          )}
          {getButtonGroup(
            courtGraphicTypes.RETURN_PLACEMENT,
            'averagesType',
            courtGraphicsFiltersValues,
            handleButtonClick
          )}
          <Flex direction="row" gap={2} alignItems="center">
            <Text fontSize="sm" fontWeight="medium" color="grey.700">
              Serve Location
            </Text>
            {showTooltip && <ServiceBoxLocationGraphicTooltip surface={surface} />}
            <ButtonGroup
              paramName="serveLocation"
              getButtonDataFunction={getServeLocationButtonDataFunction}
              handleButtonClick={handleButtonClick}
              selectedItemMultiple={courtGraphicsFiltersValues.serveLocation}
            />
          </Flex>
          {courtGraphicsFiltersValues.averagesType === 'placement' && (
            <OutShotsSwitch outShots={courtGraphicsFiltersValues?.outShots} setOutShots={handleOutShotsToggle} />
          )}
          <Averages showAverages={showAverages} setShowAverages={setShowAverages} />
        </Flex>
      );
    case courtGraphicTypes.RALLY_PLACEMENT:
      return (
        <Flex gap="16px" flexWrap="wrap" mb={5}>
          <ServePlusOneSwitch servePlusOne={servePlusOne} handleButtonClick={setServePlusOne} />
          {getButtonGroup(
            courtGraphicTypes.RALLY_PLACEMENT,
            'forehandBackhand',
            courtGraphicsFiltersValues,
            handleButtonClick
          )}
          {getButtonGroup(
            courtGraphicTypes.RALLY_PLACEMENT,
            'orientation',
            courtGraphicsFiltersValues,
            handleButtonClick
          )}
          {courtGraphicsFiltersValues.averagesType === 'placement' && (
            <OutShotsSwitch outShots={courtGraphicsFiltersValues?.outShots} setOutShots={handleOutShotsToggle} />
          )}
          <Averages showAverages={showAverages} setShowAverages={setShowAverages} />
        </Flex>
      );
    default:
      return null;
  }
}

CourtGraphicsFilters.propTypes = {
  courtGraphicsFiltersValues: PropTypes.object,
  graphicType: PropTypes.string,
  handleButtonClick: PropTypes.func,
  showAverages: PropTypes.bool,
  setShowAverages: PropTypes.func
};
