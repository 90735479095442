import { Text, Flex } from '_shared/designSystem/components';

const SubHeading = ({ children }) => (
  <Flex justify="center">
    <Text fontSize="md" fontWeight="medium" mb={5}>
      {children}
    </Text>
  </Flex>
);

export default SubHeading;
