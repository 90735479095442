import { courtGraphicTypes } from 'match/_shared/matchConstants';
import CourtContainer from 'match/components/court-graphics/court/CourtContainer';
import PageHeading from 'matchReport/components/_shared/PageHeading';
import PropTypes from 'prop-types';
import PageLayout from 'scoutReport/components/PageLayout';
import ReturnPlacementStatsTable from 'scoutReport/components/tables/ReturnPlacementStatsTable';

import { Box, Flex } from '_shared/designSystem/components';

export default function ReturnPlacementPage(props) {
  const { playerName, courtGraphicsData1stReturn, courtGraphicsData2ndReturn, surface } = props;
  return (
    <PageLayout playerName={playerName}>
      <Flex direction="column" gap={5}>
        <Flex direction="column" gap={3}>
          <PageHeading>1st Return Placement</PageHeading>
          <Box w={900}>
            <CourtContainer
              surface={surface}
              graphicType={courtGraphicTypes.RETURN_PLACEMENT}
              shots={courtGraphicsData1stReturn?.shots}
              dataNoAverages={courtGraphicsData1stReturn?.averages?.placement}
              dataWithAverages={null}
              showAverages={false}
              averagesLoading={false}
              orientation="vertical"
            />
          </Box>
          <ReturnPlacementStatsTable courtGraphicsDataNoAverages={courtGraphicsData1stReturn} />
        </Flex>
        <Flex direction="column" gap={3}>
          <PageHeading>2nd Return Placement</PageHeading>
          <Box w={900}>
            <CourtContainer
              surface={surface}
              graphicType={courtGraphicTypes.RETURN_PLACEMENT}
              shots={courtGraphicsData2ndReturn?.shots}
              dataNoAverages={courtGraphicsData2ndReturn?.averages?.placement}
              dataWithAverages={null}
              showAverages={false}
              averagesLoading={false}
              orientation="vertical"
            />
          </Box>
          <ReturnPlacementStatsTable courtGraphicsDataNoAverages={courtGraphicsData2ndReturn} />
        </Flex>
      </Flex>
    </PageLayout>
  );
}

ReturnPlacementPage.propTypes = {
  playerName: PropTypes.string,
  surface: PropTypes.string,
  courtGraphicsData1stReturn: PropTypes.object,
  courtGraphicsData2ndReturn: PropTypes.object
};
