export function updateServeLocationFilters(filters, value) {
  const updatedFilters = { ...filters };

  if (!Array.isArray(updatedFilters.serveLocation)) {
    updatedFilters.serveLocation = [];
  }

  const currentIndex = updatedFilters.serveLocation.indexOf(value);
  if (currentIndex === -1) {
    updatedFilters.serveLocation.push(value);
  } else {
    updatedFilters.serveLocation.splice(currentIndex, 1);
  }

  return updatedFilters;
}
