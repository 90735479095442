export const checkPermission = (permissionName, permissionList) => {
  if (process.env.NODE_ENV === 'test' || process.env.REACT_APP_ENV === 'dev' || process.env.STORYBOOK === 'on')
    return true;
  if (!permissionName || !permissionList) {
    return false;
  }

  if (permissionList.includes('*')) {
    return true;
  }

  return permissionList.includes(permissionName);
};
