import axios from 'axios';

import { apiClient } from '_shared/dataFetching/apiConfig';
import { getAuthApiUrl } from '_shared/dataFetching/urlConfig';

export const wimbService = {
  async validateAuthorisationCode({ authorisationCode }) {
    const formData = new URLSearchParams();
    formData.append('grant_type', 'authorisation');
    formData.append('code', authorisationCode);
    formData.append('env', process.env.REACT_APP_WIMB_ENV);
    const url = `${getAuthApiUrl()}wimb/token`;
    const res = await axios({
      method: 'post',
      url,
      data: formData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    const data = res.data;
    return data;
  },
  async getPlayerDetails({ tourId }) {
    const url = `wimb/player_details/?tour_id=${tourId}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  }
};
