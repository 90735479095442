import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Text, Box, Table, Tbody, Tr, Td, Icon, Flex } from '_shared/designSystem/components';

export default function LoadingStatus(props) {
  const { data } = props;
  return (
    <Flex justify="center" mt="150px" pb="150px">
      <Box>
        <Text mb={5} fontSize="xl" fontWeight="bold" color="grey.700">
          Loading Status
        </Text>
        <Table variant="unstyled" color="grey.700" size="sm">
          <Tbody>
            {data.map((item) => (
              <Tr key={item.label}>
                <Td w="400px">{item.label}</Td>
                <Td w="600px">
                  {item.isLoading ? (
                    <LoadingBars isLoading={item.isLoading} />
                  ) : (
                    <Icon name="tick" color="primary.500" height={5} width={5} />
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Flex>
  );
}

LoadingStatus.propTypes = {
  data: PropTypes.array
};

const LoadingBars = () => {
  const [count, setCount] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [count]);

  const boxes = Array.from({ length: count }, (_, index) => <Box key={index} w="10px" h="20px" bg="primary.500" />);

  return <Flex gap={1}>{boxes}</Flex>;
};
