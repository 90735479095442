import { Flex, Icon, Text, Center } from '_shared/designSystem/components';

import { BACK_TO_WIMBLEDON_URL } from '../constants/wimbMatchConsts';
import { removeMyWAuthTokens } from '../services/wimbLocalStorage';

export const goBackToWimbledon = () => {
  removeMyWAuthTokens();
  window.location.replace(BACK_TO_WIMBLEDON_URL);
};

export const BackToWimbledonLink = () => {
  return (
    <Flex gap={1} cursor="pointer" justify="center" align="center" onClick={() => goBackToWimbledon()}>
      <Center>
        <Icon width="12px" height="12px" name="arrowLeft" color="grey.600" />
      </Center>
      <Text fontSize="xs" fontWeight="medium" color="grey.500">
        Back to wimbledon.com
      </Text>
    </Flex>
  );
};
