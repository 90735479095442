import { PERFORMANCE_METRICS_TYPES } from 'match/_shared/matchConstants';

export const componentOrderDesktop = [
  PERFORMANCE_METRICS_TYPES.PERFORMANCE_GRAPH,
  PERFORMANCE_METRICS_TYPES.BALANCE_OF_POWER,
  PERFORMANCE_METRICS_TYPES.MOMENTUM,
  PERFORMANCE_METRICS_TYPES.MATCH_STATISTICS,
  PERFORMANCE_METRICS_TYPES.BASELINE_BATTLES,
  PERFORMANCE_METRICS_TYPES.WIN_PROBABILITY,
  PERFORMANCE_METRICS_TYPES.SERVE_EFFECTIVENESS,
  PERFORMANCE_METRICS_TYPES.PERFORMANCE_RATING,
  PERFORMANCE_METRICS_TYPES.ATTACK_DEFENCE,
  PERFORMANCE_METRICS_TYPES.SHOT_QUALITY,
  PERFORMANCE_METRICS_TYPES.SHOT_VARIATION,
  PERFORMANCE_METRICS_TYPES.DATA_COMPLETENESS,
  PERFORMANCE_METRICS_TYPES.SERVE_RETURN_GAME_PERFORMANCE
];

export const componentOrderMobile = [
  PERFORMANCE_METRICS_TYPES.PERFORMANCE_RATING,
  PERFORMANCE_METRICS_TYPES.BALANCE_OF_POWER,
  PERFORMANCE_METRICS_TYPES.MOMENTUM,
  PERFORMANCE_METRICS_TYPES.ATTACK_DEFENCE,
  PERFORMANCE_METRICS_TYPES.SHOT_QUALITY,
  PERFORMANCE_METRICS_TYPES.PERFORMANCE_GRAPH,
  PERFORMANCE_METRICS_TYPES.MATCH_STATISTICS,
  PERFORMANCE_METRICS_TYPES.BASELINE_BATTLES,
  PERFORMANCE_METRICS_TYPES.WIN_PROBABILITY,
  PERFORMANCE_METRICS_TYPES.SERVE_EFFECTIVENESS,
  PERFORMANCE_METRICS_TYPES.SHOT_VARIATION,
  PERFORMANCE_METRICS_TYPES.DATA_COMPLETENESS,
  PERFORMANCE_METRICS_TYPES.SERVE_RETURN_GAME_PERFORMANCE
];

export const numberOfMetricsInLeftColumnDesktop = 7;
