import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { surfaceTypes } from '_shared/constants/matchTypes';
import { Flex, Box, CourtSurface2dFull } from '_shared/designSystem/components';
import CourtArrow from '_shared/designSystem/components/court/CourtArrow';
import { courtArrowTypes } from '_shared/designSystem/components/court/courtConstants';
import colors from '_shared/designSystem/theme/colors';
import { isMatchReportState } from '_shared/globalState/atoms';

export default function CourtWithArrowThumbnail(props) {
  const { surface, arrowType, playType } = props;
  const isMatchReport = useRecoilValue(isMatchReportState);

  const viewBoxSize = '180 180 600 1200';

  return (
    <Flex data-testid="courtWithArrowThumbnail" h="200px" w="100px" position="relative" overflow="hidden">
      <Box transform={isMatchReport ? 'translate(-4px)' : 'none'}>
        <ArrowsThumbnail arrowType={arrowType} playType={playType} surface={surface} />
      </Box>
      <CourtSurface2dFull surface={surface} viewBoxSize={viewBoxSize} />
    </Flex>
  );
}

CourtWithArrowThumbnail.propTypes = {
  surface: PropTypes.oneOf(['hard', 'grass', 'clay']),
  arrowType: PropTypes.oneOf([
    'diagonal_left_to_right',
    'diagonal_right_to_left',
    'straight_left',
    'straight_right',
    'straight_left_and_diagonal_right',
    'straight_right_and_diagonal_left',
    'v_shape_from_baseline',
    'a_shape_from_baseline',
    'left_to_middle_from_baseline',
    'right_to_middle_from_baseline',
    'volley_straight_left',
    'volley_straight_right',
    'volley_from_net',
    'volleys',
    'between_drop_shot_and_center',
    'drop_shots'
  ]),
  playType: PropTypes.oneOf(['all', 'winning', 'losing'])
};

export const ArrowsThumbnail = (props) => {
  const { arrowType, playType, surface } = props;
  let color = colors.grey[300];
  if (playType === 'winning') color = surface === surfaceTypes.GRASS ? colors.success[600] : colors.success[500];
  else if (playType === 'losing') color = surface === surfaceTypes.GRASS ? colors.rose[500] : colors.rose[300];
  switch (arrowType) {
    case courtArrowTypes.STRAIGHT_LEFT:
      return <ArrowThumbnail color={color} top={38} left={-45} angle={0} {...props} />;
    case courtArrowTypes.STRAIGHT_RIGHT:
      return <ArrowThumbnail color={color} top={38} left={2} angle={0} {...props} />;
    case courtArrowTypes.DIAGONAL_LEFT_TO_RIGHT:
      return <ArrowThumbnail color={color} top={25} left={0} angle={14} {...props} />;
    case courtArrowTypes.DIAGONAL_RIGHT_TO_LEFT:
      return <ArrowThumbnail color={color} top={48} left={-48} angle={-14} {...props} />;
    case courtArrowTypes.V_SHAPE_FROM_BASELINE:
      return (
        <>
          <ArrowThumbnail color={color} top={50} left={-56} angle={-11} {...props} />
          <ArrowThumbnail color={color} top={32} left={10} angle={11} {...props} />
        </>
      );
    case courtArrowTypes.A_SHAPE_FROM_BASELINE:
      return (
        <>
          <ArrowThumbnail color={color} top={31} left={-15} angle={11} {...props} />
          <ArrowThumbnail color={color} top={50} left={-30} angle={-11} {...props} />
        </>
      );
    case courtArrowTypes.LEFT_TO_MIDDLE_FROM_BASELINE:
      return <ArrowThumbnail color={color} top={30} left={-20} angle={9} {...props} />;
    case courtArrowTypes.RIGHT_TO_MIDDLE_FROM_BASELINE:
      return <ArrowThumbnail color={color} top={45} left={-23} angle={-9} {...props} />;
    case courtArrowTypes.VOLLEY_STRAIGHT_LEFT:
      return <ArrowThumbnail color={color} top={75} left={-42} angle={0} arrowType={arrowType} {...props} />;
    case courtArrowTypes.VOLLEY_STRAIGHT_RIGHT:
      return <ArrowThumbnail color={color} top={75} left={0} angle={0} arrowType={arrowType} {...props} />;
    case courtArrowTypes.VOLLEY_FROM_NET:
      return (
        <>
          <ArrowThumbnail color={color} top={80} left={-72} angle={-20} arrowType={arrowType} {...props} />
          <ArrowThumbnail color={color} top={46} left={24} angle={20} arrowType={arrowType} {...props} />
        </>
      );
    case courtArrowTypes.VOLLEYS:
      return (
        <>
          <ArrowThumbnail color={color} top={96} left={-72} angle={-16} arrowType={arrowType} {...props} />
          <ArrowThumbnail color={color} top={68} left={25} angle={16} arrowType={arrowType} {...props} />
        </>
      );
    case courtArrowTypes.BETWEEN_DROP_SHOT_AND_CENTER:
    case courtArrowTypes.DROP_SHOTS:
      return <ArrowThumbnail color={color} top={60} left={-21.5} angle={0} arrowType={arrowType} {...props} />;
    case courtArrowTypes.FOREHAND_INSIDE_IN_OUT:
      return <ArrowThumbnail color={color} top={75} left={-21.5} angle={0} arrowType={arrowType} {...props} />;
    case courtArrowTypes.STRAIGHT_LEFT_AND_DIAGONAL_RIGHT:
      return (
        <>
          <ArrowThumbnail color={color} top={38} left={-45} angle={0} {...props} />
          <ArrowThumbnail color={color} top={12} left={15} angle={22} {...props} />
        </>
      );
    case courtArrowTypes.STRAIGHT_RIGHT_AND_DIAGONAL_LEFT:
      return (
        <>
          <ArrowThumbnail color={color} top={38} left={2} angle={0} {...props} />
          <ArrowThumbnail color={color} top={50} left={-66} angle={-22} {...props} />
        </>
      );
    default:
      return null;
  }
};

const ArrowThumbnail = (props) => (
  <Box position="absolute">
    <CourtArrow {...props} height="80px" gap={6} thumbnailSvgHeight="350px" />
  </Box>
);
