import React, { useState, useEffect } from 'react';

import { isWimbMyWSession } from '_customSites/wimb/services/wimbLocalStorage';
import { refreshAccessTokenUsingMyWRefreshToken } from '_customSites/wimb/services/wimbMyWRefresh';
import Ably from 'ably';
import { AblyProvider } from 'ably/react';

import { refreshUserTokens } from '_shared/dataFetching/auth';
import { getApiUrl } from '_shared/dataFetching/urlConfig';
import { checkIfAccessTokenExpired } from '_shared/utils/authUtil';
import { isDevOrTest } from '_shared/utils/environment/currentEnv';

export default function AblyConnection({ children }) {
  const [ablyClient, setAblyClient] = useState(null);

  useEffect(() => {
    if (!isDevOrTest()) {
      const authCallback = async (tokenParams, callback) => {
        try {
          let accessToken = localStorage.getItem('accessToken');
          const isAccessTokenExpired = checkIfAccessTokenExpired(accessToken);
          if (isAccessTokenExpired) {
            const res = isWimbMyWSession() ? await refreshAccessTokenUsingMyWRefreshToken() : await refreshUserTokens();
            const data = await res.json();
            accessToken = data?.access_token;
          }
          const response = await fetch(`${getApiUrl()}realtime/ably/access_token`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
          const tokenDetails = await response.json();
          callback(null, tokenDetails);
        } catch (error) {
          callback(error, null);
        }
      };
      const ably = new Ably.Realtime({
        authCallback,
        clientId: localStorage.getItem('userName')
      });
      setAblyClient(ably);
      return () => {
        ably.close();
      };
    }
  }, []);

  if (!ablyClient || isDevOrTest()) return children;

  return <AblyProvider client={ablyClient}>{children}</AblyProvider>;
}
