import { Navigate } from '@tanstack/react-location';
import OidcResponse from '_customSites/wimb/components/OidcResponse';
import PlayerLanding from '_customSites/wimb/components/PlayerLanding';
import WimbLogin from '_customSites/wimb/components/WimbLogin';
import MainLayout from '_layouts/MainLayout';
import Login from 'authentication/components/Login';
import TermsAndConditionsPage from 'authentication/components/termsAndConditions/TermsAndConditionsPage';
import exampleFeatureRoutes from 'exampleFeature/routes';
import headToHeadRoutes from 'headToHead/routes';
import leaderboardRoutes from 'leaderboard/routes';
import matchRoutes from 'match/routes';
import matchListRoutes from 'matchList/routes';
import matchReportRoutes from 'matchReport/routes';
import playersRoutes from 'players/routes';
import scheduleRoutes from 'schedule/routes';
import scoutRoutes from 'scout/routes';
import scoutReportRoutes from 'scoutReport/routes';
import tournamentsRoutes from 'tournaments/routes';

import Page404 from '_shared/errors/Page404';
import { isAtpMediaPortal, isAtpTourPortal, isWimbPortal } from '_shared/utils/environment/currentEnv';

export function getRoutes() {
  if (isWimbPortal()) return wimbRoutes;
  if (isAtpTourPortal()) return atpTourRoutes;
  if (isAtpMediaPortal()) return atpmediaRoutes;
  return tvRoutes;
}

const defaultAuthenticatedRoutes = [
  matchRoutes,
  exampleFeatureRoutes,
  matchListRoutes,
  leaderboardRoutes,
  matchReportRoutes,
  scoutRoutes,
  scoutReportRoutes,
  playersRoutes,
  headToHeadRoutes,
  tournamentsRoutes,
  scheduleRoutes
];

export const tvRoutes = [
  { path: '/', element: <Navigate to="/tournaments" /> },
  { path: '/login', element: <Login /> },
  {
    id: 'routes-behind-auth',
    element: <MainLayout />,
    children: [...defaultAuthenticatedRoutes]
  },
  { path: '*', element: <Page404 /> }
];

export const wimbRoutes = [
  { path: '/', element: <Navigate to={`/tournaments`} /> },
  { path: '/login', element: <WimbLogin /> },
  { path: '/tennisviz-login', element: <Login /> },
  { path: '/oidc_response', element: <OidcResponse /> },
  { path: '/player_landing', element: <PlayerLanding /> },
  {
    id: 'routes-behind-auth',
    element: <MainLayout />,
    children: [
      matchRoutes,
      matchListRoutes,
      leaderboardRoutes,
      scoutRoutes,
      playersRoutes,
      headToHeadRoutes,
      tournamentsRoutes,
      scheduleRoutes
    ]
  },
  { path: '*', element: <Page404 /> }
];

export const atpTourRoutes = [
  { path: '/', element: <Navigate to="/tournaments" /> },
  { path: '/login', element: <Login /> },
  { path: '/terms_and_conditions', element: <TermsAndConditionsPage /> },
  {
    id: 'routes-behind-auth',
    element: <MainLayout />,
    children: [...defaultAuthenticatedRoutes]
  },
  { path: '*', element: <Page404 /> }
];

export const atpmediaRoutes = [
  { path: '/', element: <Navigate to="/tournaments" /> },
  { path: '/login', element: <Login /> },
  {
    id: 'routes-behind-auth',
    element: <MainLayout />,
    children: [...defaultAuthenticatedRoutes]
  },
  { path: '*', element: <Page404 /> }
];
