import { getScoreString } from 'matchReport/components/courtPages/DoubleCourtPage';
import MatchReportTable from 'matchReport/components/courtPages/MatchReportTable';
import PropTypes from 'prop-types';

const ReturnPlacementStatsTable = ({ courtGraphicsDataNoAverages }) => {
  const headings = ['', 'Left', 'Middle', 'Right'];
  const tableData = [
    {
      heading: 'Win',
      data: [
        getScoreString(
          courtGraphicsDataNoAverages?.averages?.placement?.vertical,
          courtGraphicsDataNoAverages?.averages?.win?.vertical,
          'left'
        ),
        getScoreString(
          courtGraphicsDataNoAverages?.averages?.placement?.vertical,
          courtGraphicsDataNoAverages?.averages?.win?.vertical,
          'middle'
        ),
        getScoreString(
          courtGraphicsDataNoAverages?.averages?.placement?.vertical,
          courtGraphicsDataNoAverages?.averages?.win?.vertical,
          'right'
        )
      ]
    }
  ];

  return <MatchReportTable rowHeight={null} headings={headings} tableData={tableData} />;
};

ReturnPlacementStatsTable.propTypes = {
  courtGraphicsDataNoAverages: PropTypes.object
};

export default ReturnPlacementStatsTable;
