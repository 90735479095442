import { useState, useEffect } from 'react';

import last from 'lodash/last';

import { Box, Flex, Text, Slider, Button, ButtonWithIcon, Icon } from '_shared/designSystem/components';

const ScoreRangeFilter = ({
  data: matchData,
  submitScoreRange,
  onClose,
  currentScoreRangeFilter,
  currentSetFilter
}) => {
  const data = matchData?.match_score?.all_scores;

  const totalGames = getTotalNumberOfGames(data) > 10 ? 10 : getTotalNumberOfGames(data);
  const defaultLastNGames = totalGames < 4 ? totalGames : 4;

  const [startSet, setStartSet] = useState(null);
  const [startGame, setStartGame] = useState(null);
  const [startGameScore, setStartGameScore] = useState(null);
  const [startPoint, setStartPoint] = useState(null);
  const [startPointScore, setStartPointScore] = useState(null);
  const [finishSet, setFinishSet] = useState(null);
  const [finishGame, setFinishGame] = useState(null);
  const [finishGameScore, setFinishGameScore] = useState(null);
  const [finishPoint, setFinishPoint] = useState(null);
  const [finishPointScore, setFinishPointScore] = useState(null);
  const [sliderGames, setSliderGames] = useState(defaultLastNGames);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (currentScoreRangeFilter) {
      setStartSet(currentScoreRangeFilter.startSet);
      setStartGame(currentScoreRangeFilter.startGame);
      setStartGameScore(currentScoreRangeFilter.startGameScore);
      setStartPoint(currentScoreRangeFilter.startPoint);
      setStartPointScore(currentScoreRangeFilter.startPointScore);
      setFinishSet(currentScoreRangeFilter.finishSet);
      setFinishGame(currentScoreRangeFilter.finishGame);
      setFinishGameScore(currentScoreRangeFilter.finishGameScore);
      setFinishPoint(currentScoreRangeFilter.finishPoint);
      setFinishPointScore(currentScoreRangeFilter.finishPointScore);
      setSliderGames(currentScoreRangeFilter.sliderGames);
    } else if (currentSetFilter) {
      setStartSet(currentSetFilter);
    }
  }, [currentScoreRangeFilter, currentSetFilter]);

  const submitWithRange = () => {
    if (!getFinishPositionIsAfterStartPosition(startSet, startGame, startPoint, finishSet, finishGame, finishPoint)) {
      setError('Finish must be after start');
    } else {
      const formData = {
        chosenType: 'range',
        startSet,
        startGame,
        startGameScore,
        startPoint,
        startPointScore,
        finishSet,
        finishGame,
        finishGameScore,
        finishPoint,
        finishPointScore,
        sliderGames
      };
      const withPointsAdded = addPointsWhenNotSelected(data, formData);
      submitScoreRange(getApiMinMax(withPointsAdded));
      onClose();
    }
  };

  const submitWithSlider = () => {
    const formData = getRangeForLastNGames(data, sliderGames);
    const withPointsAdded = addPointsWhenNotSelected(data, formData);
    submitScoreRange(getApiMinMax(withPointsAdded));
    onClose();
  };

  const clearAllFilters = () => {
    setStartSet(null);
    setStartGame(null);
    setStartPoint(null);
    setStartGameScore(null);
    setStartPointScore(null);
    setFinishSet(null);
    setFinishGame(null);
    setFinishPoint(null);
    setFinishGameScore(null);
    setFinishPointScore(null);
    setSliderGames(defaultLastNGames);
    setError(null);
  };

  const clearFinishFilters = () => {
    setFinishSet(null);
    setFinishGame(null);
    setFinishPoint(null);
    setFinishGameScore(null);
    setFinishPointScore(null);
  };

  return (
    <Box minW="370px" maxW="585px" boxShadow={{ base: 'none', lg: 'md' }} p={6}>
      <Flex direction="column" gap={8}>
        <Flex justify="center">
          <Text fontSize="sm" fontWeight="medium" color="grey.700">
            Filter By Score Range
          </Text>
        </Flex>
        <Flex justify="space-between" align="center" flexWrap="wrap">
          <Text w={100} fontSize="sm" fontWeight="medium" color="grey.700">
            Last {sliderGames} Games
          </Text>
          <Box w="250px">
            <Slider
              value={convertGamesToSliderPercentage(sliderGames, totalGames)}
              onChange={(val) => setSliderGames(convertPercentageToSliderGames(val, totalGames))}
            />
          </Box>
          <Button onClick={() => submitWithSlider()} size="sm">
            Apply
          </Button>
        </Flex>
        <Box>
          {error && (
            <Text mb={3} fontSize="xs" color="error.500">
              {error}
            </Text>
          )}
          <Flex justify="space-between" align="center" flexWrap="wrap">
            {startSet && startGame && (
              <Flex mb={3} gap={1}>
                <Text fontSize="xs" fontWeight="medium" color="grey.700">
                  {getRangeAsText({
                    startSet,
                    startGame,
                    startGameScore,
                    startPoint,
                    startPointScore,
                    finishSet,
                    finishGame,
                    finishGameScore,
                    finishPointScore
                  })}
                </Text>
                <Icon
                  onClick={() => clearAllFilters()}
                  cursor="pointer"
                  name="crossCircle"
                  color="grey.400"
                  height={4}
                  width={4}
                />
              </Flex>
            )}
            {startSet && startGame && finishSet && finishGame && (
              <Button onClick={() => submitWithRange()} size="sm">
                Apply
              </Button>
            )}
          </Flex>
          <Flex direction="column" gap={5}>
            <Text fontSize="xs" color="grey.500">
              Or starting from
            </Text>
            <Flex gap={4}>
              {data?.map((item) => (
                <ButtonWithIcon
                  key={item.set_number}
                  onClick={() => {
                    setStartSet(item.set_number);
                    setStartGame(null);
                    clearFinishFilters();
                    setError(null);
                  }}
                  label={`${nth(item.set_number)} Set`}
                  isSelected={startSet === item.set_number}
                />
              ))}
            </Flex>
            {startSet > 0 && (
              <Flex gap={2} flexWrap="wrap">
                {data[startSet - 1]?.games?.map((item) => {
                  if (item.incomplete) return null;
                  return (
                    <ButtonWithIcon
                      key={item.game_number}
                      onClick={() => {
                        setStartGame(item.game_number);
                        setStartGameScore(item.score);
                        setStartPoint(null);
                        setStartPointScore(null);
                        if (startSet === finishSet) {
                          setFinishGame(null);
                          setFinishGameScore(null);
                          setFinishPoint(null);
                          setFinishPointScore(null);
                        }
                        setError(null);
                      }}
                      label={item.score}
                      isSelected={startGame === item.game_number}
                    />
                  );
                })}
              </Flex>
            )}
            {startGame > 0 && (
              <Flex gap={2} flexWrap="wrap">
                {data[startSet - 1].games[startGame - 1].points.map((item) => (
                  <ButtonWithIcon
                    isSelected={startPoint === item.point_number}
                    onClick={() => {
                      setStartPoint(item.point_number);
                      setStartPointScore(item.score);
                      if (startSet === finishSet && startGame === finishGame) {
                        setFinishPoint(null);
                        setFinishPointScore(null);
                      }
                      setError(null);
                    }}
                    key={item.point_number}
                    label={item.score}
                  />
                ))}
              </Flex>
            )}
          </Flex>
          {startSet && startGame && (
            <Flex mt={5} direction="column" gap={5}>
              <Text fontSize="xs" color="grey.500">
                and finishing at
              </Text>
              <Flex gap={4}>
                {data.map((item) => {
                  if (item.set_number < startSet) return null;
                  return (
                    <ButtonWithIcon
                      key={item.set_number}
                      onClick={() => {
                        setFinishSet(item.set_number);
                        setFinishGame(null);
                        setError(null);
                      }}
                      label={`${nth(item.set_number)} Set`}
                      isSelected={finishSet === item.set_number}
                    />
                  );
                })}
              </Flex>
              {finishSet > 0 && (
                <Flex gap={2} flexWrap="wrap">
                  {data[finishSet - 1]?.games?.map((item) => {
                    if (item.incomplete) return null;
                    if (finishSet === startSet && item.game_number < startGame) return null;
                    return (
                      <ButtonWithIcon
                        key={item.game_number}
                        onClick={() => {
                          setFinishGame(item.game_number);
                          setFinishGameScore(item.score);
                          setFinishPoint(null);
                          setFinishPointScore(null);
                          setError(null);
                        }}
                        label={item.score}
                        isSelected={finishGame === item.game_number}
                      />
                    );
                  })}
                </Flex>
              )}
              {finishGame > 0 && (
                <Flex gap={2} flexWrap="wrap">
                  {data[finishSet - 1].games[finishGame - 1].points.map((item) => {
                    if (
                      finishSet === startSet &&
                      finishGame === startGame &&
                      startPoint > 0 &&
                      item.point_number < startPoint
                    )
                      return null;
                    return (
                      <ButtonWithIcon
                        isSelected={finishPoint === item.point_number}
                        onClick={() => {
                          setFinishPoint(item.point_number);
                          setFinishPointScore(item.score);
                          setError(null);
                        }}
                        key={item.point_number}
                        label={item.score}
                      />
                    );
                  })}
                </Flex>
              )}
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export function nth(n) {
  return `${n}${['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th'}`;
}

export function convertGamesToSliderPercentage(games, totalGames) {
  if (games === 1) return 0;
  if (games === totalGames) return 100;
  return (games / totalGames) * 100;
}

export function convertPercentageToSliderGames(percentage, totalGames) {
  if (percentage === 100) return totalGames;
  if (percentage === 0) return 1;
  return Math.ceil((percentage / 100) * totalGames);
}

export const getTotalNumberOfGames = (data) => {
  if (!data || !Array.isArray(data)) return 0;

  return data.reduce((total, set) => {
    if (Array.isArray(set.games)) {
      return total + set.games.filter((game) => !game.incomplete).length;
    }
    return total;
  }, 0);
};

export function getFinishPositionIsAfterStartPosition(
  startSet,
  startGame,
  startPoint,
  finishSet,
  finishGame,
  finishPoint
) {
  if (finishSet < startSet) return false;
  if (finishSet > startSet) return true;
  if (finishGame < startGame) return false;
  if (finishGame > startGame) return true;
  if (finishPoint < startPoint) return false;
  if (finishPoint > startPoint) return true;
  if (startPoint === null && finishPoint === null) return true;
  if (startPoint === finishPoint) return true;
}

export function getRangeForLastNGames(data, lastNGames) {
  const finishSet = last(data).set_number;
  const finishGame = last(last(data).games).game_number;
  const finishGameScore = data[finishSet - 1].games[finishGame - 1].score;
  const finishPoint = last(data[finishSet - 1].games[finishGame - 1].points).point_number;
  const finishPointScore = last(data[finishSet - 1].games[finishGame - 1].points).score;
  let startSet;
  let startGame;
  let startGameScore;
  if (finishGame >= lastNGames) {
    startGame = finishGame - lastNGames + 1;
    startSet = finishSet;
    startGameScore = data[startSet - 1].games[startGame - 1].score;
  } else {
    const difference = lastNGames - finishGame;
    const penultimateSet = data[data.length - 2];
    startGame = penultimateSet.games.length - difference + 1;
    startSet = finishSet - 1;
    startGameScore = data[startSet - 1].games[startGame - 1].score;
  }
  return {
    startSet,
    startGame,
    startPoint: 1,
    startPointScore: '0-0',
    startGameScore,
    finishSet,
    finishGame,
    finishGameScore,
    finishPoint,
    finishPointScore,
    sliderGames: lastNGames,
    chosenType: 'slider'
  };
}

export function getRangeAsText(formData) {
  const finishGames =
    formData.finishSet && formData.finishGame
      ? ` to ${nth(formData.finishSet)} Set, ${formData.finishGameScore}${
          formData.finishPointScore ? `, ${formData.finishPointScore}` : ''
        }`
      : '';
  return `From ${nth(formData.startSet)} Set, ${formData.startGameScore}${
    formData.startPointScore ? `, ${formData.startPointScore}` : ''
  }${finishGames}`;
}

export function addPointsWhenNotSelected(data, formData) {
  if (!formData.startPoint) {
    formData.startPoint = 1;
    formData.startPointScore = '0-0';
  }
  if (!formData.finishPoint) {
    const lastPoint = last(data[formData.finishSet - 1].games[formData.finishGame - 1].points);
    formData.finishPoint = lastPoint.point_number;
    formData.finishPointScore = lastPoint.score;
  }
  return formData;
}

export function getApiMinMax(formData) {
  const apiMinPoint = `${formData.startSet}-${formData.startGame}-${formData.startPoint}`;
  const apiMaxPoint = `${formData.finishSet}-${formData.finishGame}-${formData.finishPoint}`;
  return {
    ...formData,
    apiMinPoint,
    apiMaxPoint
  };
}

export default ScoreRangeFilter;
