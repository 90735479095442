import { courtGraphicTypes } from 'match/_shared/matchConstants';
import CourtContainer from 'match/components/court-graphics/court/CourtContainer';
import PageHeading from 'matchReport/components/_shared/PageHeading';
import PropTypes from 'prop-types';
import PageLayout from 'scoutReport/components/PageLayout';

import { Box } from '_shared/designSystem/components';

import { ServeDirectionStatsTable } from '../tables/ServeDirectionStatsTable';

export default function CourtGraphicsPage(props) {
  const {
    playerName,
    heading,
    graphicType,
    courtGraphicsDataNoAverages,
    courtGraphicsPressureData,
    courtGraphicsBreakData,
    surface
  } = props;
  return (
    <PageLayout playerName={playerName}>
      <PageHeading>{heading}</PageHeading>
      <Box w={1000} mb={50}>
        <CourtContainer
          surface={surface}
          graphicType={graphicType}
          shots={courtGraphicsDataNoAverages?.shots}
          dataNoAverages={courtGraphicsDataNoAverages?.averages?.placement}
          dataWithAverages={null}
          showAverages={false}
          averagesLoadin={false}
          orientation="vertical"
        />
      </Box>
      {graphicType === courtGraphicTypes.SERVE_DIRECTION && (
        <ServeDirectionStatsTable
          courtGraphicsDataNoAverages={courtGraphicsDataNoAverages}
          pressureData={courtGraphicsPressureData}
          breakData={courtGraphicsBreakData}
        />
      )}
    </PageLayout>
  );
}

CourtGraphicsPage.propTypes = {
  playerName: PropTypes.string,
  heading: PropTypes.string,
  surface: PropTypes.string,
  graphicType: PropTypes.string,
  courtGraphicsDataNoAverages: PropTypes.object,
  courtGraphicsPressureData: PropTypes.object,
  courtGraphicsBreakData: PropTypes.object
};
