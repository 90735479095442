import isEmpty from 'lodash/isEmpty';
import {
  getAveragesTypeButtons,
  getCourtGraphic3d2d,
  getForehandBackhandButtons,
  getOrientationButtons,
  getPressureBreakButtons,
  getServeButtons
} from 'match/utils/courtGraphicsUtil';
import { displayScore } from 'match/utils/transformations';

import { ButtonGroup } from '_shared/designSystem/components';

export function getCourtParamUrl(courtParams) {
  return '';
}

export function getAverageFromCourtStatsData(data, id, averageType) {
  if (isEmpty(data) || id === null) return null;
  const result = data.filter((item) => item.id === id);
  if (isEmpty(result)) return null;
  return displayScore(result[0][`${averageType}_average`], result[0]['score_type']);
}

export function getAveragesItem(data, id) {
  if (isEmpty(data) || id === null) return null;
  const result = data.filter((item) => item.id === id);
  if (isEmpty(result)) return null;
  return result[0];
}

export function getButtonGroup(graphicType, fieldId, courtGraphicsFiltersValues, handleButtonClick) {
  if (!courtGraphicsFiltersValues) return null;
  const buttonData = getButtonDataForGraphic(graphicType, fieldId);
  return (
    <ButtonGroup
      paramName={fieldId}
      data={buttonData}
      handleButtonClick={handleButtonClick}
      selectedItem={courtGraphicsFiltersValues[`${fieldId}`]}
    />
  );
}

export function getButtonDataForGraphic(graphicType, fieldId) {
  switch (fieldId) {
    case 'serve':
      return getServeButtons();
    case 'pressureBreak':
      return getPressureBreakButtons();
    case 'forehandBackhand':
      return getForehandBackhandButtons();
    case 'orientation':
      return getOrientationButtons();
    case 'averagesType':
      return getAveragesTypeButtons(graphicType);
    default:
      return null;
  }
}

export function getInitialCourtParams() {
  return {
    serve: 1,
    servePlusOne: false,
    pressureBreak: 'all',
    forehandBackhand: 'all',
    orientation: 'horizontal',
    averagesType: 'placement',
    outShots: false,
    serveLocation: []
  };
}

export function getReduceBallSizeAmount(shots, graphicType, width) {
  if (isEmpty(shots))
    return {
      diameterRatio: 0,
      minDiameter: 0
    };
  if (shots.length > 1000) {
    if (getCourtGraphic3d2d(graphicType) === '3d') {
      return {
        diameterRatio: 0.008 * 0.3,
        minDiameter: width < 650 ? 2 : 4
      };
    } else {
      return {
        diameterRatio: 0.007 * 0.3,
        minDiameter: width < 650 ? 3 : 5
      };
    }
  }
  if (shots.length > 250) {
    if (getCourtGraphic3d2d(graphicType) === '3d') {
      return {
        diameterRatio: 0.008 * 0.5,
        minDiameter: 3
      };
    } else {
      return {
        diameterRatio: 0.007 * 0.5,
        minDiameter: 4
      };
    }
  }
  if (getCourtGraphic3d2d(graphicType) === '3d') {
    return {
      diameterRatio: 0.008,
      minDiameter: 5
    };
  } else {
    return {
      diameterRatio: 0.007,
      minDiameter: 3
    };
  }
}
