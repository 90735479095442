import { Box } from '_shared/designSystem/components';
import { ErrorMessage } from '_shared/designSystem/components';

export default function MainPageError() {
  return (
    <Box p={4}>
      <ErrorMessage message="Sorry, there has been an error in displaying this page. Please try refreshing." />
    </Box>
  );
}
