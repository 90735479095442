import Confidence from 'match/components/performance/confidence/Confidence';
import { videoTexts } from 'match/constants/videoConstants';
import { updateVideoQualityFlagType } from 'match/constants/videoQualityConstants';
import { useDataQualityFlagQuery, useUpdateDataQualityFlag } from 'match/hooks/matchHooks.js';
import { matchService } from 'match/services/matchService';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';

import { userPermissionTypes } from '_shared/constants/user';
import { Box, Button, ErrorMessage, Flex, Text } from '_shared/designSystem/components';
import { userPermissionsState } from '_shared/globalState/atoms';
import { checkPermission } from '_shared/utils/permissions';

const Completeness = ({ data, matchId }) => {
  const { points_ratio, tracking_points, level1_points } = data;
  const { permissions } = useRecoilValue(userPermissionsState);
  const { data: dataQuality } = useDataQualityFlagQuery(matchId);
  const { toggleDataQualityFlag } = useUpdateDataQualityFlag(matchId, !dataQuality?.flagged);

  const {
    data: dataVideoStatus,
    isFetching: isFetchingVideoStatus,
    error: errorVideoStatus,
    refetch: refetchVideoStatus
  } = useQuery(['matchService_getVideoStatus', matchId], () => matchService.getVideoStatus({ matchId }));

  const videoQualityFlag = dataVideoStatus?.flagged;

  function getActionFromVideoQualityFlag(videoQualityFlag) {
    return videoQualityFlag === true ? updateVideoQualityFlagType.REMOVE : updateVideoQualityFlagType.ADD;
  }

  const { mutate: updateVideoQualityFlag, error: errorUpdateVideoQualityFlag } = useMutation(
    () => matchService.updateVideoQualityFlag({ matchId, action: getActionFromVideoQualityFlag(videoQualityFlag) }),
    {
      onSuccess: () => {
        refetchVideoStatus();
      }
    }
  );

  const checkDataLevel = (percentage) => {
    return percentage >= 90 ? 'success.500' : 'error.500';
  };

  return (
    <Box>
      <Text fontSize="xs" fontWeight="normal" color="grey.400">
        Data Completeness
      </Text>
      <Flex direction="column" gap={4}>
        <Flex>
          <Text fontSize="xs" fontWeight="medium" color={checkDataLevel(points_ratio)} w={50}>
            {points_ratio?.toFixed(1)}%
          </Text>
          <Text fontSize="xs" fontWeight="normal" color="grey.400">
            {tracking_points} / {level1_points} points
          </Text>
        </Flex>
        {checkPermission(userPermissionTypes.DATA_CONFIDENCE, permissions) && <Confidence data={data} />}
      </Flex>
      {checkPermission(userPermissionTypes.UPDATE_DATA_QUALITY_FLAG, permissions) && (
        <Flex direction="column" maxW="300px">
          <Button onClick={toggleDataQualityFlag} size="xs" mt={5} bg="primary.300">
            {dataQuality?.flagged ? 'Remove Manual Data Quality Flag' : 'Set Data Quality Flag'}
          </Button>
          {errorVideoStatus || errorUpdateVideoQualityFlag ? (
            <ErrorMessage message={videoTexts.API_ERROR} />
          ) : (
            <Button
              isLoading={isFetchingVideoStatus}
              onClick={() => updateVideoQualityFlag(videoQualityFlag)}
              size="xs"
              mt={5}
              bg="primary.300"
            >
              {videoQualityFlag ? 'Remove Video Quality Flag' : 'Set Video Quality Flag'}
            </Button>
          )}
        </Flex>
      )}
    </Box>
  );
};

Completeness.propTypes = {
  data: PropTypes.object
};

export default Completeness;
