import { useEffect } from 'react';

import { useMatch } from '@tanstack/react-location';
import isEmpty from 'lodash/isEmpty';
import { useMatchReportHeaderQuery, useMatchReportPerformanceQuery } from 'matchReport/hooks/matchReportHooks';
import { useSetRecoilState } from 'recoil';

import LoadingStatus from '_shared/components/LoadingStatus';
import { Box, Flex, Text } from '_shared/designSystem/components';
import { isMatchReportState } from '_shared/globalState/atoms';
import useFeature from '_shared/utils/hooks/useFeature';
import { getAnyLoading, getLoadingData } from '_shared/utils/loadingUtil';

import DoubleCourtPage from './courtPages/DoubleCourtPage';
import ServeDirectionPage from './courtPages/ServeDirectionPage';
import FrontPage from './FrontPage';
import { Header } from './Header';
import MatchSummary from './MatchSummary';
import MatchSummaryContinued from './MatchSummaryContinued';
import Rally from './Rally';
import ServePlusOneSummary from './serve_plus_one/ServePlusOneSummary';
import ServeReturn from './ServeReturn';
import SetComparison from './SetComparison';
import WinningAndLosingPlaysV2 from './winningAndLosingPlays/WinningAndLosingPlaysV2';

const pageHeight = '1464px';
const pageWidth = '1035px';

const MatchReport = () => {
  const {
    params: { matchId }
  } = useMatch();

  const setIsMatchReport = useSetRecoilState(isMatchReportState);

  const headerRes = useMatchReportHeaderQuery(matchId);
  const performanceRes = useMatchReportPerformanceQuery(matchId);
  const winningAndLosingPlaysEnabledV2 = useFeature('winningAndLosingPlaysV2');

  useEffect(() => {
    setIsMatchReport(true);
  }, [setIsMatchReport]);

  if (headerRes.error || performanceRes.error) throw new Error('Unable to retrieve match data');

  const headerData = headerRes?.data;

  if (isEmpty(headerData?.player_details?.player1?.member1?.full_name)) {
    return <Text>That match could not be found, please check your match ID is correct</Text>;
  }

  const player1Name = headerData?.player_details?.player1?.member1?.last_name;
  const player2Name = headerData?.player_details?.player2?.member1?.last_name;

  const loadingArray = {
    performanceDataIsLoading: performanceRes.isLoading
  };

  const loadingData = getLoadingData(loadingArray);
  const Loading = <LoadingStatus data={loadingData} />;

  const Report = (
    <Box>
      <PageLayout headerData={headerData} hideHeader={true}>
        <FrontPage headerData={headerData} />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <MatchSummary matchId={matchId} performanceData={performanceRes?.data} headerData={headerData} />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <MatchSummaryContinued performanceData={performanceRes?.data} />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <ServeReturn
          type="serve"
          performanceData={performanceRes?.data}
          headerData={headerData}
          player1Name={player1Name}
          player2Name={player2Name}
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <ServeDirectionPage
          headerData={headerData}
          matchId={matchId}
          heading={`1st Serve ${player1Name}`}
          serve={1}
          player="player1"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <ServeDirectionPage
          headerData={headerData}
          matchId={matchId}
          heading={`2nd Serve ${player1Name}`}
          serve={2}
          player="player1"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <ServeDirectionPage
          headerData={headerData}
          matchId={matchId}
          heading={`1st Serve ${player2Name}`}
          serve={1}
          player="player2"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <ServeDirectionPage
          headerData={headerData}
          matchId={matchId}
          heading={`2nd Serve ${player2Name}`}
          serve={2}
          player="player2"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <ServeReturn
          type="return"
          performanceData={performanceRes?.data}
          headerData={headerData}
          player1Name={player1Name}
          player2Name={player2Name}
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <DoubleCourtPage
          headerData={headerData}
          pageType="return"
          matchId={matchId}
          heading={`${player1Name} 1st Return`}
          serve={1}
          player="player1"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <DoubleCourtPage
          headerData={headerData}
          pageType="return"
          matchId={matchId}
          heading={`${player1Name} 2nd Return`}
          serve={2}
          player="player1"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <DoubleCourtPage
          headerData={headerData}
          pageType="return"
          matchId={matchId}
          heading={`${player2Name} 1st Return`}
          serve={1}
          player="player2"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <DoubleCourtPage
          headerData={headerData}
          pageType="return"
          matchId={matchId}
          heading={`${player2Name} 2nd Return`}
          serve={2}
          player="player2"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <ServePlusOneSummary matchId={matchId} player="player1" playerName={player1Name} />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <ServePlusOneSummary matchId={matchId} player="player2" playerName={player2Name} />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <DoubleCourtPage
          headerData={headerData}
          pageType="serve_plus_one"
          matchId={matchId}
          heading={`${player1Name} Serve +1 Forehand`}
          player="player1"
          forehandBackhand="forehand"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <DoubleCourtPage
          headerData={headerData}
          pageType="serve_plus_one"
          matchId={matchId}
          heading={`${player1Name} Serve +1 Backhand`}
          player="player1"
          forehandBackhand="backhand"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <DoubleCourtPage
          headerData={headerData}
          pageType="serve_plus_one"
          matchId={matchId}
          heading={`${player2Name} Serve +1 Forehand`}
          player="player2"
          forehandBackhand="forehand"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <DoubleCourtPage
          headerData={headerData}
          pageType="serve_plus_one"
          matchId={matchId}
          heading={`${player2Name} Serve +1 Backhand`}
          player="player2"
          forehandBackhand="backhand"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <Rally
          page={1}
          performanceData={performanceRes?.data}
          headerData={headerData}
          player1Name={player1Name}
          player2Name={player2Name}
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <Rally
          page={2}
          performanceData={performanceRes?.data}
          headerData={headerData}
          player1Name={player1Name}
          player2Name={player2Name}
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <DoubleCourtPage
          headerData={headerData}
          pageType="rally"
          matchId={matchId}
          heading={`${player1Name} Rally Forehand`}
          player="player1"
          forehandBackhand="forehand"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <DoubleCourtPage
          headerData={headerData}
          pageType="rally"
          matchId={matchId}
          heading={`${player1Name} Rally Backhand`}
          player="player1"
          forehandBackhand="backhand"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <DoubleCourtPage
          headerData={headerData}
          pageType="rally"
          matchId={matchId}
          heading={`${player2Name} Rally Forehand`}
          player="player2"
          forehandBackhand="forehand"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <DoubleCourtPage
          headerData={headerData}
          pageType="rally"
          matchId={matchId}
          heading={`${player2Name} Rally Backhand`}
          player="player2"
          forehandBackhand="backhand"
        />
      </PageLayout>
      {winningAndLosingPlaysEnabledV2 && (
        <>
          <PageLayout headerData={headerData}>
            <WinningAndLosingPlaysV2
              matchId={matchId}
              player="player1"
              playerName={player1Name}
              surface={headerData?.surface}
              volumeType="volume"
              isMatch={true}
            />
          </PageLayout>
          <PageLayout headerData={headerData}>
            <WinningAndLosingPlaysV2
              matchId={matchId}
              player="player1"
              playerName={player1Name}
              surface={headerData?.surface}
              volumeType="effectiveness"
              isMatch={true}
            />
          </PageLayout>
          <PageLayout headerData={headerData}>
            <WinningAndLosingPlaysV2
              matchId={matchId}
              player="player2"
              playerName={player2Name}
              surface={headerData?.surface}
              volumeType="volume"
              isMatch={true}
            />
          </PageLayout>
          <PageLayout headerData={headerData}>
            <WinningAndLosingPlaysV2
              matchId={matchId}
              player="player2"
              playerName={player2Name}
              surface={headerData?.surface}
              volumeType="effectiveness"
              isMatch={true}
            />
          </PageLayout>
        </>
      )}
      <PageLayout headerData={headerData}>
        <SetComparison
          performanceDataAllSets={performanceRes?.data}
          headerData={headerData}
          matchId={matchId}
          player="player1"
        />
      </PageLayout>
      <PageLayout headerData={headerData}>
        <SetComparison
          performanceDataAllSets={performanceRes?.data}
          headerData={headerData}
          matchId={matchId}
          player="player2"
        />
      </PageLayout>
    </Box>
  );

  return getAnyLoading(loadingData) ? Loading : Report;
};

const PageLayout = ({ headerData, hideHeader, children }) => {
  const { player1, player2 } = headerData?.player_details;

  return (
    <Box h={pageHeight} w={pageWidth}>
      {!hideHeader && <Header player1Name={player1?.member1?.full_name} player2Name={player2?.member1?.full_name} />}
      <Flex py={5} justify="center">
        {children}
      </Flex>
    </Box>
  );
};

export default MatchReport;
