export function setLocalStorageAuthTokens(accessToken, refreshToken, userName) {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  if (userName) localStorage.setItem('userName', userName);
}

export function getLocalStorageAuthTokens() {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const userName = localStorage.getItem('userName');
  return {
    accessToken,
    refreshToken,
    userName
  };
}

export function removeLocalStorageAuthTokens() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userName');
}
