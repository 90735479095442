import { serveDirectionOrder } from 'match/components/court-graphics/court/averages/AveragesBadges';
import { displayScore } from 'match/utils/transformations';

import { Text, Table, Thead, Tr, Th, Tbody, Td } from '_shared/designSystem/components';

export const ServeDirectionStatsTable = ({ courtGraphicsDataNoAverages, pressureData, breakData }) => {
  return (
    <Table w="750px" ml="20px">
      <Thead>
        <Tr>
          <ColumnHeading></ColumnHeading>
          <ColumnHeading>Wide Ad</ColumnHeading>
          <ColumnHeading>Body Ad</ColumnHeading>
          <ColumnHeading>Tee Ad</ColumnHeading>
          <ColumnHeading>Tee Deuce</ColumnHeading>
          <ColumnHeading>Body Deuce</ColumnHeading>
          <ColumnHeading>Wide Deuce</ColumnHeading>
        </Tr>
      </Thead>
      <Tbody>
        <ServeDirectionTableRow
          heading={tableLeftColumnNames.serve_direction}
          data={courtGraphicsDataNoAverages?.averages?.placement}
          showZero
        />
        <ServeDirectionTableRow heading={tableLeftColumnNames.win} data={courtGraphicsDataNoAverages?.averages?.win} />
        <ServeDirectionTableRow heading={tableLeftColumnNames.pressure} data={pressureData?.averages?.placement} />
        <ServeDirectionTableRow heading={tableLeftColumnNames.break} data={breakData?.averages?.placement} />
        <ServeDirectionTableRow
          heading={tableLeftColumnNames.quality}
          data={courtGraphicsDataNoAverages?.averages?.quality}
        />
        <ServeDirectionTableRow
          heading={tableLeftColumnNames.unreturned}
          data={courtGraphicsDataNoAverages?.averages?.unreturned}
        />
      </Tbody>
    </Table>
  );
};

export const ServeDirectionTableRow = ({ heading, data, showZero }) => {
  return (
    <Tr>
      <Td whiteSpace="wrap" w="220px">
        {heading}
      </Td>
      {serveDirectionOrder.map((position) => (
        <Td key={position} w="85px" px={0}>
          <Text>
            {data?.[position]?.match_average === 0 && !showZero ? (
              ''
            ) : (
              <span>
                {displayScore(data?.[position]?.match_average, data?.[position]?.score_type)}
                {displayFrequency(data?.[position]?.frequency)}
              </span>
            )}
          </Text>
        </Td>
      ))}
    </Tr>
  );
};

function displayFrequency(frequency) {
  if (frequency === null) return null;
  return ` (${frequency})`;
}

const tableLeftColumnNames = {
  serve_direction: 'Serve Direction',
  win: 'Win %',
  pressure: 'Pressure Point Direction %',
  break: 'Break Point Direction %',
  quality: 'Quality',
  unreturned: 'Unreturned %'
};

const ColumnHeading = ({ children }) => (
  <Th whiteSpace="wrap" px={0}>
    {children}
  </Th>
);
