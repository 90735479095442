export const matchStatsOrder = {
  serve: [
    'aces',
    'double_faults',
    '1st_serve_in',
    '1st_serve_points_won',
    '2nd_serve_points_won',
    'break_points_saved',
    'total_serve_points_won',
    'service_games_won',
    'fastest_1st_serve_speed',
    'avg_1st_serve_speed',
    'avg_2nd_serve_speed',
    '1st_serve_accuracy'
  ],
  return: [
    '1st_serve_return_points_won',
    '2nd_serve_return_points_won',
    'break_points_won',
    'total_return_points_won',
    'return_games_won',
    '1st_serve_returns_in',
    '2nd_serve_returns_in',
    '1st_serve_return_speed',
    '2nd_serve_return_speed',
    '1st_serve_return_spin',
    '2nd_serve_return_spin'
  ],
  winners_and_unforced_errors: [
    'total_winners',
    'serve_winners',
    'return_winners',
    'forehand_winners',
    'backhand_winners',
    'volley_winners',
    'total_forced_errors',
    'return_forced_errors',
    'forehand_forced_errors',
    'backhand_forced_errors',
    'volley_forced_errors',
    'total_unforced_errors',
    'serve_unforced_errors',
    'return_unforced_errors',
    'forehand_unforced_errors',
    'backhand_unforced_errors',
    'volley_unforced_errors'
  ],
  rally: [
    'total_points_won',
    'average_rally_length',
    'rally_length_zero_to_four',
    'rally_length_five_to_eight',
    'rally_length_nine_plus',
    'forehands_in',
    'backhands_in',
    'forehand_speed',
    'backhand_speed',
    'forehand_topspin_rpm',
    'backhand_topspin_rpm'
  ]
};
