import PropTypes from 'prop-types';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Box } from '_shared/designSystem/components';
import { getComponentWithExtraProps } from '_shared/utils/componentUtils';

const Logo = ({ iconOnly, width, height, type, color }) => {
  return (
    <Box>
      {getComponentWithExtraProps(customSitesConfig.logo, {
        iconOnly,
        width,
        height,
        type,
        color
      })}
    </Box>
  );
};

Logo.propTypes = {
  iconOnly: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string
};

export default Logo;
