import { useEffect, useState } from 'react';

import { useNavigate, useSearch } from '@tanstack/react-location';
import PropTypes from 'prop-types';
import { patternsOfPlayPattern, patternsOfPlayShotTypes } from 'scout/_shared/scoutConstants';
import {
  getFilterValuesFromSearchParams,
  getNewFilterValue,
  getPatternFromSearchParams,
  patternsOfPlayInitialFilterValues,
  patternsOfPlayInitialPattern,
  updateFilterValuesBasedOnPattern
} from 'scout/util/scoutPatternsOfPlayUtil';

import { Box, LoadingSpinner, SecondaryNavBar, useBreakpointValue } from '_shared/designSystem/components';
import { convertBooleanToYesNo } from '_shared/utils/stringUtil';

import PatternsOfPlayCourt from './court/Court';
import PatternsOfPlayFilters from './PatternsOfPlayFilters';

// designed to be used on the match centre as well
export default function PatternsOfPlay(props) {
  const {
    dataNoAverages,
    dataWithAverages,
    averagesLoading,
    updateParams,
    hasPlayerFilter,
    playersData,
    isLoadingNoAverages,
    surface
  } = props;

  const [pattern, setPattern] = useState(patternsOfPlayInitialPattern);
  const [filterValues, setFilterValues] = useState(patternsOfPlayInitialFilterValues);
  const [showAverages, setShowAverages] = useState(false);

  const searchParams = useSearch();
  const navigate = useNavigate();

  const isWideCourt = useBreakpointValue(
    {
      base: false,
      sm: true
    },
    { ssr: false }
  );

  useEffect(() => {
    const pattern = getPatternFromSearchParams(searchParams);
    setPattern(pattern);
    const filterValues = getFilterValuesFromSearchParams(searchParams);
    setFilterValues(filterValues);
    updateParams(pattern, filterValues);
    setShowAverages(searchParams.showAverages === 'yes');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuButtonClick = (value) => {
    setPattern(value);
    const filters = { ...filterValues };
    const updatedFilters = updateFilterValuesBasedOnPattern(value, filters);
    setFilterValues(updatedFilters);
    updateParams(value, updatedFilters);
    navigate({ search: (old) => ({ ...old, pattern: value, graphicType: updatedFilters.graphicType }) });
  };

  const handleSetShowAverages = (value) => {
    setShowAverages(value);
    navigate({ search: (old) => ({ ...old, showAverages: convertBooleanToYesNo(value) }) });
  };

  const handleFilterButtonClick = (param, value) => {
    const newFilters = { ...filterValues };
    const newValue = getNewFilterValue(param, value, newFilters);
    newFilters[`${param}`] = newValue;
    setFilterValues(newFilters);
    updateParams(pattern, newFilters);
    navigate({ search: (old) => ({ ...old, [param]: newValue }) });
  };

  return (
    <Box px={{ base: 2, lg: 0 }}>
      <Box py={2} mb={2}>
        <SecondaryNavBar activeItem={pattern} items={menuItems} onSelectItem={handleMenuButtonClick} />
      </Box>
      <PatternsOfPlayFilters
        pattern={pattern}
        filterValues={filterValues}
        handleFilterButtonClick={handleFilterButtonClick}
        showAverages={showAverages}
        setShowAverages={handleSetShowAverages}
        hasPlayerFilter={hasPlayerFilter}
        playersData={playersData}
      />
      {isLoadingNoAverages ? (
        <LoadingSpinner />
      ) : (
        <PatternsOfPlayCourt
          dataNoAverages={dataNoAverages}
          dataWithAverages={dataWithAverages}
          showAverages={showAverages}
          averagesLoading={averagesLoading}
          surface={surface}
          pattern={pattern}
          graphicType={filterValues?.graphicType}
          shotType={filterValues?.shotType === 'all' ? patternsOfPlayShotTypes.GROUNDSTROKE : filterValues?.shotType}
          isWideCourt={isWideCourt}
        />
      )}
    </Box>
  );
}

PatternsOfPlay.propTypes = {
  dataNoAverages: PropTypes.object,
  dataWithAverages: PropTypes.object,
  averagesLoading: PropTypes.bool,
  updateParams: PropTypes.func,
  hasPlayerFilter: PropTypes.bool,
  playersData: PropTypes.object,
  isLoadingNoAverages: PropTypes.bool,
  surface: PropTypes.string
};

PatternsOfPlay.defaultProps = {
  surface: 'hard'
};

const menuItems = [
  {
    label: 'Control the Middle',
    value: patternsOfPlayPattern.CONTROL_THE_MIDDLE
  },
  {
    label: 'From the Deuce Side',
    value: patternsOfPlayPattern.FROM_THE_DEUCE_SIDE
  },
  {
    label: 'From the Advantage Side',
    value: patternsOfPlayPattern.FROM_THE_ADVANTAGE_SIDE
  },
  {
    label: 'Volleys',
    value: patternsOfPlayPattern.VOLLEYS
  }
];
