import { displayScore, zeroOrMore } from 'match/utils/transformations';
import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';

import { Collapse, Flex, Text } from '_shared/designSystem/components';
import { determineWinOpacity } from '_shared/utils/metricsUtil';

import { StatisticsBar } from './StatisticsBar';

const handleSharedScoreInLabel = (label, data) => {
  if (zeroOrMore(data?.shared?.score)) {
    return `${label}: ${displayScore(data?.shared?.score, data?.shared?.score_type)}`;
  }
  return label;
};

const showingMatchBar = (data) => {
  // hide the match bars when the score is shared
  return !zeroOrMore(data?.shared?.score);
};

const FIRST_SERVE_ACCURACY = '1st Serve Accuracy';

const StatisticsRow = ({
  data,
  dataWithAverages,
  label,
  showAverages,
  averagesLoading,
  overrideWidth,
  hidePlayerAverage,
  hasLargerText
}) => {
  const reverseOpacity = label === FIRST_SERVE_ACCURACY;

  return (
    <Flex direction="column" gap={hasLargerText ? 2 : 0} width={overrideWidth}>
      <Flex justify="center">
        <Text fontSize={hasLargerText ? { base: 'sm', md: 'md' } : 'xs'} fontWeight="normal" color="secondary.800">
          {handleSharedScoreInLabel(label, data)}
        </Text>
      </Flex>
      {showingMatchBar(data) && (
        <Flex justify="space-between">
          <StatisticsBar
            data={data}
            player="player1"
            position="left"
            averageType="score"
            showAverages={showAverages}
            overrideBarColor="primary.500"
            opacity={determineWinOpacity(data?.player1?.score, data?.player2?.score, reverseOpacity)}
            hasLargerText={hasLargerText}
          />
          <StatisticsBar
            data={data}
            player="player2"
            position="right"
            averageType="score"
            showAverages={showAverages}
            overrideBarColor={'primary.500'}
            opacity={determineWinOpacity(data?.player2?.score, data?.player1?.score, reverseOpacity)}
            hasLargerText={hasLargerText}
          />
        </Flex>
      )}
      <Collapse in={showAverages} animateOpacity>
        {averagesLoading ? (
          <Flex justify="center" align="center">
            <BeatLoader size={3} color="green" />
          </Flex>
        ) : (
          !hidePlayerAverage &&
          (dataWithAverages?.player1?.player_average || dataWithAverages?.player2?.player_average) && (
            <Flex justify="space-between" mb={hasLargerText ? 2 : 0}>
              <StatisticsBar
                data={dataWithAverages}
                player="player1"
                position="left"
                averageType="player_average"
                showAverages={showAverages}
                opacity={determineWinOpacity(
                  dataWithAverages?.player1?.player_average,
                  dataWithAverages?.player2?.player_average,
                  reverseOpacity
                )}
                hasLargerText={hasLargerText}
              />
              <StatisticsBar
                data={dataWithAverages}
                player="player2"
                position="right"
                averageType="player_average"
                showAverages={showAverages}
                opacity={determineWinOpacity(
                  dataWithAverages?.player2?.player_average,
                  dataWithAverages?.player1?.player_average,
                  reverseOpacity
                )}
                hasLargerText={hasLargerText}
              />
            </Flex>
          )
        )}
        {(dataWithAverages?.player1?.tour_average || dataWithAverages?.player2?.tour_average) && (
          <Flex justify="space-between">
            <StatisticsBar
              data={dataWithAverages}
              player="player1"
              position="left"
              averageType="tour_average"
              showAverages={showAverages}
              hasLargerText={hasLargerText}
            />
            <StatisticsBar
              data={dataWithAverages}
              player="player2"
              position="right"
              averageType="tour_average"
              showAverages={showAverages}
              hasLargerText={hasLargerText}
            />
          </Flex>
        )}
      </Collapse>
    </Flex>
  );
};

StatisticsRow.propTypes = {
  data: PropTypes.object.isRequired,
  dataWithAverages: PropTypes.object,
  label: PropTypes.string.isRequired,
  showAverages: PropTypes.bool,
  averagesLoading: PropTypes.bool,
  hasLargerText: PropTypes.bool
};

export default StatisticsRow;
