import EffectivenessBarGraphic from 'match/components/winning-and-losing-plays/EffectivenessBarGraphic';
import { BARS_OPACITY } from 'match/constants/winningAndLosingPlaysConstants';
import { determineColor, transformWinningAndLosingPlaysData } from 'match/utils/winningAndLosingPlaysUtil';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import CourtWithArrowThumbnail from 'scout/components/winning_and_losing_plays/CourtWithArrowThumbnail';
import { getArrowType } from 'scout/util/scoutWinningAndLosingPlaysUtil.js';

import { Box, Text, Flex, VStack, Icon } from '_shared/designSystem/components';
import BarGraphic from '_shared/designSystem/components/charts/barGraphic/BarGraphic';
import { isMatchReportState } from '_shared/globalState/atoms';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import { capitaliseAndPreserveCasing, addApostrophe } from '_shared/utils/stringUtil';
import { isTouchDevice } from '_shared/utils/touchDevice.util';

import CourtWithShots from './CourtWithShots';
import { dataNoAveragesObjectType, dataWithAveragesObjectType } from './SinglePlayTypes';

const VolumeType = {
  VOLUME: 'volume',
  EFFECTIVENESS: 'effectiveness'
};

const pointsWonFontSize = isWimbPortal() ? 'xs' : 'sm';

const SinglePlayContainer = ({ children, isDetailed, isMatchPage }) => {
  const isMatchReport = useRecoilValue(isMatchReportState);
  return isMatchReport ? (
    <Box>{children}</Box>
  ) : (
    <Box
      borderRadius="md"
      borderColor="white"
      borderWidth={2}
      p={4}
      _hover={
        !isDetailed
          ? {
              boxShadow: 'lg',
              borderColor: 'primary.200',
              cursor: 'pointer'
            }
          : null
      }
      cursor={!isDetailed ? 'pointer' : 'default'}
      position="relative"
    >
      {isTouchDevice() &&
        !isDetailed &&
        (isMatchPage ? (
          <Icon position="absolute" top={5} right={2} name="expand" width="24px" height="24px" color="primary.500" />
        ) : (
          <Flex justify="flex-end" mb={2}>
            <Icon name="expand" width="18px" height="18px" color="primary.500" />
          </Flex>
        ))}
      {children}
    </Box>
  );
};

const VolumeNoAverages = ({
  playType,
  dataNoAverages,
  totalData,
  shotsData,
  surface,
  isMatchPage,
  isDetailed,
  arrowType,
  selectedPlayType,
  playerData
}) => {
  const dataNoAveragesWithBarColor = dataNoAverages.map((item) => {
    return { ...item, color: determineColor(item) };
  });
  const isMatchReport = useRecoilValue(isMatchReportState);
  return (
    <SinglePlayContainer isDetailed={isDetailed} isMatchPage={isMatchPage}>
      {isMatchPage ? (
        <Box>
          <Flex direction="column" gap={6}>
            <SinglePlayHeading isDetailed={isDetailed} playType={playType} isMatchPage={isMatchPage} />
            <Flex align="flex-end" justifyContent="center" gap={2}>
              <Text fontSize="sm" fontWeight="semibold">
                Frequency of Play:
              </Text>
              <Text fontSize="lg" fontWeight="semibold" lineHeight={isMatchReport ? null : '22px'}>
                {totalData?.score}%
              </Text>
              <Text fontSize="lg" textColor="grey.500" lineHeight={isMatchReport ? null : '22px'}>
                ({totalData?.frequency}/{totalData?.frequency_total})
              </Text>
            </Flex>
            <BarGraphic
              data={dataNoAveragesWithBarColor}
              barHeight="md"
              opacity={BARS_OPACITY}
              selectedPlayType={selectedPlayType}
            />
            <Box>
              <CourtWithShots surface={surface} shots={shotsData} />
            </Box>
          </Flex>
        </Box>
      ) : (
        <Box pb={6}>
          <Flex justify="space-between" mb={6}>
            <Box>
              <SinglePlayHeading isDetailed={isDetailed} playType={playType} isMatchPage={isMatchPage} />
              <Text fontSize="sm" fontWeight="semibold" mt={4}>
                Frequency of Play: {totalData?.score}%
              </Text>
              <Text fontSize="xs" textColor="grey.500" mt={4}>
                ({totalData?.frequency}/{totalData?.frequency_total})
              </Text>
            </Box>
            <Box width="30%" display="flex" justifyContent="flex-end">
              <CourtWithArrowThumbnail
                surface={surface}
                arrowType={getArrowType(arrowType, playerData?.handedness)}
                playType="all"
              />
            </Box>
          </Flex>
          <Flex mt={8}>
            <BarGraphic
              data={dataNoAveragesWithBarColor}
              showLabels={true}
              barHeight="xl"
              opacity={BARS_OPACITY}
              selectedPlayType={selectedPlayType}
            />
          </Flex>
        </Box>
      )}
    </SinglePlayContainer>
  );
};

const VolumeWithAverages = ({ playType, dataWithAverages, isDetailed, selectedPlayType }) => {
  return (
    <SinglePlayContainer isDetailed={isDetailed}>
      <VStack gap={6} pb={4}>
        <SinglePlayHeading isDetailed={isDetailed} playType={playType} />
        {dataWithAverages.map((item, index) => {
          const dataWithAveragesWithBarColor = item.map((bar) => {
            return { ...bar, color: determineColor(bar) };
          });
          return (
            <Box width="100%" key={index}>
              <BarGraphic
                data={dataWithAveragesWithBarColor}
                barHeight="sm"
                opacity={BARS_OPACITY}
                selectedPlayType={selectedPlayType}
              />
            </Box>
          );
        })}
      </VStack>
    </SinglePlayContainer>
  );
};

const EffectivenessNoAverages = ({
  playType,
  dataNoAverages,
  surface,
  shotsData,
  isMatchPage,
  arrowType,
  isDetailed,
  pointsWon,
  volumeType,
  selectedPlayType,
  playerData,
  player
}) => {
  const shotsDataWithoutNeutralValues = shotsData.filter((shot) => shot.shot_type !== 'other');

  return (
    <SinglePlayContainer isDetailed={isDetailed} isMatchPage={isMatchPage}>
      {isMatchPage ? (
        <SinglePlayHeading isDetailed={isDetailed} playType={playType} isMatchPage={isMatchPage} />
      ) : (
        <Flex justify="space-between">
          <SinglePlayHeading isDetailed={isDetailed} playType={playType} />
          <Box width="30%" display="flex" justifyContent="flex-end">
            <CourtWithArrowThumbnail
              surface={surface}
              arrowType={getArrowType(arrowType, playerData?.handedness)}
              playType="all"
            />
          </Box>
        </Flex>
      )}
      <Box width="100%" mt={4}>
        <EffectivenessBarGraphic
          data={dataNoAverages}
          isMatchPage={isMatchPage}
          volumeType={volumeType}
          selectedPlayType={selectedPlayType}
        />
      </Box>

      <Text mt={4} fontSize={pointsWonFontSize}>
        {isMatchPage ? 'During this match' : 'In the selected matches'}, {player === 'Opponents' ? 'opponents' : player}{' '}
        won {pointsWon?.score}% of points when this play was used.
      </Text>

      {isMatchPage && (
        <Box mt={8}>
          <CourtWithShots surface={surface} shots={shotsDataWithoutNeutralValues} />
        </Box>
      )}
    </SinglePlayContainer>
  );
};

const EffectivenessWithAverages = ({
  playType,
  dataWithAverages,
  surface,
  shotsData,
  isDetailed,
  isMatchPage,
  arrowType,
  pointsWon,
  volumeType,
  selectedPlayType,
  playerData,
  player
}) => {
  const shotsDataWithoutNeutralValues = shotsData.filter((shot) => shot.shot_type !== 'other');
  const dataWithAveragesWithBarColor = dataWithAverages.map((item) => {
    return {
      ...item,
      color: determineColor(item)
    };
  });
  return (
    <SinglePlayContainer isDetailed={isDetailed} isMatchPage={isMatchPage}>
      {isMatchPage ? (
        <SinglePlayHeading isDetailed={isDetailed} playType={playType} isMatchPage={isMatchPage} />
      ) : (
        <Flex justify="space-between">
          <SinglePlayHeading isDetailed={isDetailed} playType={playType} />
          <Box width="30%" display="flex" justifyContent="flex-end">
            <CourtWithArrowThumbnail
              surface={surface}
              arrowType={getArrowType(arrowType, playerData?.handedness)}
              playType="all"
            />
          </Box>
        </Flex>
      )}
      <Box width="100%" mt={4}>
        <EffectivenessBarGraphic
          data={dataWithAveragesWithBarColor}
          isMatchPage={isMatchPage}
          volumeType={volumeType}
          selectedPlayType={selectedPlayType}
        />
      </Box>
      <Text mt={4} fontSize={pointsWonFontSize}>
        {isMatchPage ? 'During this match' : 'In the selected matches'}, {player === 'Opponents' ? 'opponents' : player}{' '}
        won {pointsWon?.score}% of points when this play was used. {addApostrophe(player)} average is{' '}
        {pointsWon?.playerAverage}%, and the tour average is {pointsWon?.tourAverage}%.
      </Text>
      {isMatchPage && (
        <Box mt={8}>
          <CourtWithShots surface={surface} shots={shotsDataWithoutNeutralValues} />
        </Box>
      )}
    </SinglePlayContainer>
  );
};

const SinglePlayHeading = ({ isDetailed, playType, isMatchPage }) => {
  const isMatchReport = useRecoilValue(isMatchReportState);
  let fontSize = 'xl';
  if (isMatchReport) fontSize = 'md !important';
  else if (isWimbPortal()) fontSize = 'lg';
  else if (isDetailed) fontSize = { base: 'xl', xl: '2xl' };
  return (
    <Box>
      <Text fontSize={fontSize} fontWeight="semibold" textAlign={isMatchPage ? 'center' : 'left'}>
        {playType === 'Forehand Inside In Out' ? 'Forehand Inside In / Out' : playType}
      </Text>
    </Box>
  );
};

export const SinglePlay = ({
  surface,
  dataNoAverages,
  dataWithAverages,
  isLoading,
  isLoadingAverages,
  showAverages,
  volumeType,
  isMatchPage,
  isDetailed,
  selectedPlayType,
  playerData,
  player
}) => {
  const {
    transformedData,
    mergedTotalData,
    transformedVolumeDataWithPlayerAndTourAverages,
    effectivenessDataNoAverages,
    effectivenessDataWithAverages
  } = transformWinningAndLosingPlaysData(dataNoAverages, dataWithAverages);

  const playType = capitaliseAndPreserveCasing(dataNoAverages?.play_id);
  const isMatchReport = useRecoilValue(isMatchReportState);

  const pointsWon = {
    score: dataNoAverages?.points_won.score || 0,
    playerAverage: dataWithAverages.points_won?.player_average || 0,
    tourAverage: dataWithAverages.points_won?.tour_average || 0
  };

  return (
    // reports are more condensed to overriding all fonts to xs
    <Box sx={isMatchReport ? { '& *': { fontSize: 'xs !important' } } : null}>
      {volumeType === VolumeType.VOLUME ? (
        !showAverages ? (
          <VolumeNoAverages
            surface={surface}
            playType={playType}
            dataNoAverages={transformedData}
            isLoading={isLoading}
            totalData={dataNoAverages.total}
            shotsData={dataNoAverages.shots}
            isMatchPage={isMatchPage}
            arrowType={dataNoAverages.play_id}
            isDetailed={isDetailed}
            pointsWon={pointsWon}
            selectedPlayType={selectedPlayType}
            playerData={playerData}
          />
        ) : (
          <VolumeWithAverages
            surface={surface}
            playType={playType}
            dataWithAverages={[...mergedTotalData, ...transformedVolumeDataWithPlayerAndTourAverages]}
            isLoadingAverages={isLoadingAverages}
            arrowType={dataNoAverages.play_id}
            isMatchPage={isMatchPage}
            isDetailed={isDetailed}
            pointsWon={pointsWon}
          />
        )
      ) : !showAverages ? (
        <EffectivenessNoAverages
          surface={surface}
          playType={playType}
          dataNoAverages={effectivenessDataNoAverages}
          isLoading={isLoading}
          shotsData={dataNoAverages.shots}
          isMatchPage={isMatchPage}
          arrowType={dataNoAverages.play_id}
          isDetailed={isDetailed}
          pointsWon={pointsWon}
          volumeType={volumeType}
          selectedPlayType={selectedPlayType}
          playerData={playerData}
          player={player}
        />
      ) : (
        <EffectivenessWithAverages
          surface={surface}
          playType={playType}
          dataWithAverages={effectivenessDataWithAverages}
          isLoadingAverages={isLoadingAverages}
          arrowType={dataNoAverages.play_id}
          shotsData={dataNoAverages.shots}
          isDetailed={isDetailed}
          isMatchPage={isMatchPage}
          pointsWon={pointsWon}
          volumeType={volumeType}
          selectedPlayType={selectedPlayType}
          playerData={playerData}
          player={player}
        />
      )}
    </Box>
  );
};

SinglePlay.propTypes = {
  surface: PropTypes.string,
  dataNoAverages: dataNoAveragesObjectType,
  dataWithAverages: dataWithAveragesObjectType,
  isLoading: PropTypes.bool,
  isLoadingAverages: PropTypes.bool,
  showAverages: PropTypes.bool,
  volumeType: PropTypes.oneOf(['volume', 'effectiveness']),
  isMatchPage: PropTypes.bool.isRequired,
  selectedPlayType: PropTypes.string,
  playerData: PropTypes.object,
  player: PropTypes.string
};

SinglePlay.defaultProps = {
  surface: '',
  dataNoAverages: {},
  dataWithAverages: {
    play_id: ''
  },
  isLoading: false,
  isLoadingAverages: false,
  showAverages: false,
  volumeType: 'volume',
  isMatchPage: true
};

export default SinglePlay;
