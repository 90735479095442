import { Flex, Text } from '_shared/designSystem/components';

const FrequencyBadge = ({ frequency }) => {
  if (frequency === null) return null;
  return (
    <Flex justify="center">
      <Text
        textAlign="center"
        minW="40px"
        bg="grey.300"
        fontSize="xs"
        fontWeight="medium"
        color="grey.500"
        px={1}
        borderRadius="lg"
      >
        ({frequency})
      </Text>
    </Flex>
  );
};

export default FrequencyBadge;
