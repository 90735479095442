import { useMatch } from '@tanstack/react-location';
import {
  useHeaderQuery,
  useWinningAndLosingPlaysV2NoAveragesQuery,
  useWinningAndLosingPlaysV2WithAveragesQuery
} from 'match/hooks/matchHooks';
import { useWinningAndLosingPlaysSettings } from 'match/hooks/winningAndLosingPlaysHooks';
import { sortPlays } from 'match/utils/winningAndLosingPlaysUtil';

import PlaysSummaryV2 from './PlaysSummaryV2';

const MatchPlaysSummary = () => {
  const {
    params: { matchId }
  } = useMatch();

  const initialValues = {
    player: 'player1',
    playType: 'all',
    set: 'all',
    volumeType: 'volume',
    showAverages: false,
    playerType: 'player'
  };

  const { state: playerSettings } = useWinningAndLosingPlaysSettings(initialValues);

  const queryParams = {
    player: playerSettings.player,
    set: playerSettings.set
  };
  const fetchParams = { retry: 0 };

  const header = useHeaderQuery(matchId);
  const noAveragesData = useWinningAndLosingPlaysV2NoAveragesQuery(matchId, queryParams, fetchParams);
  const withAveragesData = useWinningAndLosingPlaysV2WithAveragesQuery(matchId, queryParams, fetchParams);

  const sortedPlaysNoAverages = sortPlays(
    noAveragesData?.data || [],
    playerSettings.playType,
    playerSettings.volumeType
  );
  const sortedPlaysWithAverages = sortPlays(
    withAveragesData?.data || [],
    playerSettings.playType,
    playerSettings.volumeType
  );

  return (
    <PlaysSummaryV2
      playDataNoAverages={sortedPlaysNoAverages}
      playDataWithAverages={sortedPlaysWithAverages}
      showAverages={playerSettings.showAverages}
      isLoading={noAveragesData?.isLoading}
      averagesLoading={withAveragesData?.isLoading}
      surface={header?.data?.surface}
      volumeType={playerSettings.volumeType}
      isScout={false}
      playerData={header}
      playerType={playerSettings.playerType}
    />
  );
};

export default MatchPlaysSummary;
