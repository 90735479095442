import { getMobileAveragesDisplay } from 'match/components/court-graphics/court/averages/MobileAverages';
import CourtContainer from 'match/components/court-graphics/court/CourtContainer';
import { CourtGraphicsLegendMobile } from 'match/components/court-graphics/court/CourtGraphicsLegend';
import PropTypes from 'prop-types';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Flex, Box, LoadingSpinner } from '_shared/designSystem/components';

import CourtGraphicsFilters from './CourtGraphicsFilters';
import CourtStatsTable from './CourtStatsTable';

export default function ScoutCourtGraphics(props) {
  const {
    courtStatsWithAveragesIsLoading,
    courtGraphicsDataNoAverages,
    courtGraphicsDataWithAverages,
    courtGraphicsWithAveragesIsLoading,
    courtGraphicsFiltersValues,
    handleCourtGraphicFilterClick,
    graphicType,
    showAverages,
    handleSetShowAverages,
    servePlusOne,
    handleSetServePlusOne,
    courtGraphicsNoAveragesIsLoading,
    courtStatsNoAveragesIsLoading
  } = props;

  return (
    <Flex gap={5} flexWrap="wrap" mt={4} mb={4}>
      <Box w={{ base: '100%', lg: '720px' }}>
        {courtGraphicsNoAveragesIsLoading ? (
          <Box h="400px">
            <LoadingSpinner />
          </Box>
        ) : (
          <>
            <CourtGraphicsFilters
              courtGraphicsFiltersValues={courtGraphicsFiltersValues}
              graphicType={graphicType}
              handleButtonClick={handleCourtGraphicFilterClick}
              showAverages={showAverages}
              setShowAverages={handleSetShowAverages}
              servePlusOne={servePlusOne}
              setServePlusOne={handleSetServePlusOne}
              surface={customSitesConfig.defaultScoutCourt}
            />
            <Box w={{ base: '100%', lg: '720px' }}>
              <CourtContainer
                surface={customSitesConfig.defaultScoutCourt}
                graphicType={graphicType}
                shots={courtGraphicsDataNoAverages?.shots}
                dataNoAverages={courtGraphicsDataNoAverages?.averages?.[`${courtGraphicsFiltersValues.averagesType}`]}
                dataWithAverages={
                  courtGraphicsDataWithAverages?.averages?.[`${courtGraphicsFiltersValues.averagesType}`]
                }
                showAverages={showAverages}
                averagesLoading={courtGraphicsWithAveragesIsLoading}
                orientation={courtGraphicsFiltersValues?.orientation}
                outShots={courtGraphicsFiltersValues?.outShots}
              />
            </Box>
            <Box
              display={{
                base: 'initial',
                md: 'none'
              }}
            >
              <CourtGraphicsLegendMobile
                graphicType={graphicType}
                surface={customSitesConfig.defaultScoutCourt}
                outShots={courtGraphicsFiltersValues?.outShots}
              />
              {getMobileAveragesDisplay(
                graphicType,
                courtGraphicsFiltersValues?.orientation,
                courtGraphicsDataNoAverages?.averages?.[`${courtGraphicsFiltersValues.averagesType}`],
                courtGraphicsDataWithAverages?.averages?.[`${courtGraphicsFiltersValues.averagesType}`],
                showAverages,
                courtGraphicsWithAveragesIsLoading
              )}
            </Box>
          </>
        )}
      </Box>
      <Box px={{ base: 0, lg: 5 }}>
        <CourtStatsTable
          {...props}
          averagesLoading={courtStatsWithAveragesIsLoading}
          noAveragesLoading={courtStatsNoAveragesIsLoading}
        />
      </Box>
    </Flex>
  );
}

ScoutCourtGraphics.propTypes = {
  courtStatsDataNoAverages: PropTypes.array,
  courtStatsDataWithAverages: PropTypes.array,
  courtGraphicsDataNoAverages: PropTypes.object,
  courtGraphicsDataWithAverages: PropTypes.object,
  courtStatsNoAveragesIsLoading: PropTypes.bool,
  courtStatsWithAveragesIsLoading: PropTypes.bool,
  courtGraphicsWithAveragesIsLoading: PropTypes.bool,
  courtGraphicsFiltersValues: PropTypes.object,
  handleCourtGraphicFilterClick: PropTypes.func,
  showPlayerAverage: PropTypes.bool,
  graphicType: PropTypes.string,
  showAverages: PropTypes.bool,
  handleSetShowAverages: PropTypes.func
};
