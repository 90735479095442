import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import TvLogo from '_shared/components/logo/TvLogo';
import { IMAGE_LOGO_USTA, IMAGE_LOGO_LTA } from '_shared/constants/imageConsts';
import { Text, Flex, Image } from '_shared/designSystem/components';
import { userPermissionsState } from '_shared/globalState/atoms';

export default function Header(props) {
  const { playerName } = props;
  const { company } = useRecoilValue(userPermissionsState);
  return (
    <Flex
      pl={3}
      pr={6}
      py={2}
      justify="space-between"
      align="center"
      borderBottom="1px solid"
      borderColor="primary.700"
    >
      <Text fontSize="md" fontWeight="semibold">
        {`Scout Report - ${playerName}`}
      </Text>
      <Flex gap={3}>
        <TvLogo h="32px" isPurpleLandscape />
        {company === 'usta' && <Image alt="USTA" h={8} src={IMAGE_LOGO_USTA} />}
        {company === 'lta' && <Image alt="LTA" h={8} src={IMAGE_LOGO_LTA} />}
      </Flex>
    </Flex>
  );
}

Header.propTypes = {
  playerName: PropTypes.string
};
