import customSitesConfig from '_shared/config/customSitesConfig';

/**
 * Navigates to a specified URL while saving necessary state in sessionStorage.
 * @param {string} url - The URL to navigate to.
 * @param {Function} navigate - Navigation function to change routes.
 * @param {boolean} [storeScroll] - Session storage key to save current scroll position.
 */

export const SESSION_STORAGE_KEYS = {
  SCROLL_POSITION: 'scrollPosition',
  PREV_PATH: 'prevPath'
};

export const navigateTo = ({ url, navigate, storeScroll = false }) => {
  storeScroll
    ? sessionStorage.setItem(SESSION_STORAGE_KEYS.SCROLL_POSITION, window.scrollY)
    : sessionStorage.removeItem(SESSION_STORAGE_KEYS.SCROLL_POSITION);

  sessionStorage.setItem(SESSION_STORAGE_KEYS.PREV_PATH, window.location.pathname + window.location.search);

  navigate({ to: url, replace: true });
};

/**
 * Navigates to a specified URL while retrieving necessary state in sessionStorage.
 * @param {Function} navigate - Navigation function to change routes.
 */

export const navigateBack = async ({ navigate }) => {
  const pageToGoBackTo = sessionStorage.getItem(SESSION_STORAGE_KEYS.PREV_PATH);
  const scrollPosition = sessionStorage.getItem(SESSION_STORAGE_KEYS.SCROLL_POSITION);

  await navigate({
    to: pageToGoBackTo ?? customSitesConfig.tournamentsUrlPath,
    replace: true
  });

  if (scrollPosition > 0) {
    window.scrollTo(0, parseInt(scrollPosition));
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.SCROLL_POSITION);
  }
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.PREV_PATH);
};
