import { useRef } from 'react';

import { wimbledonImageUrls } from '_customSites/wimb/util/imageUtil';
import { mobileHeaderHeight, sideMenuWidthMobile } from '_layouts/margins';
import InsightsExplained from 'insightsExplained/components/InsightsExplained';
import { useRecoilValue } from 'recoil';

import {
  Box,
  Text,
  Flex,
  Icon,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Image
} from '_shared/designSystem/components';
import { mobileHeaderTextState } from '_shared/globalState/atoms';
import { isAtpTourPortal, isTvPortal } from '_shared/utils/environment/currentEnv';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';

import SideMenu from './SideMenu';

export default function MobileHeader() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isInsightsOpen, onClose: onInsightsClose } = useDisclosure();
  const menuIconRef = useRef();
  const mobileHeaderText = useRecoilValue(mobileHeaderTextState);

  const mobileHeaderTextFontSize = getMobileHeaderTextFontSize(mobileHeaderText);

  return (
    <Box>
      <Flex
        bg={isWimbPortal() ? 'grey.100' : 'secondary.800'}
        h={`${mobileHeaderHeight}px`}
        align="center"
        px={2}
        justify="space-between"
      >
        <Box onClick={onOpen}>
          <Icon
            name="menu"
            color={isWimbPortal() ? 'secondary.800' : 'white'}
            width={5}
            height={5}
            _hover={{ cursor: 'pointer' }}
          />
        </Box>
        <Text
          color={isWimbPortal() ? 'secondary.800' : 'white'}
          fontSize={{ base: mobileHeaderTextFontSize, sm: '2xl' }}
          fontWeight="semi-bold"
        >
          {mobileHeaderText}
        </Text>
        {isWimbPortal() ? <Image src={wimbledonImageUrls.LOGO} h="24px" /> : <Box></Box>}
        <SideMenuDrawer isOpen={isOpen} onClose={onClose} menuIconRef={menuIconRef} />
      </Flex>
      <InsightsExplained isOpen={isInsightsOpen} onClose={onInsightsClose} />
    </Box>
  );
}

export const SideMenuDrawer = ({ isOpen, onClose, menuIconRef }) => (
  <Drawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={menuIconRef}>
    <DrawerOverlay />
    <DrawerContent maxWidth={`${sideMenuWidthMobile}px`} bg="secondary.800">
      <Box>
        <DrawerCloseButton color={isTvPortal() || isAtpTourPortal() ? 'white' : 'primary.500'} />
      </Box>
      <Box>
        <SideMenu isMobile closeMenu={onClose} />
      </Box>
    </DrawerContent>
  </Drawer>
);

export function getMobileHeaderTextFontSize(text) {
  if (text && text.length > 25) return 'sm';
  return 'lg';
}
