import PropTypes from 'prop-types';

import { Flex, Icon, Tooltip } from '_shared/designSystem/components';

const InfoIconWithTooltip = ({ tooltipText, marginRight, color }) => (
  <Flex justify="center" mr={marginRight}>
    <Tooltip tooltipText={tooltipText} elementToHover={<InfoIcon color={color || 'secondary.800'} />} />
  </Flex>
);

InfoIconWithTooltip.propTypes = {
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  color: PropTypes.string
};

export default InfoIconWithTooltip;

const InfoIcon = ({ color }) => <Icon name="info" color={color} height={4} width={4} />;
