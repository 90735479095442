import { displayScore, zeroOrMore } from 'match/utils/transformations';
import PropTypes from 'prop-types';

import { Box, Text, Flex, Center, Tooltip } from '_shared/designSystem/components';
import { getTourAverageLabel } from '_shared/utils/metricsUtil';
import { capitaliseString } from '_shared/utils/stringUtil';

const HorizontalBar = (props) => {
  const {
    label,
    leftPercentage,
    rightPercentage,
    frequency,
    labelColor,
    labelSize,
    barHeight,
    percentageSize,
    tourAverageLeft,
    leftLabel,
    rightLabel,
    greaterColor,
    lessColor,
    textColor
  } = props;
  let weightingType;
  if (leftPercentage > rightPercentage) weightingType = 'left';
  else if (rightPercentage > leftPercentage) weightingType = 'right';
  else weightingType = 'equal';
  return (
    <Box>
      <Flex justify="center" mb={2}>
        <Flex gap={1}>
          <Text
            fontSize={labelSize ? labelSize : 'sm'}
            fontWeight="medium"
            color={labelColor ? labelColor : 'secondary.800'}
          >
            {label}
          </Text>
          {frequency > 0 && (
            <Text fontSize="sm" fontWeight="medium" color="grey.400">
              ({frequency})
            </Text>
          )}
        </Flex>
      </Flex>
      <Box borderRadius="lg" height={barHeight ? barHeight : '25px'} width="100%" bg="primary.200">
        {zeroOrMore(leftPercentage) && zeroOrMore(rightPercentage) && (
          <Flex position="relative">
            <AverageLine type="tourAverage" value={tourAverageLeft} barHeight={barHeight ? barHeight : '25px'} />
            <Center
              borderLeftRadius="lg"
              height={barHeight ? barHeight : '25px'}
              width={leftPercentage > 5 ? `${leftPercentage}%` : '5%'}
              bg={weightingType === 'right' || weightingType === 'equal' ? lessColor : greaterColor}
            >
              <Text
                fontSize={percentageSize ? percentageSize : 'xs'}
                fontWeight="normal"
                color={
                  textColor ? textColor : weightingType === 'right' || weightingType === 'equal' ? 'grey.700' : 'white'
                }
              >
                {displayScore(leftPercentage, 'percentage')}
              </Text>
            </Center>
            {weightingType === 'equal' && <Box w="2px" bg={greaterColor} />}
            <Center
              borderRightRadius="lg"
              width={rightPercentage > 5 ? `${rightPercentage}%` : '5%'}
              height={barHeight ? barHeight : '25px'}
              bg={weightingType === 'left' || weightingType === 'equal' ? lessColor : greaterColor}
            >
              <Text
                fontSize={percentageSize ? percentageSize : 'xs'}
                fontWeight="normal"
                color={
                  textColor ? textColor : weightingType === 'right' || weightingType === 'equal' ? 'grey.700' : 'white'
                }
              >
                {displayScore(rightPercentage, 'percentage')}
              </Text>
            </Center>
          </Flex>
        )}
        {leftLabel && rightLabel && zeroOrMore(leftPercentage) && zeroOrMore(rightPercentage) && (
          <Flex justify="space-between" mt={2}>
            <Text fontSize="xs" fontWeight="normal" color="secondary.800">
              {capitaliseString(leftLabel)}
            </Text>
            <Text fontSize="xs" fontWeight="normal" color="secondary.800">
              {capitaliseString(rightLabel)}
            </Text>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default HorizontalBar;

HorizontalBar.propTypes = {
  leftPercentage: PropTypes.number,
  rightPercentage: PropTypes.number,
  label: PropTypes.string,
  frequency: PropTypes.number,
  labelColor: PropTypes.string,
  barHeight: PropTypes.string,
  labelSize: PropTypes.string,
  percentageSize: PropTypes.string,
  tourAverageLeft: PropTypes.number,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  greaterColor: PropTypes.string,
  lessColor: PropTypes.string
};

HorizontalBar.defaultProps = {
  greaterColor: 'primary.600',
  lessColor: 'primary.200'
};

const AverageLine = ({ type, value, barHeight }) => {
  if (!zeroOrMore(value)) return null;
  return (
    <Tooltip
      w="10px"
      elementToHover={<Box w="2px" h={barHeight} bg={type} position="absolute" left={`${value}%`} top={0} />}
      tooltipText={type === 'tourAverage' ? `${getTourAverageLabel()} Won: ${value}%` : `Player Avg Won: ${value}%`}
      bg={type}
      textColour="white"
    />
  );
};
