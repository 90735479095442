import React, { useState } from 'react';

import { Flex, Text } from '@ellipsedata/tennis';
import PropTypes from 'prop-types';

import { Box, Icon } from '_shared/designSystem/components';

import FiltersForm from './FiltersForm';
import {
  getConversionAndStealFormFieldsData,
  getRallyFormFieldsData,
  getReturnFormFieldsData,
  getScoreFormFieldsData,
  getServeFormFieldsData,
  getPointEndFormFieldsData
} from './formFieldsData';

export default function Filters({ player1Name, player2Name, setGames, formState, handleFilterSelect }) {
  const [isFilterOpen, setIsFilterOpen] = useState({
    score: false,
    point_end: false,
    serve: false,
    return: false,
    rally: false,
    conversion_and_steal: false
  });

  const buttonColorProps = {
    bgColor: 'primary.500',
    textColor: 'white'
  };

  const filterContainerProps = {
    mt: 4,
    p: 4,
    borderWidth: 1,
    borderRadius: 'md',
    boxShadow: 'md',
    width: '100%'
  };

  const toggleFilter = (filterName) => {
    setIsFilterOpen((prevState) => {
      const newState = {
        score: false,
        point_end: false,
        serve: false,
        return: false,
        rally: false,
        conversion_and_steal: false
      };
      newState[filterName] = !prevState[filterName];
      return newState;
    });
  };

  const FilterButton = ({ label, bgColor, textColor, filterName }) => {
    const selectedBgColor = 'secondary.800';
    return (
      <Flex
        as="button"
        gap={1}
        bg={isFilterOpen[filterName] ? selectedBgColor : bgColor}
        borderRadius="md"
        px={3}
        py={1}
        justifyContent="space-between"
        alignItems="center"
        onClick={() => toggleFilter(filterName)}
        cursor="pointer"
        inlineSize="max-content"
      >
        <Text color={textColor} fontWeight="semibold" fontSize="sm">
          {label}
        </Text>
        <Icon name="chevronDown" color={textColor} height={6} width={6} />
      </Flex>
    );
  };

  return (
    <Flex direction="column" justifyContent="center" alignItems="center">
      <Flex gap={2} flexWrap="wrap" justifyContent="center">
        <FilterButton
          label="Score"
          filterName="score"
          {...buttonColorProps}
          isFilterOpen={isFilterOpen}
          toggleFilter={toggleFilter}
        />
        <FilterButton
          label="Point End"
          filterName="point_end"
          {...buttonColorProps}
          isFilterOpen={isFilterOpen}
          toggleFilter={toggleFilter}
        />
        <FilterButton
          label="Serve"
          filterName="serve"
          {...buttonColorProps}
          isFilterOpen={isFilterOpen}
          toggleFilter={toggleFilter}
        />
        <FilterButton
          label="Return"
          filterName="return"
          {...buttonColorProps}
          isFilterOpen={isFilterOpen}
          toggleFilter={toggleFilter}
        />
        <FilterButton
          label="Rally"
          filterName="rally"
          {...buttonColorProps}
          isFilterOpen={isFilterOpen}
          toggleFilter={toggleFilter}
        />
        <FilterButton
          label="Conversion and Steal"
          filterName="conversion_and_steal"
          {...buttonColorProps}
          isFilterOpen={isFilterOpen}
          toggleFilter={toggleFilter}
        />
      </Flex>
      {isFilterOpen.score && (
        <Box {...filterContainerProps}>
          <FiltersForm
            formFieldsData={getScoreFormFieldsData(setGames, formState)}
            formState={formState}
            handleFilterSelect={handleFilterSelect}
          />
        </Box>
      )}
      {isFilterOpen.point_end && (
        <Box {...filterContainerProps}>
          <FiltersForm
            formFieldsData={getPointEndFormFieldsData(player1Name, player2Name)}
            formState={formState}
            handleFilterSelect={handleFilterSelect}
          />
        </Box>
      )}
      {isFilterOpen.serve && (
        <Box {...filterContainerProps}>
          <FiltersForm
            formFieldsData={getServeFormFieldsData(player1Name, player2Name)}
            formState={formState}
            handleFilterSelect={handleFilterSelect}
          />
        </Box>
      )}
      {isFilterOpen.return && (
        <Box {...filterContainerProps}>
          <FiltersForm
            formFieldsData={getReturnFormFieldsData(player1Name, player2Name)}
            formState={formState}
            handleFilterSelect={handleFilterSelect}
          />
        </Box>
      )}
      {isFilterOpen.rally && (
        <Box {...filterContainerProps}>
          <FiltersForm
            formFieldsData={getRallyFormFieldsData(player1Name, player2Name)}
            formState={formState}
            handleFilterSelect={handleFilterSelect}
          />
        </Box>
      )}
      {isFilterOpen.conversion_and_steal && (
        <Box {...filterContainerProps}>
          <FiltersForm
            formFieldsData={getConversionAndStealFormFieldsData(player1Name, player2Name)}
            formState={formState}
            handleFilterSelect={handleFilterSelect}
          />
        </Box>
      )}
    </Flex>
  );
}

Filters.propTypes = {
  player1Name: PropTypes.string,
  player2Name: PropTypes.string,
  setGames: PropTypes.array,
  formState: PropTypes.object,
  handleFilterSelect: PropTypes.func
};
