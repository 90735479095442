import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { getPlayerHeadshotImage } from 'scout/util/scoutHeaderUtil';
import PageLayout from 'scoutReport/components/PageLayout';

import TvLogo from '_shared/components/logo/TvLogo';
import { IMAGE_LOGO_USTA, IMAGE_LOGO_LTA } from '_shared/constants/imageConsts';
import { Box, Text, Flex, Image, VStack, Icon, NationalityFlag, Center } from '_shared/designSystem/components';
import { userPermissionsState } from '_shared/globalState/atoms';

export default function FrontPage(props) {
  const { data } = props;

  const { company } = useRecoilValue(userPermissionsState);

  const playerName = data ? `${data?.player_first_name} ${data?.player_last_name}` : '';

  return (
    <PageLayout playerName={playerName} hideHeader>
      <Box pt="200px">
        <VStack gap={5}>
          <Flex gap={12} mb={10} pt={10}>
            <TvLogo w="200px" h="66px" isPurpleLandscape />
            {company === 'usta' && <Image alt="USTA" h={14} src={IMAGE_LOGO_USTA} />}
            {company === 'lta' && <Image alt="LTA" h={14} src={IMAGE_LOGO_LTA} />}
          </Flex>
          <Text color="primary.600" fontWeight="bold" fontSize="6xl">
            Scout Report
          </Text>
          <Flex direction="column" gap={5} bg="secondary.800" p={10} borderRadius="xl">
            <Center>
              <PlayerImage url={getPlayerHeadshotImage(data, true)} />
            </Center>
            <Flex gap={5} justify="center">
              <NationalityFlag nationality={data?.nationality} width={30} />
              <PlayerName name={playerName} />
            </Flex>
          </Flex>
        </VStack>
      </Box>
    </PageLayout>
  );
}

FrontPage.propTypes = {
  data: PropTypes.object
};

const PlayerImage = ({ url }) => (
  <Box borderRadius="md" border="5px solid" borderColor="primary.400" p={0}>
    <Image src={url} w={200} fallback={<FallBackImage />} />
  </Box>
);

const FallBackImage = () => (
  <Flex w={200} h="100%" bg="secondary.800" justify="center" alignItems="flex-end">
    <Icon w={170} name="profileImagePlaceholder" color="grey.500" />
  </Flex>
);

const PlayerName = ({ name }) => (
  <Box>
    <Text color="primary.200" fontSize="2xl" lineHeight="22px" align="center">
      {name}
    </Text>
  </Box>
);
