import './fullScreenError.css';

export default function FullScreenError() {
  return (
    <div className="tv-error-container">
      <div className="tv-error-content">
        <div className="tv-error-logo-container">
          <div className="tv-error-logo">
            <img
              className="tv-error-img"
              alt="logo"
              src="https://s3.eu-central-1.amazonaws.com/portal.tennisviz.com/assets/tennis-viz-logo-black.png"
            />
          </div>
        </div>
        <h2>We are sorry</h2>
        <h1>An error has occured</h1>
        <p>Please try refreshing the page.</p>
        <p>
          If the error persists please{' '}
          <a href="https://www.tennisviz.com/contact/" rel="noopener" className="tv-error-contact-support-link">
            contact support
          </a>
        </p>
        <a href={`${window.location.protocol}//${window.location.hostname}`}>
          <button className="tv-error-home-button">Home</button>
        </a>
      </div>
    </div>
  );
}
