import { displayScore, zeroOrMore } from 'match/utils/transformations';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { Box, Text, Flex, Center, Icon, Tooltip } from '_shared/designSystem/components';
import { isMatchReportState } from '_shared/globalState/atoms';

import { leftColumnContentWidthMobile } from '../performanceMargins';

export default function BalanceOfPower(props) {
  const {
    leftPercentage,
    middlePercentage,
    rightPercentage,
    bgColor,
    bgBorderColor,
    winningColor,
    losingColor,
    neutralColor,
    winningTextColor,
    losingTextColor,
    neutralTextColor,
    headingColor,
    headingText
  } = props;
  const isMatchReport = useRecoilValue(isMatchReportState);

  return (
    <Flex
      borderRadius="xl"
      bg={bgColor}
      justify="center"
      pb={4}
      pt={4}
      border={bgBorderColor ? '1px solid' : null}
      borderColor={bgBorderColor}
    >
      <Box minW={`${leftColumnContentWidthMobile}px`} w="100%" px={7}>
        <Flex justify="center" mb={2}>
          <Flex gap={1}>
            <Text fontSize="md" fontWeight="medium" color={headingColor}>
              {headingText}
            </Text>
            {!isMatchReport && (
              <Tooltip
                bg="grey.100"
                textColour="secondary.800"
                tooltipText={`${headingText} shows the percentage of shots played in attack and neutral by both players`}
                elementToHover={
                  !bgColor || bgColor === 'white' ? (
                    <Icon name="info" color="secondary.800" height={4} width={4} />
                  ) : (
                    <Icon name="info" color="white" height={4} width={4} />
                  )
                }
              />
            )}
          </Flex>
        </Flex>
        <Box borderRadius="lg" height="34px" width="100%" bg={bgColor}>
          {zeroOrMore(leftPercentage) && zeroOrMore(middlePercentage) && zeroOrMore(rightPercentage) !== null && (
            <Flex>
              <Center
                borderLeftRadius="lg"
                height="34px"
                width={leftPercentage > 5 ? `${leftPercentage}%` : '5%'}
                bg={getBgColour(leftPercentage, rightPercentage, winningColor, losingColor)}
              >
                <Text
                  fontSize="xs"
                  fontWeight="normal"
                  color={getFontColour(leftPercentage, rightPercentage, winningTextColor, losingTextColor)}
                >
                  {displayScore(leftPercentage, 'percentage')}
                </Text>
              </Center>
              <Center height="34px" width={middlePercentage > 5 ? `${middlePercentage}%` : '5%'} bg={neutralColor}>
                <Text fontSize="xs" fontWeight="normal" color={neutralTextColor}>
                  {displayScore(middlePercentage, 'percentage')}
                </Text>
              </Center>
              <Center
                borderRightRadius="lg"
                height="34px"
                width={rightPercentage > 5 ? `${rightPercentage}%` : '5%'}
                bg={getBgColour(rightPercentage, leftPercentage, winningColor, losingColor)}
              >
                <Text
                  fontSize="xs"
                  fontWeight="normal"
                  color={getFontColour(rightPercentage, leftPercentage, winningTextColor, losingTextColor)}
                >
                  {displayScore(rightPercentage, 'percentage')}
                </Text>
              </Center>
            </Flex>
          )}
        </Box>
        <Flex justify="space-between" mt={2}>
          <Text fontSize="sm" fontWeight="medium" color={headingColor}>
            Attacking
          </Text>
          <Text fontSize="sm" fontWeight="medium" color={headingColor}>
            Neutral
          </Text>
          <Text fontSize="sm" fontWeight="medium" color={headingColor}>
            Attacking
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
}

BalanceOfPower.propTypes = {
  leftPercentage: PropTypes.number,
  middlePercentage: PropTypes.number,
  rightPercentage: PropTypes.number,
  bgColor: PropTypes.string,
  bgBorderColor: PropTypes.string,
  winningColor: PropTypes.string,
  losingColor: PropTypes.string,
  neutralColor: PropTypes.string,
  winningTextColor: PropTypes.string,
  losingTextColor: PropTypes.string,
  neutralTextColor: PropTypes.string,
  headingColor: PropTypes.string,
  headingText: PropTypes.string
};

BalanceOfPower.defaultProps = {
  bgColor: 'secondary.800',
  bgBorderColor: 'secondary.800',
  winningColor: 'primary.600',
  losingColor: 'primary.200',
  neutralColor: 'grey.200',
  winningTextColor: 'white',
  losingTextColor: 'secondary.800',
  neutralTextColor: 'grey.700',
  headingColor: 'white',
  headingText: 'In Attack'
};

function getBgColour(percentage1, percentage2, winningColor, losingColor) {
  if (percentage1 === percentage2) return losingColor;
  if (percentage1 > percentage2) return winningColor;
  else return losingColor;
}

function getFontColour(percentage1, percentage2, winningTextColor, losingTextColor) {
  if (percentage1 === percentage2) return losingTextColor;
  if (percentage1 > percentage2) return winningTextColor;
  else return losingTextColor;
}
