import { fieldMappings, categoriesOrder, videoPlaylistFields } from 'match/constants/videoConstants';
import { convertChipLabel, convertValueToLabel, isCategoryPresent } from 'match/utils/videoUtil';
import PropTypes from 'prop-types';

import { Center, Flex, Icon, Text, Box } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import { capitaliseString } from '_shared/utils/stringUtil';

import Chip from './Chip';

export default function ChipsGroup({ formState, handleRemoveChip, player1Name, player2Name, handleReset, setGames }) {
  const setsSelected = Array.isArray(formState[videoPlaylistFields.SETS])
    ? formState[videoPlaylistFields.SETS]
    : formState[videoPlaylistFields.SETS]?.split(',').map(Number);
  const hasMultipleSets = setsSelected && setsSelected.length > 1;
  const hasMultipleFilters = Object.keys(formState).length > 1 || hasMultipleSets;

  const VerboseChips = () => (
    <Flex gap={2} flexWrap="wrap">
      {Object.keys(formState).map((key) =>
        key === videoPlaylistFields.SETS ? (
          setsSelected.map((set, index) => (
            <Chip key={`${key}-${set}-${index}`} label={`Set ${set}`} onRemove={() => handleRemoveChip(key, set)} />
          ))
        ) : (
          <Chip
            key={key}
            label={
              formState[key] && (key === 'from_game' || key === 'to_game' || key === 'serve_plus_one')
                ? convertChipLabel(key, formState[key], formState, setGames, player1Name, player2Name)
                : convertValueToLabel(
                    convertChipLabel(key, formState[key], formState, setGames, player1Name, player2Name),
                    player1Name,
                    player2Name
                  )
            }
            onRemove={() => handleRemoveChip(key)}
          />
        )
      )}
      {hasMultipleFilters && (
        <Center cursor="pointer">
          <Flex gap={1} alignItems="center" onClick={handleReset}>
            <Text fontSize="2xs" fontWeight="semibold" color="secondary.800">
              RESET
            </Text>
            <Icon height="12px" width="12px" color="secondary.800" name="reset" data-testid="resetIcon" />
          </Flex>
        </Center>
      )}
    </Flex>
  );

  const CategorisedChips = () => (
    <Flex gap={3} direction="column">
      {categoriesOrder.map((category) => {
        return isCategoryPresent(category, formState) ? (
          <Box key={category}>
            <Flex gap={{ base: 0, sm: 4 }} direction={{ base: 'column', sm: 'row' }}>
              <Text fontSize="xs" mb={1} w={isWimbPortal() ? '59px' : '54px'} minW={isWimbPortal() ? '59px' : '54px'}>
                {capitaliseString(category)}
              </Text>
              <Flex gap={2} wrap="wrap">
                {fieldMappings[category].map((field) => {
                  if (formState[field]) {
                    if (field === videoPlaylistFields.SETS) {
                      return setsSelected.map((set, index) => (
                        <Chip
                          key={`${field}-${set}-${index}`}
                          label={`Set ${set}`}
                          onRemove={() => handleRemoveChip(field, set)}
                        />
                      ));
                    }
                    return (
                      <Chip
                        key={field}
                        label={
                          field === 'from_game' || field === 'to_game' || field === 'serve_plus_one'
                            ? convertChipLabel(field, formState[field], formState, setGames, player1Name, player2Name)
                            : convertValueToLabel(
                                convertChipLabel(
                                  field,
                                  formState[field],
                                  formState,
                                  setGames,
                                  player1Name,
                                  player2Name,
                                  true
                                ),
                                player1Name,
                                player2Name
                              )
                        }
                        onRemove={() => handleRemoveChip(field)}
                      />
                    );
                  }
                  return null;
                })}
              </Flex>
            </Flex>
          </Box>
        ) : null;
      })}
      <Flex gap={1} alignItems="center" onClick={handleReset}>
        <Text fontSize="2xs" fontWeight="semibold" color="secondary.800">
          RESET
        </Text>
        <Icon height="12px" width="12px" color="secondary.800" name="reset" data-testid="resetIcon" />
      </Flex>
    </Flex>
  );

  return Object.keys(formState).length > 6 ? <CategorisedChips /> : <VerboseChips />;
}

ChipsGroup.propTypes = {
  player1Name: PropTypes.string,
  player2Name: PropTypes.string,
  formState: PropTypes.object.isRequired,
  handleReset: PropTypes.func,
  handleRemoveChip: PropTypes.func.isRequired,
  setGames: PropTypes.array.isRequired
};
