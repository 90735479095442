import PropTypes from 'prop-types';

import { Box, Flex, Text } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';

const Badge = ({ text, bgColour, iconColour, textColour, withLeftCircle }) => {
  const sizeOfText = isWimbPortal() ? 'xs' : 'sm';

  return (
    <Flex bg={bgColour} px={2} display="inline-block" borderRadius="md">
      <Flex gap={2}>
        {withLeftCircle && (
          <Flex alignItems="center" justifyContent="center">
            <Box w="6px" h="6px" borderRadius="full" bg={iconColour} />
          </Flex>
        )}
        <Text fontSize={sizeOfText} fontWeight="medium" color={textColour}>
          {text}
        </Text>
      </Flex>
    </Flex>
  );
};

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  colour: PropTypes.string
};

Badge.defaultProps = {
  colour: 'primary',
  withLeftCircle: false
};

export default Badge;
