import { useEffect, useState } from 'react';

import { useMatch } from '@tanstack/react-location';
import isEmpty from 'lodash/isEmpty';
import DataIncompleteAlert from 'match/_shared/DataCompletenessAlert';
import { DATE_INCOMPLETE_ALERT_TYPES, MATCH_TAB_NAMES } from 'match/_shared/matchConstants';
import { useCheckHidePageContent } from 'match/hooks/dataQualityHooks';
import {
  useDataQualityFlagQuery,
  useHeaderQuery,
  usePerformanceNoAveragesQuery,
  usePerformanceRatingGame,
  usePerformanceWithAveragesQuery
} from 'match/hooks/matchHooks';
import { useMatchPageTitle } from 'match/hooks/pageTitleHooks';
import { findAISelected } from 'match/utils/findAISelected';
import { filterBySet } from 'match/utils/performanceUtil';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { bannerAlertType } from '_shared/constants/alertTypes';
import { userPermissionTypes } from '_shared/constants/user';
import { Alert, Box, ErrorMessage, Flex, Spinner } from '_shared/designSystem/components';
import { userPermissionsState, AISelectedState, liveMatchRefetchIntervalState } from '_shared/globalState/atoms';
import { checkPermission } from '_shared/utils/permissions';

import { AblyUpdater } from '../_shared/AblyUpdater';

import PerformanceHeader from './PerformanceHeader';
import PerformanceMetrics from './PerformanceMetrics';

const Performance = () => {
  const [showAverages, setShowAverages] = useState(false);
  const [currentSetFilter, setCurrentSetFilter] = useState('all');
  const [currentScoreRangeFilter, setScoreRangeFilter] = useState(null);
  const [setsPlayedCount, setSetsPlayedCount] = useState(null);
  const [matchStatsCategory, setMatchStatsCategory] = useState('serve');
  const [performanceRatingGameBySet, setPerformanceRatingGameBySet] = useState(null);
  const [isAISelected, setIsAISelected] = useState(false);
  const [isAISearching, setIsAISearching] = useState(true);

  const setAISelectedState = useSetRecoilState(AISelectedState);
  const AISelected = useRecoilValue(AISelectedState);
  const liveMatchRefetchInterval = useRecoilValue(liveMatchRefetchIntervalState);

  const {
    params: { matchId }
  } = useMatch();

  const { data } = useHeaderQuery(matchId);

  useMatchPageTitle(matchId, MATCH_TAB_NAMES.PERFORMANCE);

  const {
    data: performanceData,
    isLoading: performanceIsLoading,
    error: performanceError,
    refetch: performanceRefetch
  } = usePerformanceNoAveragesQuery(matchId, currentSetFilter, currentScoreRangeFilter, {
    refetchInterval: liveMatchRefetchInterval
  });

  const { data: performanceWithAveragesData, isLoading: performanceWithAveragesIsLoading } =
    usePerformanceWithAveragesQuery(matchId, currentSetFilter, currentScoreRangeFilter);

  const { data: performanceRatingGameData, refetch: performanceRatingGameRefetch } = usePerformanceRatingGame(matchId, {
    refetchInterval: liveMatchRefetchInterval
  });

  const { refetch: dataQualityFlagRefetch } = useDataQualityFlagQuery(matchId);

  useEffect(() => {
    if (performanceData?.sets_played_count) setSetsPlayedCount(performanceData?.sets_played_count);
  }, [performanceData]);

  useEffect(() => {
    setPerformanceRatingGameBySet(filterBySet(performanceRatingGameData, currentSetFilter));
  }, [performanceRatingGameData, currentSetFilter]);

  // Reset AISelectedState when matchId changes
  useEffect(() => {
    if (!isAISelected) {
      setAISelectedState({ found: false, path: null });
    }
  }, [matchId, isAISelected, setAISelectedState]);

  useEffect(() => {
    if (performanceWithAveragesData && isAISelected) {
      setIsAISearching(true); // Start searching for AI Insight
      const AISelectedResult = findAISelected(performanceWithAveragesData.performance);
      setAISelectedState(AISelectedResult);
      setIsAISearching(false); // Finished searching for AI Insight
    }
  }, [performanceWithAveragesData, isAISelected, setAISelectedState]);

  const { permissions } = useRecoilValue(userPermissionsState);

  const hidePageContent = useCheckHidePageContent(matchId);

  const refetchPerformanceAndGraphData = () => {
    performanceRefetch();
    performanceRatingGameRefetch();
    dataQualityFlagRefetch();
  };

  if (!checkPermission(userPermissionTypes.PERFORMANCE, permissions))
    return (
      <Box mt={5}>
        <Alert
          message="You do not have permission to view the performance metrics. If you believe this is an error please contact the site admin"
          status="warning"
        />
      </Box>
    );

  if (performanceError)
    throw new Error(`Unable to retrieve performance data for match ${matchId}: ${performanceError}`);

  if (!performanceIsLoading && isEmpty(performanceData))
    return (
      <Box mt={5}>
        <ErrorMessage message="No performance data for that match is available" />
      </Box>
    );

  if (hidePageContent) {
    return <DataIncompleteAlert messageType={DATE_INCOMPLETE_ALERT_TYPES.HIDE_DATA} />;
  }

  return (
    <Box minH="100vh">
      <AblyUpdater
        matchId={data?.match_id}
        matchStatus={data?.match_status}
        updateDataFunction={refetchPerformanceAndGraphData}
      />
      <PerformanceHeader
        setsPlayedCount={setsPlayedCount}
        setShowAverages={setShowAverages}
        showAverages={showAverages}
        isAISelected={isAISelected}
        setIsAISelected={setIsAISelected}
        setAISelectedState={setAISelectedState}
        currentSetFilter={currentSetFilter}
        setCurrentSetFilter={setCurrentSetFilter}
        currentScoreRangeFilter={currentScoreRangeFilter}
        setScoreRangeFilter={setScoreRangeFilter}
        matchData={data}
      />
      {isAISelected && !isAISearching && !AISelected.found && (
        <Alert status={bannerAlertType.INFO} message="Currently no AI Insights available" />
      )}
      {performanceIsLoading && (
        <Flex pt={150} justify="center">
          <Spinner color="primary.500" />
        </Flex>
      )}
      {!performanceIsLoading && performanceData && (
        <PerformanceMetrics
          data={performanceData}
          dataWithAverages={performanceWithAveragesData}
          averagesLoading={performanceWithAveragesIsLoading}
          showAverages={showAverages}
          matchStatsCategory={matchStatsCategory}
          setMatchStatsCategory={setMatchStatsCategory}
          currentScoreRangeFilter={currentScoreRangeFilter}
          performanceRatingGame={{ ...performanceRatingGameData, data: performanceRatingGameBySet }}
          isAISelected={isAISelected}
          isAISelectorActive={isAISelected && AISelected.found}
        />
      )}
    </Box>
  );
};

export default Performance;
