import { courtGraphicTypes } from 'match/_shared/matchConstants';
import CourtContainer from 'match/components/court-graphics/court/CourtContainer';
import { nth } from 'match/components/performance/ScoreRangeFilter';
import { convertLabelName, displayScore } from 'match/utils/transformations';
import PageHeading from 'matchReport/components/_shared/PageHeading';
import { useMatchReportCourtGraphicsQuery } from 'matchReport/hooks/matchReportHooks';

import { Box, Flex } from '_shared/designSystem/components';

import SubHeading from '../_shared/SubHeading';

import MatchReportTable from './MatchReportTable';

const DoubleCourtPage = ({ headerData, heading, pageType, matchId, player, serve, forehandBackhand }) => {
  const surface = headerData?.surface;
  let graphicTypeTop;
  let graphicTypeBottom;
  let queryParamsTop;
  let queryParamsBottom;
  let subHeadingTop;
  let subHeadingBottom;

  const playerName = headerData?.player_details?.[`${player}`]?.member1?.last_name;

  switch (pageType) {
    case 'return':
      graphicTypeTop = courtGraphicTypes.RETURN_CONTACT_POINT;
      graphicTypeBottom = courtGraphicTypes.RETURN_PLACEMENT;
      subHeadingTop = `${playerName} ${nth(serve)} Return Contact Point`;
      subHeadingBottom = `${playerName} ${nth(serve)} Return Placement`;
      queryParamsTop = {
        graphicType: graphicTypeTop,
        player,
        serve
      };
      queryParamsBottom = {
        graphicType: graphicTypeBottom,
        player,
        serve
      };
      break;
    case courtGraphicTypes.SERVE_PLUS_ONE:
      graphicTypeTop = courtGraphicTypes.RALLY_CONTACT_POINT;
      graphicTypeBottom = courtGraphicTypes.RALLY_PLACEMENT;
      subHeadingTop = `${playerName} Serve +1 ${convertLabelName(forehandBackhand)} Contact Point`;
      subHeadingBottom = `${playerName} Serve +1 ${convertLabelName(forehandBackhand)} Placement`;
      queryParamsTop = {
        graphicType: graphicTypeTop,
        player,
        forehandBackhand,
        servePlusOne: true
      };
      queryParamsBottom = {
        graphicType: graphicTypeBottom,
        player,
        forehandBackhand,
        servePlusOne: true
      };
      break;
    case 'rally':
      graphicTypeTop = courtGraphicTypes.RALLY_CONTACT_POINT;
      graphicTypeBottom = courtGraphicTypes.RALLY_PLACEMENT;
      subHeadingTop = `${playerName} Rally ${convertLabelName(forehandBackhand)} Contact Point`;
      subHeadingBottom = `${playerName} Rally ${convertLabelName(forehandBackhand)} Placement`;
      queryParamsTop = {
        graphicType: graphicTypeTop,
        player,
        forehandBackhand
      };
      queryParamsBottom = {
        graphicType: graphicTypeBottom,
        player,
        forehandBackhand
      };
      break;
    default:
  }

  queryParamsTop.showAverages = false;
  queryParamsBottom.showAverages = false;
  queryParamsTop.set = 'all';
  queryParamsBottom.set = 'all';

  const fetchParams = { enabled: !!headerData };

  const resTop = useMatchReportCourtGraphicsQuery(matchId, queryParamsTop, fetchParams);
  const resBottom = useMatchReportCourtGraphicsQuery(matchId, queryParamsBottom, fetchParams);
  if (resTop.error || resBottom.error) throw new Error('Unable to retrieve court graphics data');
  if (resTop.isLoading || resBottom.isLoading) return null;

  const dataTop = resTop?.data;
  const dataBottom = resBottom?.data;

  const dataNoAveragesTopPlacement = dataTop?.averages?.placement;
  const dataNoAveragesBottomPlacement = dataBottom?.averages?.placement;

  const dataNoAveragesTopWin = dataTop?.averages?.win;
  const dataNoAveragesBottomWin = dataBottom?.averages?.win;

  const dataNoAveragesTopQuality = dataTop?.averages?.quality;

  let headingsTop = [];
  let tableDataTop = [];
  let tableRowHeightTop;
  let tableMarginTop;

  let headingsBottom = [];
  let tableDataBottom = [];
  let tableRowHeightBottom;
  let tableMarginBottom = '15px';

  if (graphicTypeTop === courtGraphicTypes.RETURN_CONTACT_POINT) {
    tableRowHeightTop = '118px';
    tableMarginTop = '0px';
  } else if (graphicTypeTop === courtGraphicTypes.RALLY_CONTACT_POINT) {
    tableRowHeightTop = '120px';
    tableMarginTop = '80px';
  }

  if (graphicTypeTop === courtGraphicTypes.RETURN_CONTACT_POINT) {
    headingsTop = ['Win', 'Quality'];
    tableDataTop = [
      {
        heading: null,
        data: [
          getScoreString(dataNoAveragesTopPlacement, dataNoAveragesTopWin, 'top'),
          getScoreString(dataNoAveragesTopPlacement, dataNoAveragesTopQuality, 'top')
        ]
      },
      {
        heading: null,
        data: [
          getScoreString(dataNoAveragesTopPlacement, dataNoAveragesTopWin, 'middle'),
          getScoreString(dataNoAveragesTopPlacement, dataNoAveragesTopQuality, 'middle')
        ]
      },
      {
        heading: null,
        data: [
          getScoreString(dataNoAveragesTopPlacement, dataNoAveragesTopWin, 'bottom'),
          getScoreString(dataNoAveragesTopPlacement, dataNoAveragesTopQuality, 'bottom')
        ]
      }
    ];
  } else if (graphicTypeTop === courtGraphicTypes.RALLY_CONTACT_POINT) {
    headingsTop = ['Quality'];
    tableDataTop = [
      {
        heading: null,
        data: [getScoreString(dataNoAveragesTopPlacement, dataNoAveragesTopQuality, 'top')]
      },
      {
        heading: null,
        data: [getScoreString(dataNoAveragesTopPlacement, dataNoAveragesTopQuality, 'middle')]
      },
      {
        heading: null,
        data: [getScoreString(dataNoAveragesTopPlacement, dataNoAveragesTopQuality, 'bottom')]
      }
    ];
  }

  if (graphicTypeBottom === courtGraphicTypes.RETURN_PLACEMENT) {
    headingsBottom = ['', 'Left', 'Middle', 'Right'];
    tableDataBottom = [
      {
        heading: 'Win',
        data: [
          getScoreString(dataNoAveragesBottomPlacement, dataNoAveragesBottomWin?.vertical, 'left'),
          getScoreString(dataNoAveragesBottomPlacement, dataNoAveragesBottomWin?.vertical, 'middle'),
          getScoreString(dataNoAveragesBottomPlacement, dataNoAveragesBottomWin?.vertical, 'right')
        ]
      }
    ];
  } else if (graphicTypeBottom === courtGraphicTypes.RALLY_PLACEMENT) {
    headingsBottom = [];
    tableDataBottom = [];
  }

  if (dataTop && dataBottom) {
    return (
      <Box>
        <PageHeading>{heading}</PageHeading>
        <Flex align="center" direction="column">
          <Box w={graphicTypeTop === courtGraphicTypes.RALLY_CONTACT_POINT ? 900 : 1000}>
            <SubHeading>{subHeadingTop}</SubHeading>
            <Flex>
              <Box w={graphicTypeTop === courtGraphicTypes.RALLY_CONTACT_POINT ? 750 : 850}>
                <CourtContainer
                  graphicType={graphicTypeTop}
                  surface={surface}
                  shots={dataTop?.shots}
                  dataNoAverages={dataNoAveragesTopPlacement}
                  showAverages={false}
                  orientation="vertical"
                />
              </Box>
              <Box mt={tableMarginTop}>
                <MatchReportTable rowHeight={tableRowHeightTop} headings={headingsTop} tableData={tableDataTop} />
              </Box>
            </Flex>
          </Box>
          <Box mt={5} h="1px" w="100%" bg="gray.500" />
          <Box w={850} mt={5}>
            <SubHeading>{subHeadingBottom}</SubHeading>
            <Box w={850} mt={2}>
              <CourtContainer
                graphicType={graphicTypeBottom}
                surface={surface}
                shots={dataBottom?.shots}
                dataNoAverages={dataNoAveragesBottomPlacement}
                showAverages={false}
                orientation="vertical"
              />
            </Box>
            <Box mt={tableMarginBottom}>
              <MatchReportTable
                rowHeight={tableRowHeightBottom}
                headings={headingsBottom}
                tableData={tableDataBottom}
              />
            </Box>
          </Box>
        </Flex>
      </Box>
    );
  }
  return null;
};

export function getScoreString(placementData, data, position) {
  if (placementData?.[`${position}`]?.match_average === 0) return '';
  return `${displayScore(data?.[`${position}`]?.match_average, data?.[`${position}`]?.score_type)}`;
}

export default DoubleCourtPage;
