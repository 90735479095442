import PropTypes from 'prop-types';

import { Input, Text, Flex } from '_shared/designSystem/components';

export default function InputStandard(props) {
  const { label, fieldId, onChange, value } = props;

  return (
    <Flex direction="column">
      <Text color="grey.600" fontWeight="medium" fontSize="xs" ml={1}>
        {label}
      </Text>
      <Input
        w="180px"
        borderRadius="md"
        borderColor="grey.300"
        id={fieldId}
        name={fieldId}
        size="sm"
        focusBorderColor="primary.500"
        placeholder=""
        data-testid="inputStandard"
        onChange={onChange}
        value={value}
        h="32px"
      />
    </Flex>
  );
}

InputStandard.propTypes = {
  label: PropTypes.string,
  fieldId: PropTypes.string,
  onChange: PropTypes.func
};
