import { Link } from '@tanstack/react-location';

import { Text, Button, Flex } from '_shared/designSystem/components';

export default function Page404() {
  return (
    <Flex justify="center" mt="100px">
      <Flex direction="column" align="center" gap={10} px={5}>
        <Text textAlign="center" fontSize="4xl" fontWeight="semibold">
          Page not found
        </Text>
        <Text textAlign="center" fontSize="lg">
          We can't seem to find the page you're looking for
        </Text>
        <Link to="/">
          <Button>Home</Button>
        </Link>
      </Flex>
    </Flex>
  );
}
