import PropTypes from 'prop-types';
import { patternsOfPlayPattern, patternsOfPlayGraphicType } from 'scout/_shared/scoutConstants';

import { Flex, CourtSurface2dFull } from '_shared/designSystem/components';
import colors from '_shared/designSystem/theme/colors';

import PatternsOfPlayCourtArrows from './CourtArrows';

const PatternsOfPlayCourtWithArrows = ({ surface, pattern, isWideCourt, graphicType }) => {
  const backgroundColor = `court.background.${surface}`;

  return (
    <Flex bg={backgroundColor} width={isWideCourt ? '500px' : 'unset'} justifyContent="center" alignItems="center">
      <Flex data-testid="courtWithArrows" w="350px" h="600px" position="relative">
        <PatternsOfPlayCourtArrows
          pattern={pattern}
          isWideCourt={isWideCourt}
          color={surface === 'grass' ? colors.grey[300] : colors.grey[400]}
        />
        <CourtSurface2dFull
          surface={surface}
          isWideCourt={isWideCourt}
          hasDashedLine={graphicType === patternsOfPlayGraphicType.DEPTH ? false : true}
        />
      </Flex>
    </Flex>
  );
};

PatternsOfPlayCourtWithArrows.propTypes = {
  surface: PropTypes.string,
  pattern: PropTypes.oneOf(Object.values(patternsOfPlayPattern)),
  graphicType: PropTypes.oneOf(Object.values(patternsOfPlayGraphicType)),
  isWideCourt: PropTypes.bool
};

PatternsOfPlayCourtWithArrows.defaultProps = {
  surface: 'hard',
  pattern: patternsOfPlayPattern.CONTROL_THE_MIDDLE,
  graphicType: patternsOfPlayGraphicType.DEPTH,
  isWideCourt: false
};

export default PatternsOfPlayCourtWithArrows;
