import { logBasicMessage } from '_shared/errors/log';

import { isAtpMediaPortal, isWimbPortal } from './environment/currentEnv';

export function sendGoogleAnalyticsUserId(userId) {
  // The media portal and wimbledon doesn't have GTM
  if (!isAtpMediaPortal() && !isWimbPortal()) {
    if (userId > 0 && window.dataLayer) {
      window.dataLayer.push({
        event: 'userData',
        userId: String(userId)
      });
      logBasicMessage('INFO', `Sending userId ${userId} to Google Analytics data layer`);
    } else logBasicMessage('ERROR', `Cannot send userId ${userId} to Google analytics`);
  }
}
