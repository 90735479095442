import isEmpty from 'lodash/isEmpty';
import { viewingTypes } from 'schedule/constants/scheduleConstants';

import { tournamentStatus } from '_shared/constants/matchTypes';

export function getFormStateAndViewingTypeFromUrl(search, compStatus, activeDay) {
  let newFormState = {
    round: null,
    day: null,
    playerName: search.playerName
  };
  let viewingType;

  if (!isEmpty(search.round)) {
    newFormState.round = search.round;
    viewingType = viewingTypes.LIST_VIEW;
  } else if (search.day > 0) {
    newFormState.day = search.day;
    viewingType = viewingTypes.COURT_VIEW;
  } else {
    newFormState =
      compStatus === tournamentStatus.CURRENT ? { ...newFormState, day: activeDay } : { ...newFormState, round: 'All' };
    viewingType = compStatus === tournamentStatus.CURRENT ? viewingTypes.COURT_VIEW : viewingTypes.LIST_VIEW;
  }

  return { newFormState, viewingType };
}
