import { courtGraphicTypes } from 'match/_shared/matchConstants';
import {
  isServeDirectionOrAccuracy,
  isReturnOrRallyPlacementVertical,
  isReturnOrRallyPlacementHorizontal
} from 'match/utils/courtGraphicsUtil';

import colors from '_shared/designSystem/theme/colors';
export default function CourtSurface3d(props) {
  const { surface, graphicType, orientation } = props;

  const surfaceColor = colors.court.surface[`${surface}`].hex;
  const startingOpacity = colors.court.surface[`${surface}`].opacityStart;
  const finishingOpacity = colors.court.surface[`${surface}`].opacityEnd;

  const serveDirectionDashes = (
    <svg>
      <path d="M376 313 L431 124" stroke="white" strokeWidth="2" strokeDasharray="4 4" />
      <path d="M475 313 L502 124" stroke="white" strokeWidth="2" strokeDasharray="4 4" />
      <path d="M671 313 L644 124" stroke="white" strokeWidth="2" strokeDasharray="4 4" />
      <path d="M769 313 L715 124" stroke="white" strokeWidth="2" strokeDasharray="4 4" />
    </svg>
  );

  const returnPlacementVertical = (
    <svg>
      <path d="M501 124 L450 479" stroke="white" strokeWidth="2" strokeDasharray="4 4" />
      <path d="M644 124 L694 479" stroke="white" strokeWidth="2" strokeDasharray="4 4" />
    </svg>
  );

  const returnPlacementHorizontal = (
    <svg>
      <path d="M1008 397L145 397" stroke="white" strokeWidth="2" strokeDasharray="4 4" />
      <path d="M964.5 317L186 317" stroke="white" strokeWidth="2" strokeDasharray="4 4" />
    </svg>
  );

  return (
    <svg
      data-testid="courtSurface3d"
      width="100%"
      height="100%"
      viewBox="0 0 1146 580"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_10118_388641)">
        <path
          d="M786 8.98389H755.5H361.5L90 479.499H1056L786 8.98389Z"
          fill="url(#paint0_linear_10118_388641)"
          stroke="white"
          strokeWidth="4"
        />
        <path
          d="M361.5 9H410.5L210 478.504L90 479.5L361.5 9Z"
          fill="url(#paint1_linear_10118_388641)"
          stroke="white"
          strokeWidth="4"
        />
        <path
          d="M784.5 9H730.5L937 480.002H1058L787.5 10.4814Z"
          fill="url(#paint1_linear_10118_388641)"
          stroke="white"
          strokeWidth="4"
        />
        <path d="M279 314.461H865" stroke="white" strokeWidth="4" />
        <path d="M573 313V11" stroke="white" strokeWidth="4" />
      </g>
      <g filter="url(#filter1_i_10118_388641)">
        <path d="M268 66.6318H881.796" stroke="#475467" />
        <path d="M268 73.8657H881.796" stroke="#475467" />
        <path d="M268 77.8447H881.796" stroke="#475467" />
        <path d="M268 84.8081H881.796" stroke="#475467" />
        <path d="M268 91.7715H881.796" stroke="#475467" />
        <path d="M268 99.729H881.796" stroke="#475467" />
        <path d="M268 106.692H881.796" stroke="#475467" />
        <path d="M268 113.656H881.796" stroke="#475467" />
        <path d="M268 120.619H881.796" stroke="#475467" />
        <path d="M268 70.8818H881.796" stroke="#475467" />
        <path d="M268 81.1006H881.796" stroke="#475467" />
        <path d="M268 88.3359H881.796" stroke="#475467" />
        <path d="M268 95.5693H881.796" stroke="#475467" />
        <path d="M268 102.804H881.796" stroke="#475467" />
        <path d="M268 110.039H881.796" stroke="#475467" />
        <path d="M268 117.273H881.796" stroke="#475467" />
        <path d="M277.5 61.7056V123.38" stroke="#475467" />
        <path d="M277.5 61.7056V123.38" stroke="#475467" />
        <path d="M273.5 61.7056V123.38" stroke="#475467" />
        <path d="M270.5 61.7056V123.38" stroke="#475467" />
        <path d="M316.5 61.7056V123.38" stroke="#475467" />
        <path d="M356.5 61.7056V123.38" stroke="#475467" />
        <path d="M396.5 61.7056V123.38" stroke="#475467" />
        <path d="M435.5 61.7056V123.38" stroke="#475467" />
        <path d="M475.5 61.7056V123.38" stroke="#475467" />
        <path d="M515.5 61.7056V123.38" stroke="#475467" />
        <path d="M554.5 61.7056V123.38" stroke="#475467" />
        <path d="M594.5 61.7056V123.38" stroke="#475467" />
        <path d="M634.5 61.7056V123.38" stroke="#475467" />
        <path d="M673.5 61.7056V123.38" stroke="#475467" />
        <path d="M713.5 61.7056V123.38" stroke="#475467" />
        <path d="M753.5 61.7056V123.38" stroke="#475467" />
        <path d="M792.5 61.7056V123.38" stroke="#475467" />
        <path d="M832.5 61.7056V123.38" stroke="#475467" />
        <path d="M282.5 61.7056V123.38" stroke="#475467" />
        <path d="M321.5 61.7056V123.38" stroke="#475467" />
        <path d="M361.5 61.7056V123.38" stroke="#475467" />
        <path d="M401.5 61.7056V123.38" stroke="#475467" />
        <path d="M440.5 61.7056V123.38" stroke="#475467" />
        <path d="M480.5 61.7056V123.38" stroke="#475467" />
        <path d="M520.5 61.7056V123.38" stroke="#475467" />
        <path d="M559.5 61.7056V123.38" stroke="#475467" />
        <path d="M599.5 61.7056V123.38" stroke="#475467" />
        <path d="M639.5 61.7056V123.38" stroke="#475467" />
        <path d="M678.5 61.7056V123.38" stroke="#475467" />
        <path d="M718.5 61.7056V123.38" stroke="#475467" />
        <path d="M758.5 61.7056V123.38" stroke="#475467" />
        <path d="M797.5 61.7056V123.38" stroke="#475467" />
        <path d="M837.5 61.7056V123.38" stroke="#475467" />
        <path d="M292.5 61.7056V123.38" stroke="#475467" />
        <path d="M331.5 61.7056V123.38" stroke="#475467" />
        <path d="M371.5 61.7056V123.38" stroke="#475467" />
        <path d="M411.5 61.7056V123.38" stroke="#475467" />
        <path d="M450.5 61.7056V123.38" stroke="#475467" />
        <path d="M490.5 61.7056V123.38" stroke="#475467" />
        <path d="M530.5 61.7056V123.38" stroke="#475467" />
        <path d="M569.5 61.7056V123.38" stroke="#475467" />
        <path d="M609.5 61.7056V123.38" stroke="#475467" />
        <path d="M649.5 61.7056V123.38" stroke="#475467" />
        <path d="M688.5 61.7056V123.38" stroke="#475467" />
        <path d="M728.5 61.7056V123.38" stroke="#475467" />
        <path d="M768.5 61.7056V123.38" stroke="#475467" />
        <path d="M807.5 61.7056V123.38" stroke="#475467" />
        <path d="M847.5 61.7056V123.38" stroke="#475467" />
        <path d="M302.5 61.7056V123.38" stroke="#475467" />
        <path d="M341.5 61.7056V123.38" stroke="#475467" />
        <path d="M381.5 61.7056V123.38" stroke="#475467" />
        <path d="M421.5 61.7056V123.38" stroke="#475467" />
        <path d="M460.5 61.7056V123.38" stroke="#475467" />
        <path d="M500.5 61.7056V123.38" stroke="#475467" />
        <path d="M540.5 61.7056V123.38" stroke="#475467" />
        <path d="M579.5 61.7056V123.38" stroke="#475467" />
        <path d="M619.5 61.7056V123.38" stroke="#475467" />
        <path d="M659.5 61.7056V123.38" stroke="#475467" />
        <path d="M698.5 61.7056V123.38" stroke="#475467" />
        <path d="M738.5 61.7056V123.38" stroke="#475467" />
        <path d="M778.5 61.7056V123.38" stroke="#475467" />
        <path d="M817.5 61.7056V123.38" stroke="#475467" />
        <path d="M857.5 61.7056V123.38" stroke="#475467" />
        <path d="M312.5 61.7056V123.38" stroke="#475467" />
        <path d="M351.5 61.7056V123.38" stroke="#475467" />
        <path d="M391.5 61.7056V123.38" stroke="#475467" />
        <path d="M431.5 61.7056V123.38" stroke="#475467" />
        <path d="M470.5 61.7056V123.38" stroke="#475467" />
        <path d="M510.5 61.7056V123.38" stroke="#475467" />
        <path d="M550.5 61.7056V123.38" stroke="#475467" />
        <path d="M589.5 61.7056V123.38" stroke="#475467" />
        <path d="M629.5 61.7056V123.38" stroke="#475467" />
        <path d="M669.5 61.7056V123.38" stroke="#475467" />
        <path d="M708.5 61.7056V123.38" stroke="#475467" />
        <path d="M748.5 61.7056V123.38" stroke="#475467" />
        <path d="M788.5 61.7056V123.38" stroke="#475467" />
        <path d="M827.5 61.7056V123.38" stroke="#475467" />
        <path d="M867.5 61.7056V123.38" stroke="#475467" />
        <path d="M287.5 61.7056V123.38" stroke="#475467" />
        <path d="M297.5 61.7056V123.38" stroke="#475467" />
        <path d="M307.5 61.7056V123.38" stroke="#475467" />
        <path d="M316.5 61.7056V123.38" stroke="#475467" />
        <path d="M326.5 61.7056V123.38" stroke="#475467" />
        <path d="M336.5 61.7056V123.38" stroke="#475467" />
        <path d="M346.5 61.7056V123.38" stroke="#475467" />
        <path d="M356.5 61.7056V123.38" stroke="#475467" />
        <path d="M366.5 61.7056V123.38" stroke="#475467" />
        <path d="M376.5 61.7056V123.38" stroke="#475467" />
        <path d="M386.5 61.7056V123.38" stroke="#475467" />
        <path d="M396.5 61.7056V123.38" stroke="#475467" />
        <path d="M406.5 61.7056V123.38" stroke="#475467" />
        <path d="M416.5 61.7056V123.38" stroke="#475467" />
        <path d="M426.5 61.7056V123.38" stroke="#475467" />
        <path d="M435.5 61.7056V123.38" stroke="#475467" />
        <path d="M445.5 61.7056V123.38" stroke="#475467" />
        <path d="M455.5 61.7056V123.38" stroke="#475467" />
        <path d="M465.5 61.7056V123.38" stroke="#475467" />
        <path d="M475.5 61.7056V123.38" stroke="#475467" />
        <path d="M485.5 61.7056V123.38" stroke="#475467" />
        <path d="M495.5 61.7056V123.38" stroke="#475467" />
        <path d="M505.5 61.7056V123.38" stroke="#475467" />
        <path d="M515.5 61.7056V123.38" stroke="#475467" />
        <path d="M525.5 61.7056V123.38" stroke="#475467" />
        <path d="M535.5 61.7056V123.38" stroke="#475467" />
        <path d="M545.5 61.7056V123.38" stroke="#475467" />
        <path d="M564.5 61.7056V123.38" stroke="#475467" />
        <path d="M574.5 61.7056V123.38" stroke="#475467" />
        <path d="M584.5 61.7056V123.38" stroke="#475467" />
        <path d="M594.5 61.7056V123.38" stroke="#475467" />
        <path d="M604.5 61.7056V123.38" stroke="#475467" />
        <path d="M614.5 61.7056V123.38" stroke="#475467" />
        <path d="M624.5 61.7056V123.38" stroke="#475467" />
        <path d="M634.5 61.7056V123.38" stroke="#475467" />
        <path d="M644.5 61.7056V123.38" stroke="#475467" />
        <path d="M654.5 61.7056V123.38" stroke="#475467" />
        <path d="M664.5 61.7056V123.38" stroke="#475467" />
        <path d="M683.5 61.7056V123.38" stroke="#475467" />
        <path d="M693.5 61.7056V123.38" stroke="#475467" />
        <path d="M703.5 61.7056V123.38" stroke="#475467" />
        <path d="M713.5 61.7056V123.38" stroke="#475467" />
        <path d="M723.5 61.7056V123.38" stroke="#475467" />
        <path d="M733.5 61.7056V123.38" stroke="#475467" />
        <path d="M743.5 61.7056V123.38" stroke="#475467" />
        <path d="M753.5 61.7056V123.38" stroke="#475467" />
        <path d="M763.5 61.7056V123.38" stroke="#475467" />
        <path d="M773.5 61.7056V123.38" stroke="#475467" />
        <path d="M783.5 61.7056V123.38" stroke="#475467" />
        <path d="M802.5 61.7056V123.38" stroke="#475467" />
        <path d="M812.5 61.7056V123.38" stroke="#475467" />
        <path d="M822.5 61.7056V123.38" stroke="#475467" />
        <path d="M832.5 61.7056V123.38" stroke="#475467" />
        <path d="M842.5 61.7056V123.38" stroke="#475467" />
        <path d="M852.5 61.7056V123.38" stroke="#475467" />
        <path d="M862.5 61.7056V123.38" stroke="#475467" />
        <path d="M872.5 61.7056V123.38" stroke="#475467" />
        <path d="M877.5 61.7056V123.38" stroke="#475467" />
        <path d="M881.5 61.7056V123.38" stroke="#475467" />
        <path d="M882.5 61.7056V123.38" stroke="#475467" />
        <path d="M268 122.88H881.796" stroke="#344054" />
        <path
          d="M260 50.7847C260 48.5755 261.791 46.7847 264 46.7847C266.209 46.7847 268 48.5755 268 50.7847V124.375H260V50.7847Z"
          fill="#1D2939"
        />
        <path
          d="M882 50.7847C882 48.5755 883.791 46.7847 886 46.7847C888.209 46.7847 890 48.5755 890 50.7847V124.375H882V50.7847Z"
          fill="#1D2939"
        />
        <path
          d="M271.023 58.0437C271.023 58.0437 454.447 64.7353 571.8 65.1963C689.154 65.6572 879 57.7266 879 57.7266"
          stroke="white"
          strokeWidth="6"
        />
      </g>
      {isServeDirectionOrAccuracy(graphicType) && serveDirectionDashes}
      {isReturnOrRallyPlacementVertical(graphicType, orientation) && returnPlacementVertical}
      {isReturnOrRallyPlacementHorizontal(graphicType, orientation) && returnPlacementHorizontal}

      <defs>
        <filter
          id="filter0_i_10118_388641"
          x="86.52"
          y="6.98389"
          width="972.934"
          height="478.545"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_10118_388641" />
        </filter>
        <filter
          id="filter1_i_10118_388641"
          x="260"
          y="46.7847"
          width="630"
          height="82.5903"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_10118_388641" />
        </filter>
        <linearGradient
          id="paint0_linear_10118_388641"
          x1="90"
          y1="8.98389"
          x2="879.236"
          y2="685.665"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={surfaceColor} stopOpacity={finishingOpacity} />
          <stop offset={startingOpacity} stopColor={surfaceColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10118_388641"
          x1="410.5"
          y1="9.00001"
          x2="-9.82959"
          y2="128.573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={surfaceColor} stopOpacity={finishingOpacity} />
          <stop offset={startingOpacity} stopColor={surfaceColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10118_388641"
          x1="736"
          y1="9.48145"
          x2="1155.08"
          y2="128.576"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={surfaceColor} stopOpacity={finishingOpacity} />
          <stop offset={startingOpacity} stopColor={surfaceColor} />
        </linearGradient>
      </defs>
    </svg>
  );
}

CourtSurface3d.defaultProps = {
  surface: 'hard',
  graphicType: courtGraphicTypes.SERVE_DIRECTION
};
