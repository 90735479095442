import chunk from 'lodash/chunk';
import isEmpty from 'lodash/isEmpty';

import customSitesConfig from '_shared/config/customSitesConfig';

export function getQueryParamsWithSection(queryParams, selectedSections) {
  let sectionsString = '';
  selectedSections.forEach((item) => {
    if (item.isChecked === true) {
      sectionsString += `${item.id},`;
    }
  });
  sectionsString = sectionsString.substring(0, sectionsString.length - 1);
  return `${queryParams}&reportSections=${sectionsString}`;
}

export function isSectionIncluded(reportSections, id) {
  if (!reportSections || !id) return true;
  return reportSections?.includes(id);
}

export function getMatchesPerPage(matchesSelectedData) {
  return chunk(matchesSelectedData, 36);
}

export function getCourtSurface(matchesSelectedData) {
  if (isEmpty(matchesSelectedData)) return customSitesConfig.defaultScoutCourt;
  const surfaceFrequency = {};
  matchesSelectedData.forEach((obj) => {
    const { surface } = obj;
    surfaceFrequency[surface] = (surfaceFrequency[surface] || 0) + 1;
  });
  let mostCommonSurface;
  let maxFrequency = 0;
  for (const surface in surfaceFrequency) {
    if (surfaceFrequency[surface] > maxFrequency) {
      mostCommonSurface = surface;
      maxFrequency = surfaceFrequency[surface];
    }
  }
  return convertSurface(mostCommonSurface);
}

export function convertSurface(surface) {
  switch (surface) {
    case 'outdoor_hard':
      return 'hard';
    case 'indoor_hard':
      return 'indoor';
    default:
      return surface;
  }
}
