import { videoPlaylistCategories, videoPlaylistFields } from 'match/constants/videoConstants';
import { convertValueToLabel } from 'match/utils/videoUtil';

import { formFieldTypes } from '_shared/designSystem/components/form/formTypes';

export function getScoreFormFieldsData(setGames, formState) {
  const data = [];
  data.push({
    id: videoPlaylistFields.SETS,
    label: 'Sets',
    type: formFieldTypes.BUTTON_GROUP,
    data: setGames?.map((item) => ({
      value: item.set_number.toString(),
      label: item.set_number.toString()
    }))
  });

  if (formState.sets && formState.sets.length === 1) {
    const selectedSet = setGames.find((item) => item.set_number === parseInt(formState.sets[0]));
    const gamesOptions = selectedSet
      ? selectedSet.games.map((item) => ({
          id: item.game_number,
          label: item.score
        }))
      : [];
    gamesOptions.unshift({ id: 'Select', label: 'Select' });

    data.push({
      id: videoPlaylistFields.FROM_GAME,
      label: 'From',
      type: formFieldTypes.SELECT_SINGLE,
      options: gamesOptions,
      style: { width: '80px' }
    });

    const fromGame = parseInt(formState[videoPlaylistFields.FROM_GAME]);
    if (!isNaN(fromGame)) {
      const filteredToOptions = gamesOptions.filter((item) => parseInt(item.id) >= fromGame);
      data.push({
        id: videoPlaylistFields.TO_GAME,
        label: 'To',
        type: formFieldTypes.SELECT_SINGLE,
        options: filteredToOptions,
        style: { width: '80px' }
      });
    }
  }

  data.push({
    id: videoPlaylistFields.POINT_TYPE,
    label: 'Point Type',
    type: formFieldTypes.SELECT_SINGLE,
    options: convertOptions(['all', 'pressure', 'break'])
  });
  return data;
}

export function getPointEndFormFieldsData(player1Name, player2Name) {
  return [
    {
      id: 'last_shot',
      label: 'Last Shot',
      type: formFieldTypes.SELECT_SINGLE,
      options: convertOptions(['all', 'serve', 'return', 'forehand', 'backhand', 'volley'])
    },
    {
      type: formFieldTypes.SELECT_SINGLE,
      label: 'Outcome',
      id: 'outcome',
      options: convertOptions(
        [
          'all',
          'player1_win_point',
          'player1_winner',
          'player1_all_errors',
          'player1_unforced_error',
          'player1_forced_error',
          'player2_win_point',
          'player2_winner',
          'player2_all_errors',
          'player2_unforced_error',
          'player2_forced_error'
        ],
        player1Name,
        player2Name
      )
    },
    {
      type: formFieldTypes.SELECT_SINGLE,
      label: 'Situation',
      id: 'situation',
      options: convertOptions(
        ['all', 'both_baseline', 'player1_net', 'player2_net', 'both_net'],
        player1Name,
        player2Name
      )
    }
  ];
}

export function getServeFormFieldsData(player1Name, player2Name) {
  return [
    {
      id: videoPlaylistFields.SERVE_TYPE,
      label: 'Server',
      type: formFieldTypes.SELECT_SINGLE,
      options: convertOptions(
        ['both', 'player1_all', 'player1_1st', 'player1_2nd', 'player2_all', 'player2_1st', 'player2_2nd'],
        player1Name,
        player2Name,
        videoPlaylistCategories.SERVE
      )
    },
    {
      id: videoPlaylistFields.SERVE_SIDE,
      label: 'Serve Side',
      type: formFieldTypes.BUTTON_GROUP,
      data: [
        { value: 'deuce', label: 'Deuce', id: 'deuce' },
        { value: 'ad', label: 'Ad', id: 'ad' }
      ]
    },
    {
      id: videoPlaylistFields.SERVE_DIRECTION,
      label: 'Direction',
      type: formFieldTypes.SELECT_SINGLE,
      options: convertOptions(['all', 'body', 't', 'wide'], player1Name, player2Name)
    },
    {
      id: 'serve_plus_one',
      label: 'Serve +1',
      type: formFieldTypes.BUTTON_GROUP,
      data: [
        { value: 'forehand', label: 'Forehand', id: 'forehand' },
        { value: 'backhand', label: 'Backhand', id: 'backhand' },
        { value: 'volley', label: 'Volley', id: 'volley' }
      ]
    }
  ];
}

export function getReturnFormFieldsData(player1Name, player2Name) {
  return [
    {
      id: videoPlaylistFields.RETURN_TYPE,
      label: 'Returner',
      type: formFieldTypes.SELECT_SINGLE,
      options: convertOptions(
        ['both', 'player1_all', 'player1_1st', 'player1_2nd', 'player2_all', 'player2_1st', 'player2_2nd'],
        player1Name,
        player2Name,
        videoPlaylistCategories.RETURN
      )
    },
    {
      id: videoPlaylistFields.RETURN_SHOT_TYPE,
      label: 'Shot Type',
      type: formFieldTypes.BUTTON_GROUP,
      data: [
        { value: 'forehand', label: 'Forehand', id: 'forehand' },
        { value: 'backhand', label: 'Backhand', id: 'backhand' }
      ]
    },
    {
      id: videoPlaylistFields.RETURN_SPIN,
      label: 'Spin',
      type: formFieldTypes.BUTTON_GROUP,
      data: [
        { value: 'topspin', label: 'Top Spin', id: 'topspin' },
        { value: 'slice', label: 'Slice', id: 'slice' }
      ]
    },
    {
      id: videoPlaylistFields.RETURN_DIRECTION,
      label: 'Direction',
      type: formFieldTypes.SELECT_SINGLE,
      options: convertOptions(['all', 'middle', 'crosscourt', 'down_the_line'])
    },
    {
      id: videoPlaylistFields.RETURN_POSITION,
      label: 'Position',
      type: formFieldTypes.SELECT_SINGLE,
      options: convertOptions(['all', 'up', 'neutral', 'back'])
    }
  ];
}

export function getRallyFormFieldsData(player1Name, player2Name) {
  return [
    {
      id: videoPlaylistFields.RALLY_PLAYER_HIT,
      label: 'Player Hit',
      type: formFieldTypes.BUTTON_GROUP,
      data: [
        { value: 'player1', label: player1Name, id: 'player1' },
        { value: 'player2', label: player2Name, id: 'player2' }
      ]
    },
    {
      id: videoPlaylistFields.RALLY_SHOT_TYPE,
      label: 'Shot Type',
      type: formFieldTypes.BUTTON_GROUP,
      data: [
        { value: 'forehand', label: 'Forehand', id: 'forehand' },
        { value: 'backhand', label: 'Backhand', id: 'backhand' }
      ]
    },
    {
      id: videoPlaylistFields.RALLY_STROKE_TYPE,
      label: 'Stroke Type',
      type: formFieldTypes.BUTTON_GROUP,
      data: [
        { value: 'groundstroke', label: 'Groundstroke', id: 'groundstroke' },
        { value: 'volley', label: 'Volley', id: 'volley' }
      ]
    },
    {
      id: videoPlaylistFields.RALLY_SPIN,
      label: 'Spin',
      type: formFieldTypes.BUTTON_GROUP,
      data: [
        { value: 'topspin', label: 'Top Spin', id: 'topspin' },
        { value: 'slice', label: 'Slice', id: 'slice' }
      ]
    },
    {
      id: videoPlaylistFields.RALLY_DIRECTION,
      label: 'Direction',
      type: formFieldTypes.SELECT_SINGLE,
      options: convertOptions(['all', 'crosscourt', 'down_the_line', 'inside_out', 'inside_in'])
    },
    {
      id: videoPlaylistFields.RALLY_ADVANCED,
      label: 'Advanced',
      type: formFieldTypes.SELECT_SINGLE,
      options: convertOptions(['all', 'drop_shot', 'passing_shot', 'lob'])
    }
  ];
}

export function getConversionAndStealFormFieldsData(player1Name, player2Name) {
  return [
    {
      id: videoPlaylistFields.CONVERSION_AND_STEAL,
      type: formFieldTypes.SELECT_SINGLE,
      options: convertOptions(
        ['all', 'player1_conversion', 'player1_steal', 'player2_conversion', 'player2_steal'],
        player1Name,
        player2Name
      )
    }
  ];
}

export function convertOptions(options, player1Name, player2Name, category) {
  return options.map((item) => ({
    label: convertValueToLabel(item, player1Name, player2Name, category),
    id: item
  }));
}
