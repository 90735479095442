import { useMemo, useEffect, useRef } from 'react';

import isEmpty from 'lodash/isEmpty';
import DataIncompleteAlert from 'match/_shared/DataCompletenessAlert';
import { BREAKDOWN_METRICS_TYPES, DATE_INCOMPLETE_ALERT_TYPES } from 'match/_shared/matchConstants';
import { PERFORMANCE_METRICS_TYPES } from 'match/_shared/matchConstants';
import Completeness from 'match/components/performance/completeness/Completeness';
import { generatePathForComponent } from 'match/utils/aiPaths';
import { useRecoilValue } from 'recoil';

import customSitesConfig from '_shared/config/customSitesConfig';
import { dataCompletenessStatus } from '_shared/constants/matchTypes';
import { userPermissionTypes } from '_shared/constants/user';
import { Box, Flex, useBreakpointValue } from '_shared/designSystem/components';
import { isMatchReportState, userPermissionsState, AISelectedState } from '_shared/globalState/atoms';
import useFeature from '_shared/utils/hooks/useFeature';
import { checkPermission } from '_shared/utils/permissions';
import { scrollToElement } from '_shared/utils/scrollUtils';

import PanelContainer from './_shared/PanelContainer';
import BalanceOfPowerWithAverages from './battles/BalanceOfPowerWithAverages';
import { BaselineBattles } from './battles/BaselineBattles';
import { MomentumBattle } from './battles/Momentum';
import BreakdownMetrics from './breakdownMetrics/BreakdownMetrics';
import CircularMetricsPanel from './circularMetrics/CircularMetricsPanel';
import MatchStatistics from './matchStatistics/MatchStatistics';
import PerformanceGraph from './performanceGraph/PerformanceGraph';
import {
  leftColumnContentWidth,
  leftColumnContentWidthMobile,
  performanceGraphWidth,
  rightColumnContentWidth
} from './performanceMargins';
import WinProbabilitySection from './winProbability/WinProbabilitySection';

const PerformanceMetrics = ({
  data,
  dataWithAverages,
  averagesLoading,
  showAverages,
  matchStatsCategory,
  setMatchStatsCategory,
  currentScoreRangeFilter,
  performanceRatingGame,
  isAISelected,
  isAISelectorActive
}) => {
  const matchStatisticsEnabled = useFeature('matchStatistics');
  const performanceGraphEnabled = useFeature('performanceGraph');
  const shotVariationEnabled = useFeature('shotVariation');
  const serveEffectivenessEnabled = useFeature('serveEffectiveness');
  const performanceRating = useFeature('performanceRating');
  const serveAndReturnGamePerformanceEnabled = useFeature('serveAndReturnGamePerformance');
  const isMatchReport = useRecoilValue(isMatchReportState);
  const { permissions } = useRecoilValue(userPermissionsState);

  const momentumEnabled = useFeature('momentum');

  const windowSizeIsLg = useBreakpointValue({ lg: true });

  const componentOrder = windowSizeIsLg
    ? customSitesConfig.performanceComponentOrderDesktop
    : customSitesConfig.performanceComponentOrderMobile;

  const AISelected = useRecoilValue(AISelectedState);

  const panelRefs = useRef({
    'battles.balance_of_power': useRef(null),
    'battles.momentum': useRef(null),
    'battles.baseline': useRef(null),
    'comparison_metrics.performance_rating': useRef(null),
    'comparison_metrics.shot_quality': useRef(null),
    'comparison_metrics.capitalisation': useRef(null),
    'comparison_metrics.performance_in_games': useRef(null),
    'breakdown_metrics.serve_effectiveness': useRef(null),
    'breakdown_metrics.variation': useRef(null),
    match_statistics: useRef(null),
    win_probability: useRef(null)
  });

  useEffect(() => {
    if (isAISelected && isAISelectorActive && AISelected?.path) {
      const ref = panelRefs.current[AISelected.path];
      scrollToElement(ref, -250);
    }
  }, [isAISelected, isAISelectorActive, AISelected]);

  const orderedComponents = useMemo(() => {
    return componentOrder.map((component, index) => {
      const pathsForComponent = generatePathForComponent(component);
      const isAISelectedForThisComponent = pathsForComponent.includes(AISelected?.path);

      const firstColumnComponent = index <= customSitesConfig.numberOfPerformanceMetricsInLeftColumnDesktop;

      let panelOpacity = 1;

      if (isAISelected) {
        if (!isAISelectorActive || !isAISelectedForThisComponent) {
          panelOpacity = 0.2;
        }
      }

      switch (component) {
        case PERFORMANCE_METRICS_TYPES.BALANCE_OF_POWER:
          return (
            checkPermission(userPermissionTypes.BALANCE_OF_POWER, permissions) && (
              <PanelContainer
                key={index}
                contentWidth={
                  windowSizeIsLg && firstColumnComponent ? leftColumnContentWidth : leftColumnContentWidthMobile
                }
                opacity={panelOpacity}
                ref={(panel) => (panelRefs.current['battles.balance_of_power'] = panel)}
              >
                <BalanceOfPowerWithAverages
                  data={data?.performance?.battles}
                  dataWithAverages={dataWithAverages?.performance?.battles}
                  averagesLoading={averagesLoading}
                  showAverages={showAverages}
                  currentScoreRangeFilter={currentScoreRangeFilter}
                  player1Name={data?.players?.player1?.last_name}
                  player2Name={data?.players?.player2?.last_name}
                />
              </PanelContainer>
            )
          );
        case PERFORMANCE_METRICS_TYPES.PERFORMANCE_GRAPH:
          return (
            performanceGraphEnabled &&
            checkPermission(userPermissionTypes.PERFORMANCE_GRAPHS, permissions) &&
            !isEmpty(performanceRatingGame?.data) && (
              <PanelContainer
                contentWidth={
                  windowSizeIsLg && firstColumnComponent ? performanceGraphWidth : leftColumnContentWidthMobile
                }
                key={index}
                opacity={panelOpacity}
                ref={(panel) => (panelRefs.current['comparison_metrics.performance_rating'] = panel)}
              >
                <PerformanceGraph
                  players={data?.players}
                  performanceRatingGame={performanceRatingGame?.data}
                  gamesLoading={performanceRatingGame?.isLoading}
                  performanceRatingAverages={dataWithAverages?.performance?.comparison_metrics?.performance_rating}
                />
              </PanelContainer>
            )
          );
        case PERFORMANCE_METRICS_TYPES.SHOT_VARIATION:
          return (
            shotVariationEnabled &&
            checkPermission(userPermissionTypes.SHOT_VARIATION, permissions) && (
              <PanelContainer
                contentWidth={
                  windowSizeIsLg && firstColumnComponent ? performanceGraphWidth : leftColumnContentWidthMobile
                }
                key={index}
                opacity={panelOpacity}
                ref={(panel) => (panelRefs.current['breakdown_metrics.variation'] = panel)}
              >
                <BreakdownMetrics
                  graphicType={BREAKDOWN_METRICS_TYPES.SHOT_VARIATION}
                  dataNoAverages={data?.performance?.breakdown_metrics?.variation}
                  dataWithAverages={dataWithAverages?.performance?.breakdown_metrics?.variation}
                  players={data?.players}
                  showAverages={showAverages}
                  averagesLoading={averagesLoading}
                />
              </PanelContainer>
            )
          );
        case PERFORMANCE_METRICS_TYPES.SERVE_EFFECTIVENESS:
          return (
            serveEffectivenessEnabled &&
            checkPermission(userPermissionTypes.SERVE_EFFECTIVENESS, permissions) && (
              <PanelContainer
                key={index}
                contentWidth={
                  windowSizeIsLg && firstColumnComponent ? performanceGraphWidth : leftColumnContentWidthMobile
                }
                opacity={panelOpacity}
                ref={(panel) => (panelRefs.current['breakdown_metrics.serve_effectiveness'] = panel)}
              >
                <BreakdownMetrics
                  graphicType={BREAKDOWN_METRICS_TYPES.SERVE_EFFECTIVENESS}
                  dataNoAverages={data?.performance?.breakdown_metrics?.serve_effectiveness}
                  dataWithAverages={dataWithAverages?.performance?.breakdown_metrics?.serve_effectiveness}
                  players={data?.players}
                  showAverages={showAverages}
                  averagesLoading={averagesLoading}
                />
              </PanelContainer>
            )
          );
        case PERFORMANCE_METRICS_TYPES.DATA_COMPLETENESS:
          return (
            checkPermission(userPermissionTypes.DATA_COMPLETENESS, permissions) && (
              <PanelContainer
                contentWidth={
                  windowSizeIsLg && firstColumnComponent ? performanceGraphWidth : leftColumnContentWidthMobile
                }
                key={index}
                opacity={panelOpacity}
              >
                <Flex direction="column" gap={4}>
                  <Completeness data={data?.completeness} matchId={data.match_id} />
                </Flex>
              </PanelContainer>
            )
          );
        case PERFORMANCE_METRICS_TYPES.MATCH_STATISTICS:
          return (
            matchStatisticsEnabled &&
            checkPermission(userPermissionTypes.MATCH_STATISTICS, permissions) && (
              <PanelContainer
                key={index}
                contentWidth={
                  windowSizeIsLg && firstColumnComponent ? performanceGraphWidth : leftColumnContentWidthMobile
                }
                opacity={panelOpacity}
                ref={(panel) => (panelRefs.current['match_statistics'] = panel)}
              >
                <MatchStatistics
                  players={data?.players}
                  data={data?.performance?.match_statistics}
                  dataWithAverages={dataWithAverages?.performance?.match_statistics}
                  averagesLoading={averagesLoading}
                  showAverages={showAverages}
                  matchStatsCategory={matchStatsCategory}
                  setMatchStatsCategory={setMatchStatsCategory}
                />
              </PanelContainer>
            )
          );
        case PERFORMANCE_METRICS_TYPES.PERFORMANCE_RATING:
          return (
            performanceRating &&
            checkPermission(userPermissionTypes.PERFORMANCE_RATING, permissions) && (
              <PanelContainer
                contentWidth={rightColumnContentWidth}
                key={index}
                opacity={panelOpacity}
                ref={(panel) => (panelRefs.current['comparison_metrics.performance_rating'] = panel)}
              >
                <CircularMetricsPanel
                  heading="Performance Rating"
                  tooltipText={`Performance Rating combines ${customSitesConfig.balanceOfPowerLabel}, Conversion, Steal and Shot Quality to show the overall performance level`}
                  player1Name={data?.players?.player1?.last_name}
                  player2Name={data?.players?.player2?.last_name}
                  data={data?.performance?.comparison_metrics}
                  dataWithAverages={dataWithAverages?.performance?.comparison_metrics}
                  type="medium"
                  showAverages={showAverages}
                  averagesLoading={averagesLoading}
                  orderOfMetrics={['performance_rating']}
                  noTopLabel
                  AISelected={AISelected}
                  isAISelected={isAISelected}
                />
              </PanelContainer>
            )
          );
        case PERFORMANCE_METRICS_TYPES.ATTACK_DEFENCE:
          return (
            checkPermission(userPermissionTypes.ATTACK_DEFENCE, permissions) && (
              <PanelContainer
                contentWidth={rightColumnContentWidth}
                key={index}
                opacity={panelOpacity}
                ref={(panel) => {
                  panelRefs.current['comparison_metrics.capitalisation'] = panel;
                  ['conversion', 'steal'].forEach((shotType) => {
                    const path = `comparison_metrics.capitalisation.${shotType}`;
                    panelRefs.current[path] = panel;
                  });
                }}
              >
                <CircularMetricsPanel
                  heading="Attack and Defence"
                  tooltipText="How often a player has won the point when attacking (Conversion) or defending (Steal)"
                  player1Name={data?.players?.player1?.last_name}
                  player2Name={data?.players?.player2?.last_name}
                  data={data?.performance?.comparison_metrics?.capitalisation}
                  dataWithAverages={dataWithAverages?.performance?.comparison_metrics?.capitalisation}
                  type="light"
                  showAverages={showAverages}
                  averagesLoading={averagesLoading}
                  orderOfMetrics={['conversion', 'steal']}
                  AISelected={AISelected}
                  isAISelected={isAISelected}
                />
              </PanelContainer>
            )
          );
        case PERFORMANCE_METRICS_TYPES.SHOT_QUALITY:
          return (
            checkPermission(userPermissionTypes.SHOT_QUALITY, permissions) && (
              <PanelContainer
                contentWidth={rightColumnContentWidth}
                key={index}
                opacity={panelOpacity}
                ref={(panel) => {
                  panelRefs.current['comparison_metrics.shot_quality'] = panel;
                  ['serve', 'return', 'forehand', 'backhand'].forEach((shotType) => {
                    const path = `comparison_metrics.shot_quality.${shotType}`;
                    panelRefs.current[path] = panel;
                  });
                }}
              >
                <CircularMetricsPanel
                  heading="Shot Quality"
                  tooltipText="The quality of the player's four main shots on a 0-10 scale"
                  player1Name={data?.players?.player1?.last_name}
                  player2Name={data?.players?.player2?.last_name}
                  data={data?.performance?.comparison_metrics?.shot_quality}
                  dataWithAverages={dataWithAverages?.performance?.comparison_metrics?.shot_quality}
                  type="medium"
                  showAverages={showAverages}
                  averagesLoading={averagesLoading}
                  orderOfMetrics={['serve', 'return', 'forehand', 'backhand']}
                  AISelected={AISelected}
                  isAISelected={isAISelected}
                />
              </PanelContainer>
            )
          );
        case PERFORMANCE_METRICS_TYPES.SERVE_RETURN_GAME_PERFORMANCE:
          return (
            checkPermission(userPermissionTypes.SERVE_RETURN_GAME_PERFORMANCE, permissions) &&
            serveAndReturnGamePerformanceEnabled && (
              <PanelContainer
                contentWidth={rightColumnContentWidth}
                key={index}
                opacity={panelOpacity}
                ref={(panel) => {
                  panelRefs.current['comparison_metrics.performance_in_games'] = panel;
                  ['service_games', 'return_games'].forEach((shotType) => {
                    const path = `comparison_metrics.performance_in_games.${shotType}`;
                    panelRefs.current[path] = panel;
                  });
                }}
              >
                <CircularMetricsPanel
                  heading="Serve and Return Game Performance"
                  tooltipText="The average shot quality of all shots in a player's service games vs. return games"
                  player1Name={data?.players?.player1?.last_name}
                  player2Name={data?.players?.player2?.last_name}
                  data={data?.performance?.comparison_metrics?.performance_in_games}
                  dataWithAverages={dataWithAverages?.performance?.comparison_metrics?.performance_in_games}
                  type="dark"
                  showAverages={showAverages}
                  averagesLoading={averagesLoading}
                  orderOfMetrics={['service_games', 'return_games']}
                  AISelected={AISelected}
                  isAISelected={isAISelected}
                />
              </PanelContainer>
            )
          );
        case PERFORMANCE_METRICS_TYPES.BASELINE_BATTLES:
          return (
            checkPermission(userPermissionTypes.BASELINE_NET_BATTLES, permissions) && (
              <PanelContainer
                key={index}
                contentWidth={
                  windowSizeIsLg && firstColumnComponent ? leftColumnContentWidth : leftColumnContentWidthMobile
                }
                opacity={panelOpacity}
                ref={(panel) => (panelRefs.current['battles.baseline'] = panel)}
              >
                <BaselineBattles
                  data={data?.performance?.battles}
                  dataWithAverages={dataWithAverages?.performance?.battles}
                  averagesLoading={averagesLoading}
                  showAverages={showAverages}
                  player1Name={data?.players?.player1?.last_name}
                  player2Name={data?.players?.player2?.last_name}
                />
              </PanelContainer>
            )
          );
        case PERFORMANCE_METRICS_TYPES.WIN_PROBABILITY:
          return (
            checkPermission(userPermissionTypes.WIN_PROBABILITY, permissions) && (
              <PanelContainer
                key={index}
                contentWidth={
                  windowSizeIsLg && firstColumnComponent ? leftColumnContentWidth : leftColumnContentWidthMobile
                }
                opacity={panelOpacity}
                ref={(panel) => (panelRefs.current['win_probability'] = panel)}
              >
                <WinProbabilitySection
                  win_probability={data?.performance?.win_probability}
                  player1Name={data?.players?.player1?.last_name}
                  player2Name={data?.players?.player2?.last_name}
                />
              </PanelContainer>
            )
          );
        case PERFORMANCE_METRICS_TYPES.MOMENTUM:
          return (
            !isMatchReport &&
            momentumEnabled &&
            checkPermission(userPermissionTypes.MOMENTUM, permissions) && (
              <PanelContainer
                key={index}
                contentWidth={
                  windowSizeIsLg && firstColumnComponent ? leftColumnContentWidth : leftColumnContentWidthMobile
                }
                opacity={panelOpacity}
                ref={(panel) => (panelRefs.current['battles.momentum'] = panel)}
              >
                <MomentumBattle
                  data={data?.performance?.battles}
                  currentScoreRangeFilter={currentScoreRangeFilter}
                  player1Name={data?.players?.player1?.last_name}
                  player2Name={data?.players?.player2?.last_name}
                />
              </PanelContainer>
            )
          );
        default:
          return null;
      }
    });
  }, [
    componentOrder,
    AISelected,
    isAISelected,
    isAISelectorActive,
    permissions,
    windowSizeIsLg,
    data?.performance?.battles,
    data?.performance?.breakdown_metrics?.variation,
    data?.performance?.breakdown_metrics?.serve_effectiveness,
    data?.performance?.match_statistics,
    data?.performance?.comparison_metrics,
    data?.players,
    data?.completeness,
    data.match_id,
    dataWithAverages?.performance?.battles,
    dataWithAverages?.performance?.comparison_metrics,
    dataWithAverages?.performance?.breakdown_metrics?.variation,
    dataWithAverages?.performance?.breakdown_metrics?.serve_effectiveness,
    dataWithAverages?.performance?.match_statistics,
    averagesLoading,
    showAverages,
    currentScoreRangeFilter,
    performanceGraphEnabled,
    performanceRatingGame?.data,
    performanceRatingGame?.isLoading,
    shotVariationEnabled,
    serveEffectivenessEnabled,
    matchStatisticsEnabled,
    matchStatsCategory,
    setMatchStatsCategory,
    performanceRating,
    serveAndReturnGamePerformanceEnabled,
    isMatchReport,
    momentumEnabled,
    data?.performance?.win_probability
  ]);

  const midpoint = customSitesConfig.numberOfPerformanceMetricsInLeftColumnDesktop;

  return (
    <Box pt={6}>
      {data?.completeness?.status === dataCompletenessStatus.INCOMPLETE &&
        checkPermission(userPermissionTypes.DATA_QUALITY_BANNER_SHOW_DATA, permissions) && (
          <DataIncompleteAlert messageType={DATE_INCOMPLETE_ALERT_TYPES.SHOW_DATA} />
        )}
      <Flex justify="center" px={8}>
        {windowSizeIsLg ? (
          <Flex mt={4} gap={{ lg: 0, xl: '40px' }} justify="center" flex={1}>
            <Flex direction="column" gap={10} flex={3} alignItems={{ base: 'center', lg: 'stretch' }}>
              {orderedComponents.slice(0, midpoint)}
            </Flex>
            <Flex direction="column" gap={10} flex={2} alignItems={{ base: 'center', lg: 'stretch' }}>
              {orderedComponents.slice(midpoint)}
            </Flex>
          </Flex>
        ) : (
          <Flex direction="column" gap={10} alignItems={{ base: 'center', lg: 'stretch' }}>
            {orderedComponents}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default PerformanceMetrics;
