import { globalConfig } from '_shared/config/globalConfig';
import { getCurrentEnvironment } from '_shared/utils/environment/currentEnv';

/**
 * useFeature
 *
 * A react hook, used to check if a feature is enabled.
 *
 * @param {string} feature
 *
 * @returns {boolean}
 */
function useFeature(feature) {
  const currentEnvironment = getCurrentEnvironment();
  // don't show the feature if we can't determine our current environment
  if (currentEnvironment === null) return false;

  // for dev, test and staging, all features are enabled automatically
  if (currentEnvironment === 'dev' || currentEnvironment === 'test' || currentEnvironment === 'staging') return true;

  // for prod, we can show the feature if it's enabled in the global config file
  if (currentEnvironment === 'prod' && globalConfig?.features?.[feature]?.enabled) return true;

  // default back to not showing for anything else
  return false;
}

export default useFeature;
