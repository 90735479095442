import { displayScore, zeroOrMore } from 'match/utils/transformations';
import PropTypes from 'prop-types';

import { Text, Flex } from '_shared/designSystem/components';

export const tableFields = [
  { name: 'Win %', value: 'win' },
  { name: 'Quality', value: 'quality' },
  { name: 'Speed', value: 'speed' },
  { name: 'Topspin', value: 'topspin_proportion' },
  { name: 'Slice', value: 'slice_proportion' },
  { name: 'Topspin Win %', value: 'topspin_win' },
  { name: 'Slice Win %', value: 'slice_win' }
];
export default function ServePlusOneStats(props) {
  const { data } = props;
  const showNoDataMessage = data?.forehand?.usage?.score === 0 && data?.backhand?.usage?.score === 0;

  return (
    <Flex direction="column" maxW="600px" gap={2}>
      {tableFields.map((item) => (
        <Row
          key={item.value}
          name={item.name}
          leftValue={getScoreValue(data, item.value, 'forehand', showNoDataMessage)}
          rightValue={getScoreValue(data, item.value, 'backhand', showNoDataMessage)}
        />
      ))}
    </Flex>
  );
}

ServePlusOneStats.propTypes = {
  data: PropTypes.object
};

export const Row = ({ name, leftValue, rightValue }) => (
  <Flex justify="space-between">
    <Stat position="left">{leftValue}</Stat>
    <Heading>{name}</Heading>
    <Stat position="right">{rightValue}</Stat>
  </Flex>
);

export const Heading = ({ children }) => (
  <Text fontSize="sm" fontWeight="semibold" textAlign="center" whiteSpace="nowrap">
    {children}
  </Text>
);

export const Stat = ({ children, position }) => (
  <Text w="110px" fontSize="sm" textAlign={position === 'right' ? 'right' : 'left'}>
    {children}
  </Text>
);

export function getScoreValue(data, name, handedness, showNoDataMessage) {
  if (!data || !name || !handedness || showNoDataMessage) return null;
  let result = displayScore(
    data?.[`${handedness}`]?.[`${name}`]?.score,
    data?.[`${handedness}`]?.[`${name}`]?.score_type
  );
  if (zeroOrMore(data?.[`${handedness}`]?.[`${name}`]?.frequency)) {
    if (zeroOrMore(data?.[`${handedness}`]?.[`${name}`]?.frequency_total)) {
      result = result.concat(
        ` (${data?.[`${handedness}`]?.[`${name}`]?.frequency}/${data?.[`${handedness}`]?.[`${name}`]?.frequency_total})`
      );
    } else {
      result = result.concat(` (${data?.[`${handedness}`]?.[`${name}`]?.frequency})`);
    }
  }
  return result;
}
