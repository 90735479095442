import { useNavigate } from '@tanstack/react-location';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { Box, Text, Center, Flex, Icon, SurfaceBadge } from '_shared/designSystem/components';
import { formatDate } from '_shared/utils/dateUtil';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import { navigateBack } from '_shared/utils/navigationUtils';
import { capitaliseString } from '_shared/utils/stringUtil';

const TournamentHeader = ({ data }) => {
  const navigate = useNavigate();

  if (isEmpty(data)) {
    return <Text>No tournament data to show</Text>;
  }
  return (
    <Box>
      <Flex>
        <Box pt={2} pr={1}>
          <Box
            cursor="pointer"
            onClick={() => {
              navigateBack({ navigate });
            }}
          >
            <Icon name="arrowLeft" color="primary.500" height={8} width={8} />
          </Box>
        </Box>
        <Flex direction="column">
          <Flex gap={4} align="baseline" flexWrap="wrap">
            <Text fontSize="3xl" fontWeight="medium" color="secondary.800">
              {data?.name}
            </Text>
          </Flex>
          <TournamentDetails data={data} />
        </Flex>
      </Flex>
    </Box>
  );
};

TournamentHeader.propTypes = {
  data: PropTypes.object
};

export default TournamentHeader;

export const Separator = () => (
  <Flex align="center">
    <Box h="4px" w="4px" borderRadius="sm" bg="primary.500" />
  </Flex>
);

const TournamentDetails = ({ data }) => (
  <Box display={{ base: 'none', md: 'initial' }}>
    <Flex gap={3}>
      {!isWimbPortal() && (
        <>
          <Text fontSize="sm" fontWeight="normal" color="grey.500">
            {data?.tier}
          </Text>
          <Separator />
          <Center>
            <SurfaceBadge surface={data?.surface} />
          </Center>
          <Separator />
          <Text fontSize="sm" fontWeight="normal" color="grey.500">
            {capitaliseString(data?.comp_status)}
          </Text>
          <Separator />
        </>
      )}
      <Text fontSize="sm" fontWeight="normal" color="grey.500">
        {formatDate(data?.start_date)} - {formatDate(data?.end_date)}
      </Text>
    </Flex>
  </Box>
);
