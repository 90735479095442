import isEmpty from 'lodash/isEmpty';

export function addLabelToSelectedOptions(selected, options) {
  if (isEmpty(selected)) return selected;
  if (typeof selected[0] === 'string') {
    const result = [];
    selected?.forEach((selectedItem) => {
      // eslint-disable-next-line
      const relevantOption = options.find((item) => item.id == selectedItem);
      if (!isEmpty(relevantOption));
      result.push(relevantOption);
    });
    return result;
  }
  return selected;
}

export function convertSelectedValue(value, options) {
  if (typeof value === 'string') return [];
  if (isEmpty(value)) return [];
  return addLabelToSelectedOptions(value, options);
}
