export const viewingTypes = {
  LIST_VIEW: 'listView',
  COURT_VIEW: 'courtView'
};

export const startTimeText = {
  FOLLOWED_BY: 'Followed By',
  NOT_BEFORE: 'Not Before',
  STARTS_AT: 'Starts At'
};

export const FORM_FIELDS = {
  ROUND: 'round',
  DAY: 'day'
};
