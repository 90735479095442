import PropTypes from 'prop-types';

import { Flex, Text, Center } from '_shared/designSystem/components';

import Icon from '../icon/_Icon';

export default function ButtonWithIcon(props) {
  const { label, isSelected, onClick, iconName } = props;
  return (
    <Flex cursor="pointer" onClick={onClick}>
      <Center
        bg={isSelected ? 'primary.100' : 'white'}
        borderRadius="md"
        px="12px"
        borderWidth="1px"
        borderColor="grey.300"
        h="32px"
        _hover={!isSelected ? { bg: 'primary.25' } : null}
      >
        <Flex gap={1}>
          <Text fontSize="xs" fontWeight="medium" color="grey.700" whiteSpace="nowrap">
            {label}
          </Text>
          {iconName && (
            <Center boxSize="20px">
              <Icon name={iconName} color="grey.700" height={4} width={4} />
            </Center>
          )}
        </Flex>
      </Center>
    </Flex>
  );
}

ButtonWithIcon.propTypes = {
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  iconName: PropTypes.string
};
