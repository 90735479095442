import React from 'react';

import { Flex, Text } from '_shared/designSystem/components';
import { capitaliseFirstLetterOfString } from '_shared/utils/stringUtil';

import { leftColumnContentWidth } from '../performanceMargins';

import { WinProbabilitySectionProps, WinProbabilityData } from './types';
import WinProbabilityHorizontalBar from './WinProbabilityHorizontalBar';

const WinProbabilitySection: React.FC<WinProbabilitySectionProps> = ({
  win_probability = {},
  player1Name,
  player2Name
}) => {
  const probabilities: { key: keyof WinProbabilityData }[] = [{ key: 'match' }, { key: 'set' }, { key: 'game' }];

  let winProbabilityState: 'before' | 'during' | 'after' = 'during';

  const matchPlayer1Score = win_probability.match?.player1?.score ?? null;
  const matchPlayer2Score = win_probability.match?.player2?.score ?? null;

  // Determine match state
  if (matchPlayer1Score === 100 || matchPlayer2Score === 100) {
    winProbabilityState = 'after';
  } else if (matchPlayer1Score === null && matchPlayer2Score === null) {
    winProbabilityState = 'before';
  }

  const getScores = (player1Score: number | null, player2Score: number | null) => {
    if (winProbabilityState === 'before') {
      return { player1Score: null, player2Score: null };
    }
    if (winProbabilityState === 'after') {
      return {
        player1Score: player1Score === 0 ? null : player1Score,
        player2Score: player2Score === 0 ? null : player2Score
      };
    }
    return { player1Score, player2Score }; // During match
  };

  const renderProbabilityBars = () =>
    probabilities.map(({ key }) => {
      const player1Score = win_probability[key]?.player1?.score ?? null;
      const player2Score = win_probability[key]?.player2?.score ?? null;

      const { player1Score: adjustedPlayer1Score, player2Score: adjustedPlayer2Score } = getScores(
        player1Score,
        player2Score
      );

      return (
        <WinProbabilityHorizontalBar
          key={key}
          label={win_probability[key]?.label || `${capitaliseFirstLetterOfString(key)} Win Probability`}
          leftPercentage={adjustedPlayer1Score}
          rightPercentage={adjustedPlayer2Score}
          greaterColor="secondary.600"
          lessColor="secondary.100"
          textColor="white"
        />
      );
    });

  return (
    <Flex direction="column" gap={4} w={{ base: '100%', sm: `${leftColumnContentWidth}px` }}>
      <Text fontSize="md" fontWeight="semibold" textAlign="center">
        Win Probability
      </Text>
      <Flex justify="space-between" w="100%">
        <Text fontSize="sm" fontWeight="semibold">
          {player1Name}
        </Text>
        <Text fontSize="sm" fontWeight="semibold">
          {player2Name}
        </Text>
      </Flex>
      {renderProbabilityBars()}
    </Flex>
  );
};

export default WinProbabilitySection;
