import { matchService } from 'match/services/matchService';
import { useQuery } from 'react-query';

export function useMatchReportHeaderQuery(matchId) {
  return useQuery(['matchReport_header', matchId], () => matchService.getMatchInformation({ matchId }));
}

export function useMatchReportPerformanceQuery(matchId, currentSetFilter, fetchParams) {
  return useQuery(
    ['matchReport_performance', matchId, currentSetFilter],
    () => matchService.getPerformance({ matchId, currentSetFilter, averages: true }),
    fetchParams
  );
}

export function useMatchReportSetsQuery(matchId, currentSetFilter, fetchParams) {
  return useQuery(
    ['matchReport_performance', matchId, currentSetFilter],
    () => matchService.getPerformance({ matchId, currentSetFilter, averages: false }),
    fetchParams
  );
}

export function useMatchReportCourtGraphicsQuery(matchId, queryParams) {
  return useQuery(['matchReport_court-graphics', matchId, queryParams], () =>
    matchService.getCourtGraphics({ matchId, queryParams })
  );
}

export function useMatchReportWinningAndLosingPlaysQuery(matchId, queryParams) {
  return useQuery(['matchReport_winning-and-losing-plays', matchId, queryParams], () =>
    matchService.getWinningAndLosingPlays({ matchId, queryParams })
  );
}

export function useMatchReportWinningAndLosingPlaysQueryV2(matchId, queryParams) {
  return useQuery(['matchReport_winning-and-losing-plays-v2', matchId, queryParams], () =>
    matchService.getWinningAndLosingPlaysV2({ matchId, queryParams })
  );
}
