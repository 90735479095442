import PropTypes from 'prop-types';

import { Flex, Text, Center } from '_shared/designSystem/components';

export default function ButtonGroupSingle(props) {
  const { label, isSelected, position, onClick } = props;
  const _borderRadius = 'md';

  let borderLeftRadius = _borderRadius;
  let borderWidthTopBottom = '1px';
  let borderWidthRight = '1px';
  let borderWidthLeft = '1px';
  if ((position === 'middle' || position === 'right') && position !== 'singleButton') {
    borderLeftRadius = null;
    borderWidthLeft = '0px';
  }

  let borderRightRadius = _borderRadius;
  if ((position === 'middle' || position === 'left') && position !== 'singleButton') borderRightRadius = null;

  return (
    <Flex cursor="pointer" onClick={onClick}>
      <Center
        bg={isSelected ? 'primary.100' : 'white'}
        borderLeftRadius={borderLeftRadius}
        borderRightRadius={borderRightRadius}
        px="12px"
        borderRightWidth={borderWidthRight}
        borderLeftWidth={borderWidthLeft}
        borderTopWidth={borderWidthTopBottom}
        borderBottomWidth={borderWidthTopBottom}
        borderColor="grey.300"
        h="32px"
        _hover={!isSelected ? { bg: 'primary.25' } : null}
      >
        <Text whiteSpace="nowrap" fontSize="xs" fontWeight="medium" color="grey.700">
          {label}
        </Text>
      </Center>
    </Flex>
  );
}

ButtonGroupSingle.propTypes = {
  position: PropTypes.string,
  label: PropTypes.string,
  isSelected: PropTypes.bool
};
