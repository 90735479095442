import customSitesConfig from '_shared/config/customSitesConfig';

import ErrorBoundary from './ErrorBoundary';

export default function AppErrorBoundary({ children }) {
  return (
    <ErrorBoundary
      renderError={() => {
        return customSitesConfig.fullScreenErrorComponent;
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
