import { CircularProgress as ChakraCircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { Text } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';

const CircularProgress = (props) => {
  const { percentage, topLabel, centreLabel, bottomLabel, opacity } = props;

  return (
    <ChakraCircularProgress
      value={percentage}
      color={opacity < 1 ? (isWimbPortal() ? 'performanceCircleLightGreen.200' : `primary.200`) : `primary.500`}
      trackColor="grey.100"
      size="140px"
      capIsRound
      thickness="12px"
    >
      <CircularProgressLabel>
        {topLabel && (
          <Text fontSize="xs" fontWeight="medium" color="grey.500">
            {topLabel}
          </Text>
        )}
        {centreLabel && (
          <Text fontSize="2xl" fontWeight="medium" color="grey.700">
            {centreLabel}
          </Text>
        )}
        {bottomLabel && (
          <Text fontSize="xs" fontWeight="normal" color="grey.500">
            {bottomLabel}
          </Text>
        )}
      </CircularProgressLabel>
    </ChakraCircularProgress>
  );
};

export default CircularProgress;

CircularProgress.propTypes = {
  percentage: PropTypes.number.isRequired,
  topLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  centreLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottomLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string
};
