import React from 'react';

import { Flex, Text } from '@ellipsedata/tennis';
import PropTypes from 'prop-types';

import { Icon } from '_shared/designSystem/components';

const MatchVideoButton = ({ buttonText, color, iconColor, iconBgColor, onClick }) => {
  return (
    <Flex align="center" gap={2} as="button" onClick={onClick} cursor="pointer">
      <Text fontSize="sm" fontWeight="medium" color={color}>
        {buttonText}
      </Text>
      <Flex w="18px" h="18px" borderRadius="full" bg={iconBgColor} justify="center" align="center">
        <Icon ml="2px" name="play" width={2} height={2} color={iconColor} />
      </Flex>
    </Flex>
  );
};

MatchVideoButton.propTypes = {
  buttonText: PropTypes.node,
  color: PropTypes.string,
  iconColor: PropTypes.string,
  iconBgColor: PropTypes.string,
  onClick: PropTypes.func
};

MatchVideoButton.defaultProps = {
  color: 'black',
  iconColor: 'black',
  iconBgColor: 'gray.400'
};

export default MatchVideoButton;
