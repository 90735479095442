import { MATCH_SELECTOR_COLUMNS } from 'scout/_shared/scoutConstants';

export const atpMediaMatchSelectorColumns = [
  MATCH_SELECTOR_COLUMNS.DATE,
  MATCH_SELECTOR_COLUMNS.TOURNAMENT_NAME,
  MATCH_SELECTOR_COLUMNS.OPPONENT_NAME,
  MATCH_SELECTOR_COLUMNS.RESULT,
  MATCH_SELECTOR_COLUMNS.SCORE,
  MATCH_SELECTOR_COLUMNS.ROUND,
  MATCH_SELECTOR_COLUMNS.SURFACE,
  MATCH_SELECTOR_COLUMNS.OPPONENT_RANK,
  MATCH_SELECTOR_COLUMNS.OPPONENT_HAND
];
