import isEmpty from 'lodash/isEmpty';
import { displayScore, zeroOrMore } from 'match/utils/transformations';
import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { getAverageFromCourtStatsData, getAveragesItem } from 'scout/util/scoutCourtGraphicsUtil';

import { Text, Flex, Box, Center, Table, Tr, Th, Td, Tbody } from '_shared/designSystem/components';
import colors from '_shared/designSystem/theme/colors';
import { isMatchReportState } from '_shared/globalState/atoms';
import { getTourAverageLabel } from '_shared/utils/metricsUtil';

import StatWithFhBh from './StatWithFhBh';

export default function CourtStatsTable(props) {
  const { courtStatsDataNoAverages, courtStatsDataWithAverages, averagesLoading } = props;

  const isMatchReport = useRecoilValue(isMatchReportState);

  if (isEmpty(courtStatsDataNoAverages)) return null;

  return (
    <Box overflowX={{ base: 'auto', md: 'initial' }} w={isMatchReport ? null : { base: '100vw', md: 'initial' }}>
      <Table maxW="500px" overflowX="auto">
        <Tbody>
          <Tr>
            <Th />
            <Th>
              <ColumnHeading>Score</ColumnHeading>
            </Th>
            <Th>
              <ColumnHeading>Player Avg</ColumnHeading>
            </Th>
            <Th>
              <ColumnHeading>{getTourAverageLabel()}</ColumnHeading>
            </Th>
          </Tr>
          {courtStatsDataNoAverages.map((item, index) =>
            item.isDividedByHand ? (
              <RowDividedByHand
                key={item.id}
                itemNoAverages={item}
                itemWithAverages={getAveragesItem(courtStatsDataWithAverages, item.id)}
                averagesLoading={averagesLoading}
                rowNo={index}
              />
            ) : (
              <Row
                key={item.id}
                rowNo={index}
                name={item.label}
                averagesLoading={averagesLoading}
                score={displayScore(item.score, item.score_type)}
                tourAverage={getAverageFromCourtStatsData(courtStatsDataWithAverages, item.id, 'tour')}
                playerAverage={getAverageFromCourtStatsData(courtStatsDataWithAverages, item.id, 'player')}
                frequency={item.frequency}
                frequencyTotal={item.frequency_total}
              />
            )
          )}
        </Tbody>
      </Table>
    </Box>
  );
}

CourtStatsTable.propTypes = {
  courtStatsDataNoAverages: PropTypes.array,
  courtStatsDataWithAverages: PropTypes.array,
  averagesLoading: PropTypes.bool
};

export const Row = ({ name, score, tourAverage, playerAverage, averagesLoading, rowNo, frequency, frequencyTotal }) => {
  const freq = zeroOrMore(frequency) ? ` (${frequency}/${frequencyTotal})` : '';

  return (
    <Tr>
      <Td>
        <Heading>{name}</Heading>
      </Td>
      <Td>
        <Stat averagesLoading={false} score={score} freq={freq} />
      </Td>
      <Td>
        <Stat averagesLoading={averagesLoading} rowNo={rowNo} score={playerAverage} />
      </Td>
      <Td>
        <Stat averagesLoading={averagesLoading} rowNo={rowNo} score={tourAverage} />
      </Td>
    </Tr>
  );
};

export const RowDividedByHand = (props) => {
  const { itemNoAverages, itemWithAverages, averagesLoading, rowNo } = props;
  if (averagesLoading && rowNo === 0)
    return (
      <Tr>
        <Td />
        <Td />
        <Td>
          <Center>
            <BeatLoader size={4} color={colors.primary[500]} />
          </Center>
        </Td>
        <Td>
          <Center>
            <BeatLoader size={4} color={colors.primary[500]} />
          </Center>
        </Td>
      </Tr>
    );
  else
    return (
      <Tr>
        <Td>
          <Heading>{itemNoAverages.label}</Heading>
        </Td>
        <Td>
          <StatWithFhBh scoreTypeToDisplay="score" scores={itemNoAverages?.scores} />
        </Td>
        <Td>
          <StatWithFhBh scoreTypeToDisplay="playerAverage" scores={itemWithAverages?.scores} />
        </Td>
        <Td>
          <StatWithFhBh scoreTypeToDisplay="tourAverage" scores={itemWithAverages?.scores} />
        </Td>
      </Tr>
    );
};

export const Heading = ({ children }) => (
  <Text fontSize="xs" color="grey.500" fontWeight="semibold" whiteSpace="nowrap">
    {children}
  </Text>
);

export const Stat = ({ score, freq, averagesLoading, rowNo }) => {
  if (averagesLoading && rowNo === 0)
    return (
      <Center>
        <BeatLoader size={4} color={colors.primary[500]} />
      </Center>
    );
  else
    return (
      <Flex gap={1} justify="center">
        <Text fontSize="xs" color="grey.600" fontWeight="normal" whiteSpace="nowrap">
          {score}
        </Text>
        <Text fontSize="xs" color="grey.400" fontWeight="normal" whiteSpace="nowrap">
          {freq}
        </Text>
      </Flex>
    );
};

export const ColumnHeading = ({ children }) => (
  <Text fontSize="sm" color="grey.500" fontWeight="semibold" whiteSpace="nowrap" textAlign="center">
    {children}
  </Text>
);
