import { useEffect } from 'react';

import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Form, Formik, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { Box, Flex, Input, Select } from '_shared/designSystem/components';

const TournamentsSearch = ({ seasons, formState, handleButtonClick }) => {
  return (
    <Flex bg="white" py={1}>
      <Box>
        <Formik
          initialValues={{ tournamentName: formState?.tournamentName, season: formState?.searchSeason }}
          enableReinitialize={true}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <TournamentNameSearchHandler handleButtonClick={handleButtonClick} />
              <FormControl>
                <Flex gap={5} rowGap="16px" flexWrap="wrap">
                  <Flex align="center" h="30px">
                    <FormLabel
                      htmlFor="season"
                      lineHeight="16px"
                      fontSize="xs"
                      fontWeight="medium"
                      color="grey.700"
                      pt="9px"
                    >
                      Season
                    </FormLabel>
                    <Select
                      id="season"
                      name="season"
                      value={values.season}
                      size="xs"
                      h="30px"
                      w="100px"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="all">All</option>
                      {seasons?.map((season) => (
                        <option key={season} value={season}>
                          {season}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  <Input
                    id="tournamentName"
                    name="tournamentName"
                    h="30px"
                    w="200px"
                    borderRadius="sm"
                    placeholder="Tournament Name"
                    size="xs"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tournamentName}
                  />
                </Flex>
              </FormControl>
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
};

export default TournamentsSearch;

TournamentsSearch.propTypes = {
  seasons: PropTypes.array,
  formState: PropTypes.object,
  handleButtonClick: PropTypes.func
};

const TournamentNameSearchHandler = ({ handleButtonClick }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    handleButtonClick('tournamentName', values.tournamentName); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.tournamentName]);

  useEffect(() => {
    handleButtonClick('searchSeason', values.season); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.season]);
};
