import isEmpty from 'lodash/isEmpty';
import Multiselect from 'multiselect-react-dropdown';
import PropTypes from 'prop-types';

import './SelectMulti.css';
import customSitesConfig from '_shared/config/customSitesConfig';
import { Text, Flex, Icon } from '_shared/designSystem/components';
import { borderRadiusRaw } from '_shared/designSystem/theme/borderRadius';

import { convertSelectedValue } from './formUtil';

export default function SelectMulti(props) {
  const { fieldId, label, options, setFieldValue, value, isSingleSelect } = props;

  if (isEmpty(options)) return null;

  const selectedValue = convertSelectedValue(value, options);

  return (
    <Flex direction="column">
      <Text color="grey.600" fontWeight="medium" fontSize="xs" ml={1}>
        {label}
      </Text>
      <Multiselect
        options={options}
        selectedValues={selectedValue}
        onSelect={(selectedList) => {
          setFieldValue(fieldId, selectedList);
        }}
        onRemove={(selectedList) => {
          setFieldValue(fieldId, selectedList);
        }}
        closeIcon="cancel"
        avoidHighlightFirstOption={true}
        displayValue="label"
        showCheckbox
        showArrow
        customArrow={<Icon width={5} height={5} name="chevronDown" color="grey.500" />}
        displayName="Surface"
        style={getSelectStyles()}
        singleSelect={isSingleSelect}
      />
    </Flex>
  );
}

SelectMulti.propTypes = {
  fieldId: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  setFieldValue: PropTypes.func,
  value: PropTypes.array,
  isSingleSelect: PropTypes.bool
};

function getSelectStyles() {
  const colors = customSitesConfig?.colors;
  return {
    searchBox: {
      borderRadius: borderRadiusRaw.md,
      borderColor: colors?.grey[300],
      fontSize: '12px',
      minHeight: '32px',
      padding: '2px 5px 2px 5px',
      maxWidth: '280px',
      minWidth: '180px'
    },
    chips: {
      background: colors?.primary[500],
      fontSize: '12px',
      height: '19px',
      borderRadius: borderRadiusRaw.sm,
      color: colors?.white
    },
    option: {
      color: colors?.grey[500],
      fontSize: '12px'
    }
  };
}
