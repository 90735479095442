import React, { useState } from 'react';

import { Box, Flex, Text, Collapse, List, ListItem } from '@ellipsedata/tennis';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { Icon, useBreakpointValue } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';

const PlaylistMobile = ({ playlist, onSelectPoint, currentIndex }) => {
  const [isOpen, setIsOpen] = useState(false);
  const fontSize = { base: 'xs', md: 'sm' };
  const isSmall = useBreakpointValue({ base: false, sm: true });
  const iconSize = { base: 7, md: 9 };

  const toggleDropdown = () => setIsOpen(!isOpen);

  if (isEmpty(playlist)) return null;

  return (
    <Box data-testid="dropdown" w="100%">
      <Flex
        onClick={toggleDropdown}
        w="100%"
        align="center"
        px={{ base: 0, md: 1 }}
        pr={{ base: 1, md: 3 }}
        bg={isWimbPortal() ? 'primary.500' : 'primary.200'}
        color={isWimbPortal() ? 'white' : 'secondary.800'}
      >
        <Icon
          name={isOpen ? 'chevronUp' : 'chevronDown'}
          height={iconSize}
          width={iconSize}
          mr="2px"
          cursor="pointer"
        />
        <PlaylistRow
          index={currentIndex}
          length={playlist.length}
          summary={playlist[currentIndex].summary}
          set={`Set ${playlist[currentIndex].set}`}
          games={playlist[currentIndex].games}
          points={playlist[currentIndex].points}
          fontSize={fontSize}
        />
      </Flex>
      <Collapse in={isOpen}>
        <List bg="white" mt={5} borderTop="2px solid" borderColor="primary.500" pt={3}>
          {playlist?.map((item, index) => (
            <ListItem
              key={index}
              bg={currentIndex === index ? (isWimbPortal() ? 'primary.500' : 'primary.200') : 'none'}
              color={currentIndex === index ? (isWimbPortal() ? 'white' : 'secondary.800') : 'secondary.800'}
              cursor="pointer"
              _hover={{ bg: 'primary.100' }}
              onClick={() => {
                onSelectPoint(index);
                toggleDropdown();
              }}
              data-testid={`playlist-item-${index}`}
              borderBottom="1px solid"
              borderColor="grey.200"
              py={isSmall ? 3 : 1}
              px="3px"
            >
              <PlaylistRow
                index={index}
                length={playlist.length}
                summary={item.summary}
                set={`Set ${item.set}`}
                games={item.games}
                points={item.points}
                fontSize={fontSize}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Box>
  );
};

PlaylistMobile.propTypes = {
  playlist: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectPoint: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired
};

export default PlaylistMobile;

const PlaylistRow = ({ index, length, summary, set, games, points, fontSize }) => {
  const isSmall = useBreakpointValue({ base: false, sm: true });

  return (
    <Flex w="100%" justify="space-between">
      <Flex align="center">
        <Text fontSize={fontSize} minW={{ base: '40px', md: '50px' }} mr={1}>
          {index + 1}/{length}
        </Text>
        <Text fontSize={fontSize}>{summary}</Text>
      </Flex>
      {isSmall ? (
        <Flex w={{ base: '120px', md: '130px' }} gap={1} justify="flex-end">
          <Text fontSize={fontSize}>{set}</Text>
          <Text fontSize={fontSize}>{games}</Text>
          <Text fontSize={fontSize}>{points}</Text>
        </Flex>
      ) : (
        <Flex w="100px" gap={1} justify="flex-end">
          <Flex flexDirection="column" w="70px">
            <Text fontSize="xs" textAlign="right">
              {set}
            </Text>
            <Text fontSize="xs" textAlign="right">
              {games} {points}
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
