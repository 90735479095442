import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useRecoilValue } from 'recoil';

import { Box } from '_shared/designSystem/components';
import themeColors from '_shared/designSystem/theme/colors';
import { fontSizes } from '_shared/designSystem/theme/typography';
import { isMatchReportState } from '_shared/globalState/atoms';
export default function PieChart(props) {
  const { data, labels, colors } = props;

  const isMatchReport = useRecoilValue(isMatchReportState);

  const options = {
    dataLabels: {
      enabled: true,
      formatter: function (value, { w, seriesIndex }) {
        return w.config.series[seriesIndex];
      },
      style: {
        fontSize: fontSizes.xs,
        fontWeight: 'normal',
        fontFamily: 'Inter, Arial, sans-serif',
        colors: [
          themeColors.grey['800'],
          themeColors.grey['800'],
          themeColors.grey['800'],
          themeColors.grey['800'],
          themeColors.grey['800']
        ]
      },
      dropShadow: {
        enabled: false
      }
    },
    labels,
    legend: {
      show: false
    },
    grid: {
      padding: {
        left: -30,
        right: -30
      }
    },
    colors: isEmpty(colors)
      ? [
          themeColors.error['300'],
          themeColors.warning['300'],
          themeColors.success['300'],
          themeColors.blue['300'],
          themeColors.purple['300']
        ]
      : colors,
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      style: {
        fontSize: fontSizes.sm,
        fontWeight: 'normal',
        fontFamily: 'Inter, Arial, sans-serif'
      }
    },
    chart: isMatchReport
      ? {
          animations: {
            enabled: false,
            animateGradually: {
              enabled: false
            },
            dynamicAnimation: {
              enabled: false
            }
          }
        }
      : {
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 10,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 450
            }
          }
        }
  };

  return <Box>{!isEmpty(data) && <Chart options={options} series={data} type="pie" />}</Box>;
}

PieChart.propTypes = {
  data: PropTypes.array,
  labels: PropTypes.array,
  colors: PropTypes.array
};
