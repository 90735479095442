export const saveToSessionStorage = (key, name, value) => {
  const savedData = getFromSessionStorage(key) || {};
  savedData[name] = value;
  sessionStorage.setItem(key, JSON.stringify(savedData));
};

export const getFromSessionStorage = (key) => {
  const savedData = sessionStorage.getItem(key);
  return savedData ? JSON.parse(savedData) : null;
};
