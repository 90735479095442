import React from 'react';

import { displayScore } from 'match/utils/transformations';

import { Flex, CourtBadgeGroup, CourtBadge } from '_shared/designSystem/components';

export const InCourtBadgeGroups = (
  dataWithAverages,
  dataNoAverages,
  position,
  label,
  size,
  matchAverageVariant,
  showAverages,
  averagesLoading
) => (
  <Flex
    position="absolute"
    top={position.top}
    left={position.left}
    right={position.right}
    zIndex={1}
    data-testid="in-badge-group"
  >
    <CourtBadgeGroup
      dataWithAverages={dataWithAverages}
      dataNoAverages={dataNoAverages}
      size={size}
      label={label}
      showAverages={showAverages}
      averagesLoading={averagesLoading}
      matchAverageVariant={matchAverageVariant}
    />
  </Flex>
);

export const QualityCourtBadgeGroupDesktop = ({ data, position, attributes, averagesLoading }) => (
  <Flex
    direction="column"
    position="absolute"
    top={position.top}
    left={position.left}
    right={position.right}
    zIndex={1}
    gap={1}
    data-testid="to_deuce-quality-badge-group-desktop"
  >
    {attributes.map((attribute) => (
      <CourtBadge
        key={attribute}
        text={displayScore(data[attribute].score, data[attribute].score_type)}
        variant="matchAverage"
        loading={averagesLoading}
      />
    ))}
  </Flex>
);

export const QualityCourtBadgeGroupDesktopAverages = ({
  dataNoAverages,
  dataWithAverages,
  courtSide,
  attribute,
  showAverages,
  averagesLoading
}) => (
  <Flex direction="column" gap={1} data-testid={`${courtSide}-${attribute}-badge-averages-group-mobile`}>
    <CourtBadge
      text={displayScore(
        dataNoAverages?.[courtSide][attribute].score,
        dataNoAverages?.[courtSide][attribute].score_type
      )}
      variant="matchAverage"
    />
    {showAverages && (
      <>
        <CourtBadge
          text={displayScore(
            dataWithAverages?.[courtSide][attribute].player_average,
            dataWithAverages?.[courtSide][attribute].score_type
          )}
          variant="playerAverage"
          loading={averagesLoading}
        />
        <CourtBadge
          text={displayScore(
            dataWithAverages?.[courtSide][attribute].tour_average,
            dataWithAverages?.[courtSide][attribute].score_type
          )}
          variant="tourAverage"
          loading={averagesLoading}
        />
      </>
    )}
  </Flex>
);

export const QualityCourtBadgeGroupMobile = ({
  dataWithAverages,
  dataNoAverages,
  courtSide,
  attribute,
  averagesLoading
}) => (
  <Flex
    direction="column"
    gap={3}
    justifyContent="space-around"
    data-testid={`${courtSide}-${attribute}-badge-group-mobile`}
  >
    <CourtBadge
      text={displayScore(
        dataNoAverages?.[courtSide][attribute].score,
        dataNoAverages?.[courtSide][attribute].score_type
      )}
      variant="matchAverage"
      loading={averagesLoading}
    />
    <QualityCourtBadge
      data={dataWithAverages}
      courtSide={courtSide}
      attribute={attribute}
      dataSet="player_average"
      variant="playerAverage"
      averagesLoading={averagesLoading}
    />
    <QualityCourtBadge
      data={dataWithAverages}
      courtSide={courtSide}
      attribute={attribute}
      dataSet="tour_average"
      variant="tourAverage"
      averagesLoading={averagesLoading}
    />
  </Flex>
);

const QualityCourtBadge = ({ data, courtSide, attribute, variant, dataSet, averagesLoading }) => (
  <CourtBadge
    text={displayScore(data?.[courtSide][attribute][dataSet], data?.[courtSide][attribute].score_type)}
    variant={variant}
    loading={averagesLoading}
  />
);
