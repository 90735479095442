import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

export function getLoadingData(loadingObject) {
  if (isEmpty(loadingObject)) return [];
  const keys = Object.keys(loadingObject);
  const result = [];
  keys.forEach((item) => {
    const label = item.replace('IsLoading', '');
    result.push({
      label: startCase(label),
      isLoading: loadingObject[`${item}`]
    });
  });
  return result;
}

export function getAnyLoading(loadingArray) {
  if (isEmpty(loadingArray)) return false;
  let loading = false;
  loadingArray.forEach((item) => {
    if (item.isLoading) loading = true;
  });
  return loading;
}
