import { useState, useEffect } from 'react';

import { useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import isEmpty from 'lodash/isEmpty';
import { useMutation, useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';
import { scoutTabTypes } from 'scout/_shared/scoutConstants';
import { scoutService } from 'scout/service/scoutService';
import { updateServeLocationFilters } from 'scout/util/scoutServeLocationUtils';
import {
  convertCourtParams,
  convertFormStateToPageUrlParams,
  convertFormStateToScoutState,
  convertSearchParams,
  getCourtMutationObject,
  getGraphicTypeFromTab,
  getMainMenuItems,
  handleTimeFrameChange,
  matchHeaderFieldsChanged,
  removeMatchIdsFromParams
} from 'scout/util/scoutUtil';

import NoPermissionAlert from '_shared/components/NoPermissionAlert';
import { userPermissionTypes } from '_shared/constants/user';
import { Box, ErrorMessage, HorizontalNavBar, LoadingSpinner, useDisclosure } from '_shared/designSystem/components';
import ErrorBoundary from '_shared/errors/ErrorBoundary';
import { logBasicMessage } from '_shared/errors/log';
import { userPermissionsState } from '_shared/globalState/atoms';
import { mobileHeaderTextState } from '_shared/globalState/atoms';
import useFeature from '_shared/utils/hooks/useFeature';
import usePageTitle from '_shared/utils/hooks/usePageTitle';
import { checkPermission } from '_shared/utils/permissions';

import { capitaliseString, convertBooleanToYesNo } from '../../_shared/utils/stringUtil';

import ScoutCourtGraphics from './court-graphics/ScoutCourtGraphics';
import ScoutInsights from './insights/ScoutInsights';
import { ScoutMatchSelectorModal } from './matchSelector/ScoutMatchSelectorModal';
import ScoutPatternsOfPlay from './patterns_of_play/ScoutPatternsOfPlay';
import ScoutHeader from './ScoutHeader';
import ScoutPlaysSummary from './winning_and_losing_plays/ScoutPlaysSummary';

/**
 * API calls, state management and functional logic are all at the top level here for a couple of reasons.
 * It makes the components lower down more reusable, without tying them to a particular API endpoint to call
 * for the data e.g. to use in the scout PDF report. It also means fewer issues with the match filtering
 * and tab state. Having it all up top keep things simple, with the downside of it not looking very clean.
 *
 * It uses react-query mutations rather than queries, because each API call can take a long time to complete
 * with it being generated from multiple matches, so this way we can control it better rather than relying
 * on just state updates, which can sometimes occur frequently for forms.
 */

export default function Scout() {
  const {
    params: { playerId }
  } = useMatch();

  const searchParams = useSearch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const setMobileHeaderText = useSetRecoilState(mobileHeaderTextState);

  const [scoutFormValues, setScoutFormValues] = useState({});
  const [scoutFetchingValues, setScoutFetchingValues] = useState({});
  const [currentTab, setCurrentTab] = useState(scoutTabTypes.INSIGHTS);
  const [playType, setPlayType] = useState('all');
  const [courtGraphicsFiltersValues, setCourtGraphicsFiltersValues] = useState({});
  const [showAverages, setShowAverages] = useState(false);
  const [servePlusOne, setServePlusOne] = useState(false);

  const { permissions } = useRecoilValue(userPermissionsState);

  const patternsOfPlayEnabled = useFeature('patternsOfPlay');
  const winningAndLosingPlaysV2Enabled = useFeature('winningAndLosingPlaysV2');

  const { data: playerDetailsData, isLoading: playerDetailsLoading } = useQuery(
    ['scoutService_getPlayerDetails', playerId],
    () => scoutService.getPlayerDetails({ playerId })
  );

  const {
    data: winLossData,
    mutate: winLossMutate,
    isLoading: winLossLoading
  } = useMutation((values) => scoutService.getPlayerDetailsWinLoss({ playerId, scoutFormValues: values }));

  const { data: formPopulationData, mutate: formPopulationMutate } = useMutation((values) =>
    scoutService.getFormPopulation({ playerId, scoutFormValues: values })
  );

  const {
    data: matchSelectorMatches,
    mutate: matchSelectorMutate,
    isLoading: matchSelectorLoading
  } = useMutation((values) => scoutService.getMatchSelectorData({ playerId, scoutFormValues: values }));

  const {
    data: insightsNoAveragesData,
    mutate: insightsNoAveragesMutate,
    isLoading: insightsNoAveragesIsLoading
  } = useMutation((mutationInputObject) =>
    scoutService.getInsights({
      playerId,
      scoutFormValues: mutationInputObject.values,
      averages: false
    })
  );

  const {
    data: insightsWithAveragesData,
    mutate: insightsWithAveragesMutate,
    isLoading: insightsWithAveragesIsLoading
  } = useMutation((mutationInputObject) =>
    scoutService.getInsights({
      playerId,
      scoutFormValues: mutationInputObject.values,
      averages: true
    })
  );

  const {
    data: performanceRatingNoAveragesData,
    mutate: performanceRatingNoAveragesMutate,
    isLoading: performanceRatingNoAveragesIsLoading
  } = useMutation((mutationInputObject) =>
    scoutService.getInsightsPerformanceRating({
      playerId,
      scoutFormValues: mutationInputObject.values,
      averages: false
    })
  );

  const { data: performanceRatingWithAveragesData, mutate: performanceRatingWithAveragesMutate } = useMutation(
    (mutationInputObject) =>
      scoutService.getInsightsPerformanceRating({
        playerId,
        scoutFormValues: mutationInputObject.values,
        averages: true
      })
  );

  const {
    data: courtStatsDataNoAverages,
    mutate: courtStatsMutateNoAverages,
    isLoading: courtStatsNoAveragesIsLoading
  } = useMutation((mutationInputObject) =>
    scoutService.getCourtStats({
      playerId,
      graphicType: mutationInputObject.graphicType,
      courtGraphicsParams: mutationInputObject.courtGraphicsParams,
      averages: false
    })
  );

  const {
    data: courtStatsDataWithAverages,
    mutate: courtStatsMutateWithAverages,
    isLoading: courtStatsWithAveragesIsLoading
  } = useMutation((mutationInputObject) =>
    scoutService.getCourtStats({
      playerId,
      graphicType: mutationInputObject.graphicType,
      courtGraphicsParams: mutationInputObject.courtGraphicsParams,
      averages: true
    })
  );

  const {
    data: courtGraphicsDataNoAverages,
    mutate: courtGraphicsMutateNoAverages,
    isLoading: courtGraphicsNoAveragesIsLoading
  } = useMutation((mutationInputObject) =>
    scoutService.getCourtGraphics({
      playerId,
      graphicType: mutationInputObject.graphicType,
      courtGraphicsParams: mutationInputObject.courtGraphicsParams,
      averages: false
    })
  );

  const {
    data: courtGraphicsDataWithAverages,
    mutate: courtGraphicsMutateWithAverages,
    isLoading: courtGraphicsWithAveragesIsLoading
  } = useMutation((mutationInputObject) =>
    scoutService.getCourtGraphics({
      playerId,
      graphicType: mutationInputObject.graphicType,
      courtGraphicsParams: mutationInputObject.courtGraphicsParams,
      averages: true
    })
  );

  const {
    data: winningAndLosingPlaysData,
    mutate: winningAndLosingPlaysMutate,
    isLoading: winningAndLosingPlaysIsLoading
  } = useMutation((mutateObject) =>
    scoutService.getWinningAndLosingPlays({
      playerId,
      playType: mutateObject.playType,
      scoutFormValues: mutateObject.scoutFormValues
    })
  );

  // mobile header
  useEffect(() => {
    if (!isEmpty(playerDetailsData?.player_first_name)) {
      setMobileHeaderText(`${playerDetailsData?.player_first_name} ${playerDetailsData?.player_last_name}`);
    }
  }, [setMobileHeaderText, playerDetailsData?.player_first_name, playerDetailsData?.player_last_name]);

  // page title
  usePageTitle(
    `${playerDetailsData?.player_first_name} ${playerDetailsData?.player_last_name} - ${capitaliseString(currentTab)}`,
    playerDetailsLoading
  );

  // on first page load get params from url
  useEffect(() => {
    const formValues = convertSearchParams(searchParams);
    const courtGraphicsFiltersValuesInitial = convertCourtParams(searchParams);
    setScoutFormValues(formValues);
    setScoutFetchingValues(formValues);
    setCourtGraphicsFiltersValues(courtGraphicsFiltersValuesInitial);
    navigate({
      search: (old) => ({
        ...old,
        ...convertFormStateToPageUrlParams(formValues),
        ...convertFormStateToPageUrlParams(courtGraphicsFiltersValuesInitial)
      })
    });

    if (!searchParams.tab) {
      searchParams.tab = scoutTabTypes.INSIGHTS;
      navigate({ search: (old) => ({ ...old, tab: scoutTabTypes.INSIGHTS }) });
    }
    setCurrentTab(searchParams.tab);

    const playType = searchParams.playType ? searchParams.playType : 'all';
    setPlayType(playType);

    setShowAverages(searchParams.showAverages === 'yes');

    setServePlusOne(searchParams.servePlusOne === 'yes');

    // initial API fetches from url params
    switch (searchParams.tab) {
      case scoutTabTypes.SERVE:
      case scoutTabTypes.RETURN:
      case scoutTabTypes.GROUNDSTROKES:
        const graphicType = getGraphicTypeFromTab(searchParams.tab);
        getCourtData(graphicType, formValues, courtGraphicsFiltersValuesInitial);
        break;
      case scoutTabTypes.WINNING_AND_LOSING_PLAYS:
        winningAndLosingPlaysMutate({ playType, scoutFormValues: formValues });
        break;
      case scoutTabTypes.INSIGHTS:
        insightsNoAveragesMutate({ values: formValues });
        insightsWithAveragesMutate({ values: formValues });
        performanceRatingNoAveragesMutate({ values: formValues });
        performanceRatingWithAveragesMutate({ values: formValues });
        break;
      default:
    }
    matchSelectorMutate({ scoutFormValues: removeMatchIdsFromParams(formValues) });
    formPopulationMutate(formValues);
    winLossMutate(formValues);
    logBasicMessage('INFO', `User loaded Scout Area for playerId ${playerId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle form updates
  const handleHeaderFormUpdate = (formState) => {
    const newState = convertFormStateToScoutState(formState);
    const withUpdatedHeader = handleTimeFrameChange(scoutFormValues, newState);
    setScoutFormValues(withUpdatedHeader);
  };

  const handleHeaderFormSubmit = (formState) => {
    const newState = convertFormStateToScoutState(formState);
    setScoutFormValues(newState);
    setScoutFetchingValues(newState);
    const urlParams = convertFormStateToPageUrlParams(newState);
    navigate({ search: (old) => ({ ...old, ...urlParams }) });
    fetchScoutDataAfterFormUpdate(currentTab, newState);
  };

  const handleMatchSelectorFormUpdate = (formState) => {
    const newState = convertFormStateToScoutState(formState);
    const withUpdatedHeader = handleTimeFrameChange(scoutFormValues, newState);
    if (matchHeaderFieldsChanged(scoutFormValues, withUpdatedHeader)) formPopulationMutate(withUpdatedHeader);
    setScoutFormValues(withUpdatedHeader);
    matchSelectorMutate(removeMatchIdsFromParams(withUpdatedHeader));
  };

  const handleMatchSelectorFormSubmit = (formState) => {
    const newState = convertFormStateToScoutState(formState);
    setScoutFormValues(newState);
    setScoutFetchingValues(newState);
    const urlParams = convertFormStateToPageUrlParams(newState);
    navigate({ search: (old) => ({ ...old, ...urlParams }) });
    fetchScoutDataAfterFormUpdate(currentTab, newState);
    onClose();
  };

  // handle button clicks
  const openMatchSelector = () => {
    onOpen();
  };

  const closeMatchSelector = () => {
    onClose();
  };

  const handleNavBarClick = (id) => {
    setCurrentTab(id);
    // need to reset fields between court tabs
    if (id === scoutTabTypes.RETURN || id === scoutTabTypes.GROUNDSTROKES) {
      const newFilters = { ...courtGraphicsFiltersValues };
      newFilters.averagesType = 'placement';
      setCourtGraphicsFiltersValues(newFilters);
      getCourtData(getGraphicTypeFromTab(id), scoutFormValues, newFilters);
      navigate({
        search: (old) => ({
          ...old,
          tab: id,
          ...convertFormStateToPageUrlParams(newFilters)
        })
      });
    } else {
      navigate({
        search: (old) => ({ ...old, tab: id })
      });
      fetchScoutDataAfterFormUpdate(id, scoutFormValues);
    }
  };

  const handleWinningAndLosingPlaysButtonClick = (paramName, value) => {
    switch (paramName) {
      case 'playType':
        const newValue = value === playType ? 'all' : value;
        setPlayType(newValue);
        navigate({ search: (old) => ({ ...old, playType: newValue }) });
        winningAndLosingPlaysMutate({ playType: newValue, scoutFormValues });
        break;
      default:
        return null;
    }
  };

  function handleCourtGraphicFilterClick(paramName, value) {
    let updatedFilters = { ...courtGraphicsFiltersValues };

    if (paramName === 'serveLocation') {
      updatedFilters = updateServeLocationFilters(updatedFilters, value);
    } else {
      updatedFilters[paramName] =
        ['pressureBreak', 'forehandBackhand'].includes(paramName) && value === updatedFilters[paramName]
          ? 'all'
          : value;
    }

    if (paramName !== 'averagesType') {
      const graphicType = getGraphicTypeFromTab(currentTab);
      const mutationObject = getCourtMutationObject(graphicType, scoutFormValues, updatedFilters);
      courtGraphicsMutateNoAverages(mutationObject);
      courtGraphicsMutateWithAverages(mutationObject);
    }

    setCourtGraphicsFiltersValues(updatedFilters);
    navigate({
      search: (old) => ({
        ...old,
        [paramName]: paramName === 'serveLocation' ? updatedFilters.serveLocation.join(',') : value
      })
    });
  }

  // refetching data for form updates/submissions
  function fetchScoutDataAfterFormUpdate(tab, formValues) {
    const graphicType = getGraphicTypeFromTab(tab);
    switch (tab) {
      case scoutTabTypes.INSIGHTS:
        insightsNoAveragesMutate({ values: formValues });
        insightsWithAveragesMutate({ values: formValues });
        performanceRatingNoAveragesMutate({ values: formValues });
        performanceRatingWithAveragesMutate({ values: formValues });
        break;
      case scoutTabTypes.SERVE:
      case scoutTabTypes.RETURN:
      case scoutTabTypes.GROUNDSTROKES:
        getCourtData(graphicType, formValues, courtGraphicsFiltersValues);
        break;
      case scoutTabTypes.WINNING_AND_LOSING_PLAYS:
        winningAndLosingPlaysMutate({ playType, scoutFormValues: formValues });
        break;
      default:
    }
    winLossMutate(formValues);
  }

  function getCourtData(graphicType, formValues, courtGraphicsFiltersValuesInitial) {
    courtStatsMutateNoAverages(getCourtMutationObject(graphicType, formValues));
    courtStatsMutateWithAverages(getCourtMutationObject(graphicType, formValues));
    courtGraphicsMutateNoAverages(getCourtMutationObject(graphicType, formValues, courtGraphicsFiltersValuesInitial));
    courtGraphicsMutateWithAverages(getCourtMutationObject(graphicType, formValues, courtGraphicsFiltersValuesInitial));
  }

  function handleSetShowAverages() {
    const newShowAverages = !showAverages;
    navigate({ search: (old) => ({ ...old, showAverages: convertBooleanToYesNo(newShowAverages) }) });
    setShowAverages(newShowAverages);
  }

  const handleSetServePlusOne = () => {
    const newShowServePlusOne = !servePlusOne;
    navigate({ search: (old) => ({ ...old, servePlusOne: convertBooleanToYesNo(newShowServePlusOne) }) });
    setServePlusOne(newShowServePlusOne);
    handleCourtGraphicFilterClick('servePlusOne', newShowServePlusOne);
  };

  if (!checkPermission(userPermissionTypes.SCOUT_AREA, permissions)) {
    return <NoPermissionAlert />;
  }

  if (playerDetailsLoading) return <LoadingSpinner />;

  return (
    <>
      <Box mb={4}>
        <ScoutHeader
          winLossLoading={winLossLoading}
          playerDetailsData={playerDetailsData}
          winLoss={winLossData}
          handleHeaderFormSubmit={handleHeaderFormSubmit}
          openMatchSelector={openMatchSelector}
          scoutFormValues={scoutFormValues}
          handleHeaderFormUpdate={handleHeaderFormUpdate}
        />
        <ScoutMatchSelectorModal
          isOpen={isOpen}
          onClose={closeMatchSelector}
          playerDetailsData={playerDetailsData}
          formPopulationData={formPopulationData}
          matchSelectorMatches={matchSelectorMatches}
          scoutFormValues={scoutFormValues}
          handleMatchSelectorFormSubmit={handleMatchSelectorFormSubmit}
          handleMatchSelectorFormUpdate={handleMatchSelectorFormUpdate}
          matchSelectorLoading={matchSelectorLoading}
        />
      </Box>
      <Box mb={2}>
        <HorizontalNavBar
          items={getMainMenuItems(patternsOfPlayEnabled, winningAndLosingPlaysV2Enabled)}
          activeItem={currentTab}
          overrideClickHandler={handleNavBarClick}
        />
      </Box>
      <ErrorBoundary
        renderError={() => (
          <Box p={4}>
            <ErrorMessage message="An error has occured displaying the scout area. Please try refreshing the page" />
          </Box>
        )}
      >
        <MainContentContainer
          playerDetailsData={playerDetailsData}
          scoutFormValues={scoutFormValues}
          scoutFetchingValues={scoutFetchingValues}
          tab={currentTab}
          insightsNoAveragesData={insightsNoAveragesData}
          insightsWithAveragesData={insightsWithAveragesData}
          insightsNoAveragesIsLoading={insightsNoAveragesIsLoading}
          insightsWithAveragesIsLoading={insightsWithAveragesIsLoading}
          performanceRatingNoAveragesData={performanceRatingNoAveragesData}
          performanceRatingWithAveragesData={performanceRatingWithAveragesData}
          performanceRatingNoAveragesIsLoading={performanceRatingNoAveragesIsLoading}
          courtStatsDataNoAverages={courtStatsDataNoAverages}
          courtStatsDataWithAverages={courtStatsDataWithAverages}
          courtStatsNoAveragesIsLoading={courtStatsNoAveragesIsLoading}
          courtStatsWithAveragesIsLoading={courtStatsWithAveragesIsLoading}
          courtGraphicsDataNoAverages={courtGraphicsDataNoAverages}
          courtGraphicsDataWithAverages={courtGraphicsDataWithAverages}
          courtGraphicsNoAveragesIsLoading={courtGraphicsNoAveragesIsLoading}
          courtGraphicsWithAveragesIsLoading={courtGraphicsWithAveragesIsLoading}
          courtGraphicsFiltersValues={courtGraphicsFiltersValues}
          handleCourtGraphicFilterClick={handleCourtGraphicFilterClick}
          winningAndLosingPlaysData={winningAndLosingPlaysData}
          winningAndLosingPlaysIsLoading={winningAndLosingPlaysIsLoading}
          handleWinningAndLosingPlaysButtonClick={handleWinningAndLosingPlaysButtonClick}
          playType={playType}
          setPlayType={setPlayType}
          showAverages={showAverages}
          handleSetShowAverages={handleSetShowAverages}
          servePlusOne={servePlusOne}
          handleSetServePlusOne={handleSetServePlusOne}
        />
      </ErrorBoundary>
    </>
  );
}

const MainContentContainer = (props) => {
  switch (props.tab) {
    case scoutTabTypes.INSIGHTS:
      return props.insightsNoAveragesIsLoading ? (
        <LoadingSpinner />
      ) : (
        <ScoutInsights
          dataNoAverages={props.insightsNoAveragesData}
          dataWithAverages={props.insightsWithAveragesData}
          averagesLoading={props.insightsWithAveragesIsLoading}
          isMatchReport={false}
          showAverages={props.showAverages}
          handleSetShowAverages={props.handleSetShowAverages}
          performanceRatingNoAveragesData={props.performanceRatingNoAveragesData}
          performanceRatingWithAveragesData={props.performanceRatingWithAveragesData}
          performanceRatingNoAveragesIsLoading={props.performanceRatingNoAveragesIsLoading}
        />
      );
    case scoutTabTypes.SERVE:
      return <ScoutCourtGraphics {...props} showPlayerAverage={true} graphicType="serve_direction" />;
    case scoutTabTypes.RETURN:
      return <ScoutCourtGraphics {...props} showPlayerAverage={true} graphicType="return_placement" />;
    case scoutTabTypes.GROUNDSTROKES:
      return <ScoutCourtGraphics {...props} showPlayerAverage={true} graphicType="rally_placement" />;
    case scoutTabTypes.WINNING_AND_LOSING_PLAYS:
      return (
        <ScoutPlaysSummary
          playerDetailsData={props.playerDetailsData}
          scoutFetchingValues={props.scoutFetchingValues}
        />
      );
    case scoutTabTypes.PATTERNS_OF_PLAY:
      return <ScoutPatternsOfPlay {...props} surface="hard" />;
    default:
      return null;
  }
};
