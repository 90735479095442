import { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { scoutService } from 'scout/service/scoutService';
import { getPatternsOfPlayParams } from 'scout/util/scoutPatternsOfPlayUtil';

import PatternsOfPlay from './PatternsOfPlay';
export default function ScoutPatternsOfPlay(props) {
  const { playerDetailsData, scoutFetchingValues, surface } = props;

  const playerId = playerDetailsData?.player_id;

  const [patternsOfPlayParams, setPatternsOfPlayParams] = useState(null);

  const { data: dataNoAverages, isLoading: isLoadingNoAverages } = useQuery(
    ['scoutService_patternsOfPlayNoAverages', playerId, patternsOfPlayParams, scoutFetchingValues],
    () => scoutService.getPatternsOfPlay({ playerId, patternsOfPlayParams, scoutFetchingValues, averages: false }),
    { enabled: !isEmpty(patternsOfPlayParams) }
  );

  const { data: dataWithAverages, isLoading: isLoadingWithAverages } = useQuery(
    ['scoutService_patternsOfPlayWithAverages', playerId, patternsOfPlayParams, scoutFetchingValues],
    () => scoutService.getPatternsOfPlay({ playerId, patternsOfPlayParams, scoutFetchingValues, averages: true }),
    { enabled: !isEmpty(patternsOfPlayParams) }
  );

  function updateParams(pattern, filterValues) {
    const patternsOfPlayParams = getPatternsOfPlayParams(pattern, filterValues);
    setPatternsOfPlayParams(patternsOfPlayParams);
  }

  return (
    <PatternsOfPlay
      dataNoAverages={dataNoAverages}
      dataWithAverages={dataWithAverages}
      averagesLoading={isLoadingWithAverages}
      updateParams={updateParams}
      isLoadingNoAverages={isLoadingNoAverages}
      surface={surface}
    />
  );
}

ScoutPatternsOfPlay.propTypes = {
  playerDetailsData: PropTypes.object,
  scoutFetchingValues: PropTypes.object,
  surface: PropTypes.oneOf(['hard', 'grass', 'clay'])
};

ScoutPatternsOfPlay.defaultProps = {
  surface: 'hard'
};
