import PropTypes from 'prop-types';

import { Flex, Switch, Text, Legend } from '_shared/designSystem/components';
import { getTourAverageLabel } from '_shared/utils/metricsUtil';

export const Averages = (props) => {
  const { showAverages } = props;
  return (
    <Flex alignItems="center" gap={6}>
      <AveragesSwitch {...props} />
      {showAverages && (
        <Legend
          fields={[
            { label: 'Player Avg', color: 'playerAverage' },
            { label: getTourAverageLabel(), color: 'tourAverage' }
          ]}
        />
      )}
    </Flex>
  );
};

Averages.propTypes = {
  showAverages: PropTypes.bool,
  setShowAverages: PropTypes.func
};

const AveragesSwitch = (props) => {
  const { showAverages, setShowAverages } = props;
  return (
    <Flex alignItems="center" gap={2}>
      <Switch
        isChecked={showAverages}
        colorScheme="green"
        onChange={() => {
          setShowAverages(!showAverages);
        }}
      />
      <Text fontSize="sm" fontWeight="medium" color="grey.700">
        Averages
      </Text>
    </Flex>
  );
};

AveragesSwitch.propTypes = {
  showAverages: PropTypes.bool,
  setShowAverages: PropTypes.func
};

export const OutShotsSwitch = (props) => {
  const { outShots, setOutShots } = props;
  return (
    <Flex alignItems="center" gap={2}>
      <Switch
        isChecked={outShots}
        colorScheme="green"
        onChange={() => {
          setOutShots(!outShots);
        }}
      />
      <Text fontSize="sm" fontWeight="medium" color="grey.700">
        Show Errors
      </Text>
    </Flex>
  );
};

export const ServePlusOneSwitch = (props) => {
  const { servePlusOne, handleButtonClick } = props;
  return (
    <Flex alignItems="center" gap={2} mr={4}>
      <Switch
        colorScheme="green"
        isChecked={servePlusOne}
        onChange={() => {
          handleButtonClick('servePlusOne', !servePlusOne);
        }}
      />
      <Text fontSize="sm" fontWeight="medium" color="grey.700">
        Serve +1
      </Text>
    </Flex>
  );
};

export const AISelectorSwitch = (props) => {
  const { isAISelected, setIsAISelected } = props;
  return (
    <Flex alignItems="center" gap={2}>
      <Switch
        isChecked={isAISelected}
        colorScheme="green"
        onChange={() => {
          setIsAISelected(!isAISelected);
        }}
      />
      <Text fontSize="sm" fontWeight="medium" color="grey.700">
        AI Insight Select
      </Text>
    </Flex>
  );
};
