import { useEffect, useState } from 'react';

import { useNavigate } from '@tanstack/react-location';
import TermsAndConditionsForm from 'authentication/components/termsAndConditions/TermsAndConditionsForm';
import { authenticationService } from 'authentication/services/authenticationService';
import { TERMS_AND_CONDITIONS_IN_PROGRESS } from 'authentication/util/authConstants';
import { useMutation, useQuery } from 'react-query';

import { Alert, Box, Flex, LoadingSpinner } from '_shared/designSystem/components';
import useFeature from '_shared/utils/hooks/useFeature';

export default function TermsAndConditionsPage() {
  const navigate = useNavigate();
  const [submitError, setSubmitError] = useState();

  const termsAndConditions = useFeature('termsAndConditions');

  const {
    isFetched: acceptedDataIsFetched,
    data: acceptedData,
    isLoading: acceptedIsLoading,
    error: acceptedError
  } = useQuery(['authenticationService_getTermsAndConditionsAccepted'], () =>
    authenticationService.getTermsAndConditionsAccepted()
  );

  const { data: textsData, isLoading: textsIsLoading } = useQuery(
    ['authenticationService_getTermsAndConditionsTexts'],
    () => authenticationService.getTermsAndConditionsTexts()
  );

  const { mutate: submitAcceptedMutate } = useMutation(
    (object) => authenticationService.submitTermsAndConditionsAccepted(object),
    {
      onSuccess: (data) => {
        localStorage.removeItem(TERMS_AND_CONDITIONS_IN_PROGRESS);
        navigate({ to: '/tournaments' });
      },
      onError: (error) => {
        setSubmitError('An error occurred, please try again');
      }
    }
  );

  useEffect(() => {
    if (!termsAndConditions) {
      navigate({ to: '/tournaments' });
    } else {
      if (acceptedDataIsFetched) {
        if (acceptedData?.terms_and_conditions_accepted === true && acceptedData?.privacy_notice_accepted === true) {
          localStorage.removeItem(TERMS_AND_CONDITIONS_IN_PROGRESS);
          navigate({ to: '/tournaments' });
        } else {
          localStorage.setItem(TERMS_AND_CONDITIONS_IN_PROGRESS, 'yes');
        }
      }
    }
  }, [acceptedDataIsFetched, acceptedData, navigate, termsAndConditions]);

  const submitAccepted = (versions) => {
    submitAcceptedMutate(versions);
  };

  if (acceptedError)
    return (
      <Box p={10}>
        <Alert status="error" message="An error has occurred, please try refreshing the page" />
      </Box>
    );

  if (acceptedIsLoading || textsIsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Flex justify="center" mt="100px">
      <TermsAndConditionsForm
        termsAndConditionsTexts={textsData}
        submitAccepted={submitAccepted}
        submitError={submitError}
      />
    </Flex>
  );
}
