import { useEffect, useState } from 'react';

import { useMatch, useSearch } from '@tanstack/react-location';
import isEmpty from 'lodash/isEmpty';
import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { scoutTabTypes } from 'scout/_shared/scoutConstants';
import { scoutService } from 'scout/service/scoutService';
import { convertSearchParams } from 'scout/util/scoutUtil';
import {
  useScoutReportCourtGraphicsQuery,
  useScoutReportCourtStatsQuery
} from 'scoutReport/hooks/scoutReportQueryHooks';
import { getCourtSurface, getMatchesPerPage, isSectionIncluded } from 'scoutReport/util/scoutReportUtil';

import LoadingStatus from '_shared/components/LoadingStatus';
import { Box } from '_shared/designSystem/components';
import { isMatchReportState } from '_shared/globalState/atoms';
import useFeature from '_shared/utils/hooks/useFeature';
import { getAnyLoading, getLoadingData } from '_shared/utils/loadingUtil';

import CourtGraphicsPage from './pages/CourtGraphicsPage';
import CourtStatsPage from './pages/CourtStatsPage';
import FrontPage from './pages/FrontPage';
import GroundstrokesCourtPage from './pages/GroundstrokesCourtPage';
import InsightsPage from './pages/InsightsPage';
import MatchesPage from './pages/MatchesPage';
import ReturnPlacementPage from './pages/ReturnPlacementPage';
import ServeEffectivenessPage from './pages/ServeEffectivenessPage';
import WinningAndLosingPlaysPageV2 from './pages/WinningAndLosingPlaysPageV2';

export default function ScoutReport() {
  const {
    params: { playerId }
  } = useMatch();
  const serveEffectivenessEnabled = useFeature('serveEffectiveness');

  const setIsMatchReport = useSetRecoilState(isMatchReportState);
  const winningAndLosingPlaysEnabledV2 = useFeature('winningAndLosingPlaysV2');

  useEffect(() => {
    setIsMatchReport(true);
  }, [setIsMatchReport]);

  const searchParams = useSearch();

  const [scoutFormValues, setScoutFormValues] = useState({});

  const fetchParams = { enabled: !isEmpty(scoutFormValues), staleTime: Infinity };

  useEffect(() => {
    const formValues = convertSearchParams(searchParams);
    setScoutFormValues(formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: playerDetailsData, isLoading: playerDetailsIsLoading } = useQuery(
    ['scoutService_getPlayerDetails'],
    () => scoutService.getPlayerDetails({ playerId })
  );

  const { data: matchesSelectedData, isLoading: matchesSelectedIsLoading } = useQuery(
    ['scoutService_getMatchSelectorData', scoutFormValues],
    () => scoutService.getMatchSelectorData({ playerId, scoutFormValues }),
    fetchParams
  );

  const { data: insightsDataWithAverages, insightsWithAveragesIsLoading } = useQuery(
    ['scoutService_getInsightsWithAverages', scoutFormValues],
    () => scoutService.getInsights({ playerId, scoutFormValues, averages: true }),
    fetchParams
  );

  const { data: courtStatsDataServeWithAverages, isLoading: courtStatsServeWithAveragesIsLoading } =
    useScoutReportCourtStatsQuery(playerId, 'serve_direction', scoutFormValues, fetchParams);

  const { data: courtStatsDataReturnWithAverages, isLoading: courtStatsReturnWithAveragesIsLoading } =
    useScoutReportCourtStatsQuery(playerId, 'return_placement', scoutFormValues, fetchParams);

  const { data: courtStatsDataGroundstrokesWithAverages, isLoading: courtStatsGroundstrokesWithAveragesIsLoading } =
    useScoutReportCourtStatsQuery(playerId, 'rally_placement', scoutFormValues, fetchParams);

  const { data: courtGraphicsData1stServe, isLoading: courtGraphics1stServeIsLoading } =
    useScoutReportCourtGraphicsQuery(playerId, 'serve_direction', scoutFormValues, '&serve=1', fetchParams);

  const { data: courtGraphicsData1stServePressure, isLoading: courtGraphics1stServePressureIsLoading } =
    useScoutReportCourtGraphicsQuery(
      playerId,
      'serve_direction',
      scoutFormValues,
      '&serve=1&pressure_break=pressure',
      fetchParams
    );

  const { data: courtGraphicsData1stServeBreak, isLoading: courtGraphics1stServeBreakIsLoading } =
    useScoutReportCourtGraphicsQuery(
      playerId,
      'serve_direction',
      scoutFormValues,
      '&serve=1&pressure_break=break',
      fetchParams
    );

  const { data: courtGraphicsData2ndServe, isLoading: courtGraphics2ndServeIsLoading } =
    useScoutReportCourtGraphicsQuery(playerId, 'serve_direction', scoutFormValues, '&serve=2', fetchParams);

  const { data: courtGraphicsData2ndServePressure, isLoading: courtGraphics2ndServePressureIsLoading } =
    useScoutReportCourtGraphicsQuery(
      playerId,
      'serve_direction',
      scoutFormValues,
      '&serve=2&pressure_break=pressure',
      fetchParams
    );

  const { data: courtGraphicsData2ndServeBreak, isLoading: courtGraphics2ndServeBreakIsLoading } =
    useScoutReportCourtGraphicsQuery(
      playerId,
      'serve_direction',
      scoutFormValues,
      '&serve=2&pressure_break=break',
      fetchParams
    );

  const { data: courtGraphicsData1stReturn, isLoading: courtGraphics1stReturnIsLoading } =
    useScoutReportCourtGraphicsQuery(playerId, 'return_placement', scoutFormValues, '&serve=1', fetchParams);

  const { data: courtGraphicsData2ndReturn, isLoading: courtGraphics2ndReturnIsLoading } =
    useScoutReportCourtGraphicsQuery(playerId, 'return_placement', scoutFormValues, '&serve=2', fetchParams);

  const { data: courtGraphicsDataRallyForehand, isLoading: courtGraphicsRallyForehandIsLoading } =
    useScoutReportCourtGraphicsQuery(playerId, 'rally_placement', scoutFormValues, '&shot_type=forehand', fetchParams);

  const { data: courtGraphicsDataRallyBackhand, isLoading: courtGraphicsRallyBackhandIsLoading } =
    useScoutReportCourtGraphicsQuery(playerId, 'rally_placement', scoutFormValues, '&shot_type=backhand', fetchParams);

  const {
    data: courtGraphicsDataRallyForehandServePlusOne,
    isLoading: courtGraphicsRallyForehandIsLoadingServePlusOne
  } = useScoutReportCourtGraphicsQuery(
    playerId,
    'rally_placement',
    scoutFormValues,
    '&serve_plus_one=yes&shot_type=forehand',
    fetchParams
  );

  const {
    data: courtGraphicsDataRallyBackhandServePlusOne,
    isLoading: courtGraphicsRallyBackhandIsLoadingServePlusOne
  } = useScoutReportCourtGraphicsQuery(
    playerId,
    'rally_placement',
    scoutFormValues,
    '&serve_plus_one=yes&shot_type=backhand',
    fetchParams
  );

  const { data: winningAndLosingPlaysV2, isLoading: winningAndLosingPlaysV2IsLoading } = useQuery(
    ['scoutService_getWinningAndLosingPlaysV2', scoutFormValues],
    () =>
      scoutService.getWinningAndLosingPlaysV2({
        playerId,
        scoutFormValues
      }),
    { ...fetchParams, enabled: !isEmpty(scoutFormValues) && winningAndLosingPlaysEnabledV2 }
  );

  const { data: winningAndLosingPlaysV2Opponents, isLoading: winningAndLosingPlaysV2OpponentsIsLoading } = useQuery(
    ['scoutService_getWinningAndLosingPlaysV2Opponents', scoutFormValues],
    () =>
      scoutService.getWinningAndLosingPlaysV2({
        playerId,
        scoutFormValues,
        playerType: 'opponents'
      }),
    { ...fetchParams, enabled: !isEmpty(scoutFormValues) && winningAndLosingPlaysEnabledV2 }
  );

  const loadingArray = {
    playerDetailsIsLoading: playerDetailsIsLoading,
    matchesSelectedIsLoading: matchesSelectedIsLoading,
    insightsWithAveragesIsLoading: insightsWithAveragesIsLoading,
    courtStatsServeWithAveragesIsLoading: courtStatsServeWithAveragesIsLoading,
    courtStatsReturnWithAveragesIsLoading: courtStatsReturnWithAveragesIsLoading,
    courtStatsGroundstrokesWithAveragesIsLoading: courtStatsGroundstrokesWithAveragesIsLoading,
    courtGraphics1stServeIsLoading: courtGraphics1stServeIsLoading,
    courtGraphics1stServePressureIsLoading: courtGraphics1stServePressureIsLoading,
    courtGraphics1stServeBreakIsLoading: courtGraphics1stServeBreakIsLoading,
    courtGraphics2ndServeIsLoading: courtGraphics2ndServeIsLoading,
    courtGraphics2ndServePressureIsLoading: courtGraphics2ndServePressureIsLoading,
    courtGraphics2ndServeBreakIsLoading: courtGraphics2ndServeBreakIsLoading,
    courtGraphics1stReturnIsLoading: courtGraphics1stReturnIsLoading,
    courtGraphics2ndReturnIsLoading: courtGraphics2ndReturnIsLoading,
    courtGraphicsRallyForehandIsLoading: courtGraphicsRallyForehandIsLoading,
    courtGraphicsRallyBackhandIsLoading: courtGraphicsRallyBackhandIsLoading,
    courtGraphicsRallyForehandServePlusOneIsLoading: courtGraphicsRallyForehandIsLoadingServePlusOne,
    courtGraphicsRallyBackhandServePlusOneIsLoading: courtGraphicsRallyBackhandIsLoadingServePlusOne
  };

  if (winningAndLosingPlaysEnabledV2) {
    loadingArray['winningAndLosingPlaysV2IsLoading'] = winningAndLosingPlaysV2IsLoading;
    loadingArray['winningAndLosingPlaysV2OpponentsIsLoading'] = winningAndLosingPlaysV2OpponentsIsLoading;
  }

  const loadingData = getLoadingData(loadingArray);
  const Loading = <LoadingStatus data={loadingData} />;

  const playerName = playerDetailsData
    ? `${playerDetailsData?.player_first_name} ${playerDetailsData?.player_last_name}`
    : '';
  const matchesPerPage = getMatchesPerPage(matchesSelectedData);
  const surface = getCourtSurface(matchesSelectedData);

  const Report = (
    <Box>
      <FrontPage data={playerDetailsData} />
      {matchesPerPage.map((item, index) => (
        <MatchesPage key={index} playerName={playerName} data={item} page={index} />
      ))}
      {isSectionIncluded(searchParams?.reportSections, scoutTabTypes.INSIGHTS) && (
        <InsightsPage playerName={playerName} insightsDataWithAverages={insightsDataWithAverages} />
      )}
      {serveEffectivenessEnabled && (
        <ServeEffectivenessPage playerName={playerName} insightsDataWithAverages={insightsDataWithAverages} />
      )}
      {isSectionIncluded(searchParams?.reportSections, scoutTabTypes.SERVE) && (
        <>
          <CourtGraphicsPage
            playerName={playerName}
            heading="1st Serve"
            surface={surface}
            graphicType="serve_direction"
            courtGraphicsDataNoAverages={courtGraphicsData1stServe}
            courtGraphicsPressureData={courtGraphicsData1stServePressure}
            courtGraphicsBreakData={courtGraphicsData1stServeBreak}
          />
          <CourtGraphicsPage
            playerName={playerName}
            heading="2nd Serve"
            surface={surface}
            graphicType="serve_direction"
            courtGraphicsDataNoAverages={courtGraphicsData2ndServe}
            courtGraphicsPressureData={courtGraphicsData2ndServePressure}
            courtGraphicsBreakData={courtGraphicsData2ndServeBreak}
          />
          <CourtStatsPage
            playerName={playerName}
            heading="Serve Statistics"
            courtStatsDataNoAverages={courtStatsDataServeWithAverages}
            courtStatsDataWithAverages={courtStatsDataServeWithAverages}
          />
        </>
      )}
      {isSectionIncluded(searchParams?.reportSections, scoutTabTypes.RETURN) && (
        <>
          <ReturnPlacementPage
            playerName={playerName}
            courtGraphicsData1stReturn={courtGraphicsData1stReturn}
            courtGraphicsData2ndReturn={courtGraphicsData2ndReturn}
            surface={surface}
          />
          <CourtStatsPage
            playerName={playerName}
            heading="Return Statistics"
            courtStatsDataNoAverages={courtStatsDataReturnWithAverages}
            courtStatsDataWithAverages={courtStatsDataReturnWithAverages}
          />
        </>
      )}
      {isSectionIncluded(searchParams?.reportSections, scoutTabTypes.GROUNDSTROKES) && (
        <>
          <GroundstrokesCourtPage
            playerName={playerName}
            courtGraphicsDataRallyForehand={courtGraphicsDataRallyForehandServePlusOne}
            courtGraphicsDataRallyBackhand={courtGraphicsDataRallyBackhandServePlusOne}
            surface={surface}
            pageHeading="Serve +1"
            firstSubHeading="Forehand Placement"
            secondSubHeading="Backhand Placement"
          />
          <GroundstrokesCourtPage
            playerName={playerName}
            courtGraphicsDataRallyForehand={courtGraphicsDataRallyForehand}
            courtGraphicsDataRallyBackhand={courtGraphicsDataRallyBackhand}
            surface={surface}
            pageHeading="Groundstrokes"
            firstSubHeading="Forehand Placement"
            secondSubHeading="Backhand Placement"
          />
          <CourtStatsPage
            playerName={playerName}
            heading="Groundstrokes Statistics"
            courtStatsDataNoAverages={courtStatsDataGroundstrokesWithAverages}
            courtStatsDataWithAverages={courtStatsDataGroundstrokesWithAverages}
          />
        </>
      )}
      {winningAndLosingPlaysEnabledV2 &&
        isSectionIncluded(searchParams?.reportSections, scoutTabTypes.WINNING_AND_LOSING_PLAYS) && (
          <>
            <WinningAndLosingPlaysPageV2
              playerName={playerName}
              heading={`${playerDetailsData?.player_last_name} Frequency of Plays`}
              data={winningAndLosingPlaysV2}
              playerDetailsData={playerDetailsData}
              volumeType="volume"
              surface={surface}
            />
            <WinningAndLosingPlaysPageV2
              playerName={playerName}
              heading={`${playerDetailsData?.player_last_name} Impact of Plays`}
              data={winningAndLosingPlaysV2}
              playerDetailsData={playerDetailsData}
              volumeType="effectiveness"
              surface={surface}
            />
            <WinningAndLosingPlaysPageV2
              playerName={playerName}
              heading="Opponents Frequency of Plays"
              data={winningAndLosingPlaysV2Opponents}
              playerDetailsData={playerDetailsData}
              volumeType="volume"
              surface={surface}
              playerType="opponents"
            />
            <WinningAndLosingPlaysPageV2
              playerName={playerName}
              heading="Opponents Impact of Plays"
              data={winningAndLosingPlaysV2Opponents}
              playerDetailsData={playerDetailsData}
              volumeType="effectiveness"
              surface={surface}
              playerType="opponents"
            />
          </>
        )}
    </Box>
  );

  return getAnyLoading(loadingData) ? Loading : Report;
}
