import { WIMB_REDIRECT_URL } from '_customSites/wimb/constants/wimbAuthConsts';
import { isWimbMyWSession, setMyWRedirectPath } from '_customSites/wimb/services/wimbLocalStorage';
import { isWimbTvSession } from '_customSites/wimb/services/wimbLocalStorage';
import { removeMyWAuthTokens } from '_customSites/wimb/services/wimbLocalStorage';
import { refreshAccessTokenUsingMyWRefreshToken } from '_customSites/wimb/services/wimbMyWRefresh';
import axios from 'axios';

import customSitesConfig from '_shared/config/customSitesConfig';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import { getLocalStorageAuthTokens } from '_shared/utils/localStorage';

import { refreshUserTokens } from './auth';
import { getApiUrl } from './urlConfig';

export const apiClient = axios.create({
  baseURL: getApiUrl(),
  withCredentials: true,
  params: {}
});

apiClient.interceptors.request.use((config) => {
  const token = getLocalStorageAuthTokens();
  if (token.accessToken) {
    config.headers['Authorization'] = `Bearer ${token.accessToken}`;
  }
  return config;
});

let refreshPromise = null;
const clearPromise = () => (refreshPromise = null);

apiClient.interceptors.response.use(undefined, async (error) => {
  const config = error.config;
  if (error?.response?.status === 401 && !config._retry) {
    config._retry = true;
    if (!refreshPromise) {
      refreshPromise = isWimbMyWSession()
        ? refreshAccessTokenUsingMyWRefreshToken().finally(clearPromise)
        : refreshUserTokens().finally(clearPromise);
    }
    const res = await refreshPromise;
    if (res.status !== 200) {
      if (res !== 'refreshInProgress') {
        if (isWimbPortal() && !isWimbTvSession()) {
          removeMyWAuthTokens();
          setMyWRedirectPath(window.location.pathname + window.location.search);
          window.location.replace(WIMB_REDIRECT_URL);
        } else {
          if (isWimbPortal()) {
            window.location.href = '/login';
          } else {
            const currentPath = window.location.pathname;
            const base64path = btoa(currentPath);
            const logoutUrl = `${customSitesConfig.logoutUrlPath}?r=${base64path}`;
            window.location.href = logoutUrl;
          }
          return Promise.resolve('User logged out');
        }
      } else {
        return Promise.resolve('Refresh currently in progress');
      }
    }
    return apiClient(config);
  }
  return Promise.reject(error);
});
