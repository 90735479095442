import { displayScore } from 'match/utils/transformations';
import PageHeading from 'matchReport/components/_shared/PageHeading';
import { useMatchReportSetsQuery } from 'matchReport/hooks/matchReportHooks';

import { Box } from '_shared/designSystem/components';

import MatchReportTable from './courtPages/MatchReportTable';

export default function SetComparison(props) {
  const { headerData, matchId, player, performanceDataAllSets } = props;

  const setsCount = headerData?.match_score?.all_scores?.length;

  const setHeadings = getColumnHeadings(setsCount);

  const {
    data: set1Data,
    error: set1Error,
    isLoading: set1IsLoading
  } = useMatchReportSetsQuery(matchId, 1, { enabled: !!headerData && setsCount >= 1 });
  const {
    data: set2Data,
    error: set2Error,
    isLoading: set2IsLoading
  } = useMatchReportSetsQuery(matchId, 2, { enabled: !!headerData && setsCount >= 2 });
  const {
    data: set3Data,
    error: set3Error,
    isLoading: set3IsLoading
  } = useMatchReportSetsQuery(matchId, 3, { enabled: !!headerData && setsCount >= 3 });
  const {
    data: set4Data,
    error: set4Error,
    isLoading: set4IsLoading
  } = useMatchReportSetsQuery(matchId, 4, { enabled: !!headerData && setsCount >= 4 });
  const {
    data: set5Data,
    error: set5Error,
    isLoading: set5IsLoading
  } = useMatchReportSetsQuery(matchId, 5, { enabled: !!headerData && setsCount >= 5 });

  if (set1IsLoading || set2IsLoading || set3IsLoading || set4IsLoading || set5IsLoading) return null;

  if (set1Error || set2Error || set3Error || set4Error || set5Error)
    throw new Error('Unable to retrieve set comparison data');

  const setsData = {
    set0: performanceDataAllSets,
    set1: set1Data,
    set2: set2Data,
    set3: set3Data,
    set4: set4Data,
    set5: set5Data
  };

  const tableData = getAllTableData(setsData, setsCount, player);

  return (
    <Box>
      <PageHeading>{headerData?.player_details?.[`${player}`]?.member1?.last_name} Set Comparison</PageHeading>
      <MatchReportTable headings={setHeadings} tableData={tableData} size="sm" rowHeight="48px" />
    </Box>
  );
}

export function getColumnHeadings(setsCount) {
  const result = ['', 'Match'];
  for (let i = 1; i <= setsCount; i++) {
    result.push(`Set ${i}`);
  }
  return result;
}

const keys = [
  {
    key: '1st_serve_in',
    heading: '1st Serve In %'
  },
  {
    key: '1st_serve_points_won',
    heading: '1st Serve Points Won'
  },
  {
    key: '2nd_serve_points_won',
    heading: '2nd Serve Points Won'
  },
  {
    key: 'avg_1st_serve_speed',
    heading: 'Avg 1st Serve Speed'
  },
  {
    key: 'serve_quality',
    heading: 'Serve Quality'
  },
  {
    key: '1st_serve_return_points_won',
    heading: '1st Serve Return Points Won'
  },
  {
    key: '2nd_serve_return_points_won',
    heading: '2nd Serve Return Points Won'
  },
  {
    key: '1st_serve_returns_in',
    heading: '1st Serve Returns In'
  },
  {
    key: '2nd_serve_returns_in',
    heading: '2nd Serve Returns In'
  },
  {
    key: '1st_serve_return_speed',
    heading: '1st Serve Return Speed'
  },
  {
    key: '1st_serve_return_spin',
    heading: '1st Serve Return Spin'
  },
  {
    key: 'break_points_won',
    heading: 'Break Points Won'
  },
  {
    key: 'return_quality',
    heading: 'Return Quality'
  },
  {
    key: 'in_attack',
    heading: 'In Attack'
  },
  {
    key: 'conversion_score',
    heading: 'Conversion Score'
  },
  {
    key: 'steal_score',
    heading: 'Steal Score'
  },
  {
    key: 'battle_baseline_win',
    heading: 'Battle Baseline Win %'
  },
  {
    key: 'at_net_win',
    heading: 'At Net Win %'
  },
  {
    key: 'opponent_at_net_win',
    heading: 'Opponent At Net Win %'
  },
  {
    key: 'forehand_quality',
    heading: 'Forehand Quality'
  },
  {
    key: 'forehands_in',
    heading: 'Forehands In'
  },
  {
    key: 'forehand_speed',
    heading: 'Forehand Speed'
  },
  {
    key: 'backhand_quality',
    heading: 'Backhand Quality'
  },
  {
    key: 'backhands_in',
    heading: 'Backhands In'
  },
  {
    key: 'backhand_speed',
    heading: 'Backhand Speed'
  }
];

export function getValueObjects(data, player) {
  const result = {
    '1st_serve_in': data?.match_statistics?.serve['1st_serve_in'],
    '1st_serve_points_won': data?.match_statistics?.serve['1st_serve_points_won'],
    '2nd_serve_points_won': data?.match_statistics?.serve['2nd_serve_points_won'],
    avg_1st_serve_speed: data?.match_statistics?.serve['avg_1st_serve_speed'],
    serve_quality: data?.comparison_metrics?.shot_quality?.serve,
    '1st_serve_return_points_won': data?.match_statistics?.return['1st_serve_return_points_won'],
    '2nd_serve_return_points_won': data?.match_statistics?.return['2nd_serve_return_points_won'],
    '1st_serve_returns_in': data?.match_statistics?.return['1st_serve_returns_in'],
    '2nd_serve_returns_in': data?.match_statistics?.return['2nd_serve_returns_in'],
    '1st_serve_return_speed': data?.match_statistics?.return['1st_serve_return_speed'],
    '1st_serve_return_spin': data?.match_statistics?.return['1st_serve_return_spin'],
    break_points_won: data?.match_statistics?.return?.break_points_won,
    return_quality: data?.comparison_metrics?.shot_quality?.return,
    in_attack: data?.battles?.balance_of_power,
    conversion_score: data?.comparison_metrics?.capitalisation?.conversion,
    steal_score: data?.comparison_metrics?.capitalisation?.steal,
    battle_baseline_win: data?.battles?.baseline,
    at_net_win: data?.battles[`${player}_at_net`],
    opponent_at_net_win: data?.battles[`${player === 'player1' ? 'player2' : 'player1'}_at_net`],
    forehand_quality: data?.comparison_metrics?.shot_quality?.forehand,
    forehand_speed: data?.match_statistics?.rally?.forehand_speed,
    backhand_quality: data?.comparison_metrics?.shot_quality?.backhand,
    backhand_speed: data?.match_statistics?.rally?.backhand_speed,
    forehands_in: data?.match_statistics?.rally?.forehands_in,
    backhands_in: data?.match_statistics?.rally?.backhands_in
  };
  return result;
}

export function getAllTableData(setsData, setsCount, player) {
  const allData = [];
  keys.forEach((key) => {
    const row = {};
    row.heading = key.heading;
    const data = [];

    for (let i = 0; i <= setsCount; i++) {
      const setData = setsData?.[`set${i}`];
      const setDataAsFields = getValueObjects(setData?.performance, player);
      const keyObject = setDataAsFields?.[`${key.key}`];
      const scoreToDisplay = displayScore(keyObject?.[`${player}`]?.score, keyObject?.[`${player}`]?.score_type);
      data.push(scoreToDisplay);
    }
    row.data = data;
    allData.push(row);
  });
  return allData;
}
