import { isAtpTourPortal, isWimbPortal } from '_shared/utils/environment/currentEnv';

function getBorderRadius() {
  if (isWimbPortal() || isAtpTourPortal()) {
    return {
      none: '0',
      sm: '0',
      base: '0',
      md: '0',
      lg: '0',
      xl: '0',
      '2xl': '0',
      '3xl': '0',
      full: '9999px'
    };
  }
  return {
    none: '0',
    xs: '2px',
    sm: '4px',
    md: '6px',
    lg: '8px',
    xl: '12px',
    '2xl': '16px',
    '3xl': '24px',
    full: '9999px'
  };
}

export const borderRadius = {
  radii: getBorderRadius()
};

// used in styles outside of Chakra
export const borderRadiusRaw = getBorderRadius();
