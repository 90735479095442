import ScoutReport from 'scoutReport/components/ScoutReport';

const routes = {
  path: 'scout-report',
  element: null,
  children: [
    {
      path: ':playerId',
      element: <ScoutReport />
    }
  ]
};

export default routes;
