import isEmpty from 'lodash/isEmpty';
import MatchSelectorModalButton from 'scout/components/matchSelector/MatchSelectorModalButton';

import customSitesConfig from '_shared/config/customSitesConfig';
import { formFieldTypes } from '_shared/designSystem/components/form/formTypes';
import { isWimbPortal, isTvPortal } from '_shared/utils/environment/currentEnv';

export function getScoutSeasonsOnlyForm(seasonsActive) {
  if (isEmpty(seasonsActive)) return [];
  let seasonsOptions = [];
  const seasonLabel = isWimbPortal ? 'Championships' : 'Seasons';
  seasonsActive.forEach((item) =>
    seasonsOptions.push({
      label: item.toString(),
      id: item.toString()
    })
  );
  return [
    {
      type: formFieldTypes.SELECT_MULTI,
      label: seasonLabel,
      fieldId: 'seasons',
      options: seasonsOptions
    },
    {
      type: formFieldTypes.SUBMIT_BUTTON,
      label: 'Apply',
      fieldId: 'submitButton'
    }
  ];
}

export function getScoutHeaderForm(seasonsActive, includeSubmit, openMatchSelector, noOfSelectedMatches) {
  if (isEmpty(seasonsActive)) return [];
  let seasonsOptions = [];
  seasonsActive.forEach((item) =>
    seasonsOptions.push({
      label: item.toString(),
      id: item.toString()
    })
  );

  if (customSitesConfig.scoutFiltersOnlySeason) {
    return [
      {
        type: formFieldTypes.SELECT_MULTI,
        label: isWimbPortal() ? 'Championships' : 'Seasons',
        fieldId: 'seasons',
        options: seasonsOptions
      },
      {
        type: formFieldTypes.CUSTOM_COMPONENT,
        fieldId: 'matchSelectorButton',
        component: (
          <MatchSelectorModalButton openMatchSelector={openMatchSelector} noOfSelectedMatches={noOfSelectedMatches} />
        )
      },
      {
        type: formFieldTypes.SUBMIT_BUTTON,
        label: 'Apply',
        fieldId: 'submitButton'
      }
    ];
  }

  const result = [
    {
      type: formFieldTypes.SELECT_MULTI,
      label: 'Seasons',
      fieldId: 'seasons',
      options: seasonsOptions
    }
  ];
  if (customSitesConfig.showScoutSurfaceAndLast52WeeksFilter) {
    result.push({
      type: formFieldTypes.BOOLEAN,
      label: 'Last 52 Weeks',
      fieldId: 'last52Weeks'
    });
    result.push({
      type: formFieldTypes.SELECT_MULTI,
      label: 'Surfaces',
      fieldId: 'surfaces',
      options: [
        {
          label: 'Indoor Hard',
          id: 'indoor_hard'
        },
        {
          label: 'Outdoor Hard',
          id: 'outdoor_hard'
        },
        {
          label: 'Clay',
          id: 'clay'
        },
        {
          label: 'Grass',
          id: 'grass'
        }
      ]
    });
  }
  result.push({
    type: formFieldTypes.TEXT,
    label: 'Last No. Matches',
    fieldId: 'lastNoMatches'
  });
  if (includeSubmit) {
    result.push({
      type: formFieldTypes.CUSTOM_COMPONENT,
      fieldId: 'matchSelectorButton',
      component: (
        <MatchSelectorModalButton openMatchSelector={openMatchSelector} noOfSelectedMatches={noOfSelectedMatches} />
      )
    });
  }
  if (includeSubmit) {
    result.push({
      type: formFieldTypes.SUBMIT_BUTTON,
      label: 'Apply',
      fieldId: 'submitButton'
    });
  }
  return result;
}

export function getInitialMatchHeaderForm() {
  if (customSitesConfig.showScoutSurfaceAndLast52WeeksFilter) {
    return {
      seasons: [],
      last52Weeks: true,
      surfaces: [],
      lastNoMatches: ''
    };
  } else {
    return {
      seasons: [],
      lastNoMatches: ''
    };
  }
}

export function getQueryParams(location) {
  const href = location.current.href;
  return href.replace('/scout/', '');
}

export function getNumberSelectedMatches(scoutFormValues) {
  if (isEmpty(scoutFormValues)) return 0;
  if (isEmpty(scoutFormValues.matchIds)) return 0;
  return scoutFormValues.matchIds.length;
}

export function getPlayerHeadshotImage(data, isMatchReport) {
  if (isEmpty(data)) return null;
  if (isWimbPortal()) return data?.image_url_wimb;
  if (isTvPortal() && isMatchReport) return data?.image_url_tv;
  return data?.image_url_atptour;
}
