import PropTypes from 'prop-types';

import colors from '_shared/designSystem/theme/colors';

export default function CourtSurface2dFull(props) {
  const { surface, viewBoxSize } = props;

  const surfaceColor = colors?.court?.surface[`${surface}`]?.hex;
  const startingOpacity = colors?.court?.surface[`${surface}`]?.opacityStart;
  const finishingOpacity = colors?.court?.surface[`${surface}`]?.opacityEnd;

  return (
    <svg
      data-testid="courtSurface2dFull"
      width="100%"
      height="100%"
      viewBox={viewBoxSize || '0 0 950 1600'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {props.isWideCourt ? (
        <g>
          <g style={{ WebkitTransform: `scale(1, -1) translateY(-800px) translateX(-87px)` }}>
            <path d="M855 1.65256H87 V630 H1035 V1.65256Z" fill="url(#paint0_linear_10150_388300)" />
            <path d="M75.698 2.30513H178.785 V620 H1034.698V2.30513Z" fill="url(#paint2_linear_10150_388300)" />

            <path d="M200 0 H88.298 V630 H200V1Z" stroke="white" strokeWidth="2" />
            <path d="M200 0 H1036 V630 H937V1Z" stroke="white" strokeWidth="2" />
            <path d="M200 0 H36 V630 H937V1Z" stroke="white" strokeWidth="2" />
            <path d="M200 339 L938 339" stroke="white" strokeWidth="2" />
            <path d="M564 0 L564 339" stroke="white" strokeWidth="2" />
            {props.hasDashedLine && (
              <line
                x1="564"
                y1="630"
                x2="564"
                y2="345"
                stroke={colors.warning[200]}
                strokeWidth="4"
                strokeDasharray="5, 15"
              />
            )}
          </g>
          <g transform="translate(-87, 800)">
            <path d="M855 1.65256H87 V630 H1035 V1.65256Z" fill="url(#paint0_linear_10150_388300)" />
            <path d="M75.698 2.30513H178.785 V620 H1034.698V2.30513Z" fill="url(#paint2_linear_10150_388300)" />

            <path d="M200 0 H88.298 V630 H200V1Z" stroke="white" strokeWidth="2" />
            <path d="M200 0 H1036 V630 H937V1Z" stroke="white" strokeWidth="2" />
            <path d="M200 0 H36 V630 H937V1Z" stroke="white" strokeWidth="2" />
            <path d="M200 339 L938 339" stroke="white" strokeWidth="2" />
            <path d="M564 0 L564 339" stroke="white" strokeWidth="2" />
          </g>
        </g>
      ) : (
        <g>
          <g style={{ WebkitTransform: `scale(1, -1) translateY(-800px) translateX(-87px)` }}>
            <path d="M855 1.65256H273 V630 H855 V1.65256Z" fill="url(#paint0_linear_10150_388300)" />
            <path d="M273 1H345.298 V630 H273V1Z" fill="url(#paint1_linear_10150_388300)" />
            <path d="M854.698 2.30513H778.785 V620 H854.698V2.30513Z" fill="url(#paint2_linear_10150_388300)" />

            <path d="M855 0 H273 V630 H855V1.65256Z" stroke="white" strokeWidth="2" />
            <path d="M273 0 H345.298 V630 H273V1Z" stroke="white" strokeWidth="2" />
            <path d="M854.698 0 H778.785 V630 H854.698V2.30513Z" stroke="white" strokeWidth="2" />
            <path d="M345 339 L778 339" stroke="white" strokeWidth="2" />
            <path d="M564 0 L564 339" stroke="white" strokeWidth="2" />
            {props.hasDashedLine && (
              <line
                x1="564"
                y1="630"
                x2="564"
                y2="345"
                stroke={colors.warning[200]}
                strokeWidth="2"
                strokeDasharray="5, 15"
              />
            )}
          </g>
          <g transform="translate(-87, 800)">
            <path d="M855 1.65256H273 V630 H855 V1.65256Z" fill="url(#paint0_linear_10150_388300)" />
            <path d="M273 1H345.298 V630 H273V1Z" fill="url(#paint1_linear_10150_388300)" />
            <path d="M854.698 2.30513H778.785 V620 H854.698V2.30513Z" fill="url(#paint2_linear_10150_388300)" />

            <path d="M855 0 H273 V630 H855V1.65256Z" stroke="white" strokeWidth="2" />
            <path d="M273 0 H345.298 V630 H273V1Z" stroke="white" strokeWidth="2" />
            <path d="M854.698 0 H778.785 V630 H854.698V2.30513Z" stroke="white" strokeWidth="2" />
            <path d="M345 339 L778 339" stroke="white" strokeWidth="2" />
            <path d="M564 0 L564 339" stroke="white" strokeWidth="2" />
          </g>
        </g>
      )}
      <defs>
        <linearGradient
          id="paint0_linear_10150_388300"
          x1="855"
          y1="1"
          x2="140.469"
          y2="282.015"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={surfaceColor} stopOpacity={finishingOpacity} />
          <stop offset={startingOpacity} stopColor={surfaceColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10150_388300"
          x1="855"
          y1="1"
          x2="140.469"
          y2="282.015"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={surfaceColor} stopOpacity={finishingOpacity} />
          <stop offset={startingOpacity} stopColor={surfaceColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10150_388300"
          x1="855"
          y1="1"
          x2="140.469"
          y2="282.015"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={surfaceColor} stopOpacity={finishingOpacity} />
          <stop offset={startingOpacity} stopColor={surfaceColor} />
        </linearGradient>
      </defs>
    </svg>
  );
}

CourtSurface2dFull.propTypes = {
  surface: PropTypes.oneOf(['hard', 'grass', 'clay']),
  isWideCourt: PropTypes.bool,
  hasDashedLine: PropTypes.bool
};

CourtSurface2dFull.defaultProps = {
  isWideCourt: false,
  hasDashedLine: false
};
