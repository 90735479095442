import { Image, Box, Center } from '_shared/designSystem/components';

import { atptourImageUrls } from '../util/atptourImageUtil';

export default function AtpTourLogo({ width, height, color, type }) {
  let url;
  if (type === 'stacked' && color === 'white') url = atptourImageUrls.ATP_TENNISIQ_LOGO_STACKED_WHITE;
  else if (type === 'stacked' && color === 'navy') url = atptourImageUrls.ATP_TENNISIQ_LOGO_STACKED_NAVY;
  else if (type === 'horizontal' && color === 'white') url = atptourImageUrls.ATP_TENNISIQ_LOGO_HORIZONTAL_WHITE;
  else url = atptourImageUrls.ATP_TENNISIQ_LOGO_HORIZONTAL_NAVY;

  return (
    <Box>
      <Image w={width} h={height} src={url} />
    </Box>
  );
}

export const AtpTourLoginLogo = () => (
  <Center h="50px" w="100%">
    <Image alt="tvLogoIcon" src={atptourImageUrls.ATP_TENNISIQ_LOGO_HORIZONTAL_NAVY} w="250px" />
  </Center>
);
