import { useEffect, useState } from 'react';

import { useNavigate, useSearch } from '@tanstack/react-location';

import { convertBooleanToYesNo } from '_shared/utils/stringUtil';
import { updateUrl } from '_shared/utils/urlUtil';

export const useWinningAndLosingPlaysSettings = (initialValues) => {
  const search = useSearch();
  const navigate = useNavigate();

  const [player, setPlayer] = useState(initialValues.player);
  const [playType, setPlayType] = useState(initialValues.playType);
  const [set, setSet] = useState(initialValues.set);
  const [volumeType, setVolumeType] = useState(initialValues.volumeType);
  const [showAverages, setShowAverages] = useState(initialValues.showAverages);
  const [playerType, setPlayerType] = useState(initialValues.playerType);

  useEffect(() => {
    if (search.player) setPlayer(search.player);
    if (search.set) setSet(search.set);
    if (search.playType) setPlayType(search.playType);
    if (search.volumeType) setVolumeType(search.volumeType);
    if (search.playerType) setPlayerType(search.playerType);
    if (search.showAverages !== undefined) {
      setShowAverages(search.showAverages === 'yes');
    }
  }, [search]);

  const handleAveragesToggle = (value) => {
    setShowAverages(value);
    updateUrl('showAverages', convertBooleanToYesNo(value), navigate);
  };

  const handleButtonClick = (paramName, value) => {
    let newValue;

    switch (paramName) {
      case 'player':
        setPlayer(value);
        updateUrl(paramName, value, navigate);
        break;
      case 'set':
        newValue = value === set ? 'all' : value;
        setSet(newValue);
        updateUrl(paramName, newValue, navigate);
        break;
      case 'playType':
        newValue = value === playType ? 'all' : value;
        setPlayType(newValue);
        updateUrl(paramName, newValue, navigate);
        break;
      case 'volumeType':
        setVolumeType(value);
        updateUrl(paramName, value, navigate);
        break;
      case 'playerType':
        setPlayerType(value);
        updateUrl(paramName, value, navigate);
        break;
      default:
        return null;
    }
  };

  return {
    state: {
      player,
      playType,
      set,
      volumeType,
      showAverages,
      playerType
    },
    handlers: {
      handleButtonClick,
      handleAveragesToggle
    }
  };
};
