import AverageBars from 'match/_shared/AverageBars';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Box, Collapse, Flex, Text } from '_shared/designSystem/components';
import { getComponentWithExtraProps } from '_shared/utils/componentUtils';

import { leftColumnContentWidth } from '../performanceMargins';

const BalanceOfPowerWithAverages = ({
  data,
  dataWithAverages,
  averagesLoading,
  showAverages,
  player1Name,
  player2Name
}) => {
  return (
    <Flex direction="column" gap={4} w={{ base: '100%', sm: `${leftColumnContentWidth}px` }}>
      <Flex justify="space-between" w="100%">
        <Text fontSize="sm" fontWeight="semibold">
          {player1Name}
        </Text>
        <Text fontSize="sm" fontWeight="semibold">
          {player2Name}
        </Text>
      </Flex>
      <Flex direction="column" gap={4}>
        {getComponentWithExtraProps(customSitesConfig.balanceOfPowerComponent, {
          leftPercentage: data?.balance_of_power?.player1?.score,
          middlePercentage: data?.balance_of_power?.neutral?.score,
          rightPercentage: data?.balance_of_power?.player2?.score
        })}
        <BattlesAverages
          data={dataWithAverages?.balance_of_power}
          showAverages={showAverages}
          averagesLoading={averagesLoading}
        />
      </Flex>
    </Flex>
  );
};

export const BattlesAverages = ({ data, showAverages, averagesLoading }) => (
  <Collapse in={showAverages} animateOpacity>
    <Flex justify="space-between">
      <Box w="145px">
        <AverageBars labelPosition="left" data={data?.player1} isLoading={averagesLoading} />
      </Box>
      <Box w="145px">
        <AverageBars labelPosition="left" data={data?.player2} isLoading={averagesLoading} />
      </Box>
    </Flex>
  </Collapse>
);

export default BalanceOfPowerWithAverages;
