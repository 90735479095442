import {
  TV_LOGO_ICON_GREEN_WHITE,
  TV_LOGO_LANDSCAPE_PURPLE,
  TV_LOGO_LANDSCAPE_WHITE
} from '_shared/constants/imageConsts';
import { Box, Center, Image } from '_shared/designSystem/components';

export default function TvLogo(props) {
  const { iconOnly, isPurpleLandscape, ...rest } = props;
  return (
    <Box>
      {iconOnly ? (
        <Image alt="tvLogoIcon" src={TV_LOGO_ICON_GREEN_WHITE} {...rest} />
      ) : (
        <Image
          alt="tvLogoLandscape"
          src={isPurpleLandscape ? TV_LOGO_LANDSCAPE_PURPLE : TV_LOGO_LANDSCAPE_WHITE}
          {...rest}
        />
      )}
    </Box>
  );
}

export function TvLoginLogo() {
  return (
    <Center h="80px" w="100%">
      <Image alt="tvLogoIcon" src={TV_LOGO_LANDSCAPE_PURPLE} w="250px" />
    </Center>
  );
}
