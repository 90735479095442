import React, { lazy, Suspense } from 'react';

import PropTypes from 'prop-types';

const IconComponent = lazy(() => import('./_Icon'));

export default function Icon({ fallback, ...props }) {
  return (
    <Suspense fallback={fallback || null}>
      <IconComponent {...props} />
    </Suspense>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  fallback: PropTypes.node,
  collection: PropTypes.oneOf(['standard'])
};
