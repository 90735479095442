import { videoPlaylistFields } from 'match/constants/videoConstants';
import PropTypes from 'prop-types';

import { Flex, ButtonGroup, Text, Select, Box } from '_shared/designSystem/components';
import { formFieldTypes } from '_shared/designSystem/components/form/formTypes';

export default function FiltersForm(props) {
  const { formFieldsData, formState, handleFilterSelect } = props;

  return (
    <Flex flexWrap="wrap" columnGap={4} rowGap={4}>
      {formFieldsData.map((item) => {
        switch (item.type) {
          case formFieldTypes.SELECT_SINGLE:
            return (
              <Flex align="flex-end" style={item.style} key={item.id}>
                <Box>
                  <Text color="grey.600" fontWeight="medium" fontSize="xs" ml={1}>
                    {item.label}
                  </Text>
                  <Select
                    value={formState[item.id] || 'all'}
                    onChange={(event) => handleFilterSelect(item.id, event.target.value)}
                    borderColor="grey.300"
                    size="xs"
                    h="32px"
                  >
                    {item.options.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>
            );

          case formFieldTypes.BUTTON_GROUP:
            return (
              <Flex align="flex-end" mr="-20px" key={item.id}>
                <Flex flexDirection="column" style={item.style}>
                  <Text color="grey.600" fontWeight="medium" fontSize="xs" ml={1}>
                    {item.label}
                  </Text>
                  <ButtonGroup
                    {...(item.id !== videoPlaylistFields.SETS && { selectedItem: formState[item.id] })}
                    {...(item.id === videoPlaylistFields.SETS && { selectedItemMultiple: formState[item.id] || [] })}
                    paramName={item.id}
                    data={item.data}
                    handleButtonClick={handleFilterSelect}
                  />
                </Flex>
              </Flex>
            );
          default:
            return null;
        }
      })}
    </Flex>
  );
}

FiltersForm.propTypes = {
  formFieldsData: PropTypes.array,
  formState: PropTypes.object,
  handleFilterSelect: PropTypes.func
};
