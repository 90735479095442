export const metricScoreType = {
  OUT_OF_TEN: 'out_of_ten',
  PERCENTAGE: 'percentage',
  MPH: 'mph',
  PERCENTAGE_ONE_DECIMAL: 'percentage_one_decimal',
  COUNT: 'count',
  DECIMAL: 'decimal',
  DECIMAL_TWO_PLACES: 'decimal_two_places',
  RPM: 'rpm',
  METRES: 'metres',
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  STRING: 'string'
};

export const averageType = {
  MATCH: 'MATCH',
  PLAYER_AVERAGE: 'PLAYER_AVERAGE',
  TOUR_AVERAGE: 'TOUR_AVERAGE'
};
