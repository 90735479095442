import PropTypes from 'prop-types';
import { patternsOfPlayPattern } from 'scout/_shared/scoutConstants';

import { Box } from '_shared/designSystem/components';
import CourtArrow from '_shared/designSystem/components/court/CourtArrow';
import colors from '_shared/designSystem/theme/colors';

import { ARROW_PATTERN_TYPES, ARROW_PATTERN_TYPES_WIDE_COURT } from '../../constants';

const PatternsOfPlayCourtArrows = ({ pattern, color, isWideCourt }) => {
  const arrows = isWideCourt ? ARROW_PATTERN_TYPES_WIDE_COURT[pattern] : ARROW_PATTERN_TYPES[pattern];

  const Arrow = (props) => (
    <Box position="absolute" h="500px" data-testid="court-arrows">
      <CourtArrow {...props} gap={isWideCourt ? 18 : 13} />
    </Box>
  );

  return (
    <>
      {arrows.map((arrowProps, index) => (
        <Arrow key={index} color={color} {...arrowProps} />
      ))}
    </>
  );
};

PatternsOfPlayCourtArrows.propTypes = {
  pattern: PropTypes.oneOf(Object.values(patternsOfPlayPattern)),
  color: PropTypes.string,
  isWideCourt: PropTypes.bool
};

PatternsOfPlayCourtArrows.defaultProps = {
  pattern: 'control_the_middle',
  color: colors.grey[300],
  isWideCourt: false
};

export default PatternsOfPlayCourtArrows;
