import { useNavigate } from '@tanstack/react-location';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { MATCH_SELECTOR_COLUMNS } from 'scout/_shared/scoutConstants';

import customSitesConfig from '_shared/config/customSitesConfig';
import {
  Box,
  Table,
  Th,
  Thead,
  Tr,
  Tbody,
  Td,
  SurfaceBadge,
  Center,
  Icon,
  Spinner,
  Flex,
  Text
} from '_shared/designSystem/components';
import { isMatchReportState } from '_shared/globalState/atoms';
import { formatDate, formatDateYearOnly } from '_shared/utils/dateUtil';
import { navigateTo } from '_shared/utils/navigationUtils';
import { capitaliseFirstLetterOfString } from '_shared/utils/stringUtil';

export default function ScoutMatchSelectorTable(props) {
  const { columnHeadings, data, selectRow, selectAll, selectedAll, matchIds, matchSelectorLoading } = props;

  const isMatchReport = useRecoilValue(isMatchReportState);

  const navigate = useNavigate();

  if (matchSelectorLoading)
    return (
      <Flex justify="center" py="40px">
        <Spinner color="primary.500" />
      </Flex>
    );

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            {!isMatchReport && (
              <Th>
                <Center
                  onClick={() => selectAll()}
                  cursor="pointer"
                  border="1.5px solid"
                  borderColor="primary.500"
                  w={4}
                  h={4}
                >
                  {selectedAll && <Icon name="tick" color="primary.500" height={3.5} width={3.5} />}
                </Center>
              </Th>
            )}
            {columnHeadings?.map((item, index) => (
              <Th key={index} whiteSpace="normal">
                {item}
              </Th>
            ))}
            {!isMatchReport && <Th whiteSpace="normal">Go To Match</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((item) => {
            const selected = !isEmpty(matchIds) && matchIds?.includes(item?.match_id?.toString());
            return (
              <Tr
                _hover={{ bg: 'primary.50' }}
                cursor="pointer"
                key={item.match_id}
                onClick={() => selectRow(item.match_id)}
                bg={selected ? 'primary.25' : null}
              >
                {!isMatchReport && (
                  <Td maxW="30px">
                    <Center cursor="pointer" border="1.5px solid" borderColor="primary.500" w={4} h={4}>
                      {selected && <Icon name="tick" color="primary.500" height={3.5} width={3.5} />}
                    </Center>
                  </Td>
                )}
                {!!customSitesConfig.matchSelectorColumns?.includes(MATCH_SELECTOR_COLUMNS.DATE) && (
                  <Td>{formatDate(item.date)}</Td>
                )}
                {!!customSitesConfig.matchSelectorColumns?.includes(MATCH_SELECTOR_COLUMNS.YEAR) && (
                  <Td>{formatDateYearOnly(item.date)}</Td>
                )}
                {!!customSitesConfig.matchSelectorColumns?.includes(MATCH_SELECTOR_COLUMNS.TOURNAMENT_NAME) && (
                  <Td whiteSpace="nowrap">{item.tournament_name}</Td>
                )}
                <Td>
                  <Text>{item.opponent_name}</Text>
                </Td>
                <Td>{capitaliseFirstLetterOfString(item.result)}</Td>
                <Td>{item.score}</Td>
                <Td>{item.round}</Td>
                {!!customSitesConfig.matchSelectorColumns?.includes(MATCH_SELECTOR_COLUMNS.SURFACE) && (
                  <Td>
                    <SurfaceBadge surface={item.surface} />
                  </Td>
                )}
                <Td>{item.opponent_rank}</Td>
                <Td>{capitaliseFirstLetterOfString(item.opponent_hand)}</Td>
                {!isMatchReport && (
                  <Td>
                    <Box>
                      <Icon
                        name="externalLink"
                        color="primary.500"
                        height={6}
                        width={6}
                        onClick={() => {
                          navigateTo({
                            url: `/match/${item.match_id}`,
                            navigate
                          });
                        }}
                      />
                    </Box>
                  </Td>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
}

ScoutMatchSelectorTable.propTypes = {
  data: PropTypes.array,
  columnHeadings: PropTypes.array,
  selectRow: PropTypes.func,
  selectAll: PropTypes.func,
  matchIds: PropTypes.array,
  selectedAll: PropTypes.bool,
  matchSelectorLoading: PropTypes.bool
};
