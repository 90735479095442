import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { displayScore } from 'match/utils/transformations';

import { Flex, Box, VStack, Text, Grid } from '_shared/designSystem/components';
import { capitaliseString } from '_shared/utils/stringUtil';

import { COLUMN_HEIGHT, TOTAL_WIDTH, GRID_ROWS, GRID_ROW_HEIGHT, BAR_WIDTH, ITEM_HEIGHT } from './constants';
import { BarColor, SingleStackedBarProps, StackedBarSectionProps, DataItem, PlayerType } from './types';

const createLabel = (id: string): string => (id === 'lobs_moonballs' ? 'Lobs / Moonballs' : capitaliseString(id));

const BarContent: React.FC<{ data: DataItem; player: string; textColor: string }> = ({ data, player, textColor }) => (
  <Flex direction="column" justify="center" alignItems="center" height="100%">
    <Text color={textColor} fontWeight="semibold" fontSize="xs" textAlign="center" lineHeight="16px">
      {createLabel(data.id)}
    </Text>
    <Flex gap={1}>
      <Text color={textColor} fontSize="xs">
        {displayScore(data[player].score, data[player].score_type)}
      </Text>
      <Text color={textColor} fontSize="xs">{`(${data[player].frequency})`}</Text>
    </Flex>
  </Flex>
);

const StackedBarSection: React.FC<StackedBarSectionProps> = ({ data, index, player, height, width, barColors }) => {
  if (isEmpty(data[player])) return null;
  const { bg, text } = barColors[index % barColors.length];

  return (
    <Box height={height} width={width} backgroundColor={bg}>
      <BarContent data={data} player={player} textColor={text} />
    </Box>
  );
};

const ServeEffectivenessBar: React.FC<{
  dataNoAverages: DataItem[];
  player: PlayerType;
  barColors: BarColor[];
}> = ({ dataNoAverages, player, barColors }) => (
  <VStack height={`${COLUMN_HEIGHT}px`} width={TOTAL_WIDTH} spacing={0}>
    {dataNoAverages?.map((data, index) => (
      <StackedBarSection
        key={index}
        data={data}
        index={index}
        player={player}
        height={getHeight(dataNoAverages, player, parseFloat(data[player].score.toString()))}
        width="100%"
        barColors={barColors}
      />
    ))}
  </VStack>
);

export const assignColors = (gridData: DataItem[], player: PlayerType) => {
  const sortedData = [...gridData].sort((a, b) => a[player].score - b[player].score);

  let currentIndex = 0;
  let lastScore = null;
  const colorAssignments = sortedData.map((data) => {
    if (lastScore !== data[player].score) {
      currentIndex++;
      lastScore = data[player].score;
    }
    return [data, currentIndex];
  });

  return gridData.map((data) => colorAssignments.find(([d]) => d === data)!);
};

const NonServeEffectivenessBar: React.FC<{
  dataNoAverages: DataItem[];
  player: PlayerType;
  barColors: BarColor[];
}> = ({ dataNoAverages, player, barColors }) => {
  const [firstBar, ...gridData] = dataNoAverages;
  const gridHeight = GRID_ROWS * GRID_ROW_HEIGHT;
  const firstBarHeight = `${COLUMN_HEIGHT - gridHeight}px`;

  const colorAssignedData = assignColors(gridData, player);

  return (
    <VStack height={`${COLUMN_HEIGHT}px`} width={TOTAL_WIDTH} spacing={0} alignItems="flex-start">
      {firstBar && (
        <Box height={firstBarHeight} width="100%">
          <StackedBarSection
            data={firstBar}
            index={0}
            player={player}
            height="100%"
            width="100%"
            barColors={barColors}
          />
        </Box>
      )}
      <Grid
        templateColumns={`repeat(2, ${BAR_WIDTH})`}
        templateRows={`repeat(${GRID_ROWS}, ${GRID_ROW_HEIGHT}px)`}
        gap={0}
        height={`${gridHeight}px`}
      >
        {colorAssignedData.map(([data, colorIndex], index) => (
          <StackedBarSection
            key={index}
            data={data as DataItem}
            index={colorIndex as number}
            player={player}
            height={`${GRID_ROW_HEIGHT}px`}
            width="100%"
            barColors={barColors}
          />
        ))}
      </Grid>
    </VStack>
  );
};

const SingleStackedBar: React.FC<SingleStackedBarProps> = ({
  dataNoAverages,
  player,
  customStackColors,
  isServeEffectiveness = true
}) => {
  return isServeEffectiveness ? (
    <ServeEffectivenessBar dataNoAverages={dataNoAverages} player={player} barColors={customStackColors} />
  ) : (
    <NonServeEffectivenessBar dataNoAverages={dataNoAverages} player={player} barColors={customStackColors} />
  );
};

export default SingleStackedBar;

export function getHeight(data: DataItem[], player: PlayerType, score: number): string {
  if (isEmpty(data)) return '0';
  if (data.every((item) => item[player].score === 0)) return `${100 / data.length}%`;
  return score < 10 ? ITEM_HEIGHT : `${score}%`;
}
