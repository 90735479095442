import Scout from 'scout/components/Scout';

const routes = {
  path: 'scout',
  element: null,
  children: [
    {
      path: ':playerId',
      element: <Scout />
    }
  ]
};
export default routes;
