import { MatchScore } from 'match/components/header/MatchScore';
import { useRecoilValue } from 'recoil';

import TvLogo from '_shared/components/logo/TvLogo';
import { IMAGE_LOGO_USTA, IMAGE_LOGO_LTA } from '_shared/constants/imageConsts';
import { Badge, Box, Flex, Icon, Image, Text, VStack } from '_shared/designSystem/components';
import { userPermissionsState } from '_shared/globalState/atoms';
import { formatDate } from '_shared/utils/dateUtil';

const FrontPage = ({ headerData }) => {
  const { company } = useRecoilValue(userPermissionsState);

  const player1Image = headerData?.player_details?.player1?.member1?.image_url_tv;
  const player2Image = headerData?.player_details?.player2?.member1?.image_url_tv;
  const player1Name = headerData?.player_details?.player1?.member1?.full_name;
  const player2Name = headerData?.player_details?.player2?.member1?.full_name;

  return (
    <Box pt="200px">
      <VStack gap={5}>
        <Flex gap={12} mb={10} pt={10}>
          <TvLogo w="200px" h="66px" isPurpleLandscape />
          {company === 'usta' && <Image alt="USTA" h={14} src={IMAGE_LOGO_USTA} />}
          {company === 'lta' && <Image alt="LTA" h={14} src={IMAGE_LOGO_LTA} />}
        </Flex>
        <Text color="primary.600" fontWeight="bold" fontSize="6xl">
          Match Report
        </Text>
        <Box bg="secondary.800" p={10} borderRadius="xl">
          <Flex gap={10} mb={5}>
            <PlayerImage url={player1Image} />
            <PlayerImage url={player2Image} />
          </Flex>
          <Flex>
            <PlayerName name={player1Name} />
            <Flex w={10} justify="center">
              <Text fontSize="2xl" color="primary.200" lineHeight="22px">
                vs
              </Text>
            </Flex>
            <PlayerName name={player2Name} />
          </Flex>
        </Box>
        <Text>
          {headerData?.competition_name} ({headerData?.round}) {formatDate(headerData?.date)}
        </Text>
        {headerData?.atp_id && <Badge text={headerData?.atp_id?.toString()} />}
        <MatchScore data={headerData} />
      </VStack>
    </Box>
  );
};

const PlayerImage = ({ url }) => (
  <Box borderRadius="md" border="5px solid" borderColor="primary.400" p={0}>
    <Image src={url} w={200} fallback={<FallBackImage />} />
  </Box>
);

const FallBackImage = () => (
  <Flex w={200} h="100%" bg="secondary.800" justify="center" alignItems="flex-end">
    <Icon w={170} name="profileImagePlaceholder" color="grey.500" />
  </Flex>
);

const PlayerName = ({ name }) => (
  <Box w={210}>
    <Text color="primary.200" fontSize="2xl" lineHeight="22px" align="center">
      {name}
    </Text>
  </Box>
);

export default FrontPage;
