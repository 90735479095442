import { Box, Flex, Skeleton, SkeletonText, VStack } from '_shared/designSystem/components';

const colors = { startColor: 'grey.200', endColor: 'grey.300' };

export const MatchNoAveragesSkeleton = ({ isMatchPage }) => (
  <Box borderRadius="md" boxShadow="md" padding={4}>
    <Flex justify="center" mb={5} pt={5}>
      <Skeleton h="35px" w="250px" {...colors} />
    </Flex>
    <Skeleton height="80px" width="100%" {...colors} />
    <Box justifyContent="flex-start" mt={2}>
      <SkeletonText noOfLines={1} width="100px" {...colors} />
      <SkeletonText noOfLines={1} width="50px" mt={1} {...colors} />
    </Box>
    <Box mt={8}>
      <Skeleton height={isMatchPage ? '800px' : '180px'} {...colors} />
    </Box>
  </Box>
);

export const MatchWithAveragesSkeleton = () => (
  <VStack gap={8} borderRadius="md" boxShadow="md" padding={4}>
    <Flex justify="center" pt={5}>
      <Skeleton h="35px" w="250px" />
    </Flex>
    <Skeleton height="70px" width="100%" {...colors} />
    <Skeleton height="70px" width="100%" {...colors} />
    <Skeleton height="70px" width="100%" {...colors} />
    <Skeleton height="70px" width="100%" {...colors} />
  </VStack>
);
