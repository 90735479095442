export function drawAveragesLines(
  svg,
  width,
  performanceRatingAverages,
  lineColors,
  marginRightInner,
  marginLeftInner,
  yScale
) {
  // draw the dotted averages lines
  const player1AverageY = yScale(performanceRatingAverages?.player1?.player_average);
  const player2AverageY = yScale(performanceRatingAverages?.player2?.player_average);
  const tourAverageY = yScale(performanceRatingAverages?.player1?.tour_average);

  function addAverageLine(svg, averageY, color) {
    if (averageY) {
      svg
        .append('line')
        .attr('x1', marginLeftInner)
        .attr('x2', width - marginRightInner)
        .attr('y1', averageY)
        .attr('y2', averageY)
        .style('stroke-dasharray', '4,1') // each dash has width 4 and 1 space between them
        .style('stroke', color)
        .style('stroke-width', 2); // this width means the lines are visible but not too dominating
    }
  }
  addAverageLine(svg, player1AverageY, lineColors?.player1Line);
  addAverageLine(svg, player2AverageY, lineColors?.player2Line);
  addAverageLine(svg, tourAverageY, lineColors?.tourAverageLine);
}
