import { line, curveNatural, area } from 'd3';

export function drawPerformanceLineAndArea(
  svg,
  width,
  height,
  marginRightInner,
  xMargin,
  yMargin,
  graphData,
  xScale,
  yScale,
  lineColors
) {
  // calculations for the lines for the player ratings
  const lineWidth = 2;
  const player1Line = line() // we need a separate function for each line because we will shade in the area between them, so this makes it possible
    .curve(curveNatural) // we want the lines curved
    .x((d) => xScale(d.matchScore)) // scaling x with the matchScore
    .y((d) => yScale(d.player1Rating)); // scaling y with performance rating

  const player2Line = line() // same thing here for player2
    .curve(curveNatural)
    .x((d) => xScale(d.matchScore))
    .y((d) => yScale(d.player2Rating));

  svg
    .append('defs')
    .append('clipPath') // this is clipping the left and right hand side of both performance lines, basically drawing a box around the whole thing
    .attr('id', 'performanceGraphShaded')
    .append('rect')
    .attr('width', width - marginRightInner)
    .attr('height', height - yMargin)
    .attr('x', xMargin)
    .attr('y', yMargin);

  // areas
  // these functions calculate the areas above and below the lines, so we can omit the parts we don't want
  const areaAbovePlayer1Line = area().curve(curveNatural).x(player1Line.x()).y0(player1Line.y()).y1(0); // getting everything from player1 line to the top of the graph
  const areaBelowPlayer1Line = area() // getting everything from player1 line to the bottom of the graph
    .curve(curveNatural)
    .x(player1Line.x())
    .y0(player1Line.y())
    .y1(height - yMargin); // bottom of graph
  const areaAbovePlayer2Line = area().curve(curveNatural).x(player2Line.x()).y0(player2Line.y()).y1(0);
  const areaBelowPlayer2Line = area()
    .curve(curveNatural)
    .x(player2Line.x())
    .y0(player2Line.y())
    .y1(height - yMargin); // bottom of graph

  // we need two clip paths now, one for each area above the lines
  svg
    .append('defs')
    .append('clipPath')
    .attr('id', 'clip-player1')
    .append('path')
    .datum(graphData)
    .attr('d', areaAbovePlayer1Line);

  svg
    .append('defs')
    .append('clipPath')
    .attr('id', 'clip-player2')
    .append('path')
    .datum(graphData)
    .attr('d', areaAbovePlayer2Line);

  // this is where we actually do the filling. If player1 is above player2, we want to fill the area below player1, in
  // player1's colour, but then clip it to above the player2 line, using the clip paths defined above
  const areaFillOpacity = 0.4; // we want a bit of transparency so that we can see the average lines underneath
  svg
    .append('path')
    .datum(graphData)
    .attr('d', areaBelowPlayer1Line)
    .attr('clip-path', 'url(#clip-player2)')
    .attr('fill', lineColors?.player1Fill)
    .attr('opacity', areaFillOpacity);

  // and the same for if player2 is above player1
  svg
    .append('path')
    .datum(graphData)
    .attr('d', areaBelowPlayer2Line)
    .attr('clip-path', 'url(#clip-player1)')
    .attr('fill', lineColors?.player2Fill)
    .attr('opacity', areaFillOpacity);

  // the fill areas don't take into the account the width of the lines very well, so when you make them transparent
  // they affect the lines. Solution is to draw the lines after the fill areas
  svg
    .append('path') // drawing the actual line now
    .attr('d', player1Line(graphData)) // using the specific function for player1
    .attr('fill', 'none') // if we set this on, it would fill everything below the line
    .style('stroke-width', lineWidth)
    .style('stroke', lineColors?.player1Line);

  svg
    .append('path')
    .attr('d', player2Line(graphData))
    .attr('fill', 'none')
    .style('stroke-width', lineWidth)
    .style('stroke', lineColors?.player2Line);
}
