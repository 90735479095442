import { wimbledonImageUrls } from '_customSites/wimb/util/imageUtil';

import { HERO_IMAGE_PLACEHOLDER_DIRECTION, TIER_MAP_IMAGE, TV_PORTAL_ASSETS_PATH } from '_shared/constants/imageConsts';

export const getPlayerImageHeadshotUrl = (playerId) => {
  if (!playerId) {
    return null;
  }
  return `${TV_PORTAL_ASSETS_PATH}player-images/headshot/${playerId}.png`;
};

export const getPlayerImageFullBodyUrl = (playerId) => {
  if (!playerId) {
    return null;
  }
  return `${TV_PORTAL_ASSETS_PATH}player-images/full-body/${playerId}.png`;
};

export const getPlaceholderFullBody = () => {
  return `${TV_PORTAL_ASSETS_PATH}player-images/placeholders/full-body.png`;
};

export const getTournamentTierImage = (tier, tournamentName, isWimbledon = false) => {
  if (
    TIER_MAP_IMAGE.GRAND_SLAM === tier &&
    tournamentName?.indexOf('Wimbledon') === -1 &&
    tournamentName?.indexOf('Singles') === -1
  ) {
    return null;
  }

  switch (tier) {
    case TIER_MAP_IMAGE.ATP_MASTERS_1000:
      return 'https://www.atptour.com/assets/atpwt/images/tournament/badges/categorystamps_1000.png';
    case TIER_MAP_IMAGE.ATP_500:
      return 'https://www.atptour.com/assets/atpwt/images/tournament/badges/categorystamps_500.png';
    case TIER_MAP_IMAGE.ATP_250:
      return 'https://www.atptour.com/assets/atpwt/images/tournament/badges/categorystamps_250.png';
    case TIER_MAP_IMAGE.GRAND_SLAM:
      return isWimbledon ? wimbledonImageUrls.LOGO : null;
    default:
      return null;
  }
};

export const getMaleHeroPlaceholder = (direction) => {
  const fileName =
    direction === HERO_IMAGE_PLACEHOLDER_DIRECTION.LEFT
      ? 'hero-placeholder-male-facing-left.png'
      : 'hero-placeholder-male-facing-right.png';
  return `${TV_PORTAL_ASSETS_PATH}player-images/placeholders/${fileName}`;
};
