import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Box, Flex, LoadingSpinner, Text, useBreakpointValue } from '_shared/designSystem/components';
import { tvPerformanceTrackerColors } from '_shared/designSystem/theme/chartColors';
import { isMatchReportState } from '_shared/globalState/atoms';

import { performanceGraphWidth } from '../performanceMargins';

import PerformanceGraphShaded from './performanceGraphShaded/PerformanceGraphShaded';

const PerformanceGraph = ({ performanceRatingGame, gamesLoading, players, performanceRatingAverages }) => {
  const player1Name = players?.player1?.last_name ?? players?.player1?.member1?.last_name;
  const player2Name = players?.player2?.last_name ?? players?.player2?.member1?.last_name;
  const isMatchReport = useRecoilValue(isMatchReportState);

  const graphHeight = 350;

  const graphWidth = useBreakpointValue(
    {
      base: 350,
      sm: 550
    },
    { ssr: false }
  );

  if (gamesLoading) return <LoadingSpinner />;

  if (isEmpty(performanceRatingGame)) {
    return null;
  }

  const performanceTrackerColors = customSitesConfig?.performanceTrackerColors || tvPerformanceTrackerColors;

  return (
    <Box w={{ base: '100%', sm: `${performanceGraphWidth}px` }} pb={4}>
      {isMatchReport ? (
        <Flex justify="center">
          <Text fontSize="md" fontWeight="semibold">
            Performance Tracker
          </Text>
        </Flex>
      ) : (
        <Text fontWeight="semibold">Performance Tracker</Text>
      )}
      <PerformanceGraphShaded
        width={graphWidth}
        height={graphHeight}
        performanceRatingGame={performanceRatingGame}
        player1Name={player1Name}
        player2Name={player2Name}
        lineColors={performanceTrackerColors}
        performanceRatingAverages={performanceRatingAverages}
      />
    </Box>
  );
};

PerformanceGraph.propTypes = {
  performanceRatingGame: PropTypes.array,
  gamesLoading: PropTypes.bool,
  players: PropTypes.object,
  performanceRatingAverages: PropTypes.object
};

export default PerformanceGraph;
