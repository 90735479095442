import React from 'react';

import { userPermissionTypes } from '_shared/constants/user';
import { Box, UnorderedList } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import { checkPermission } from '_shared/utils/permissions';

import { ACCORDION_TITLE } from './constants';
import { getCustomText, Paragraph, NormalParagraphText, InsightsListItem } from './helperComponents';

export const generateInsightsExplainedBody = (permissions) => {
  const baseContent = [];

  if (checkPermission(userPermissionTypes.BALANCE_OF_POWER, permissions)) {
    baseContent.push({
      title: ACCORDION_TITLE.IN_ATTACK,
      content: (
        <Box>
          <NormalParagraphText>
            <b>How do we calculate whether a player is in attack, neutral or defence? </b>The technology analyses
            multiple data points when identifying whether the player is in attack, neutral, or defence. These include:
          </NormalParagraphText>
          <UnorderedList mb={2}>
            <InsightsListItem>The quality of the incoming ball.</InsightsListItem>
            <InsightsListItem>The player's location on the court (left and right, forwards and back).</InsightsListItem>
            <InsightsListItem>
              The player's contact point (on the stretch vs in the slot, high vs low).
            </InsightsListItem>
            <InsightsListItem>The opponent's recovery position.</InsightsListItem>
          </UnorderedList>
          {getCustomText('inAttack')}
        </Box>
      )
    });
  }

  if (checkPermission(userPermissionTypes.ATTACK_DEFENCE, permissions)) {
    baseContent.push({
      title: ACCORDION_TITLE.CONVERSION_SCORE,
      content: (
        <Box>
          <NormalParagraphText>
            <b>What is it? </b> Conversion Score calculates how often a player has won a point when attacking. The
            Conversion Score shows how efficient a player is when they are in attack.
          </NormalParagraphText>
          {getCustomText('conversion')}
        </Box>
      )
    });

    baseContent.push({
      title: ACCORDION_TITLE.STEAL_SCORE,
      content: (
        <Box>
          <NormalParagraphText>
            <b>What is it? </b> Steal Score calculates how often a player has won the point when defending.
          </NormalParagraphText>
          <NormalParagraphText>
            Steal Score highlights the players with the best defensive skills and shows which players can win matches
            with lower In Attack scores.
          </NormalParagraphText>
          {getCustomText('steal')}
        </Box>
      )
    });
  }

  if (checkPermission(userPermissionTypes.SHOT_QUALITY, permissions)) {
    baseContent.push({
      title: ACCORDION_TITLE.SHOT_QUALITY,
      content: (
        <Paragraph>
          <NormalParagraphText>
            <b>What is it? </b> Shot Quality measures the quality of the player's serve, return, forehand and backhand
            on a 0-10 scale.
          </NormalParagraphText>
          <NormalParagraphText>
            <b>How Is Shot Quality Calculated?: </b> Algorithmic technology processes the ball and player tracking data
            to calculate Shot Quality.
          </NormalParagraphText>
          <NormalParagraphText>
            Shot Quality is calculated in real time by analysing the speed, spin, depth and width of every shot.
          </NormalParagraphText>
          <NormalParagraphText>
            There are different ways of creating high-quality shots. One player could hit heavy spin and steep bounce
            angles, while another hits a flatter, skiddier ball. The AI recognises the nuances in tennis, and both could
            achieve an equally high Shot Quality score.
          </NormalParagraphText>
          <NormalParagraphText>
            The characteristics of the incoming ball are also taken into account. The AI recognises that it's more
            challenging to hit a high-quality shot, having received a high-quality shot.
          </NormalParagraphText>
          <NormalParagraphText>
            Each shot type requires a different combination of speed, spin, depth and width. For example, the quality of
            a drop shot will be measured very differently compared to backhand cross-court.
          </NormalParagraphText>
          {getCustomText('shotQuality')}
        </Paragraph>
      )
    });
  }

  if (checkPermission(userPermissionTypes.PERFORMANCE_RATING, permissions)) {
    baseContent.push({
      title: ACCORDION_TITLE.PERFORMANCE_RATING,
      content: (
        <Paragraph>
          <NormalParagraphText>
            <b>What is it? </b> The Performance Rating combines the In Attack, Conversion, Steal and Shot Quality data
            to create a metric showing a player's overall performance level.
          </NormalParagraphText>
          <NormalParagraphText>
            The Performance Rating combines all Insights data to objectively measure a player's performance,
            understanding that there are many different ways to be successful in tennis.
          </NormalParagraphText>
          {getCustomText('performanceRating')}
        </Paragraph>
      )
    });
  }

  if (!isWimbPortal()) {
    if (checkPermission(userPermissionTypes.WINNING_AND_LOSING_PLAYS, permissions)) {
      baseContent.push({
        title: ACCORDION_TITLE.WINNING_AND_LOSING_PLAYS,
        content: (
          <Paragraph>
            <NormalParagraphText>
              <b>What is it? </b> Winning and Losing Plays highlight the tactics and patterns of play, which are
              winning/losing the match for both players. Winning Plays are a more sophisticated tool than the winner's
              statistic because they identify the most significant pattern, understanding that this is not always the
              last shot of the point.
            </NormalParagraphText>
            <NormalParagraphText>Winning Plays Combine:</NormalParagraphText>
            <UnorderedList>
              <InsightsListItem>Winners</InsightsListItem>
              <InsightsListItem>Shots which move the player into attack</InsightsListItem>
              <InsightsListItem>Shots leading to an opponent error</InsightsListItem>
            </UnorderedList>
            <NormalParagraphText>Losing Plays Combine:</NormalParagraphText>
            <UnorderedList>
              <InsightsListItem>Unforced errors</InsightsListItem>
              <InsightsListItem>Shots which lead to the opponent being in attack</InsightsListItem>
              <InsightsListItem>Shots leading to an opponent's winner</InsightsListItem>
            </UnorderedList>
            {getCustomText('winningAndLosingPlays')}
          </Paragraph>
        )
      });
    }

    if (checkPermission(userPermissionTypes.SERVE_EFFECTIVENESS, permissions)) {
      baseContent.push({
        title: ACCORDION_TITLE.SERVE_EFFECTIVENESS,
        content: (
          <Paragraph>
            <NormalParagraphText>
              <b>What is it? </b> Serve Effectiveness shows the percentage of points where the server creates an
              advantage for the player. To calculate this, we aggregate aces, serve winners, unreturned serves and
              points when the player is in attack on Serve +1.
            </NormalParagraphText>
            {getCustomText('serveEffectiveness')}
          </Paragraph>
        )
      });
    }

    if (checkPermission(userPermissionTypes.SHOT_VARIATION, permissions)) {
      baseContent.push({
        title: ACCORDION_TITLE.SHOT_VARIATION,
        content: (
          <Paragraph>
            <NormalParagraphText>
              <b>What is it? </b> Shot Variation measures how much a player varies their shot selection and mixes up
              their play.
            </NormalParagraphText>
            <NormalParagraphText>
              It is calculated by comparing the use of Core shots (topspin groundstrokes hit through the opponent's
              baseline) with their use of Variation (slice groundstrokes, short slices, drop shots, angles, and net
              shots (volleys and smashes)).
            </NormalParagraphText>
          </Paragraph>
        )
      });
    }

    if (checkPermission(userPermissionTypes.BASELINE_NET_BATTLES, permissions)) {
      baseContent.push({
        title: ACCORDION_TITLE.BATTLES,
        content: (
          <Paragraph>
            <NormalParagraphText>
              <b>What is it? </b> Battles show the situation the player was in when the point finished and the
              percentage of points they won in this situation. The three battles are:
            </NormalParagraphText>
            <UnorderedList>
              <InsightsListItem>The player is within the baseline area</InsightsListItem>
              <InsightsListItem>The player is at the net</InsightsListItem>
              <InsightsListItem>The player's opponent is at the net</InsightsListItem>
            </UnorderedList>
          </Paragraph>
        )
      });
    }
  }

  return baseContent;
};

export const insightsExplainedBody = generateInsightsExplainedBody();
