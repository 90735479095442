import MatchReport from 'matchReport/components/MatchReport';

const routes = {
  path: 'match-report',
  element: null,
  children: [
    {
      path: ':matchId',
      element: <MatchReport />
    }
  ]
};

export default routes;
