import AverageBars from 'match/_shared/AverageBars';
import { generateIdForShotType } from 'match/utils/aiPaths';
import { convertScoreToPercentage, displayScore, zeroOrMore } from 'match/utils/transformations';
import PropTypes from 'prop-types';

import { Box, Center, CircularProgress, Collapse, Flex, Text } from '_shared/designSystem/components';
import { determineWinOpacity } from '_shared/utils/metricsUtil';
import { capitaliseString } from '_shared/utils/stringUtil';

import CircularMetricsHeading from './CircularMetricsHeading';

export default function CircularMetricsPanel(props) {
  const {
    heading,
    tooltipText = '',
    player1Name,
    player2Name,
    data,
    dataWithAverages,
    type,
    showAverages,
    averagesLoading,
    orderOfMetrics,
    noTopLabel,
    AISelected,
    isAISelected
  } = props;

  return (
    <Box>
      <Flex direction="column" gap={3}>
        <Center>
          <CircularMetricsHeading headingText={heading} tooltipText={tooltipText} />
        </Center>
        <Flex gap={7}>
          <Center w="50%">
            <Text fontSize="sm" fontWeight="semibold">
              {player1Name}
            </Text>
          </Center>
          <Center w="50%">
            <Text fontSize="sm" fontWeight="semibold">
              {player2Name}
            </Text>
          </Center>
        </Flex>
        {orderOfMetrics.map((shotType) => {
          if (!data[shotType]) return null;
          const idForShotType = generateIdForShotType(shotType);
          const isAISelectedForThisShotType = AISelected?.path === idForShotType;

          return (
            <Flex gap={7} key={shotType} opacity={isAISelected && !isAISelectedForThisShotType ? 0.2 : 1}>
              {['player1', 'player2'].map((player) => {
                if (!data[shotType][player]) return null;

                const playerScoringData = data[shotType][player];
                const otherPlayerScoringData = data[shotType][player === 'player1' ? 'player2' : 'player1'];
                const playerAveragesData = dataWithAverages?.[shotType][player];
                const otherPlayerAveragesData =
                  dataWithAverages?.[shotType][player === 'player1' ? 'player2' : 'player1'];
                return (
                  <Flex w="50%" align="center" justify="center" key={`${shotType}-${player}`}>
                    <Flex direction="column" gap={2} w={140}>
                      <CircularProgress
                        topLabel={noTopLabel ? null : capitaliseString(shotType)}
                        percentage={convertScoreToPercentage(playerScoringData.score, playerScoringData.score_type)}
                        centreLabel={displayScore(playerScoringData.score, playerScoringData.score_type)}
                        bottomLabel={
                          zeroOrMore(playerScoringData.frequency) && zeroOrMore(playerScoringData.frequency_total)
                            ? `(${playerScoringData.frequency}/${playerScoringData.frequency_total})`
                            : null
                        }
                        type={type}
                        opacity={
                          player1Name.length > 0 &&
                          player2Name.length > 0 &&
                          determineWinOpacity(playerScoringData?.score, otherPlayerScoringData?.score)
                        }
                      />
                      <Collapse in={showAverages} animateOpacity>
                        <AverageBars
                          labelPosition="right"
                          data={dataWithAverages?.[shotType]?.[player]}
                          isLoading={averagesLoading}
                          opacity={
                            player1Name.length > 0 &&
                            player2Name.length > 0 &&
                            determineWinOpacity(
                              playerAveragesData?.player_average,
                              otherPlayerAveragesData?.player_average
                            )
                          }
                        />
                      </Collapse>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
}

CircularMetricsPanel.propTypes = {
  heading: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  player1Name: PropTypes.string.isRequired,
  player2Name: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  dataWithAverages: PropTypes.object,
  type: PropTypes.string,
  showAverages: PropTypes.bool,
  averagesLoading: PropTypes.bool,
  orderOfMetrics: PropTypes.array,
  noTopLabel: PropTypes.bool,
  AISelected: PropTypes.object,
  isAISelected: PropTypes.bool
};
