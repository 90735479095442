import React, { useEffect } from 'react';

import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Alert, Box, Button, Center, Flex, Input, Select, Switch } from '@ellipsedata/tennis';
import { Formik, Form, ErrorMessage, useFormikContext } from 'formik';
import { getUrlParamErrors } from 'leaderboard/util/transformations';
import { getInitialSearchFormValues } from 'leaderboard/util/transformations';
import isEmpty from 'lodash/isEmpty';

import { Icon } from '_shared/designSystem/components';
import { convertBooleanToYesNo } from '_shared/utils/stringUtil';

const SearchForm = ({ formOptions, mutate, urlParams, addParamToUrl, setUrlParamsErrors }) => {
  const initialValues = getInitialSearchFormValues(formOptions, urlParams);

  useEffect(() => {
    if (!isEmpty(formOptions) && !isEmpty(urlParams)) {
      setUrlParamsErrors(getUrlParamErrors(formOptions, urlParams));
    }

    mutate(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormReset = (resetForm) => {
    const initialFormValues = getInitialSearchFormValues(formOptions, []);
    resetForm({ values: initialFormValues });
    Object.keys(initialFormValues).forEach((item) => {
      addParamToUrl(item, initialFormValues[item]);
    });
  };

  if (isEmpty(formOptions))
    return <Alert status="warning">No form options available, please try refreshing the page</Alert>;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        mutate(values);
        setSubmitting(false);
        setUrlParamsErrors([]);
      }}
    >
      {({ isSubmitting, values, handleChange, handleBlur, resetForm }) => {
        const handleCheckboxChange = (event) => {
          const { name, checked } = event.target;
          const checkboxValue = convertBooleanToYesNo(checked);
          handleChange({
            target: {
              name,
              value: checkboxValue
            }
          });
        };

        return (
          <Form>
            <FormObserver addParamToUrl={addParamToUrl} />
            <Flex gap={1} flexWrap="wrap">
              {formOptions.map((item) => (
                <FormControl key={item.field_value} w="330px">
                  <Flex>
                    <Flex align="center" w="130px" h="30px">
                      <FormLabel
                        htmlFor={item.field_value}
                        lineHeight="16px"
                        fontSize="xs"
                        fontWeight="medium"
                        color="grey.700"
                        pt="9px"
                      >
                        {item.field_name}
                      </FormLabel>
                    </Flex>
                    <Flex align="center" h="30px" w="200px">
                      <Box>
                        {(item.type === 'select' || !item.type) && (
                          <Select
                            id={item.field_value}
                            name={item.field_value}
                            value={values[item.field_value]}
                            size="xs"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            {!item.no_all_option && <option value="all">All</option>}
                            {item.options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </Select>
                        )}
                        {item.type === 'checkbox' && (
                          <Switch
                            id={item.field_value}
                            name={item.field_value}
                            colorScheme="green"
                            onBlur={handleBlur}
                            onChange={handleCheckboxChange}
                            isChecked={values[item.field_value] === 'yes'}
                          />
                        )}
                        {item.type === 'number' && (
                          <Input
                            borderRadius="xs"
                            id={item.field_value}
                            name={item.field_value}
                            value={values[item.field_value]}
                            size="xs"
                            type="number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            min="1"
                          />
                        )}
                      </Box>
                      <ErrorMessage name={item.field_value} />
                    </Flex>
                  </Flex>
                </FormControl>
              ))}
              <Flex w="330px" gap={4}>
                <Button mt="4px" size="xs" spinnerPlacement="start" type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
                <Center cursor="pointer">
                  <Icon
                    name="reset"
                    width="14px"
                    height="14px"
                    color="primary.500"
                    onClick={() => handleFormReset(resetForm)}
                  />
                </Center>
              </Flex>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

const FormObserver = ({ addParamToUrl }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    Object.keys(values).forEach((item) => {
      addParamToUrl(item, values[`${item}`]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  return null;
};

export default SearchForm;
