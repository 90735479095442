import React from 'react';

import { Player } from '_mockData/apiResponses/players/playersTypes';
import { getFormattedPlayerName, getRankingMovementStyle, getRankingNumberStyle } from 'players/util/playersUtil';

import PlayerImageHeadshot from '_shared/components/PlayerImageHeadshot';
import customSitesConfig from '_shared/config/customSitesConfig';
import { Text, Flex, Box, Card, NationalityFlag, Icon } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';

type PlayersGridCardProps = {
  data: Player;
  openScoutPage: (player_id: number) => void;
};

const PlayersGridCard = ({ data, openScoutPage }: PlayersGridCardProps) => {
  const { rankMovementIcon, rankMovementColour } = getRankingMovementStyle(data.ranking_movement);

  const { rankFontSize, rankLineHeight, rankWidth, rankFontWeight } = getRankingNumberStyle(data.player_rank);

  const playerName = getFormattedPlayerName(data.first_name, data.last_name, data.initial);

  const isNew = data.ranking_movement === 'new';

  return (
    <Card key={data.player_id} disabled={false} status={null} onClick={() => openScoutPage(data.player_id)}>
      <Flex justify="space-between">
        <Flex align="center">
          <Flex>
            {!customSitesConfig.playersListNameOnly && (
              <Flex w={rankWidth} align="center">
                <Box>
                  <Text fontWeight={rankFontWeight} fontSize={rankFontSize} lineHeight={rankLineHeight} mb={2}>
                    {data.player_rank}
                  </Text>
                  {data.ranking_movement !== 'same' && (
                    <Box w={rankWidth}>
                      <Flex>
                        <Icon
                          width={isNew ? '30px' : '20px'}
                          height="20px"
                          name={rankMovementIcon}
                          color={rankMovementColour}
                          fallback={null}
                        />
                        {!isNew && (
                          <Text fontSize="sm" color={rankMovementColour}>
                            {data.places_changed}
                          </Text>
                        )}
                      </Flex>
                    </Box>
                  )}
                </Box>
              </Flex>
            )}
            <PlayerImageHeadshot
              playerId={data.player_id}
              imageUrl={isWimbPortal() ? data.image_url_wimb : data.image_url_atptour}
              playerName={data.last_name}
              w="55px"
              h="55px"
            />
            {customSitesConfig.playersListNameOnly ? (
              <Flex align="center">
                <Text ml={3} fontWeight="semibold" fontSize={isWimbPortal() ? 'lg' : 'xl'}>
                  {playerName}
                </Text>
              </Flex>
            ) : (
              <Box mr={3} ml={2}>
                <Text fontWeight="semibold" fontSize={isWimbPortal() ? 'lg' : 'xl'}>
                  {playerName}
                </Text>
                {!customSitesConfig.playersListNameOnly && (
                  <Text fontSize="sm">Ranking Points: {data.ranking_points}</Text>
                )}
              </Box>
            )}
          </Flex>
        </Flex>
        <NationalityFlag nationality={data.nationality} width={30} />
      </Flex>
    </Card>
  );
};

export default PlayersGridCard;
