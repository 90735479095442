import { useQuery } from 'react-query';
import { scoutService } from 'scout/service/scoutService';
import { getSearchParamUrl } from 'scout/util/scoutUtil';

export function useScoutReportCourtGraphicsQuery(playerId, graphicType, scoutFormValues, extraParams, fetchParams) {
  return useQuery(
    [`scoutReport_court-graphics_${graphicType}_${extraParams}`, scoutFormValues],
    () =>
      scoutService.getCourtGraphics({
        playerId,
        graphicType,
        courtGraphicsParams: `${getSearchParamUrl(scoutFormValues)}${extraParams}`,
        averages: false
      }),
    fetchParams
  );
}

export function useScoutReportCourtStatsQuery(playerId, graphicType, scoutFormValues, fetchParams) {
  return useQuery(
    [`scoutReport_court-stats_${graphicType}`, scoutFormValues],
    () =>
      scoutService.getCourtStats({
        playerId,
        graphicType,
        courtGraphicsParams: `${getSearchParamUrl(scoutFormValues)}`,
        averages: true
      }),
    fetchParams
  );
}
