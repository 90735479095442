import React from 'react';

import { Flex, Text } from '@ellipsedata/tennis';
import PropTypes from 'prop-types';

import { Icon } from '_shared/designSystem/components';

const ApplyButton = ({ onClick, disabled = true }) => {
  return (
    <Flex
      as="button"
      gap={2}
      bg={disabled ? 'grey.100' : null}
      border="solid 2px"
      borderColor={disabled ? 'grey.100' : 'primary.500'}
      borderRadius="md"
      px={2}
      py={1}
      justifyContent="space-between"
      alignItems="center"
      onClick={disabled ? null : onClick}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      inlineSize="max-content"
    >
      <Text color={disabled ? 'grey.400' : 'primary.700'} fontWeight="semibold" fontSize="xs">
        Apply
      </Text>
      <Icon name="play" color={disabled ? 'grey.400' : 'primary.700'} height={3} width={3} />
    </Flex>
  );
};

ApplyButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default ApplyButton;
