import { Select } from 'chakra-react-select';
import { Form, FormikProvider } from 'formik';

import { Box, Button, ButtonGroup, Flex, Switch, Text, Icon } from '_shared/designSystem/components';
import { isAtpTourPortal } from '_shared/utils/environment/currentEnv';
import { navigateBack, SESSION_STORAGE_KEYS } from '_shared/utils/navigationUtils';

const SearchBar = ({
  players,
  formik,
  onFieldValueChange,
  onCompareClick,
  setGender,
  gender,
  genderOptions,
  navigate
}) => {
  const pageToGoBackTo = sessionStorage.getItem(SESSION_STORAGE_KEYS.PREV_PATH);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box px={{ base: 2, md: null }} mt={{ base: 4, md: 2 }}>
          <Flex alignItems="center" justify="center" flexWrap="wrap" gap={4}>
            {!!pageToGoBackTo && (
              <Box
                cursor="pointer"
                onClick={() => {
                  navigateBack({ navigate });
                }}
                mr={4}
              >
                <Icon name="arrowLeft" color="primary.500" height={8} width={8} />
              </Box>
            )}
            {!isAtpTourPortal() && (
              <Box>
                <ButtonGroup
                  data={genderOptions}
                  paramName="gender"
                  handleButtonClick={(_, value) => {
                    formik.resetForm();
                    navigate({ search: () => ({}) });
                    setGender(value);
                  }}
                  selectedItem={gender}
                />
              </Box>
            )}
            <PlayerSearchSelect
              player="player1"
              formik={formik}
              onFieldValueChange={onFieldValueChange}
              allPlayers={players}
              filteredPlayers={players?.filter(
                (player) => player.gender === gender && player.player_id !== formik.values.player2
              )}
            />
            <PlayerSearchSelect
              player="player2"
              formik={formik}
              onFieldValueChange={onFieldValueChange}
              allPlayers={players}
              filteredPlayers={players?.filter(
                (player) => player.gender === gender && player.player_id !== formik.values.player1
              )}
            />
            <Button size="sm" onClick={() => onCompareClick()}>
              Compare
            </Button>
            <Flex gap={2}>
              <Switch
                colorScheme={isAtpTourPortal() ? 'blue' : 'green'}
                onChange={() => onFieldValueChange('showAverages', !formik.values.showAverages)}
                isChecked={formik.values.showAverages}
              />
              <Text fontSize="sm" fontWeight="medium">
                Averages
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default SearchBar;

const PlayerSearchSelect = ({ formik, player, onFieldValueChange, allPlayers, filteredPlayers }) => {
  const playerId = formik.values[`${player}`];
  const selectedPlayer = allPlayers?.find((player) => player?.player_id === playerId);
  const initialValue = selectedPlayer
    ? {
        value: `${selectedPlayer?.player_id}`,
        label: `${selectedPlayer?.first_name} ${selectedPlayer?.last_name}`
      }
    : '';

  return (
    <Flex display="flex" alignItems="center">
      <Text fontSize="sm" fontWeight="medium" w="80px" color="grey.700">
        {player === 'player1' ? 'Player 1' : 'Player 2'}
      </Text>
      <Box w="200px">
        <Select
          id={`select_${player}`}
          value={initialValue}
          useBasicStyles
          size="sm"
          onChange={(e) => {
            onFieldValueChange(player, e.value);
          }}
          closeMenuOnSelect
          options={filteredPlayers?.map((option) => ({
            value: option?.player_id,
            label: `${option?.first_name} ${option?.last_name}`
          }))}
          placeholder="Select player"
        ></Select>
      </Box>
    </Flex>
  );
};
