import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

export const capitaliseString = (string: string): string => {
  if (!string) return null;
  return startCase(toLower(string));
};

export const capitaliseFirstLetterOfString = (string: string) => {
  if (!string) return null;
  return string.charAt(0).toUpperCase() + toLower(startCase(toLower(string)).slice(1));
};

export const capitaliseAndPreserveCasing = (string: string): string => {
  if (!string) return null;
  return string
    .replace(/_/g, ' ')
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const convertBooleanToYesNo = (param: boolean): string => {
  if (param === true) return 'yes';
  return 'no';
};

export const convertYesNoToBoolean = (param: 'yes' | 'no'): boolean => {
  if (param === 'yes') return true;
  return false;
};

export const searchByString = (data: any[], searchString: string, searchProperty: string): any => {
  if (isEmpty(data)) return [];
  if (isEmpty(searchString) || searchString.trim() === '') return data;

  const searchWords = searchString
    .trim()
    .toLowerCase()
    .split(' ')
    .map((word: string) => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));

  const searchPatterns = searchWords.map((word: string | RegExp) => new RegExp(word, 'i'));

  return data.filter((element: { [x: string]: string; first_name: any; last_name: any }) => {
    let searchValue: string;
    if (searchProperty === 'full_name') {
      searchValue = `${element.first_name} ${element.last_name}`.toLowerCase();
    } else {
      searchValue = element[searchProperty].toLowerCase();
    }
    return searchPatterns.every((pattern: { test: (arg0: any) => any }) => pattern.test(searchValue));
  });
};

export function generateRandomId() {
  return Math.random().toString(36).substring(2, 9);
}

export const addApostrophe = (string: string): string => {
  if (!string) return '';

  // Check if the string is already plural (ends with 's')
  if (string.endsWith('s')) {
    return string + "'";
  } else {
    return string + "'s";
  }
};
