import Schedule from 'schedule/components/Schedule';

const routes = {
  path: 'schedule',
  children: [
    {
      path: ':tournamentId',
      element: <Schedule />
    }
  ]
};
export default routes;
