import PropTypes from 'prop-types';

import { courtArrowTypes } from './courtConstants';

export default function CourtArrow(props) {
  const { top, left, color, angle, arrowType, thumbnailSvgHeight } = props;
  let { numberSlots = 20, width = '142', height = '142', gap = 13 } = props;

  if (
    [
      courtArrowTypes.VOLLEY_STRAIGHT_LEFT,
      courtArrowTypes.VOLLEY_STRAIGHT_RIGHT,
      courtArrowTypes.VOLLEY_FROM_NET
    ].includes(arrowType)
  ) {
    numberSlots = 7;
  } else if ([courtArrowTypes.VOLLEYS].includes(arrowType)) {
    numberSlots = 12;
  } else if ([courtArrowTypes.BETWEEN_DROP_SHOT_AND_CENTER].includes(arrowType)) {
    numberSlots = 17;
  }
  const slotsArray = [];

  for (let i = 0; i <= numberSlots; i++) {
    const adjustY = i * gap;
    slotsArray.push(
      <g transform={`translate(0 ${adjustY})`} key={i}>
        <svg width={width} height={height} viewBox="0 0 142 742" fill="none">
          <path d="M0.5 57.5L71 0L141 57.5V85L71 28L0.5 85V57.5Z" fill={color} />
        </svg>
      </g>
    );
  }

  return (
    <svg height={thumbnailSvgHeight || '100%'}>
      <g style={{ WebkitTransform: `rotate(${angle}deg) translateY(${top}px) translateX(${left}px)` }}>
        <svg width={width} height={height} viewBox="0 0 142 742" fill="none">
          <path d="M0.5 57.5L71 0L141 57.5V142L71 85L0.5 142V57.5Z" fill={color} />
        </svg>
        {slotsArray.map((item) => item)}
      </g>
    </svg>
  );
}

CourtArrow.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  color: PropTypes.string,
  angle: PropTypes.number,
  arrowType: PropTypes.string.isRequired,
  thumbnailSvgHeight: PropTypes.string,
  numberSlots: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  gap: PropTypes.number
};
