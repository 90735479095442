import SinglePlay from 'match/components/winning-and-losing-plays/SinglePlay';
import { sortPlays } from 'match/utils/winningAndLosingPlaysUtil';
import PageHeading from 'matchReport/components/_shared/PageHeading';
import PropTypes from 'prop-types';
import PageLayout from 'scoutReport/components/PageLayout';

import { Box, Flex } from '_shared/designSystem/components';

export default function WinningAndLosingPlaysPageV2(props) {
  const { playerName, heading, data, playerDetailsData, volumeType, surface, playerType } = props;

  const orderedPlays = sortPlays(data || [], 'all', volumeType);

  return (
    <PageLayout playerName={playerName}>
      <PageHeading>{heading}</PageHeading>
      <Flex flexWrap="wrap" gap="15px" justify="center" rowGap="15px">
        {orderedPlays.map((item) => (
          <Box w="325px" key={item.play_id} border="solid 2px" borderColor="grey.200" px={3} pt={2} pb={1}>
            <SinglePlay
              surface={surface}
              dataNoAverages={item}
              volumeType={volumeType}
              isMatchPage={false}
              playerData={playerDetailsData}
              player={playerType === 'opponents' ? 'Opponents' : playerDetailsData?.player_last_name}
            />
          </Box>
        ))}
      </Flex>
    </PageLayout>
  );
}

WinningAndLosingPlaysPageV2.propTypes = {
  playerName: PropTypes.string,
  heading: PropTypes.string,
  surface: PropTypes.string,
  data: PropTypes.array,
  volumeType: PropTypes.string,
  playerDetailsData: PropTypes.object,
  playerType: PropTypes.string
};
