import { useEffect } from 'react';

import { useNavigate, useSearch } from '@tanstack/react-location';
import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';

import customSitesConfig from '_shared/config/customSitesConfig';
import { LoadingSpinner } from '_shared/designSystem/components';
import { matchPageGoBackToState } from '_shared/globalState/atoms';

import { wimbUserPlayerState } from '../globalState/wimbAtoms';
import { setWimbPlayerId } from '../services/wimbLocalStorage';
import { wimbService } from '../services/wimbService';
import { getCurrentDefaultTournamentId } from '../util/tournamentUtil';

export default function PlayerLanding() {
  const { tour_id } = useSearch();
  const navigate = useNavigate();
  const setWimbUserPlayerState = useSetRecoilState(wimbUserPlayerState);
  const setMatchPageGoBackToState = useSetRecoilState(matchPageGoBackToState);

  const { data, isLoading, error } = useQuery(['wimbService.getPlayerDetails'], () =>
    wimbService.getPlayerDetails({ tourId: tour_id })
  );

  useEffect(() => {
    if (data) {
      if (data?.error?.includes('No player found with tour id')) {
        navigate({ to: customSitesConfig.redirectAfterLogin });
      } else if (data.player_id && data.gender) {
        setWimbUserPlayerState({
          gender: data?.gender,
          playerId: data?.player_id
        });
        setWimbPlayerId(data?.player_id);
        if (data.match_id) {
          setMatchPageGoBackToState(`/schedule/${getCurrentDefaultTournamentId(data?.gender)}`);
          navigate({ to: `/match/${data.match_id}` });
        } else {
          navigate({ to: `/schedule/${getCurrentDefaultTournamentId(data?.gender)}` });
        }
      }
    } else if (error) {
      navigate({ to: customSitesConfig.redirectAfterLogin });
    }
  }, [data, error, tour_id, setWimbUserPlayerState, setMatchPageGoBackToState, navigate]);

  if (isLoading) return <LoadingSpinner />;

  return null;
}
