import PropTypes from 'prop-types';

import { Text, Center, Flex, Icon, Box } from '_shared/designSystem/components';

const AcceptanceRow = ({ tickValue, handleTick, id, openModalLinkText, handleDocsClick }) => (
  <Flex align="center" gap={4}>
    <Center onClick={() => handleTick(id)} cursor="pointer" border="1.5px solid" borderColor="primary.500" w={6} h={6}>
      {tickValue && <Icon name="tick" color="primary.500" height={5} width={5} />}
    </Center>
    <Box>
      <Text as="span" fontSize="lg">
        {`I agree to the `}
      </Text>
      <Text as="span" fontSize="lg" cursor="pointer" color="primary.700" onClick={() => handleDocsClick(id)}>
        {openModalLinkText}
      </Text>
    </Box>
  </Flex>
);

export default AcceptanceRow;

AcceptanceRow.propTypes = {
  tickValue: PropTypes.bool,
  handleTick: PropTypes.func,
  id: PropTypes.string,
  openModalLinkText: PropTypes.string,
  handleDocsClick: PropTypes.func
};
