import isEmpty from 'lodash/isEmpty';

export function getMinYRating(data) {
  if (isEmpty(data)) return 0;
  const minRatingPerGame = data.map((item) =>
    Math.min(item.rolling_performance_average_p1, item.rolling_performance_average_p2)
  );
  const minRatingAcrossAllGames = Math.floor(Math.min(...minRatingPerGame));
  return minRatingAcrossAllGames < 5 ? minRatingAcrossAllGames : 5;
}

export function getShowBreakOfServe(performanceRatingGame, index) {
  if (isEmpty(performanceRatingGame)) return false;
  if (index >= performanceRatingGame.length - 1) return false; // last game
  const nextGame = performanceRatingGame[index + 1];
  return nextGame?.break_of_serve === 1;
}

export function getShowGameScore(performanceRatingGame, index) {
  if (isEmpty(performanceRatingGame)) return false;
  const lastGame = performanceRatingGame.at(-1);
  if (index === performanceRatingGame.length - 1 && lastGame?.score_p1 === 0 && lastGame?.score_p2 === 0) return false;
  const penultimateGame = performanceRatingGame.at(-2);
  const lastTwoGamesHaveSameScore =
    lastGame?.score_p1 === penultimateGame?.score_p1 && lastGame?.score_p2 === penultimateGame?.score_p2;
  return !(index === performanceRatingGame.length - 1 && lastTwoGamesHaveSameScore);
}

export function adjustDataForTieBreaks(data) {
  if (isEmpty(data)) return null;
  const updated = data.map((item, index) => {
    if (index > 0 && item.server === 'player0') {
      item.server = data[index - 1].server === 'player1' ? 'player2' : 'player1';
    }
    return item;
  });
  return updated;
}
