import { standardColors } from '_shared/designSystem/theme/colors';

const wimbColors = {
  purple: '#54008b',
  green: '#176B3D',
  darkPurple: '#390063',
  darkGreen: '#004125'
};

const siteSpecific = {
  primary: {
    25: standardColors.grey[25],
    50: standardColors.grey[50],
    100: standardColors.purple[100],
    200: wimbColors.green,
    300: wimbColors.green,
    400: wimbColors.green,
    500: wimbColors.green,
    600: wimbColors.green,
    700: wimbColors.darkGreen,
    800: wimbColors.darkGreen,
    900: wimbColors.darkGreen
  },
  secondary: {
    25: '#e1d1eb',
    50: '#bea6cd',
    100: '#7D5B8A',
    200: '#6B4972',
    300: '#5B3A5D',
    400: '#4C2D4B',
    500: '#3F233C',
    600: '#361A32',
    700: '#2D1328',
    800: wimbColors.darkPurple,
    900: '#260042'
  },
  grey: {
    25: standardColors.grey[25],
    50: standardColors.grey[50],
    100: standardColors.grey[100],
    200: standardColors.grey[200],
    300: standardColors.grey[300],
    400: standardColors.grey[400],
    500: wimbColors.purple,
    600: wimbColors.darkPurple,
    700: wimbColors.darkPurple,
    800: wimbColors.darkPurple,
    900: wimbColors.darkPurple
  },
  wimbGreen: {
    25: '#e0f8eb',
    50: '#e0f8eb',
    100: '#e0f8eb',
    200: '#a1ebc2',
    300: '#63dd9a',
    400: '#63dd9a',
    500: '#2bc972',
    600: '#2bc972',
    700: '#52B57F',
    800: '#52B57F',
    900: '#52B57F'
  },
  performanceCircleLightGreen: {
    200: '#73a68a'
  }
};

const featureSpecificColors = {
  black: '#000000',
  white: '#FFFFFF',
  court: {
    background: {
      hard: '#AACCA3',
      clay: '#F8A88F',
      grass: '#CFB175',
      indoor: '#81CAE9'
    },
    surface: {
      hard: {
        hex: '#637CB2',
        opacityStart: 1,
        opacityEnd: 0.9
      },
      clay: {
        hex: '#CC4400',
        opacityStart: 0.8,
        opacityEnd: 0.7
      },
      grass: {
        hex: '#8EB25E',
        opacityStart: 1,
        opacityEnd: 0.9
      },
      indoor: {
        hex: '#637CB2',
        opacityStart: 1,
        opacityEnd: 0.9
      }
    }
  },
  brightYellow: '#E4F53B'
};

const colors = {
  ...standardColors,
  ...siteSpecific,
  ...featureSpecificColors,
  illustration: {
    purple: '#a07cc9',
    green: '#056736',
    lightGreen: '#7aa83b',
    lightBlue: '#8dbaee',
    blue: '#002855',
    dustyPink: '#f1d3d5',
    strawberries: '#e04e39',
    lime: '#dbe442',
    yellow: '#ffec2d',
    apple: '#6dac88',
    orange: '#e87723',
    cream: '#f8fef0'
  }
};

colors.playerAverage = '#dbe442';
colors.tourAverage = '#9A803A';
colors.matchAverage = wimbColors.green;

export default colors;
