import { Box, Flex, useBreakpointValue } from '@ellipsedata/tennis';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import Icon from '../icon/_Icon';

const VideoPlayer = ({
  url,
  width,
  height,
  handleVideoEnd,
  hasPlaylist,
  handlePlayPrev,
  handlePlayNext,
  disablePrev,
  disableNext,
  isPlaying,
  setIsPlaying
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const showControlsOnVideo = false;

  const PlaylistControlsMobile = () => (
    <Flex
      justifyContent="center"
      position="absolute"
      bottom="40%"
      right="none"
      width="100%"
      py="4"
      gap={8}
      alignItems="center"
    >
      <ControlButton
        onClick={handlePlayPrev}
        iconName="playPrevious"
        disabled={disablePrev}
        color="white"
        iconLabel="skip-previous"
      />
      <ControlButton onClick={() => setIsPlaying(true)} iconName="play" color="white" width={8} height={8} />
      <ControlButton
        onClick={handlePlayNext}
        iconName="playNext"
        disabled={disableNext}
        color="white"
        iconLabel="skip-next"
      />
    </Flex>
  );

  return (
    <Box position="relative">
      <ReactPlayer
        url={url}
        width={width}
        height={height}
        onEnded={handleVideoEnd}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        playing={isPlaying}
        data-testid="react-player"
        controls
        playsinline
        config={{
          file: {
            attributes: {
              disablePictureInPicture: true,
              controlslist: 'nodownload noremoteplayback noplaybackrate',
              'webkit-playsinline': true,
              'x5-playsinline': true
            }
          }
        }}
      />
      {hasPlaylist && isMobile && !isPlaying && showControlsOnVideo && <PlaylistControlsMobile />}
    </Box>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  handleVideoEnd: PropTypes.func,
  hasPlaylist: PropTypes.bool,
  handlePlayPrev: PropTypes.func,
  handlePlayNext: PropTypes.func,
  disablePrev: PropTypes.bool,
  disableNext: PropTypes.bool,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func
};

VideoPlayer.defaultProps = {
  url: '',
  width: '100%',
  height: '100%',
  hasPlaylist: false
};

export default VideoPlayer;

const ControlButton = ({ onClick, iconName, iconLabel, disabled, color, height = 6, width = 6 }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    borderRadius="3"
    cursor={disabled ? null : 'pointer'}
    opacity={disabled ? 0.5 : 1}
  >
    <Icon
      name={iconName}
      color={color}
      height={height}
      width={width}
      onClick={onClick}
      data-testid={`${iconLabel}-button`}
    />
  </Flex>
);
