import { useQuery } from 'react-query';

import { apiClient } from '_shared/dataFetching/apiConfig';
import { getSiteParam } from '_shared/dataFetching/urlConfig';

export const playersService = {
  async getPlayers() {
    const url = `players?${getSiteParam()}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  }
};

export const useGetPlayers = () => {
  return useQuery(['playersService_get_players'], () => playersService.getPlayers(), {
    staleTime: Infinity
  });
};
