import { Text, Flex, Box, UnorderedList, ListItem } from '_shared/designSystem/components';
import { isWimbPortal, isTvPortal } from '_shared/utils/environment/currentEnv';

const paragraphFont = isWimbPortal() ? 'paragraphText' : 'sm';

export const NormalParagraphText = ({ children }) => (
  <Text fontSize={paragraphFont} mb={2}>
    {children}
  </Text>
);

export const ShotQualityTableRow = ({ heading, value }) => (
  <Flex>
    <Text w="100px" fontSize={paragraphFont}>
      {heading}
    </Text>
    <Text w="50px" fontSize={paragraphFont}>
      {value}
    </Text>
  </Flex>
);

export const Paragraph = ({ children }) => (
  <Flex direction="column" gap={4}>
    {children}
  </Flex>
);

export const SubHeading = ({ children }) => (
  <Box mb={3}>
    <Text fontSize="lg" color="secondary.800" fontWeight="bold">
      {children}
    </Text>
  </Box>
);

export const InsightsListItem = ({ children }) => (
  <ListItem>
    <Text fontSize={paragraphFont}>{children}</Text>
  </ListItem>
);

export function getCustomText(type) {
  if (isWimbPortal()) {
    switch (type) {
      case 'inAttack':
        return (
          <NormalParagraphText>
            <b>Insight: </b>The 2024 Gentleman's draw average In Attack score was 24%. The 2024 Ladies's draw average In Attack score was 24%. The player who had a higher In Attack score won the match 68% of the time.
          </NormalParagraphText>
        );
      case 'conversion':
        return (
          <NormalParagraphText>
            <b>Insight: </b>The 2024 Gentleman's draw average Conversion score was 70%. The 2024 Ladies's draw average Conversion score was 67%. This highlights the benefit of having a higher In Attack score.
          </NormalParagraphText>
        );
      case 'steal':
        return (
          <NormalParagraphText>
            <b>Insight: </b>The 2024 Gentleman's draw average Steal score was 30%. The 2024 Ladies' draw average Steal score was 33%. However, the best defenders can average close to or even above 40%.
          </NormalParagraphText>
        );
      case 'shotQuality':
        return (
          <>
            <NormalParagraphText>
              <b>Insight: </b> The 2024 Gentleman's Shot Quality draw averages were:
            </NormalParagraphText>
            <Flex direction="column">
              <ShotQualityTableRow heading="Serve" value="7.9" />
              <ShotQualityTableRow heading="Return" value="6.5" />
              <ShotQualityTableRow heading="Forehand" value="7.5" />
              <ShotQualityTableRow heading="Backhand" value="7.2" />
            </Flex>
            <NormalParagraphText>The 2024 Ladies' Shot Quality draw averages were:</NormalParagraphText>
            <Flex direction="column">
              <ShotQualityTableRow heading="Serve" value="7.6" />
              <ShotQualityTableRow heading="Return" value="6.9" />
              <ShotQualityTableRow heading="Forehand" value="7.4" />
              <ShotQualityTableRow heading="Backhand" value="7.1" />
            </Flex>
          </>
        );
      case 'performanceRating':
        return (
          <NormalParagraphText>
            <b>Insight: </b> The 2024 Gentleman's Performance Rating draw average was 7.47. The 2024 Ladies' Performance Rating draw average was 7.59.
          </NormalParagraphText>
        );
      case 'faqs':
        return (
          <Box>
            <SubHeading>FAQ's</SubHeading>
            <Paragraph>
              <NormalParagraphText>
                What is a <b>Pressure Point</b>? A Pressure Point is any point leading to a break point, break point
                itself, and any point in a tie-break.
              </NormalParagraphText>
              <NormalParagraphText>
                Pressure Points = 0-30, 0-40, 15-30, 15-40, 30-30, 30-40, 40-40, 40-Adv
              </NormalParagraphText>
            </Paragraph>
          </Box>
        );
      case 'serveEffecttiveness':
        return (
          <NormalParagraphText>
            <b>Insight: </b>The 2023 Gentleman’s 1st Serve Effectiveness draw average was 62.3%. The Ladies’ 1st Serve
            Effectiveness was 43.8%.
          </NormalParagraphText>
        );
      case 'winningAndLosingPlays':
        return (
          <NormalParagraphText>
            <b>Insight: </b>The most common Winning Play at the 2023 Championships was the crosscourt backhand exchange.
          </NormalParagraphText>
        );
      default:
        return null;
    }
  } else {
    switch (type) {
      case 'inAttack':
        return (
          <>
            <NormalParagraphText>
              <b>Insight: </b>The men's tour average In Attack score is 24%{isTvPortal() && ' and the women’s tour average In Attack score is 24%'}.
            </NormalParagraphText>
          </>
        );
      case 'conversion':
        return (
          <NormalParagraphText>
            <b>Insight: </b>The men's tour average Conversion score is 67%{isTvPortal() && ' and the women’s tour average Conversion score is 66%'}. This highlights the benefit of having a higher In
            Attack score.
          </NormalParagraphText>
        );
      case 'steal':
        return (
          <NormalParagraphText>
            <b>Insight: </b>The men's tour average Steal score is 33%{isTvPortal() && ' and the women’s tour average Steal score is 34%'}. However, the best defenders can average close to or
            even above 40%.
          </NormalParagraphText>
        );
      case 'shotQuality':
        return (
          <>
            <NormalParagraphText>
              <b>Insight: </b>The men's Shot Quality tour averages are:
            </NormalParagraphText>
            <Flex direction="column">
              <ShotQualityTableRow heading="Serve" value="7.9" />
              <ShotQualityTableRow heading="Return" value="6.5" />
              <ShotQualityTableRow heading="Forehand" value="7.5" />
              <ShotQualityTableRow heading="Backhand" value="7.1" />
            </Flex>
            {isTvPortal() && (
              <>
                <NormalParagraphText>
                  The women's Shot Quality tour averages are:
                </NormalParagraphText>
                <Flex direction="column">
                  <ShotQualityTableRow heading="Serve" value="7.5" />
                  <ShotQualityTableRow heading="Return" value="6.8" />
                  <ShotQualityTableRow heading="Forehand" value="7.4" />
                  <ShotQualityTableRow heading="Backhand" value="7.2" />
                </Flex>
            </>)}
          </>
        );
      case 'performanceRating':
        return (
          <NormalParagraphText>
            <b>Insight: </b>The men's tour average Performance Rating is 7.45{isTvPortal() && ' and the women’s tour average Performance Rating is 7.55'}.
          </NormalParagraphText>
        );
      case 'faqs':
        return (
          <Box>
            <SubHeading>FAQs</SubHeading>
            <Paragraph>
              <NormalParagraphText>
                What is a <b>Pressure Point</b>? A Pressure Point is any point leading to a break point and a break
                point itself.
              </NormalParagraphText>
              <NormalParagraphText>
                Pressure Points = 0-30, 0-40, 15-30, 15-40, 30-30, 30-40, 40-40, 40-Adv
              </NormalParagraphText>
              <NormalParagraphText>
                How are the <b>Tour and Player averages</b> calculated?
              </NormalParagraphText>
              <Flex direction="column">
                <UnorderedList>
                  <InsightsListItem>
                    Tour averages are calculated using main draw matches from the last 52 weeks at ATP 250, 500, 1000,
                    United Cup, Next-Gen and Tour Finals.
                  </InsightsListItem>
                  <InsightsListItem>
                    Player averages are calculated using all available matches on the ATP Tour in the last 52 weeks.
                  </InsightsListItem>
                  <InsightsListItem>
                    Performance Rating and Shot quality player averages are displayed for players with five or more
                    main-draw matches of tracking data available in the last 52 weeks.
                  </InsightsListItem>
                </UnorderedList>
              </Flex>
            </Paragraph>
          </Box>
        );
      default:
        return null;
    }
  }
}
