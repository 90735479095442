import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import { viewingTypes } from 'schedule/constants/scheduleConstants';

export function getOrderOfPlay(matches, daySequence) {
  if (isEmpty(matches)) return [];
  if (daySequence === '') return matches;
  const matchesForSelectedDay = matches.filter((item) => item.day_sequence === daySequence);
  const groupedMatches = values(groupBy(matchesForSelectedDay, 'court_id'));
  const orderedGroupMatches = groupedMatches.map((item) => orderBy(item, ['court_sequence'], ['asc']));
  return orderedGroupMatches;
}

export function getDays(matches) {
  if (isEmpty(matches)) return [];
  const days = Array.from(new Set(matches.map((item) => item.day_sequence)));
  return sortBy(days);
}

export function setFormStateAndNavigate(formState, setFormState, param, value, navigate, setViewingType) {
  const newFormState = { ...formState };
  let newValue;
  if (['round', 'day'].includes(param)) {
    switch (param) {
      case 'round':
        newValue = value === formState.round ? 'All' : value;
        newFormState.round = newValue;
        newFormState.day = null;
        setViewingType(viewingTypes.LIST_VIEW);
        break;
      case 'day':
        newValue = value === '' ? null : parseInt(value);
        newFormState.day = newValue;
        newFormState.round = null;
        setViewingType(newValue === null ? viewingTypes.LIST_VIEW : viewingTypes.COURT_VIEW);
        break;
      default:
    }
    setFormState({ ...newFormState });
    navigate({
      search: () => ({ ...newFormState })
    });
  } else if (param.playerName !== null) {
    const newFormStatePlayerName = { ...formState };
    setFormState({ ...newFormStatePlayerName, playerName: param.playerName });
    navigate({ search: (old) => ({ ...old, playerName: param.playerName }) });
  }
}

export function getScheduleRoundsData(data) {
  if (isEmpty(data)) return [];
  if (isEmpty(data.matches)) return [];
  const result = [];
  data.matches.forEach((element) => {
    result.push(element.round);
  });
  result.push('All');
  return uniq(result);
}
