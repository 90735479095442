import { Worker, Viewer } from '@react-pdf-viewer/core';
import PropTypes from 'prop-types';

import { Box, Modal, ModalCloseButton, ModalContent, ModalOverlay } from '_shared/designSystem/components';

export default function PdfViewModal(props) {
  const { isOpen, onClose, pdfUrl } = props;
  return (
    <Modal isOpen={isOpen} size="6xl" onClose={onClose}>
      <ModalOverlay bg="#1a202c" />
      <ModalContent>
        <ModalCloseButton size="lg" />
        <Box pt="50px">
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
            <Box>
              <Viewer fileUrl={pdfUrl} />
            </Box>
          </Worker>
        </Box>
      </ModalContent>
    </Modal>
  );
}

PdfViewModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pdfUrl: PropTypes.string
};
