import { useEffect } from 'react';

import { useNavigate } from '@tanstack/react-location';
import { matchListService } from 'matchList/services/matchListService';
import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';

import { Box, Flex, Text, ErrorMessage, Spinner } from '_shared/designSystem/components';
import { mobileHeaderTextState } from '_shared/globalState/atoms';
import { navigateTo } from '_shared/utils/navigationUtils';

import MatchListTable from './MatchListTable';

export default function MatchList() {
  const setMobileHeaderText = useSetRecoilState(mobileHeaderTextState);

  const { isLoading, error, data } = useQuery(['matchListService_getMatches'], () => matchListService.getMatches(), {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true
  });

  const navigate = useNavigate();

  useEffect(() => {
    setMobileHeaderText('Live Matches');
  }, [setMobileHeaderText]);

  useEffect(() => {
    sessionStorage.removeItem('tournamentSearch');
  }, []);

  const openMatchPage = (matchId, navigate) => {
    navigateTo({
      url: `/match/${matchId}/performance`,
      navigate,
      storeScroll: window.location.pathname.includes('/match-list') ? true : false
    });
  };

  if (error)
    return (
      <>
        <TitleHeader />
        <ErrorMessage message="Unable to retrieve matches, please try refreshing the page." />
      </>
    );

  if (isLoading)
    return (
      <>
        <TitleHeader />
        <Flex pt={100} justify="center">
          <Spinner color="primary.500" />
        </Flex>
      </>
    );

  return (
    <Box>
      <TitleHeader />
      <MatchListTable data={data} openMatchPage={openMatchPage} navigate={navigate} showTournamentColumn={true} />
    </Box>
  );
}

const TitleHeader = () => (
  <Box h="66px" maxH={{ base: 0, md: '66px' }} display={{ base: 'none', md: 'flex' }}>
    <Text fontSize="3xl" fontWeight="medium" color="secondary.800">
      Live Matches
    </Text>
  </Box>
);
