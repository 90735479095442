import { Button } from '@chakra-ui/react';

import { isTvPortal, isWimbPortal } from '_shared/utils/environment/currentEnv';

export const NavBarButton = (props) => {
  const { isSelected, label } = props;

  const bg = isSelected ? (isTvPortal() ? 'primary.300' : 'primary.500') : 'secondary.800';
  const hover = isSelected ? { background: 'primary.500' } : { background: 'grey.600' };
  const color = isSelected && isTvPortal() ? 'charcoal.300' : 'white';

  return (
    <Button
      size={isWimbPortal() ? 'xs' : 'sm'}
      outline="none"
      bg={bg}
      _hover={hover}
      color={color}
      h="28px"
      borderRadius="md"
    >
      {label}
    </Button>
  );
};
