import PropTypes from 'prop-types';

import { Flex, Text, Center, Icon } from '_shared/designSystem/components';
export default function MatchSelectorModalButton(props) {
  const { openMatchSelector, noOfSelectedMatches } = props;
  return (
    <Flex cursor="pointer" onClick={openMatchSelector}>
      <Center
        bg={noOfSelectedMatches > 0 ? 'primary.100' : 'white'}
        borderRadius="md"
        px="12px"
        borderWidth="1px"
        borderColor="grey.300"
        h="32px"
      >
        <Flex gap={1}>
          <Text fontSize="xs" fontWeight="medium" color="grey.700" whiteSpace="nowrap">
            Select Matches
          </Text>
          {noOfSelectedMatches > 0 && (
            <Text fontSize="xs" fontWeight="medium" color="grey.700" whiteSpace="nowrap">
              ({noOfSelectedMatches})
            </Text>
          )}
          <Center boxSize="20px">
            <Icon name="filter" color="grey.700" height={4} width={4} />
          </Center>
        </Flex>
      </Center>
    </Flex>
  );
}

MatchSelectorModalButton.propTypes = {
  noOfSelectedMatches: PropTypes.number,
  openMatchSelector: PropTypes.func
};
