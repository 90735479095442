import { displayScore, zeroOrMore } from 'match/utils/transformations';
import PropTypes from 'prop-types';

import { Flex, Text, Badge, Center } from '_shared/designSystem/components';

export default function StatWithFhBh(props) {
  const { scores, scoreTypeToDisplay } = props;

  switch (scoreTypeToDisplay) {
    case 'score':
      return (
        <Flex direction="column" gap={1} w="100px">
          <Flex gap={1}>
            <Center w="35px">
              <Badge bgColour="blue.50" iconColour="blue.500" textColour="blue.700" text="FH" />
            </Center>
            <Text fontSize="xs" color="grey.600" fontWeight="normal" whiteSpace="nowrap">
              {displayScore(scores?.forehand?.score, scores?.forehand?.score_type)}
            </Text>
            <Text fontSize="xs" color="grey.400" fontWeight="normal" whiteSpace="nowrap">
              {zeroOrMore(scores?.forehand?.frequency)
                ? ` (${scores?.forehand?.frequency}/${scores?.forehand?.frequency_total})`
                : ''}
            </Text>
          </Flex>
          <Flex gap={1}>
            <Center w="35px">
              <Badge bgColour="blue.50" iconColour="blue.500" textColour="blue.700" text="BH" w="20px" />
            </Center>
            <Text fontSize="xs" color="grey.600" fontWeight="normal" whiteSpace="nowrap">
              {displayScore(scores?.backhand?.score, scores?.backhand?.score_type)}
            </Text>
            <Text fontSize="xs" color="grey.400" fontWeight="normal" whiteSpace="nowrap">
              {zeroOrMore(scores?.backhand?.frequency)
                ? ` (${scores?.backhand?.frequency}/${scores?.backhand?.frequency_total})`
                : ''}
            </Text>
          </Flex>
        </Flex>
      );
    case 'playerAverage':
      return (
        <Flex direction="column" w="100px" align="center">
          <Text fontSize="xs" color="grey.600" fontWeight="normal" whiteSpace="nowrap">
            {displayScore(scores?.forehand?.player_average, scores?.forehand?.score_type)}
          </Text>
          <Text fontSize="xs" color="grey.600" fontWeight="normal" whiteSpace="nowrap">
            {displayScore(scores?.backhand?.player_average, scores?.backhand?.score_type)}
          </Text>
        </Flex>
      );
    case 'tourAverage':
      return (
        <Flex direction="column" w="100px" align="center">
          <Text fontSize="xs" color="grey.600" fontWeight="normal" whiteSpace="nowrap">
            {displayScore(scores?.forehand?.tour_average, scores?.forehand?.score_type)}
          </Text>
          <Text fontSize="xs" color="grey.600" fontWeight="normal" whiteSpace="nowrap">
            {displayScore(scores?.backhand?.tour_average, scores?.backhand?.score_type)}
          </Text>
        </Flex>
      );
    default:
      return;
  }
}

StatWithFhBh.propTypes = {
  scoreTypeToDisplay: PropTypes.string,
  scores: PropTypes.object
};
