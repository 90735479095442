import { Box } from '_shared/designSystem/components';

import { AveragesReturnPlacementHorizontalGroup } from './AveragesReturnPlacement';

export const AveragesHorizontal = (props) => {
  const { width, height, coordinates } = props;
  return (
    <Box>
      <Box position="absolute" top={height * coordinates.top.top} left={width * coordinates.top.left}>
        <AveragesReturnPlacementHorizontalGroup level="top" {...props} />
      </Box>
      <Box position="absolute" top={height * coordinates.middle.top} left={width * coordinates.middle.left}>
        <AveragesReturnPlacementHorizontalGroup level="middle" {...props} />
      </Box>
      <Box position="absolute" top={height * coordinates.bottom.top} left={width * coordinates.bottom.left}>
        <AveragesReturnPlacementHorizontalGroup level="bottom" {...props} />
      </Box>
    </Box>
  );
};
