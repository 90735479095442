import { select, axisBottom } from 'd3';
import { getShowBreakOfServe, getShowGameScore } from 'match/utils/performanceGraphShadedUtil';

import colors from '_shared/designSystem/theme/colors';

export function drawXAxis(svg, width, height, yMargin, xScale, lineColors, performanceRatingGame) {
  // creating the x-axis label boxes
  const minWidthToShowXAxis = 350;
  const matchScoreYAdjustment = 40;
  const matchScoreYAdjustmentSecondRow = 10;
  const labelBoxWidth = 15;
  const labelBoxHeight = 20;
  if (width > minWidthToShowXAxis) {
    svg
      .append('g') // add this as a group
      .attr('class', 'x-axis')
      .style('stroke-width', 0) // hide the x-axis line as we already have it from the y-axis tick line
      .attr('transform', `translate(0, ${height - yMargin})`) // move axis from default position of the top to down the bottom
      .call(axisBottom(xScale).tickSize(0)) // remove the tick lines from the x-axis, don't need them as we're drawing our own boxes
      .selectAll('.tick text') // iterating through all the ticks
      .each(function (matchScore, index) {
        // for all of the matchScores on the x-axis
        const parts = matchScore.split('-');
        const player1Games = parts[0];
        const player2Games = parts[1];
        const server = parts[2];
        const breakOfServe = getShowBreakOfServe(performanceRatingGame, index); // manually setting the break of serve to show one game previously
        const showGameScore = getShowGameScore(performanceRatingGame, index); // for a live match, we want to hide the game score if the game is in progress

        const tick = select(this);
        const xPosition = xScale(matchScore); // use the xScale to position our boxes

        if (showGameScore) {
          svg
            .append('rect') // make a rectangle for the top box, for player1 games
            .attr('x', xPosition - 7.5) // manual positioning adjustment
            .attr('y', height + matchScoreYAdjustment - 30) // bit fiddly, but adjusting to get it in the right place
            .attr('width', labelBoxWidth)
            .attr('height', labelBoxHeight)
            .style('fill', getBoxColor(server, breakOfServe, 'player1')); // background colour of the top box
          svg
            .append('rect') // rectangle for the bottom game score
            .attr('x', xPosition - 7.5)
            .attr('y', height + matchScoreYAdjustment - 15 + matchScoreYAdjustmentSecondRow) // extra manual positioning
            .attr('width', labelBoxWidth)
            .attr('height', labelBoxHeight)
            .style('fill', getBoxColor(server, breakOfServe, 'player2'));
          svg
            .append('text') // this is the actual game score text for player1 games, placed inside the box
            .attr('x', xPosition)
            .attr('y', height + matchScoreYAdjustment - 17) // some manual adjustment to get inside the box nicely
            .style('fill', colors.grey[700]) // text colour
            .style('text-anchor', 'middle')
            .style('font-size', '12px')
            .text(player1Games);
          svg
            .append('text')
            .attr('x', xPosition)
            .attr('y', height + matchScoreYAdjustment + matchScoreYAdjustmentSecondRow)
            .style('fill', colors.grey[700])
            .style('text-anchor', 'middle')
            .style('font-size', '12px')
            .text(player2Games);
        }
        tick.style('display', 'none');
      });

    // show the chips for which row is which player
    const chipX = 20;
    const chipW = 8;
    svg
      .append('rect')
      .attr('x', chipX)
      .attr('y', height + matchScoreYAdjustment - 30)
      .attr('width', chipW)
      .attr('height', labelBoxHeight)
      .style('fill', lineColors?.player1Line);

    svg
      .append('rect')
      .attr('x', chipX)
      .attr('y', height + matchScoreYAdjustment - 15 + matchScoreYAdjustmentSecondRow)
      .attr('width', chipW)
      .attr('height', labelBoxHeight)
      .style('fill', lineColors?.player2Line);
  }
}

function getBoxColor(server, breakOfServe, player) {
  if (breakOfServe && server === player) return colors.blue[200];
  if (server === player || server === 'player0') return colors.white;
  return colors.grey[200];
}
