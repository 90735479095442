import { useRecoilState } from 'recoil';

import { insightsExplainedState } from '_shared/globalState/atoms';

/**
 * Usage:
 *
 * import { useInsightsExplained } from 'insightsExplained/hooks/useInsightsExplained';
 * import { ACCORDION_TITLE } from 'insightsExplained/constants';
 *
 * const { openDrawerWithAccordion } = useInsightsExplained();
 *
 * onClick={() => openDrawerWithAccordion()}  // Opens the drawer with no accordion opened
 *
 * onClick={() => openDrawerWithAccordion(ACCORDION_TITLE.IN_ATTACK)}  // Opens the drawer and the "In Attack" accordion
 */

export const useInsightsExplained = () => {
  const [state, setState] = useRecoilState(insightsExplainedState);

  const openDrawerWithAccordion = (accordionTitle = null) => {
    setState({
      isOpen: true,
      activeAccordionTitle: accordionTitle
    });
  };

  const closeDrawer = () => {
    setState({
      isOpen: false,
      activeAccordionTitle: null
    });
  };

  return {
    isOpen: state.isOpen,
    activeAccordionTitle: state.activeAccordionTitle,
    openDrawerWithAccordion,
    closeDrawer
  };
};
