import { Box, Flex, Tooltip, Icon, Text } from '_shared/designSystem/components';
import colors from '_shared/designSystem/theme/colors';

// Standalone component for rendering the court graphic
const ServeLocationCourtGraphic = ({ surfaceColor }) => {
  const serviceBoxLetters = [
    ['W', 'B', 'T'], // DEUCE side
    ['T', 'B', 'W'] // ADV side
  ];

  const renderRow = (serviceLocations) => (
    <Flex direction="row">
      {serviceLocations.map((location, index) => (
        <Box
          key={location}
          h="60px"
          w="20px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="white"
          borderRight={index < serviceLocations.length - 1 ? '1px dotted white' : 'none'}
        >
          {location}
        </Box>
      ))}
    </Flex>
  );

  return (
    <Flex direction="column" w="130px" alignItems="center">
      <Flex direction="row" w="124px">
        <Box w="50%" display="flex" justifyContent="center" alignItems="center">
          <Text textAlign="center">Deuce</Text>
        </Box>
        <Box w="50%" display="flex" justifyContent="center" alignItems="center">
          <Text textAlign="center">Adv</Text>
        </Box>
      </Flex>
      <Flex h="62px" w="124px" direction="row" bg={surfaceColor} border="1px solid white" borderBottom="none">
        {serviceBoxLetters.map((letter, index) => (
          <Box key={index} borderRight={index === 0 ? '2px solid white' : 'none'}>
            <Text textAlign="center">{renderRow(letter)}</Text>
          </Box>
        ))}
      </Flex>
      <Box w="130px" border={'2px solid white'} />
    </Flex>
  );
};

const ServiceBoxLocationGraphicTooltip = ({ surface }) => {
  const InfoIcon = <Icon name="info" color="grey.900" height={4} width={4} />;

  const surfaceColor = colors.court.surface[surface]?.hex || 'transparent';

  return (
    <Tooltip
      tooltipText={<ServeLocationCourtGraphic surfaceColor={surfaceColor} />}
      elementToHover={InfoIcon}
      placement="bottom"
      bg={surfaceColor}
    />
  );
};

export default ServiceBoxLocationGraphicTooltip;
