import { wimbFontsGuidelines } from './fontsSpec';

export function convertFontSizeToWimbTextGuidelines(chakraProps) {
  /**
   * Wimbledon uses very specific branding guidelines for digital text, which is as follows:
   *
   * Normal text
   * Gotham book, no caps, char space 0
   *
   * H5 - small titles, dates and times
   * Gotham medium, caps, char space 50
   *
   * H4 - small headlines
   * Gotham medium, no caps, char space 0
   *
   * H3 - secondary titles
   * Gotham medium, caps, char space 50
   *
   * H2 - titles
   * Gotham medium, caps, char spacing 100
   *
   * H1 - headlines
   * Gotham Medium, no caps, char space 0
   *
   * The method used here is to take the fontSize, and adjust accordingly purely based on that
   */

  switch (chakraProps.fontSize) {
    case 'xs':
      return wimbFontsGuidelines.normal;
    case 'sm':
      return wimbFontsGuidelines.h5;
    case 'md':
      return wimbFontsGuidelines.h4;
    case 'lg':
      return wimbFontsGuidelines.h3;
    case 'xl':
      return wimbFontsGuidelines.h2;
    case '2xl':
    case '3xl':
    case '4xl':
    case '5xl':
    case '6xl':
      return wimbFontsGuidelines.h2;
    case 'paragraphText':
      return wimbFontsGuidelines.paragraphText;
    case 'mainMenuItem':
      return wimbFontsGuidelines.mainMenuItem;
    case 'matchCardPlayerNames':
      return wimbFontsGuidelines.matchCardPlayerNames;
    default:
      return wimbFontsGuidelines.h4;
  }
}
