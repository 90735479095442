import React from 'react';

import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';

import { Flex, Text } from '_shared/designSystem/components';

export const CourtBadgeStyles = {
  baseStyle: {
    borderRadius: '2xl',
    px: 2,
    justify: 'center',
    alignItems: 'center'
  },
  variants: {
    matchAverage: {
      bg: 'matchAverage',
      color: 'white'
    },
    primary: {
      bg: 'primary.500',
      color: 'white'
    },
    primaryLight: {
      bg: 'primary.200',
      color: 'white'
    },
    frequency: {
      bg: 'grey.300',
      color: 'grey.500'
    },
    playerAverage: {
      bg: 'playerAverage',
      color: 'grey.500'
    },
    tourAverage: {
      bg: 'tourAverage',
      color: 'white'
    },
    alternateMatchAverage: {
      bg: 'blueLight.300',
      color: 'grey.700'
    }
  },
  sizes: {
    small: {
      minWidth: '55px',
      fontSize: 'xs'
    },
    large: {
      minWidth: '55px',
      fontSize: 'sm'
    }
  }
};

const CourtBadge = ({ text, variant, size, loading }) => {
  const { bg, color } = CourtBadgeStyles.variants[variant] || CourtBadgeStyles.variants.matchAverage;
  const { minWidth, fontSize } = CourtBadgeStyles.sizes[size] || CourtBadgeStyles.sizes.small;

  return (
    <Flex
      bg={bg}
      borderRadius={CourtBadgeStyles.baseStyle.borderRadius}
      px={CourtBadgeStyles.baseStyle.px}
      minWidth={minWidth}
      justify={CourtBadgeStyles.baseStyle.justify}
      alignItems={CourtBadgeStyles.baseStyle.alignItems}
    >
      {loading ? (
        <BeatLoader size={3} color="white" data-testid="loader" margin="7px 5px" />
      ) : (
        <Text fontSize={fontSize} fontWeight="medium" color={color}>
          {text}
        </Text>
      )}
    </Flex>
  );
};

CourtBadge.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    'matchAverage',
    'frequency',
    'playerAverage',
    'tourAverage',
    'alternateMatchAverage',
    'primary',
    'primaryLight'
  ]),
  size: PropTypes.oneOf(['small', 'large']),
  loading: PropTypes.bool
};

CourtBadge.defaultProps = {
  loading: false,
  size: 'small'
};

export default CourtBadge;
