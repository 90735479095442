import React from 'react';

import { displayScore } from 'match/utils/transformations';

import { Box, Text, Flex, Center } from '_shared/designSystem/components';

type WeightingType = 'left' | 'right' | 'equal';

interface WinProbabilityHorizontalBarProps {
  label: string;
  leftPercentage: number | null;
  rightPercentage: number | null;
  greaterColor: string;
  lessColor: string;
  textColor: string;
}

const WinProbabilityHorizontalBar: React.FC<WinProbabilityHorizontalBarProps> = ({
  label,
  leftPercentage,
  rightPercentage,
  greaterColor,
  lessColor,
  textColor
}) => {
  const weightingType: WeightingType =
    leftPercentage > rightPercentage ? 'left' : rightPercentage > leftPercentage ? 'right' : 'equal';

  const renderBar = (percentage: number | null, isLeft: boolean) => {
    if (percentage === null) return null;

    const isGreater = (isLeft && weightingType === 'left') || (!isLeft && weightingType === 'right');
    const bgColor = isGreater || weightingType === 'equal' ? greaterColor : lessColor;
    const color = textColor || (isGreater || weightingType === 'equal' ? 'white' : 'grey.700');

    return (
      <Center
        height="25px"
        width={`${Math.max(percentage, 8)}%`}
        bg={bgColor}
        borderRadius={isLeft ? 'lg 0 0 lg' : '0 lg lg 0'}
      >
        <Text fontSize="xs" fontWeight="normal" color={color}>
          {displayScore(percentage, 'percentage')}
        </Text>
      </Center>
    );
  };

  return (
    <Box>
      <Text fontSize="sm" fontWeight="medium" color="secondary.800" textAlign="center" mb={2}>
        {label}
      </Text>
      <Box borderRadius="lg" height="25px" width="100%" bg="primary.200" overflow="hidden">
        <Flex>
          {renderBar(leftPercentage, true)}
          {weightingType === 'equal' && <Box w="2px" bg={greaterColor} />}
          {renderBar(rightPercentage, false)}
        </Flex>
      </Box>
    </Box>
  );
};

export default WinProbabilityHorizontalBar;
