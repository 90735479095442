export const courtArrowTypes = {
  DIAGONAL_LEFT_TO_RIGHT: 'diagonal_left_to_right',
  DIAGONAL_RIGHT_TO_LEFT: 'diagonal_right_to_left',
  STRAIGHT_LEFT: 'straight_left',
  STRAIGHT_RIGHT: 'straight_right',
  V_SHAPE_FROM_BASELINE: 'v_shape_from_baseline',
  A_SHAPE_FROM_BASELINE: 'a_shape_from_baseline',
  LEFT_TO_MIDDLE_FROM_BASELINE: 'left_to_middle_from_baseline',
  RIGHT_TO_MIDDLE_FROM_BASELINE: 'right_to_middle_from_baseline',
  VOLLEY_STRAIGHT_LEFT: 'volley_straight_left',
  VOLLEY_STRAIGHT_RIGHT: 'volley_straight_right',
  VOLLEY_FROM_NET: 'volley_from_net',
  VOLLEYS: 'volleys',
  DROP_SHOTS: 'drop_shots',
  BASELINE_TO_CENTER: 'baseline_to_center',
  BETWEEN_DROP_SHOT_AND_CENTER: 'between_drop_shot_and_center',
  STRAIGHT_LEFT_AND_DIAGONAL_RIGHT: 'straight_left_and_diagonal_right',
  STRAIGHT_RIGHT_AND_DIAGONAL_LEFT: 'straight_right_and_diagonal_left'
};
