import { wimbledonImageUrls } from '_customSites/wimb/util/imageUtil';

import Logo from '_shared/components/logo/Logo';
import { Image } from '_shared/designSystem/components';

export default function WimbLogo({ width, height }) {
  return <Image src={wimbledonImageUrls.LOGO} w={width} h={height} />;
}

export const WimbLoginLogo = () => <Logo width="70px" height="70px" />;
