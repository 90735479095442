import { BREAKDOWN_METRICS_TYPES } from 'match/_shared/matchConstants';
import BreakdownMetrics from 'match/components/performance/breakdownMetrics/BreakdownMetrics';
import PageHeading from 'matchReport/components/_shared/PageHeading';
import PropTypes from 'prop-types';
import PageLayout from 'scoutReport/components/PageLayout';

import { Flex } from '_shared/designSystem/components';

const ServeEffectivenessPage = ({ playerName, insightsDataWithAverages }) => {
  return (
    <PageLayout playerName={playerName}>
      <PageHeading>Serve Effectiveness and Shot Variation</PageHeading>
      <Flex justify="center">
        <Flex mt={4} direction="row" gap="100px" justify="center" flex={1}>
          <Flex direction="column" minW="300px" w="100%">
            <BreakdownMetrics
              graphicType={BREAKDOWN_METRICS_TYPES.SERVE_EFFECTIVENESS}
              dataNoAverages={insightsDataWithAverages?.serve_effectiveness}
              dataWithAverages={insightsDataWithAverages?.serve_effectiveness}
              players={null}
              showAverages={true}
              isScout
              isReport
            />
          </Flex>
          <Flex direction="column" minW="300px">
            <BreakdownMetrics
              graphicType={BREAKDOWN_METRICS_TYPES.SHOT_VARIATION}
              dataNoAverages={insightsDataWithAverages?.variation}
              dataWithAverages={insightsDataWithAverages?.variation}
              players={null}
              showAverages={true}
              isScout
              isReport
            />
          </Flex>
        </Flex>
      </Flex>
    </PageLayout>
  );
};

ServeEffectivenessPage.propTypes = {
  playerName: PropTypes.string,
  insightsDataWithAverages: PropTypes.object
};

export default ServeEffectivenessPage;
