import { extendTheme } from '@chakra-ui/react';
import { atptourThemeOverrides } from '_customSites/atptour/theme/theme';
import { wimbThemeOverrides } from '_customSites/wimb/theme/theme';

import { ButtonStyles } from '_shared/designSystem/components/button/Button';
import { isAtpTourPortal, isWimbPortal } from '_shared/utils/environment/currentEnv';

import { borderRadius } from './borderRadius';
import { breakpoints } from './breakpoints';
import colors from './colors';
import { fonts, fontSizes } from './typography';

let overrides = {
  colors,
  fonts,
  fontSizes,
  ...borderRadius,
  breakpoints,
  components: {
    Button: ButtonStyles,
    Divider: {
      baseStyle: {
        bg: 'gray.500'
      },
      variants: {
        menuSelected: {
          bg: 'primary.700',
          height: '2px'
        }
      }
    }
  }
};

if (isWimbPortal()) {
  overrides = wimbThemeOverrides;
} else if (isAtpTourPortal()) {
  overrides = atptourThemeOverrides;
}

const theme = extendTheme(overrides);

// We also inherit all of Chakra's default theme values
// https://chakra-ui.com/docs/theming/theme

export default theme;
