import { Flex, HorizontalBar } from '_shared/designSystem/components';

import { BattlesAverages } from './BalanceOfPowerWithAverages';

export default function BattlesMetricSection({
  data,
  dataWithAverages,
  label,
  fieldName,
  showAverages,
  averagesLoading,
  greaterColor,
  lessColor
}) {
  return (
    <Flex direction="column" gap={4}>
      <HorizontalBar
        label={label}
        frequency={data?.[`${fieldName}`]?.frequency}
        leftPercentage={data?.[`${fieldName}`]?.player1?.score}
        rightPercentage={data?.[`${fieldName}`]?.player2?.score}
        greaterColor={greaterColor}
        lessColor={lessColor}
      />
      <BattlesAverages
        data={dataWithAverages?.[`${fieldName}`]}
        showAverages={showAverages}
        averagesLoading={averagesLoading}
      />
    </Flex>
  );
}
