import { ChakraProvider } from '@chakra-ui/react';
import { Outlet, ReactLocation, Router } from '@tanstack/react-location';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';
import { getRoutes } from 'routes';

import theme from '_shared/designSystem/theme';
import AppErrorBoundary from '_shared/errors/AppErrorBoundary';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  }
});

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING;
if (GA_TRACKING_ID) {
  ReactGA.initialize(GA_TRACKING_ID);
}

const location = new ReactLocation();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppErrorBoundary>
        <ChakraProvider theme={theme}>
          <RecoilRoot>
            <Router location={location} routes={getRoutes()}>
              <Outlet />
            </Router>
          </RecoilRoot>
        </ChakraProvider>
      </AppErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
