import { Th as ChakraTh } from '@chakra-ui/react';

const Th = (props) => (
  <ChakraTh
    bg="grey.100"
    whiteSpace="nowrap"
    fontSize="sm"
    fontWeight="semibold"
    textTransform="capitalize"
    color="secondary.800"
    {...props}
  >
    {props.children}
  </ChakraTh>
);

export default Th;
