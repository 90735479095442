import React from 'react';

import { displayScore } from 'match/utils/transformations';
import PropTypes from 'prop-types';

import { Flex, Text } from '_shared/designSystem/components';

import CourtBadge from './CourtBadge';

const CourtBadgeGroup = ({
  dataWithAverages,
  dataNoAverages,
  direction,
  label,
  size,
  showAverages,
  averagesLoading,
  matchAverageVariant
}) => {
  const { score, frequency, frequency_total, score_type } = dataNoAverages || {};
  const { player_average, tour_average } = dataWithAverages || {};
  const renderBadges = () => {
    return (
      <>
        <CourtBadge
          text={displayScore(score, score_type)}
          variant={matchAverageVariant || 'matchAverage'}
          size={size}
        />
        {showAverages ? (
          <>
            <CourtBadge
              text={displayScore(player_average, score_type)}
              variant="playerAverage"
              size={size}
              loading={averagesLoading}
            />
            <CourtBadge
              text={displayScore(tour_average, score_type)}
              variant="tourAverage"
              size={size}
              loading={averagesLoading}
            />
          </>
        ) : (
          frequency !== null && <CourtBadge text={`${frequency}/${frequency_total}`} variant="frequency" size={size} />
        )}
      </>
    );
  };

  return (
    <Flex direction={direction} gap={direction === 'row' ? 2 : 1} justify="flex-end">
      {label && (
        <Flex bg="white" px={2} mx={1} mb={1} justify="center" borderRadius={3}>
          <Text fontSize="xs" fontWeight="medium" color="black">
            {label}
          </Text>
        </Flex>
      )}
      {renderBadges()}
    </Flex>
  );
};

CourtBadgeGroup.propTypes = {
  dataWithAverages: PropTypes.object,
  dataNoAverages: PropTypes.object,
  direction: PropTypes.oneOf(['row', 'column']),
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  averagesLoading: PropTypes.bool
};

CourtBadgeGroup.defaultProps = {
  direction: 'column',
  label: null,
  size: 'small',
  averagesLoading: false
};

export default CourtBadgeGroup;
