import { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import { convertLabelName } from 'match/utils/transformations';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { Flex, Text, ButtonGroupSingle } from '_shared/designSystem/components';
import { userPermissionsState } from '_shared/globalState/atoms';
import { checkPermission } from '_shared/utils/permissions';

import { leftColumnContentWidth } from '../performanceMargins';

import { matchStatsOrder } from './matchStatsOrder';
import StatisticsRow from './StatisticsRow';
import WinnersAndUnforcedErrors from './WinnersAndUnforcedErrors';

export default function MatchStatistics(props) {
  const { data, dataWithAverages, players, showAverages, matchStatsCategory, setMatchStatsCategory, averagesLoading } =
    props;

  const { permissions } = useRecoilValue(userPermissionsState);
  const permittedMatchStatsOrder = getPermittedMatchStatsOrder(matchStatsOrder, permissions);

  useEffect(() => {
    if (!isEmpty(permittedMatchStatsOrder)) {
      setMatchStatsCategory(Object.keys(permittedMatchStatsOrder)[0]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Flex direction="column" gap={4} w={{ base: '100%', sm: `${leftColumnContentWidth}px` }}>
      <Flex justify="center">
        <MatchStatistcsButtons
          data={Object.keys(permittedMatchStatsOrder)}
          matchStatsCategory={matchStatsCategory}
          setMatchStatsCategory={setMatchStatsCategory}
        />
      </Flex>
      <Flex justify="space-between" w="100%">
        <Text fontSize="sm" fontWeight="semibold">
          {players.player1?.last_name}
        </Text>
        <Text fontSize="sm" fontWeight="semibold">
          {players.player2?.last_name}
        </Text>
      </Flex>
      {matchStatsCategory === 'winners_and_unforced_errors' ? (
        <WinnersAndUnforcedErrors data={data.winners_and_unforced_errors} />
      ) : (
        permittedMatchStatsOrder[`${matchStatsCategory}`] &&
        permittedMatchStatsOrder[`${matchStatsCategory}`].map((statType) => {
          return data?.[matchStatsCategory]?.[statType] ? (
            <StatisticsRow
              data={data?.[matchStatsCategory]?.[statType]}
              dataWithAverages={dataWithAverages?.[matchStatsCategory]?.[statType]}
              label={convertLabelName(statType)}
              key={statType}
              showAverages={showAverages}
              averagesLoading={averagesLoading}
            />
          ) : null;
        })
      )}
    </Flex>
  );
}

MatchStatistics.propTypes = {
  data: PropTypes.object.isRequired,
  dataWithAverages: PropTypes.object,
  players: PropTypes.object.isRequired,
  matchStatsCategory: PropTypes.string,
  setMatchStatsCategory: PropTypes.func,
  showAverages: PropTypes.bool,
  averagesLoading: PropTypes.bool
};

export const MatchStatistcsButtons = (props) => {
  const { data, matchStatsCategory, setMatchStatsCategory } = props;
  if (matchStatsCategory)
    return (
      <Flex>
        {data.map((item, index) => {
          let position;
          if (data.length < 2) position = 'singleButton';
          else position = index === 0 ? 'left' : index === data.length - 1 ? 'right' : 'middle';
          return (
            <ButtonGroupSingle
              key={item}
              onClick={() => setMatchStatsCategory(item)}
              label={convertLabelName(item)}
              position={position}
              isSelected={matchStatsCategory === item}
            />
          );
        })}
      </Flex>
    );
};

MatchStatistcsButtons.propTypes = {
  data: PropTypes.array.isRequired,
  matchStatsCategory: PropTypes.string,
  setMatchStatsCategory: PropTypes.func
};

export function getPermittedMatchStatsOrder(matchStatsOrder, permissions) {
  let permittedTabs = {};
  Object.keys(matchStatsOrder).forEach((item) => {
    if (checkPermission('match_statistics_' + item, permissions)) permittedTabs[`${item}`] = matchStatsOrder[`${item}`];
  });
  return permittedTabs;
}
