import { displayScore, zeroOrMore } from 'match/utils/transformations';
import PropTypes from 'prop-types';

import HeadingWithTooltip from '_shared/components/HeadingWithTooltip';
import { Box, Text, Flex, Center } from '_shared/designSystem/components';
import { capitaliseString } from '_shared/utils/stringUtil';

export default function HorizontalBarTriple(props) {
  const {
    heading,
    leftPercentage,
    middlePercentage,
    rightPercentage,
    leftLabel,
    middleLabel,
    rightLabel,
    colorScheme,
    tooltipText,
    isMatchReport
  } = props;

  const isDark = colorScheme === 'dark';

  return (
    <Flex
      border={isDark ? null : 'solid 1px'}
      borderColor={isDark ? null : 'grey.300'}
      borderRadius="lg"
      bg={isDark ? 'secondary.800' : 'grey.25'}
      justify="center"
      pb={4}
      pt={4}
    >
      <Box w="100%" px={7}>
        <HeadingWithTooltip heading={heading} tooltipText={tooltipText} isMatchReport={isMatchReport} />
        <Box borderRadius="lg" height="34px" width="100%">
          {zeroOrMore(leftPercentage) && zeroOrMore(middlePercentage) && zeroOrMore(rightPercentage) !== null && (
            <Flex position="relative">
              <Center
                borderLeftRadius="lg"
                height="34px"
                width={leftPercentage > 5 ? `${leftPercentage}%` : '5%'}
                bg={getBgColour(leftPercentage, rightPercentage)}
              >
                <Text fontSize="xs" fontWeight="normal" color={getFontColour(leftPercentage, rightPercentage)}>
                  {displayScore(leftPercentage, 'percentage')}
                </Text>
              </Center>
              <Center height="34px" width={middlePercentage > 5 ? `${middlePercentage}%` : '5%'} bg="grey.200">
                <Text fontSize="xs" fontWeight="normal" color="grey.700">
                  {displayScore(middlePercentage, 'percentage')}
                </Text>
              </Center>
              <Center
                borderRightRadius="lg"
                height="34px"
                width={rightPercentage > 5 ? `${rightPercentage}%` : '5%'}
                bg={getBgColour(rightPercentage, leftPercentage)}
              >
                <Text fontSize="xs" fontWeight="normal" color={getFontColour(rightPercentage, leftPercentage)}>
                  {displayScore(rightPercentage, 'percentage')}
                </Text>
              </Center>
            </Flex>
          )}
        </Box>
        <Flex justify="space-between" mt={2}>
          <Text fontSize="sm" fontWeight="medium" color={isDark ? 'white' : 'secondary.800'}>
            {capitaliseString(leftLabel)}
          </Text>
          <Text fontSize="sm" fontWeight="medium" color={isDark ? 'white' : 'secondary.800'}>
            {capitaliseString(middleLabel)}
          </Text>
          <Text fontSize="sm" fontWeight="medium" color={isDark ? 'white' : 'secondary.800'}>
            {capitaliseString(rightLabel)}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
}

HorizontalBarTriple.propTypes = {
  heading: PropTypes.string,
  leftPercentage: PropTypes.number,
  middlePercentage: PropTypes.number,
  rightPercentage: PropTypes.number,
  leftLabel: PropTypes.string,
  middleLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  colorScheme: PropTypes.string,
  tooltipText: PropTypes.string,
  isMatchReport: PropTypes.bool
};

function getBgColour(percentage1, percentage2) {
  if (percentage1 === percentage2) return 'primary.200';
  if (percentage1 > percentage2) return 'primary.600';
  else return 'primary.200';
}

function getFontColour(percentage1, percentage2) {
  if (percentage1 === percentage2) return 'secondary.800';
  if (percentage1 > percentage2) return 'white';
  else return 'secondary.800';
}
