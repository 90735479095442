export const findAISelected = (data, path = '') => {
  if (typeof data !== 'object' || data === null) {
    return { found: false, path: null };
  }

  // Check if the object itself has the `ai_selected` property
  if (data.hasOwnProperty('ai_selected')) {
    return { found: true, path: path };
  }

  // Recursively check each key in the object
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const result = findAISelected(data[key], path ? `${path}.${key}` : key);
      if (result.found) {
        return result;
      }
    }
  }

  // If no `ai_selected` property is found
  return { found: false, path: null };
};
