export const wimbButtonStyles = {
  baseStyle: {
    borderRadius: '0px',
    outline: 'none',
    textTransform: 'uppercase'
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      fontFamily: 'GothamMedium',
      letterSpacing: '.05em',
      fontWeight: 'normal'
    },
    md: {
      fontSize: 'md',
      fontFamily: 'GothamMedium',
      letterSpacing: '.05em',
      fontWeight: 'normal'
    }
  },
  variants: {
    outline: {
      border: '2px solid',
      borderColor: 'primary.500',
      color: 'primary.500',
      _hover: { bg: 'primary.50' }
    },
    solid: {
      bg: 'primary.500',
      color: 'white',
      _hover: { bg: 'primary.700' }
    },
    form: {
      bg: 'primary.500',
      color: 'white',
      _hover: { bg: 'primary.700' },
      height: '32px',
      borderRadius: '0px'
    }
  },
  defaultProps: {
    size: 'md',
    variant: 'solid'
  }
};
