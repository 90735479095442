import isNumber from 'lodash/isNumber';
import round from 'lodash/round';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

import { metricScoreType } from '_shared/constants/metricTypes';

export function convertScoreToPercentage(score, scoreType) {
  // for anything that doesn't convert, make it 0, so that at least the graphic can show
  let percentage = 0;
  if (!scoreType) return 0;
  try {
    if (scoreType === metricScoreType.OUT_OF_TEN || scoreType === metricScoreType.DECIMAL_TWO_PLACES) {
      percentage = round(score * 10, 0);
    } else if (scoreType === metricScoreType.PERCENTAGE) {
      percentage = round(score, 0);
    } else return 0;
    if (typeof percentage != 'number' || isNaN(percentage)) return 0;
    return percentage;
  } catch (e) {
    return 0;
  }
}

export const displayScore = (score, scoreType) => {
  if (scoreType === metricScoreType.STRING) return score;
  if (!isNumber(score)) return ' ';
  switch (scoreType) {
    case metricScoreType.PERCENTAGE:
      return `${score}%`;
    case metricScoreType.PERCENTAGE_ONE_DECIMAL:
      return `${score.toFixed(1)}%`;
    case metricScoreType.OUT_OF_TEN:
    case metricScoreType.DECIMAL:
      return addDecimalPlace(score);
    case metricScoreType.DECIMAL_TWO_PLACES:
      return addTwoDecimalPlaces(score);
    case metricScoreType.COUNT:
      return `${score}`;
    case metricScoreType.MPH:
      return `${score} mph`;
    case metricScoreType.RPM:
      return `${score} rpm`;
    case metricScoreType.METRES:
      return `${score.toFixed(3)} metres`;
    case metricScoreType.POSITIVE:
      return `+${score}`;
    case metricScoreType.NEGATIVE:
      return `-${score}`;
    default:
      return score;
  }
};

export const addDecimalPlace = (score) => {
  return score.toFixed(1);
};

export const addTwoDecimalPlaces = (score) => {
  return score.toFixed(2);
};

export function zeroOrMore(number) {
  return isNumber(number) && number >= 0;
}

export function convertLabelName(string) {
  if (convertRallyLabel(string)) return convertRallyLabel(string);
  return startCase(toLower(string));
}

export function convertRallyLabel(label) {
  switch (label) {
    case 'rally_length_zero_to_four':
      return '0 - 4';
    case 'rally_length_five_to_eight':
      return '5 - 8';
    case 'rally_length_nine_plus':
      return '9+';
    case 'winners_and_unforced_errors':
      return 'Winners / UE';
    case 'forehand_topspin_rpm':
      return 'Forehand Topspin RPM';
    case 'backhand_topspin_rpm':
      return 'Backhand Topspin RPM';
    case 'topspin_rpm_average':
      return 'Topspin RPM Average';
    case 'slice_rpm_average':
      return 'Slice RPM Average';
    default:
      return false;
  }
}

export function checkNationality(nationality) {
  switch (nationality) {
    case 'UK':
      return 'GB';
    case 'TW':
    case 'RU':
    case 'BY':
      return null;
    default:
      return nationality;
  }
}
