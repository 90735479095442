import PropTypes from 'prop-types';

import { HorizontalBar, Text, Flex, Box } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';

import CourtSurface3d from '../court/CourtSurface3d';

import ServePlusOneStats from './ServePlusOneStats';

export default function ServePlusOne(props) {
  const { data } = props;
  const showNoDataMessage = data?.forehand?.usage?.score === 0 && data?.backhand?.usage?.score === 0;
  return (
    <Flex maxW="550px" p={5} borderRadius="lg" bg="white" direction="column">
      <Text color="secondary.800" fontSize="lg" fontWeight="semibold" textAlign="center">
        Shot Usage
      </Text>
      <Box>
        {showNoDataMessage ? (
          <Box borderRadius="lg" height="40px" width="100%" bg="primary.200" mt={2} />
        ) : (
          <HorizontalBar
            leftPercentage={data?.forehand?.usage?.score}
            rightPercentage={data?.backhand?.usage?.score}
            label={null}
            barHeight="40px"
            percentageSize="lg"
            lessColor={isWimbPortal() ? 'grey.200' : null}
          />
        )}
        <Flex justify="space-between" py={3}>
          <Text fontSize="medium" fontWeight="semibold">
            Forehand
          </Text>
          <Text fontSize="medium" fontWeight="semibold">
            Backhand
          </Text>
        </Flex>
        <ServePlusOneStats data={data} />
      </Box>
    </Flex>
  );
}

ServePlusOne.propTypes = {
  data: PropTypes.object.isRequired
};

export const ServePlusOneBackgroundCourt = ({ surface }) => {
  const backgroundColor = `court.background.${surface}`;
  return (
    <Box bg={backgroundColor} pb="30px" px={10} opacity={0.5}>
      <CourtSurface3d surface={surface} />
    </Box>
  );
};

ServePlusOneBackgroundCourt.propTypes = {
  surface: PropTypes.string
};

export function ServePlusOneWithCourt(props) {
  const { surface, data } = props;
  return (
    <Box position="relative" p={2} w="100%" h="600px" overflow="hidden">
      <Box position="absolute" top={0} left="50%" transform="translateX(-50%)">
        <Box w="1400px" h="600px">
          <ServePlusOneBackgroundCourt surface={surface} />
        </Box>
      </Box>
      <Box
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
        pt="50px"
        w={['350px', '550px', '550px', '550px', '550px', '550px']}
      >
        <ServePlusOne data={data} />
      </Box>
    </Box>
  );
}

ServePlusOneWithCourt.propTypes = {
  surface: PropTypes.string,
  data: PropTypes.object
};
