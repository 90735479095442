import { useQuery } from 'react-query';

import { apiClient } from '_shared/dataFetching/apiConfig';
import { getSiteParam } from '_shared/dataFetching/urlConfig';

export const leaderboardService = {
  async getSearchResults({ queryParams }) {
    try {
      const uniqueParam = Math.floor(100000 + Math.random() * 900000);
      const siteParam = getSiteParam();
      const queryString = Object.entries(queryParams)
        .filter(([key, value]) => value !== 'all' && value !== '')
        .map(([key, value]) => {
          const paramValue = typeof value === 'boolean' ? (value ? 'yes' : 'no') : value;
          return `${key}=${paramValue}`;
        })
        .join('&');
      const url = `leaderboard?${siteParam}&unique_param=${uniqueParam}&${queryString}`;

      const res = await apiClient.get(url);
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  async getFormOptions() {
    const url = `leaderboard/form_options?${getSiteParam()}`;
    const res = await apiClient.get(url);
    return res.data;
  }
};

// react query hooks
export const useGetSearchResults = (queryParams, options) => {
  return useQuery(
    ['leaderboardService_getSearchResults', queryParams],
    () => leaderboardService.getSearchResults({ queryParams }),
    {
      ...options,
      enabled: !!queryParams,
      onError: (error) => {
        if (error.response && error.response.status === 400) {
          console.error('Error 400: ', error.response.data.message);
        } else {
          console.error('An unexpected error occurred: ', error.message);
        }
      }
    }
  );
};

export const useGetLeaderboardFormOptions = () => {
  return useQuery('leaderboardService_getFormOptions', leaderboardService.getFormOptions);
};
