import { useNavigate } from '@tanstack/react-location';
import PropTypes from 'prop-types';

import { Box, Flex, Select, useBreakpointValue } from '_shared/designSystem/components';

import { NavBarButton } from './NavBarButton';

/**
 * In items, the id should be with hyphens and not camel case e.g. court-graphics, as it will be used as the
 * url path for the tab. The overrideClickHandler is if you want to use a special way of handling the click,
 * and not just going to the route.
 */
export default function HorizontalNavBar(props) {
  const { items, activeItem, overrideClickHandler } = props;
  const windowSizeIsMd = useBreakpointValue({ md: true });

  const navigate = useNavigate();

  const handleDropDownSelect = (event) => {
    if (overrideClickHandler) overrideClickHandler(event.target.value);
    else navigate({ to: event.target.value });
  };

  const handleLongBarClick = (id) => {
    if (overrideClickHandler) overrideClickHandler(id);
    else navigate({ to: id });
  };

  return (
    <Box>
      {windowSizeIsMd ? (
        <Flex gap="8px" h="38px" alignItems="center" borderRadius="lg" px="4px">
          {items.map((item) => (
            <Box key={item.id} onClick={() => handleLongBarClick(item.id)}>
              <NavBarButton label={item.label} isSelected={activeItem === item.id} />
            </Box>
          ))}
        </Flex>
      ) : (
        <Select defaultValue={activeItem} size="md" w="250px" onChange={handleDropDownSelect}>
          {items.map((item) => (
            <option key={item.id} value={item.id}>
              {item.label}
            </option>
          ))}
        </Select>
      )}
    </Box>
  );
}

HorizontalNavBar.propTypes = {
  items: PropTypes.array.isRequired,
  activeItem: PropTypes.string,
  overrideClickHandler: PropTypes.func
};
