import { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { getVisibleMatchIds } from 'scout/util/scoutMatchSelectorUtil';
import { updateMatchIds } from 'scout/util/scoutUtil';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Flex, Text } from '_shared/designSystem/components';

import ScoutMatchSelectorForm from './ScoutMatchSelectorForm';
import ScoutMatchSelectorTable from './ScoutMatchSelectorTable';

export default function ScoutMatchSelector(props) {
  const { matchSelectorMatches, handleMatchSelectorFormSubmit, scoutFormValues, matchSelectorLoading } = props;

  const [matchIds, setMatchIds] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  useEffect(() => {
    if (!isEmpty(scoutFormValues?.matchIds)) {
      setMatchIds(scoutFormValues?.matchIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const localSubmitForm = (currentFormState) => {
    const visibleMatchIds = getVisibleMatchIds(matchSelectorMatches, matchIds);
    const newFormState = {
      ...currentFormState,
      matchIds: visibleMatchIds
    };
    handleMatchSelectorFormSubmit(newFormState);
  };

  const selectRow = (item) => {
    const newMatches = updateMatchIds(matchIds, item);
    setMatchIds(newMatches);
  };

  const selectAll = () => {
    if (!selectedAll) {
      const allMatchIds = [];
      matchSelectorMatches?.forEach((item) => allMatchIds.push(item?.match_id?.toString()));
      setMatchIds(allMatchIds);
    } else {
      setMatchIds([]);
    }
    setSelectedAll(!selectedAll);
  };

  return (
    <Flex direction="column" gap={6}>
      <Text fontSize="3xl" fontWeight="medium" color="secondary.800">
        Match Selector
      </Text>
      <ScoutMatchSelectorForm {...props} handleMatchSelectorFormSubmit={localSubmitForm} />
      <ScoutMatchSelectorTable
        matchIds={matchIds}
        data={matchSelectorMatches}
        columnHeadings={customSitesConfig.matchSelectorColumns}
        selectRow={selectRow}
        selectAll={selectAll}
        selectedAll={selectedAll}
        matchSelectorLoading={matchSelectorLoading}
      />
    </Flex>
  );
}

ScoutMatchSelector.propTypes = {
  playerDetailsData: PropTypes.object,
  formPopulationData: PropTypes.object,
  matchSelectorMatches: PropTypes.array,
  scoutFormValues: PropTypes.object,
  handleMatchSelectorFormSubmit: PropTypes.func,
  handleMatchSelectorFormUpdate: PropTypes.func,
  matchSelectorLoading: PropTypes.bool
};
