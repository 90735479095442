import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { Box, Flex, Text } from '_shared/designSystem/components';

export default function Legend(props) {
  const { fields, direction } = props;
  if (isEmpty(fields)) return null;
  return (
    <Flex
      direction={direction === 'horizontal' ? 'row' : 'column'}
      gap={direction === 'horizontal' ? 5 : 2}
      flexWrap="wrap"
      justify="center"
    >
      {fields.map((item) => (
        <Flex gap={direction === 'horizontal' ? 2 : 3} key={item.label}>
          {item.color === 'ring' ? (
            <Box pt="2px">
              <Box
                borderRadius="full"
                width="10px"
                height="10px"
                bg="white"
                border="0.5px solid"
                borderColor="grey.500"
              />
            </Box>
          ) : (
            <Box pt="2px">
              <Box borderRadius="full" width="10px" height="10px" bg={item.color} border={item.border} />
            </Box>
          )}
          <Text fontSize="sm" fontWeight="medium" color="grey.500" lineHeight="14px">
            {item.label}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
}

Legend.propTypes = {
  fields: PropTypes.array,
  direction: PropTypes.string
};

Legend.defaultProps = {
  direction: 'horizontal'
};
