import { useEffect } from 'react';

import customSitesConfig from '_shared/config/customSitesConfig';

const usePageTitle = (title: string, isLoading: boolean = false) => {
  useEffect(() => {
    if (!isLoading) {
      if (!title) document.title = customSitesConfig.defaultPageTitle;
      else document.title = title;
    }
  }, [title, isLoading]);
};

export default usePageTitle;
