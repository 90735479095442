import { mainAreaMaxWidth } from '_layouts/margins';
import isEmpty from 'lodash/isEmpty';
import MatchStatusText from 'matchList/components/_shared/MatchStatusText';
import PropTypes from 'prop-types';

import customSitesConfig from '_shared/config/customSitesConfig';
import { matchStatus } from '_shared/constants/matchTypes';
import { resultName } from '_shared/constants/matchTypes';
import { Alert, Badge, Box, Flex, Icon, Table, Tbody, Td, Text, Th, Thead, Tr } from '_shared/designSystem/components';
import { formatDate } from '_shared/utils/dateUtil';

export default function MatchListTable(props) {
  const { data, openMatchPage, navigate, showTournamentColumn } = props;

  if (isEmpty(data))
    return (
      <Box mt={5}>
        <Alert message="No matches to show" status="info" />
      </Box>
    );

  return (
    <Box maxW={customSitesConfig.hideOptionalMatchListColumns ? '800px' : `${mainAreaMaxWidth}px`}>
      <Box overflowX={{ base: 'auto', md: 'initial' }}>
        <Table>
          <Thead>
            <Tr>
              <Th>Players</Th>
              <Th>Score</Th>
              {showTournamentColumn && <Th>Tournament</Th>}
              <Th>Round</Th>
              {!customSitesConfig.hideOptionalMatchListColumns && <Th>Date</Th>}
              {!customSitesConfig.hideOptionalMatchListColumns && <Th>Court</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((item) => (
              <Tr
                _hover={{ bg: 'primary.25' }}
                cursor="pointer"
                onClick={() => openMatchPage(item.match_id, navigate)}
                key={item.match_id}
              >
                <Td>
                  <PlayerNames item={item} />
                </Td>
                <Td>
                  <MatchScore item={item} />
                </Td>
                {showTournamentColumn && <LinkedCell matchId={item.match_id}>{item.competition_name}</LinkedCell>}
                <LinkedCell matchId={item.match_id}>{item.round}</LinkedCell>
                {!customSitesConfig.hideOptionalMatchListColumns && (
                  <Td>
                    <Text>{formatDate(item.start_time)}</Text>
                  </Td>
                )}
                {!customSitesConfig.hideOptionalMatchListColumns && (
                  <LinkedCell matchId={item.match_id}>{item.court_id}</LinkedCell>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}

MatchListTable.propTypes = {
  data: PropTypes.array,
  openMatchPage: PropTypes.func,
  navigate: PropTypes.func,
  showTournamentColumn: PropTypes.bool
};

const LinkedCell = ({ matchId, children }) => (
  <Td>
    <Text>{children}</Text>
  </Td>
);

const TennisBallIcon = () => (
  <Flex height="20px" align="center">
    <Icon name="tennisBall" color="primary.500" height={2.5} width={2.5} />
  </Flex>
);

const TickIcon = () => (
  <Flex height="20px" align="center">
    <Icon name="tick" color="primary.500" height={4} width={4} />
  </Flex>
);

export const PlayerNames = ({ item }) => {
  return (
    <Flex>
      <PlayerStatus item={item} player="player1" position="left" />
      <Text>{item.players}</Text>
      <PlayerStatus item={item} player="player2" position="right" />
    </Flex>
  );
};

const PlayerStatusWrapper = ({ position, children }) => {
  if (!children) return null;
  return (
    <Box mr={position === 'left' ? 1 : 0} ml={position === 'right' ? 1 : 0}>
      {children}
    </Box>
  );
};

export const PlayerStatus = ({ item, player, position }) => {
  let displayResult;
  switch (item?.match_status) {
    case matchStatus.FORTHCOMING:
      return null;
    case matchStatus.LIVE:
    case matchStatus.SUSPENDED:
      if (item?.score?.player_serving === player) displayResult = <TennisBallIcon />;
      break;
    case matchStatus.COMPLETE:
      switch (item?.score?.result_name) {
        case resultName.ABANDONED:
        case resultName.CANCELLED:
          break;
        case resultName.RETIRED:
        case resultName.DEFAULTED:
        case resultName.WALKOVER:
        case resultName.WON:
          if (item?.score?.match_winner === player) displayResult = <TickIcon />;
          break;
        default:
      }
      break;
    default:
  }
  return <PlayerStatusWrapper position={position}>{displayResult}</PlayerStatusWrapper>;
};

export const MatchScore = ({ item }) => {
  switch (item.match_status) {
    case matchStatus.FORTHCOMING:
      return <Text color="grey.500">Upcoming</Text>;
    case matchStatus.LIVE:
      return (
        <Flex gap={3}>
          <Badge
            text="Live"
            bgColour="secondary.50"
            iconColour="secondary.800"
            textColour="secondary.800"
            withLeftCircle
          />
          <Text>{item?.score?.match_score}</Text>
          <Text color="primary.500" fontWeight="medium">
            {item?.score?.point_score}
          </Text>
        </Flex>
      );
    case matchStatus.SUSPENDED:
      return (
        <Flex gap={3}>
          <MatchStatusText result={item.match_status} color="grey.500" />
          <Text>{item?.score?.match_score}</Text>
          <Text color="primary.500" fontWeight="medium">
            {item?.score?.point_score}
          </Text>
        </Flex>
      );
    case matchStatus.COMPLETE:
      if (
        item?.score?.result_name === resultName.WALKOVER ||
        item?.score?.result_name === resultName.RETIRED ||
        item?.score?.result_name === resultName.DEFAULTED
      )
        return <Text>{item?.score?.match_score}</Text>;
      else if (item?.score?.result_name === resultName.ABANDONED) {
        return <MatchStatusText result={item?.score?.result_name} color="grey.500" />;
      } else {
        return (
          <Flex gap={3}>
            <MatchStatusText result={item?.score?.result_name} color="grey.500" />
            <Text>{item?.score?.match_score}</Text>
          </Flex>
        );
      }
    default:
      return null;
  }
};
