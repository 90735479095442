import { useEffect, useState } from 'react';

import { matchService } from 'match/services/matchService';
import PropTypes from 'prop-types';
import { BarLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';

import {
  Text,
  VStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton
} from '_shared/designSystem/components';
import { logBasicMessage } from '_shared/errors/log';
import { userPermissionsState } from '_shared/globalState/atoms';
import { capitaliseFirstLetterOfString } from '_shared/utils/stringUtil';

import { MatchReportCsvModalContent } from './MatchReportCsvModalContent';

export const MatchReportCsvModal = ({ isOpen, onClose, matchId }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [failedFiles, setFailedFiles] = useState(null);
  const [allFilesFailed, setAllFilesFailed] = useState(false);
  const [reportCreated, setReportCreated] = useState(false);
  const [selectedSections, setSelectedSections] = useState([]);

  const { csv_types } = useRecoilValue(userPermissionsState);

  async function handleReportFlow() {
    setFailedFiles(null);
    setAllFilesFailed(false);
    setReportCreated(false);

    logBasicMessage('INFO', `User started to download csv match report`);
    let retry = false;
    try {
      await runReportGeneration();
    } catch (error) {
      retry = true;
    }
    if (retry) {
      logBasicMessage('INFO', `First attempt at csv match report generation failed, retrying`);
      try {
        await runReportGeneration();
      } catch (error) {
        setIsDownloading(false);
        let sendingError = `Error downloading csv match report: ${error} `;
        if (error.request) sendingError += ` | Request: ${JSON.stringify(error.request)} `;
        if (error.response) sendingError += ` | Response: ${JSON.stringify(error.response)} `;
        logBasicMessage('ERROR', sendingError);
      }
    }
  }

  const runReportGeneration = async () => {
    setIsDownloading(true);
    const formValues = selectedSections.filter((section) => section.isChecked).map((section) => section.id);
    const res = await matchService.getCsvReportFiles({ matchId, formValues });

    const downloadPromises = res.files
      .filter((file) => file.status === 'success') // Filter for successful files
      .map(async (file) => {
        await downloadFile(file);
      });

    const failedFiles = res.files.filter((file) => file.status === 'failed'); // Filter for failed files
    setFailedFiles(failedFiles);

    if (failedFiles.length === res.files.length || res.files.length === 0) {
      setAllFilesFailed(true);
      logBasicMessage('ERROR', `All csv match report files failed to download`);
    }

    await Promise.all(downloadPromises);

    setIsDownloading(false);
    setReportCreated(true);

    return downloadPromises;
  };

  const downloadFile = async (file) => {
    const { file_url, file_name } = file;
    await openReport(file_url, file_name);
  };

  const openReport = async (file_url, file_name) => {
    try {
      const response = await fetch(file_url);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = file_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      logBasicMessage('INFO', `User successfully downloaded csv match report ${file_name}`);
    } catch (error) {
      logBasicMessage('ERROR', `Failed to download csv match report: ${error}`);
    }
  };

  const showDownloadMessage = () => {
    if (isDownloading) {
      return (
        <VStack justify="center">
          <Text mb={3} fontSize="sm" fontWeight="normal" color="grey.700">
            Downloading Report
          </Text>
          <BarLoader height="8px" width="300px" color="#96BF32" />
        </VStack>
      );
    } else if (reportCreated && !allFilesFailed) {
      // Check if report is created and not all files failed
      return <Button onClick={handleReportFlow}>Download Again</Button>;
    } else {
      return <Button onClick={handleReportFlow}>Download</Button>;
    }
  };

  const closeModal = () => {
    setFailedFiles(null);
    setIsDownloading(false);
    setReportCreated(false);
    setAllFilesFailed(false);
    onClose();
  };

  useEffect(() => {
    if (csv_types) {
      const csvTypes = csv_types.map((type) => ({
        id: type,
        label: capitaliseFirstLetterOfString(type),
        isChecked: true
      }));
      setSelectedSections(csvTypes);
    }
  }, [csv_types]);

  return (
    <Modal isOpen={isOpen} size="lg" onClose={() => closeModal()}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <MatchReportCsvModalContent
          failedFiles={failedFiles}
          allFilesFailed={allFilesFailed}
          showDownloadMessage={showDownloadMessage}
          selectedSections={selectedSections}
          setSelectedSections={setSelectedSections}
        />
      </ModalContent>
    </Modal>
  );
};

MatchReportCsvModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  playerId: PropTypes.number,
  queryParams: PropTypes.string,
  playerName: PropTypes.string,
  company: PropTypes.string
};
