import { authenticationService } from 'authentication/services/authenticationService';
import { useQuery } from 'react-query';

import { Center, Divider, Flex, Icon, Text } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';

const Logout = ({ iconOnly, isTopMenu }) => {
  const iconSize = isWimbPortal() ? '18px' : '20px';
  const textColor = isWimbPortal() ? 'secondary.800' : 'white';
  const fontSize = isWimbPortal() ? '15px' : 'sm';

  const { refetch } = useQuery('authenticationService_logout', () => authenticationService.logout(), {
    enabled: false
  });

  const handleLogout = () => {
    refetch();
  };

  return (
    <>
      {!isTopMenu && <Divider mt={4} mb={1} />}
      <Flex
        onClick={() => handleLogout()}
        cursor="pointer"
        justify="space-between"
        align="center"
        h={isTopMenu ? '100%' : '55%'}
      >
        {!iconOnly && (
          <Text fontSize={fontSize} fontWeight="sm" color={textColor} pr={1}>
            Log Out
          </Text>
        )}
        <Center>
          <Icon width={iconSize} height={iconSize} name="logOut" color={textColor} />
        </Center>
      </Flex>
    </>
  );
};

export default Logout;
