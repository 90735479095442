import { useEffect, useRef } from 'react';

import { ChannelProvider, useChannel } from 'ably/react';
import { useSetRecoilState } from 'recoil';

import { matchStatus } from '_shared/constants/matchTypes';
import { Box } from '_shared/designSystem/components';
import { liveMatchRefetchIntervalState } from '_shared/globalState/atoms';
import { isAtpTourPortal, isDevOrTest } from '_shared/utils/environment/currentEnv';

export const AblyUpdater = ({ matchId, matchStatus: _matchStatus, updateDataFunction }) => {
  const ablyChannelName = `portal-match:${matchId}`;
  return !isDevOrTest() && _matchStatus === matchStatus.LIVE ? (
    <ChannelProvider channelName={ablyChannelName}>
      <AblyMessageHandler ablyChannelName={ablyChannelName} updateDataFunction={updateDataFunction} />
    </ChannelProvider>
  ) : (
    <Box />
  );
};

const AblyMessageHandler = ({ ablyChannelName, updateDataFunction }) => {
  const refetchTimeout = isAtpTourPortal() ? 10000 : 0; // TennisIQ delay by 10s
  const fallbackTimeout = 60000; // time we want to wait before assuming Ably has stopped working
  const refetchInterval = 10000; // if Ably stops, refetch every 10s

  // aim here is wait for the fallback interval, if a new message appears in that time
  // then cancel it and start again
  const setLiveMatchRefetchInterval = useSetRecoilState(liveMatchRefetchIntervalState);
  const fallbackTimerRef = useRef(null);

  const clearFallbackTimer = () => {
    if (fallbackTimerRef.current) {
      clearTimeout(fallbackTimerRef.current);
      fallbackTimerRef.current = null;
    }
  };

  const setFallbackTimer = () => {
    clearFallbackTimer();
    fallbackTimerRef.current = setTimeout(() => {
      setLiveMatchRefetchInterval(refetchInterval);
    }, fallbackTimeout);
  };

  useChannel(ablyChannelName, 'header', (message) => {
    setFallbackTimer();
    setTimeout(() => {
      updateDataFunction();
      setLiveMatchRefetchInterval(false);
    }, refetchTimeout);
  });

  useEffect(() => {
    setFallbackTimer(); // run once at start in case we haven't even received a single message from Ably
    return () => clearFallbackTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box />;
};
