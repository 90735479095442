import { Flex, Box } from '_shared/designSystem/components';

import FrequencyBadge from './_shared/FrequencyBadge';
import { verticalOrientationOrder } from './AveragesBadges';
import { CustomBadge } from './CustomBadge';
import { getAverage } from './util';

export const AveragesReturnPlacementVertical = ({
  dataNoAverages,
  dataWithAverages,
  showAverages,
  averagesLoading,
  width,
  height
}) => {
  return (
    <Box position="absolute" width={width * 0.63} top={height * 0.816} left={width * 0.186} pt={3}>
      <Flex direction="column" gap={2}>
        <Flex justify="space-around">
          {verticalOrientationOrder.map((position) => {
            const averageFigure = getAverage(dataNoAverages, position, 'match');
            return (
              <Flex direction="column" gap={1} key={position}>
                <CustomBadge key={position} text={averageFigure} type="matchAverage" />
                {!showAverages && <FrequencyBadge frequency={dataNoAverages?.[position]?.frequency} />}
              </Flex>
            );
          })}
        </Flex>
        {showAverages && averagesLoading && (
          <Flex justify="space-around">
            {[1, 2, 3].map((item, index) => (
              <CustomBadge loading={averagesLoading} key={index} type={`playerAverage`} />
            ))}
          </Flex>
        )}
        {showAverages &&
          dataWithAverages &&
          ['player', 'tour'].map((item) => (
            <Flex justify="space-around" key={item}>
              {verticalOrientationOrder.map((position) => {
                const averageFigure = getAverage(dataWithAverages, position, item);
                return (
                  <CustomBadge loading={averagesLoading} key={position} text={averageFigure} type={`${item}Average`} />
                );
              })}
            </Flex>
          ))}
      </Flex>
    </Box>
  );
};

export const AveragesReturnPlacementHorizontalGroup = (props) => (
  <Flex>
    <AveragesReturnPlacementHorizontalType type="match" {...props} />
    <AveragesReturnPlacementHorizontalType type="player" {...props} />
    <AveragesReturnPlacementHorizontalType type="tour" {...props} />
  </Flex>
);

export const AveragesReturnPlacementHorizontalType = ({
  type,
  dataNoAverages,
  dataWithAverages,
  showAverages,
  averagesLoading,
  level
}) => {
  let averageFigure;
  if (type === 'match') {
    averageFigure = getAverage(dataNoAverages, level, 'match');
  } else {
    averageFigure = getAverage(dataWithAverages, level, type);
  }
  if (type === 'match')
    return (
      <Flex gap={2}>
        <CustomBadge text={averageFigure} type={`${type}Average`} />
        {!showAverages && dataNoAverages?.[`${level}`]?.frequency !== null && (
          <Flex justify="center">
            <FrequencyBadge frequency={dataNoAverages?.[`${level}`]?.frequency} />
          </Flex>
        )}
      </Flex>
    );
  if (type !== 'match' && showAverages)
    return <CustomBadge loading={averagesLoading} text={averageFigure} type={`${type}Average`} />;
  return null;
};
