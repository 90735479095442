import { Link } from '@tanstack/react-location';
import { BackToWimbledonLink } from '_customSites/wimb/components/BackToWimbledonLink';
import { isWimbMyWSession } from '_customSites/wimb/services/wimbLocalStorage';
import { sideMenuWidth, sideMenuWidthMobile } from '_layouts/margins';
import { useInsightsExplained } from 'insightsExplained/hooks/useInsightsExplained';
import { useRecoilValue } from 'recoil';
import { useTournamentsQuery } from 'tournaments/hooks/tournamentsHooks';

import customSitesConfig from '_shared/config/customSitesConfig';
import { userPermissionTypes } from '_shared/constants/user';
import { Box, Flex, Text, Icon, Center } from '_shared/designSystem/components';
import { userPermissionsState } from '_shared/globalState/atoms';
import { isAtpTourPortal, isTvPortal, isWimbPortal } from '_shared/utils/environment/currentEnv';
import useFeature from '_shared/utils/hooks/useFeature';
import { checkPermission } from '_shared/utils/permissions';

import AdditionalOptions from './AdditionalOptions';
import SideMenuLogo from './SideMenuLogo';
import { useGetLiveTournaments } from './TopMenu';

export default function SideMenu({ isMobile, closeMenu }) {
  const leaderboardEnabled = useFeature('leaderboard');
  const tournamentsEnabled = useFeature('tournaments');
  const scoutEnabled = useFeature('scout');
  const headToHeadEnabled = useFeature('headToHead');
  const { permissions } = useRecoilValue(userPermissionsState);
  const { data } = useTournamentsQuery();
  const liveTournaments = useGetLiveTournaments(data);

  const { openDrawerWithAccordion } = useInsightsExplained();

  const menuBackgroundColour = isWimbPortal() ? 'grey.25' : 'secondary.800';
  const fontSize = isWimbPortal() ? '15px' : 'sm';
  const textColor = isWimbPortal() ? 'secondary.800' : 'white';
  const todayAtTextColor = isTvPortal() ? 'primary.300' : 'electricYellow';

  const handleInsightsExplainedClick = () => {
    closeMenu();
    setTimeout(() => {
      openDrawerWithAccordion();
    }, 500);
  };

  const MenuItemRow = ({ label, link }) => (
    <Link to={link} key={link}>
      <Flex gap={2} onClick={closeMenu ? () => closeMenu() : null} pl={2}>
        <Flex alignContent="center">
          <Text fontSize={fontSize} color={textColor}>
            {label}
          </Text>
        </Flex>
      </Flex>
    </Link>
  );

  const TodayAtLinkColumn = ({ liveTournaments }) => (
    <Flex px={2} direction="column">
      {liveTournaments.map((item, index) => (
        <Box key={index} mb={4} onClick={closeMenu ? () => closeMenu() : null}>
          <Link to={`/schedule/${item.tournament_id}`} key={item.tournament_id}>
            <Text fontSize="sm" color={todayAtTextColor}>
              Today at {item.name}
            </Text>
          </Link>
        </Box>
      ))}
    </Flex>
  );

  return (
    <Box minW={isMobile ? `${sideMenuWidthMobile}px` : `${sideMenuWidth}px`} maxW={`${sideMenuWidth}px`} h="100vh">
      <Flex direction="column" h="100%">
        <Link to="/" key="home">
          <SideMenuLogo />
        </Link>
        <Flex
          direction="column"
          justify="space-between"
          flex="1"
          overflow="hidden"
          px={2}
          pt={8}
          bg={menuBackgroundColour}
        >
          <Box flex="1" overflowY="auto">
            <Flex direction="column" gap={5}>
              {isWimbMyWSession() && <BackToWimbledonLink />}
              {tournamentsEnabled && (
                <MenuItemRow
                  iconName="trophy"
                  label="Tournaments"
                  link={customSitesConfig.tournamentsUrlPath}
                  closeMenu={closeMenu}
                />
              )}
              {scoutEnabled && checkPermission(userPermissionTypes.SCOUT_AREA, permissions) && (
                <MenuItemRow iconName="user" label="Scout Area" link="/players" />
              )}
              {leaderboardEnabled && checkPermission(userPermissionTypes.LEADERBOARD, permissions) && (
                <MenuItemRow iconName="barChart" label="Leaderboard" link="/leaderboard" />
              )}
              {headToHeadEnabled && checkPermission(userPermissionTypes.HEAD_TO_HEAD, permissions) && (
                <MenuItemRow iconName="info" label="Head to Head" link="/head-to-head" />
              )}
              {customSitesConfig.showTodayAtInMenu && <TodayAtLinkColumn liveTournaments={liveTournaments} />}
            </Flex>
          </Box>
          <Box py={4} px={2}>
            <Flex gap={2} cursor="pointer" w="180px" alignItems="center" onClick={handleInsightsExplainedClick}>
              {!isAtpTourPortal() && (
                <Center>
                  <Icon width="20px" height="20px" name="info" color={textColor} />
                </Center>
              )}
              <Text fontSize={fontSize} color={textColor}>
                Insights Explained
              </Text>
            </Flex>
          </Box>
          <Box h={isAtpTourPortal() ? '100px' : '70px'} px={2}>
            {isWimbMyWSession() ? null : <AdditionalOptions />}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}
