import BalanceOfPower from 'match/components/performance/battles/BalanceOfPower';
import BattlesMetricSection from 'match/components/performance/battles/BattlesMetricSection';
import Momentum from 'match/components/performance/battles/Momentum';

export const WimbBalanceOfPower = (props) => (
  <BalanceOfPower
    {...props}
    bgColor={null}
    bgBorderColor="secondary.800"
    winningColor="primary.500"
    losingColor="grey.200"
    neutralColor="grey.50"
    winningTextColor="white"
    losingTextColor="primary.800"
    neutralTextColor="primary.800"
    headingColor="secondary.800"
    headingText="In Attack"
  />
);

export const WimbMomemtum = (props) => (
  <Momentum
    {...props}
    bgColor={null}
    bgBorderColor="secondary.800"
    labelColor="secondary.800"
    greaterColor="primary.300"
    lessColor="grey.200"
  />
);

export const WimbBattlesMetricSection = (props) => (
  <BattlesMetricSection {...props} greaterColor="primary.300" lessColor="grey.200" />
);
