import { useNavigate } from '@tanstack/react-location';
import { maxSiteWidth } from '_layouts/margins';
import { GENDER_OPTION_VALUES } from 'headToHead/constants/headToHeadConstants';
import PropTypes from 'prop-types';

import PlayerImageHeadshot from '_shared/components/PlayerImageHeadshot';
import { HERO_IMAGE_PLACEHOLDER_DIRECTION } from '_shared/constants/imageConsts';
import { Box, Flex, Image, useBreakpointValue } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import { getMaleHeroPlaceholder } from '_shared/utils/imageUtil';
import { navigateTo } from '_shared/utils/navigationUtils';

export default function PlayerHeroImagesContainer({
  player1HeroUrl,
  player2HeroUrl,
  player1ThumbnailUrl,
  player2ThumbnailUrl,
  player1LastName,
  player2LastName,
  gender,
  children,
  player1Id,
  player2Id,
  isFetchingMatchup,
  reloadHeroImages
}) {
  const navigate = useNavigate();
  const showHeroImages = useBreakpointValue({
    lg: true
  });

  const playerImageSize = { lg: '150px', xl: '180px' };

  return showHeroImages ? (
    <>
      {isWimbPortal() ? (
        <Flex
          width="100%"
          justify="space-between"
          position="fixed"
          top={{ lg: '200px', xl: '150px' }}
          maxW={`${maxSiteWidth}px`}
          px="40px"
        >
          <PlayerImageHeadshot
            w={playerImageSize}
            h={playerImageSize}
            imageUrl={player1ThumbnailUrl}
            playerName={player1LastName}
            playerId={player1Id}
          />
          <PlayerImageHeadshot
            w={playerImageSize}
            h={playerImageSize}
            imageUrl={player2ThumbnailUrl}
            playerName={player2LastName}
            playerId={player2Id}
          />
        </Flex>
      ) : (
        <Flex
          width="100%"
          justify="space-between"
          position="fixed"
          align="flex-start"
          top="120px"
          maxW={`${maxSiteWidth}px`}
        >
          {player1HeroUrl || gender === GENDER_OPTION_VALUES.MALE ? (
            <Image
              w="27%"
              objectFit="contain"
              src={
                reloadHeroImages && isFetchingMatchup
                  ? getMaleHeroPlaceholder(HERO_IMAGE_PLACEHOLDER_DIRECTION.RIGHT)
                  : player1HeroUrl
              }
              fallbackSrc={
                gender === GENDER_OPTION_VALUES.MALE
                  ? getMaleHeroPlaceholder(HERO_IMAGE_PLACEHOLDER_DIRECTION.RIGHT)
                  : undefined
              }
              alt={player1LastName}
              _hover={{
                opacity: 0.5,
                cursor: 'pointer'
              }}
              onClick={() =>
                navigateTo({
                  url: `/scout/${player1Id}`,
                  navigate
                })
              }
            />
          ) : (
            <div />
          )}
          {player2HeroUrl || gender === GENDER_OPTION_VALUES.MALE ? (
            <Image
              w="27%"
              objectFit="contain"
              src={
                reloadHeroImages && isFetchingMatchup
                  ? getMaleHeroPlaceholder(HERO_IMAGE_PLACEHOLDER_DIRECTION.LEFT)
                  : player2HeroUrl
              }
              fallbackSrc={
                gender === GENDER_OPTION_VALUES.MALE
                  ? getMaleHeroPlaceholder(HERO_IMAGE_PLACEHOLDER_DIRECTION.LEFT)
                  : undefined
              }
              alt={player2LastName}
              _hover={{
                opacity: 0.5,
                cursor: 'pointer'
              }}
              onClick={() =>
                navigateTo({
                  url: `/scout/${player2Id}`,
                  navigate
                })
              }
            />
          ) : (
            <div />
          )}
        </Flex>
      )}
      <Box
        position="absolute"
        top="90px"
        left="50%"
        transform="translate(-50%, 0)"
        maxW={{ lg: '550px', xl: '650px', '2xl': '750px' }}
        minW={{ xl: '700px' }}
        bg="white"
      >
        {children}
      </Box>
    </>
  ) : (
    <Box margin="auto" maxW={{ md: '700px' }} bg="white" pt={5}>
      {children}
    </Box>
  );
}

PlayerHeroImagesContainer.propTypes = {
  player1HeroUrl: PropTypes.string,
  player2HeroUrl: PropTypes.string,
  player1ThumbnailUrl: PropTypes.string,
  player2ThumbnailUrl: PropTypes.string,
  player1LastName: PropTypes.string,
  player2LastName: PropTypes.string,
  gender: PropTypes.string,
  children: PropTypes.any,
  player1Id: PropTypes.number,
  player2Id: PropTypes.number,
  isFetchingMatchup: PropTypes.bool,
  reloadHeroImages: PropTypes.bool
};
