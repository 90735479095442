import { Text, Flex } from '_shared/designSystem/components';

const PageHeading = ({ children }) => (
  <Flex justify="center" mb="20px" mt="20px">
    <Text fontSize="xl" color="grey.700" fontWeight="bold">
      {children}
    </Text>
  </Flex>
);

export default PageHeading;
