import { PERFORMANCE_METRICS_TYPES } from 'match/_shared/matchConstants';

const metricTypePaths = {
  [PERFORMANCE_METRICS_TYPES.BALANCE_OF_POWER]: ['battles.balance_of_power'],
  [PERFORMANCE_METRICS_TYPES.MOMENTUM]: ['battles.momentum'],
  [PERFORMANCE_METRICS_TYPES.BASELINE_BATTLES]: ['battles.baseline'],
  [PERFORMANCE_METRICS_TYPES.PERFORMANCE_RATING]: ['comparison_metrics.performance_rating'],
  [PERFORMANCE_METRICS_TYPES.SHOT_QUALITY]: [
    'comparison_metrics.shot_quality',
    'comparison_metrics.shot_quality.serve',
    'comparison_metrics.shot_quality.return',
    'comparison_metrics.shot_quality.forehand',
    'comparison_metrics.shot_quality.backhand'
  ],
  [PERFORMANCE_METRICS_TYPES.ATTACK_DEFENCE]: [
    'comparison_metrics.capitalisation',
    'comparison_metrics.capitalisation.conversion',
    'comparison_metrics.capitalisation.steal'
  ],
  [PERFORMANCE_METRICS_TYPES.SERVE_RETURN_GAME_PERFORMANCE]: [
    'comparison_metrics.performance_in_games',
    'comparison_metrics.performance_in_games.service',
    'comparison_metrics.performance_in_games.return'
  ],
  [PERFORMANCE_METRICS_TYPES.SERVE_EFFECTIVENESS]: ['breakdown_metrics.serve_effectiveness'],
  [PERFORMANCE_METRICS_TYPES.SHOT_VARIATION]: ['breakdown_metrics.variation'],
  [PERFORMANCE_METRICS_TYPES.MATCH_STATISTICS]: ['match_statistics']
};

export const generatePathForComponent = (metricType) => {
  return metricTypePaths[metricType] || [''];
};

const shotTypePaths = {
  serve: 'comparison_metrics.shot_quality.serve',
  return: 'comparison_metrics.shot_quality.return',
  forehand: 'comparison_metrics.shot_quality.forehand',
  backhand: 'comparison_metrics.shot_quality.backhand',
  conversion: 'comparison_metrics.capitalisation.conversion',
  steal: 'comparison_metrics.capitalisation.steal',
  service_games: 'comparison_metrics.performance_in_games.service',
  return_games: 'comparison_metrics.performance_in_games.return'
};

export const generateIdForShotType = (shotType) => {
  return shotTypePaths[shotType] || '';
};
