import { BREAKDOWN_METRICS_TYPES } from 'match/_shared/matchConstants';
import BreakdownMetrics from 'match/components/performance/breakdownMetrics/BreakdownMetrics';

import { Box, Flex, Text } from '_shared/designSystem/components';

const MatchSummaryContinued = ({ performanceData }) => {
  const { performance, players } = performanceData || {};

  if (performance) {
    return (
      <Box>
        <Flex justify="center" mb={10}>
          <Box>
            <Text textAlign="center" fontWeight="semibold" fontSize="lg" mb={2}>
              Shot Variation
            </Text>
            <Box w="450px">
              <Box>
                <BreakdownMetrics
                  graphicType={BREAKDOWN_METRICS_TYPES.SHOT_VARIATION}
                  dataNoAverages={performance?.breakdown_metrics?.variation}
                  dataWithAverages={performance?.breakdown_metrics?.variation}
                  isMatch={true}
                  players={players}
                  showAverages={false}
                  averagesLoading={false}
                  isReport
                />
              </Box>
            </Box>
          </Box>
        </Flex>
        <Box>
          <Text textAlign="center" fontWeight="semibold" fontSize="lg" mb={7}>
            Serve Effectiveness
          </Text>
          <Flex gap="70px">
            <Flex direction="column" gap={20} align="center">
              <Box w="450px">
                <Box>
                  <BreakdownMetrics
                    graphicType={BREAKDOWN_METRICS_TYPES.SERVE_EFFECTIVENESS}
                    dataNoAverages={performanceData?.performance?.breakdown_metrics?.serve_effectiveness}
                    dataWithAverages={performanceData?.performance?.breakdown_metrics?.serve_effectiveness}
                    isMatch={true}
                    players={performanceData?.players}
                    showAverages={false}
                    averagesLoading={false}
                    serveType={1}
                    isReport
                  />
                </Box>
              </Box>
            </Flex>
            <Box>
              <BreakdownMetrics
                graphicType={BREAKDOWN_METRICS_TYPES.SERVE_EFFECTIVENESS}
                dataNoAverages={performanceData?.performance?.breakdown_metrics?.serve_effectiveness}
                dataWithAverages={performanceData?.performance?.breakdown_metrics?.serve_effectiveness}
                isMatch={true}
                players={performanceData?.players}
                showAverages={false}
                averagesLoading={false}
                serveType={2}
                isReport
              />
            </Box>
          </Flex>
        </Box>
      </Box>
    );
  }
  return null;
};

export default MatchSummaryContinued;
