export const updateUrl = (fieldName, fieldValue, navigate) => {
  const newObject = {};
  newObject[`${fieldName}`] = fieldValue;
  navigate({ search: (old) => ({ ...old, ...newObject }) });
};

export const removeFieldFromUrl = (fieldName, navigate) => {
  navigate({
    search: (old) => {
      delete old[`${fieldName}`];
      return old;
    }
  });
};

export function clearUrlParams(navigate) {
  const url = new URL(window.location);
  url.search = '';
  navigate({ search: url.search });
}
