import PageHeading from 'matchReport/components/_shared/PageHeading';
import PropTypes from 'prop-types';
import ScoutInsights from 'scout/components/insights/ScoutInsights';
import PageLayout from 'scoutReport/components/PageLayout';

export default function InsightsPage(props) {
  const { playerName, insightsDataWithAverages } = props;
  return (
    <PageLayout playerName={playerName}>
      <PageHeading>Insights</PageHeading>
      <ScoutInsights
        isMatchReport
        dataNoAverages={insightsDataWithAverages}
        dataWithAverages={insightsDataWithAverages}
        showAverages
      />
    </PageLayout>
  );
}

InsightsPage.propTypes = {
  playerName: PropTypes.string,
  insightsDataWithAverages: PropTypes.object
};
