import PropTypes from 'prop-types';

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, Box } from '_shared/designSystem/components';

import ScoutMatchSelector from './ScoutMatchSelector';

export const ScoutMatchSelectorModal = (props) => {
  const { isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="7xl" w="95%">
        <ModalCloseButton />
        <Box p={6}>
          <ScoutMatchSelector {...props} />
        </Box>
      </ModalContent>
    </Modal>
  );
};

ScoutMatchSelectorModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  playerDetailsData: PropTypes.object,
  formPopulationData: PropTypes.object,
  matchSelectorMatches: PropTypes.array,
  scoutFormValues: PropTypes.object,
  handleMatchSelectorFormSubmit: PropTypes.func,
  handleMatchSelectorFormUpdate: PropTypes.func,
  matchSelectorLoading: PropTypes.bool
};
