import { Flex, CourtBadgeGroup } from '_shared/designSystem/components';
import colors from '_shared/designSystem/theme/colors';

import {
  InCourtBadgeGroups,
  QualityCourtBadgeGroupDesktopAverages,
  QualityCourtBadgeGroupDesktop
} from './BadgeComponents';

export const DirectionAndAccuracyBadgePositions = ({
  deuceDataWithAverages,
  deuceDataNoAverages,
  advantageDataWithAverages,
  advantageDataNoAverages,
  isWideCourt,
  showAverages,
  averagesLoading
}) => (
  <>
    <Flex
      position="absolute"
      top="170px"
      left={isWideCourt ? '150px' : '95px'}
      zIndex={1}
      data-testid="direction-accuracy-deuce-badge-group"
    >
      <CourtBadgeGroup
        dataWithAverages={deuceDataWithAverages}
        dataNoAverages={deuceDataNoAverages}
        showAverages={showAverages}
        averagesLoading={averagesLoading}
        size="large"
      />
    </Flex>
    <Flex
      position="absolute"
      top="170px"
      right={isWideCourt ? '150px' : '95px'}
      zIndex={1}
      data-testid="direction-accuracy-advantage-badge-group"
    >
      <CourtBadgeGroup
        dataWithAverages={advantageDataWithAverages}
        dataNoAverages={advantageDataNoAverages}
        showAverages={showAverages}
        averagesLoading={averagesLoading}
        size="large"
      />
    </Flex>
  </>
);

export const InBadgePositions = ({ dataWithAverages, dataNoAverages, isWideCourt, showAverages, averagesLoading }) => {
  const positions = {
    deuce: { top: '165px', left: isWideCourt ? '155px' : '95px' },
    deuceLong: { top: '10px', left: isWideCourt ? '120px' : '85px' },
    deuceWide: { top: '110px', left: '30px' },
    deuceNet: { top: '315px', left: isWideCourt ? '155px' : '115px' },
    advantage: { top: '165px', right: isWideCourt ? '155px' : '95px' },
    advantageLong: { top: '10px', right: isWideCourt ? '115px' : '85px' },
    advantageWide: { top: '110px', right: '30px' },
    advantageNet: { top: '315px', right: isWideCourt ? '155px' : '115px' }
  };

  const badgeGroups = [
    { side: 'to_deuce', type: 'in', position: 'deuce', label: 'In', size: 'large' },
    { side: 'to_deuce', type: 'long', position: 'deuceLong', label: 'Long', isWideCourt },
    { side: 'to_deuce', type: 'wide', position: 'deuceWide', label: 'Wide', isWideCourt },
    { side: 'to_deuce', type: 'net', position: 'deuceNet', label: 'Net', isWideCourt },
    {
      side: 'to_advantage',
      type: 'in',
      position: 'advantage',
      label: 'In',
      size: 'large',
      variant: 'alternateMatchAverage'
    },
    {
      side: 'to_advantage',
      type: 'long',
      position: 'advantageLong',
      label: 'Long',
      isWideCourt,
      variant: 'alternateMatchAverage'
    },
    {
      side: 'to_advantage',
      type: 'wide',
      position: 'advantageWide',
      label: 'Wide',
      isWideCourt,
      variant: 'alternateMatchAverage'
    },
    {
      side: 'to_advantage',
      type: 'net',
      position: 'advantageNet',
      label: 'Net',
      isWideCourt,
      variant: 'alternateMatchAverage'
    }
  ];

  return (
    <>
      {badgeGroups.map(({ side, type, position, label, size, isWideCourt, variant }) => {
        if (isWideCourt === false) return null;
        const key = `${side}-${type}-badge-group`;
        return (
          <div data-testid={key} key={key}>
            {InCourtBadgeGroups(
              dataWithAverages?.[side]?.[type],
              dataNoAverages?.[side]?.[type],
              positions[position],
              label,
              size,
              variant,
              showAverages,
              averagesLoading
            )}
          </div>
        );
      })}
    </>
  );
};

export const QualityBadgePositions = ({
  dataWithAverages,
  dataNoAverages,
  isWideCourt,
  showAverages,
  averagesLoading
}) => {
  const deucePosition = {
    top: '170px',
    left: isWideCourt && showAverages ? '95px' : isWideCourt ? '145px' : '95px'
  };
  const advantagePosition = {
    top: '170px',
    right: isWideCourt && showAverages ? '95px' : isWideCourt ? '140px' : '95px'
  };

  return isWideCourt && showAverages ? (
    <>
      <Flex
        direction="row"
        position="absolute"
        {...deucePosition}
        gap={1}
        zIndex={1}
        data-testid={`to_deuce-quality-badge-group`}
      >
        {['speed', 'spin'].map((attribute) => (
          <QualityCourtBadgeGroupDesktopAverages
            dataNoAverages={dataNoAverages}
            dataWithAverages={dataWithAverages}
            courtSide="to_deuce"
            attribute={attribute}
            key={`deuce-${attribute}`}
            showAverages={showAverages}
            averagesLoading={averagesLoading}
          />
        ))}
      </Flex>
      <Flex
        direction="row"
        position="absolute"
        {...advantagePosition}
        gap={1}
        zIndex={1}
        data-testid={`to_advantage-quality-badge-group`}
      >
        {['speed', 'spin'].map((attribute) => (
          <QualityCourtBadgeGroupDesktopAverages
            dataNoAverages={dataNoAverages}
            dataWithAverages={dataWithAverages}
            courtSide="to_advantage"
            attribute={attribute}
            key={`advantage-${attribute}`}
            showAverages={showAverages}
            averagesLoading={averagesLoading}
          />
        ))}
      </Flex>
    </>
  ) : (
    <Flex direction="column" gap={1}>
      <QualityCourtBadgeGroupDesktop
        data={dataNoAverages?.to_deuce}
        position={deucePosition}
        attributes={['speed', 'spin']}
        showAverages={showAverages}
        averagesLoading={averagesLoading}
      />
      <QualityCourtBadgeGroupDesktop
        data={dataNoAverages?.to_advantage}
        position={advantagePosition}
        attributes={['speed', 'spin']}
        showAverages={showAverages}
        averagesLoading={averagesLoading}
      />
    </Flex>
  );
};

export const DepthBadgePositions = ({
  dataNoAverages,
  dataWithAverages,
  isWideCourt,
  showAverages,
  averagesLoading
}) => {
  const positions = ['top', 'middle', 'bottom'];
  const locations = ['left', 'middle', 'right'];

  const viewBoxWidth = isWideCourt ? 112 : 66;
  const leftOffsetBase = isWideCourt ? 84 : 76;
  const borderStyle = `2px dotted ${colors.warning[200]}`;

  const getTopOffset = (rowIndex) => ['10px', '76px', '144px'][rowIndex];
  const getLeftOffset = (colIndex) => `${colIndex * viewBoxWidth + leftOffsetBase}px`;
  const getBorderStyle = (position, location) => ({
    borderLeft: location === 'middle' ? borderStyle : 'none',
    borderRight: location === 'middle' ? borderStyle : 'none',
    borderTop: position === 'middle' ? borderStyle : 'none',
    borderBottom: position === 'middle' ? borderStyle : 'none',
    height: position === 'bottom' ? '154px' : '70px'
  });

  return positions.flatMap((position, rowIndex) =>
    locations.map((location, colIndex) => {
      const key = `${position}_${location}`;
      const topOffset = getTopOffset(rowIndex);
      const leftOffset = getLeftOffset(colIndex);
      const scoreBoxStyles = getBorderStyle(position, location);

      // Uncomment when heatmap colours are approved
      // const score = dataNoAverages?.scores[rowIndex][colIndex]?.score || 0;
      // const heatmapColor = surface === 'clay' ? `rgba(71, 84, 103, ${score / 25})` : `rgba(180, 35, 24, ${score / 20})`;
      return (
        <Flex
          key={key}
          position="absolute"
          top={topOffset}
          left={leftOffset}
          zIndex={1}
          style={{
            // backgroundColor: heatmapColor, // uncomment when heatmap colours are approved
            ...scoreBoxStyles
          }}
          data-testid={`${position}-${location}-depth-badge`}
        >
          <Flex w={`${viewBoxWidth}px`} height={scoreBoxStyles.height} justifyContent="center" alignItems="center">
            <CourtBadgeGroup
              dataNoAverages={dataNoAverages?.scores[rowIndex][colIndex]}
              dataWithAverages={dataWithAverages?.scores[rowIndex][colIndex]}
              showAverages={showAverages}
              averagesLoading={averagesLoading}
            />
          </Flex>
        </Flex>
      );
    })
  );
};
