export const breakpoints = {
  /**
   * Small breakpoint
   *
   * This breakpoint will match:
   *  * some phones in portrait,
   *  * most phones in landscape
   *  * all tablets, laptops & desktops.
   */
  sm: '576px',
  /**
   * Medium breakpoint
   *
   * This breakpoint will match:
   *  * most tablets in portrait,
   *  * some larger phones in landscape,
   *  * most laptops & desktops, if the window takes more than 50% of the screen.
   */
  md: '768px',
  /**
   * Large breakpoint
   *
   * This breakpoint will match:
   *  * some very large phones in landscape,
   *  * many tablets in landscape,
   *  * most laptops & desktops, if the window takes up 3/4 of the screen.
   */
  lg: '992px',
  /**
   * Extra Large breakpoint
   *
   * This breakpoint will match:
   *  * No phones,
   *  * No tablets,
   *  * most laptops & desktops, if the window takes up most of the screen.
   */
  xl: '1200px',
  '2xl': '1400px'
};
