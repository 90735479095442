import { Td as ChakraTd } from '@chakra-ui/react';

const Td = (props) => (
  <ChakraTd
    whiteSpace="nowrap"
    borderBottom="1px solid"
    borderBottomColor="grey.200"
    fontSize="sm"
    color="grey.500"
    {...props}
  >
    {props.children}
  </ChakraTd>
);

export default Td;
