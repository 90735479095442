import { useEffect, useRef } from 'react';

import { scaleLinear, select, scalePoint } from 'd3';
import { adjustDataForTieBreaks, getMinYRating } from 'match/utils/performanceGraphShadedUtil';
import PropTypes from 'prop-types';

import { Box } from '_shared/designSystem/components';

import './performanceGraphShadedStyles.css';

import { drawAveragesLines } from './averages';
import PerformanceGraphShadedLegend from './PerformanceGraphShadedLegend';
import { drawPerformanceLineAndArea } from './performanceLines';
import { drawSetLinesAndLabels } from './setLines';
import { drawXAxis } from './xAxis';
import { drawYAxis } from './yAxis';

export default function PerformanceGraphShaded(props) {
  const {
    width,
    height,
    performanceRatingGame: _performanceRatingGame,
    player1Name,
    player2Name,
    id = 'performanceGraphShaded',
    lineColors,
    performanceRatingAverages
  } = props;
  const svgRef = useRef();
  const wrapperRef = useRef();

  function createXAxisIdentifier(gameData) {
    return `${gameData?.score_p1}-${gameData?.score_p2}-${gameData?.server}-${gameData?.set}-${gameData?.game}-${gameData?.break_of_serve}`;
  }

  useEffect(() => {
    const svg = select(svgRef.current);
    svg.selectAll('*').remove();
    const xMargin = 5;
    const yMargin = 30;
    const marginLeftInner = 35;
    const marginRightInner = 5;

    // until we get the API updated to handle tie breaks better, doing this manually on the frontend for now
    const performanceRatingGame = adjustDataForTieBreaks(_performanceRatingGame);

    // this is the main source of the graph data
    const graphData = performanceRatingGame.map((item) => ({
      matchScore: createXAxisIdentifier(item),
      player1Rating: item.rolling_performance_average_p1,
      player2Rating: item.rolling_performance_average_p2
    }));

    const minimumGames = getMinYRating(performanceRatingGame);

    // draw the X axis
    const xScale = scalePoint()
      .domain(graphData.map((d) => d.matchScore)) // we are using the matchScore as the actual graphData for the axis
      .range([marginLeftInner, width - marginRightInner]); // the min and max width where the x-axis will be

    drawXAxis(svg, width, height, yMargin, xScale, lineColors, performanceRatingGame);

    // draw the Y axis
    const yScale = scaleLinear()
      .domain([minimumGames, 10]) // setting the min and max y-axis values for the performance rating
      .range([height - yMargin, yMargin]); // the min and max in height of the y-axis

    drawYAxis(svg, width, yMargin, marginLeftInner, yScale);

    // draw the set lines and labels
    drawSetLinesAndLabels(svg, height, yMargin, performanceRatingGame, xScale, createXAxisIdentifier, lineColors);

    // draw the averages lines
    drawAveragesLines(svg, width, performanceRatingAverages, lineColors, marginRightInner, marginLeftInner, yScale);

    // draw performance lines and the area fill between them
    drawPerformanceLineAndArea(
      svg,
      width,
      height,
      marginRightInner,
      xMargin,
      yMargin,
      graphData,
      xScale,
      yScale,
      lineColors
    );
  }, [width, height, _performanceRatingGame, player1Name, player2Name, performanceRatingAverages, lineColors]);

  return (
    <Box data-testid="performanceTrackerGraph">
      <Box ref={wrapperRef} mb="135px">
        <svg ref={svgRef} className="performance-graph-shaded-svg">
          <g className="x-axis" clipPath={`url(#x-axis)`} />
          <g className="y-axis" id="y-axis" />
          <g className="x-axis-label" />
          <g className="content" clipPath={`url(#${id})`}></g>
        </svg>
      </Box>
      <PerformanceGraphShadedLegend {...props} />
    </Box>
  );
}

PerformanceGraphShaded.propTypes = {
  performanceRatingGame: PropTypes.array,
  player1Name: PropTypes.string,
  player2Name: PropTypes.string,
  lineColors: PropTypes.object,
  performanceRatingAverages: PropTypes.object
};
