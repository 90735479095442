import { Flex } from '_shared/designSystem/components';
import { isAtpMediaPortal, isAtpTourPortal, isTvPortal, isWimbPortal } from '_shared/utils/environment/currentEnv';

import Logo from '../logo/Logo';

export default function TopMenuLogo({ iconOnly }) {
  if (isTvPortal() || isAtpMediaPortal()) {
    return (
      <Flex align="center" pt={iconOnly ? 0 : 1} bg="secondary.800" w={iconOnly ? '20px' : '110px'}>
        <Logo iconOnly={iconOnly} width={iconOnly ? '25px' : '110px'} height={iconOnly ? '42px' : '34px'} />
      </Flex>
    );
  }
  if (isWimbPortal()) {
    return (
      <Flex align="center" justify="center" bg="grey.25" pt="20px">
        <Logo width="60px" height="60px" />
      </Flex>
    );
  }
  if (isAtpTourPortal()) {
    return iconOnly ? (
      <Flex h="40px" align="center">
        <Logo height="20px" type="stacked" color="white" />
      </Flex>
    ) : (
      <Flex w="100%" h="40px" bg="darkNavy" align="center">
        <Logo width="150px" type="horizontal" color="white" />
      </Flex>
    );
  }
}
