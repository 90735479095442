import { Flex, Skeleton, Stack } from '_shared/designSystem/components';

const BarsPanelSkeleton = () => {
  const startColor = 'grey.200';
  const endColor = 'grey.300';
  return (
    <Stack padding={4} spacing={1} boxShadow="md">
      <Flex justify="center">
        <Skeleton height="16px" width="200px" startColor={startColor} endColor={endColor} />
      </Flex>
      <Flex justify="space-between">
        <Skeleton height="16px" width="100px" startColor={startColor} endColor={endColor} />
        <Skeleton height="16px" width="100px" startColor={startColor} endColor={endColor} />
      </Flex>
      {[...Array(10)].map((x, i) => (
        <Skeleton key={i} height="14px" mt={6} startColor={startColor} endColor={endColor} />
      ))}
    </Stack>
  );
};

export default BarsPanelSkeleton;

export const MatchupSkeleton = () => {
  const startColor = 'grey.200';
  const endColor = 'grey.300';
  return (
    <Stack padding={4} spacing="38px" boxShadow="md">
      <Flex justify="center">
        <Skeleton h="149px" w="100%" startColor={startColor} endColor={endColor} />
      </Flex>
      <Flex justify="center">
        <Skeleton h="149px" w="100%" startColor={startColor} endColor={endColor} />
      </Flex>
    </Stack>
  );
};
