import React from 'react';

import { Text, Flex, Center } from '@ellipsedata/tennis';
import PropTypes from 'prop-types';

import Icon from '_shared/designSystem/components/icon/_Icon';

const Chip = ({ label, onRemove }) => {
  return (
    <Flex gap={2} bg="grey.200" px={1} py="1px">
      <Text fontSize="xs" fontWeight="semibold" color="secondary.800">
        {label.toUpperCase()}
      </Text>
      {onRemove && (
        <Center cursor="pointer">
          <Icon height="14px" width="14px" color="secondary.800" name="cross" onClick={onRemove} />
        </Center>
      )}
    </Flex>
  );
};

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func
};

export default Chip;
