import { Image, Text, Flex, Button } from '_shared/designSystem/components';

import { WIMB_REDIRECT_URL } from '../constants/wimbAuthConsts';
import { wimbledonImageUrls } from '../util/imageUtil';

export default function WimbLogin() {
  const handleLoginClick = () => {
    window.location.replace(WIMB_REDIRECT_URL);
  };

  return (
    <Flex justify="center" mt="100px">
      <Flex direction="column" align="center" gap={6}>
        <Image src={wimbledonImageUrls.LOGO} w="100px" h="100px" />
        <Text fontSize="2xl" textAlign="center" color="grey.500">
          Performance Analytics
        </Text>
        <Button mt={5} onClick={handleLoginClick}>
          Login
        </Button>
      </Flex>
    </Flex>
  );
}
