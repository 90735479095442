import PageHeading from 'matchReport/components/_shared/PageHeading';
import PropTypes from 'prop-types';
import CourtStatsTable from 'scout/components/court-graphics/CourtStatsTable';
import PageLayout from 'scoutReport/components/PageLayout';

import { Box } from '_shared/designSystem/components';

export default function CourtStatsPage(props) {
  const { playerName, heading, courtStatsDataNoAverages, courtStatsDataWithAverages } = props;
  return (
    <PageLayout playerName={playerName}>
      <PageHeading>{heading}</PageHeading>
      <Box>
        <CourtStatsTable
          courtStatsDataNoAverages={courtStatsDataNoAverages}
          courtStatsDataWithAverages={courtStatsDataWithAverages}
          averagesLoading={false}
        />
      </Box>
    </PageLayout>
  );
}

CourtStatsPage.propTypes = {
  playerName: PropTypes.string,
  heading: PropTypes.string,
  courtStatsDataNoAverages: PropTypes.array,
  courtStatsDataWithAverages: PropTypes.array
};
