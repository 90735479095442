import PropTypes from 'prop-types';

import { Text, Flex, Center, Box } from '_shared/designSystem/components';

export default function ButtonForm(props) {
  const { label, onClick, isSelected } = props;
  return (
    <Flex cursor="pointer" onClick={onClick}>
      <Center borderRadius="lg" px="9px" borderWidth="1px" borderColor="grey.300" h="32px">
        <Flex justify="center" align="center">
          {isSelected ? (
            <Box h="7px" w="7px" bg="success.500" borderRadius="full" mr={2} />
          ) : (
            <Box h="7px" w="7px" borderRadius="full" mr={2} border="solid 1px" borderColor="grey.500" />
          )}
          <Text whiteSpace="nowrap" fontSize="xs" fontWeight="medium" color="grey.700">
            {label}
          </Text>
        </Flex>
      </Center>
    </Flex>
  );
}

ButtonForm.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool
};
