export const videoPlaylistFields = {
  SETS: 'sets',
  FROM_GAME: 'from_game',
  TO_GAME: 'to_game',
  POINT_TYPE: 'point_type',
  LAST_SHOT: 'last_shot',
  OUTCOME: 'outcome',
  SITUATION: 'situation',
  SERVE_TYPE: 'serve_type',
  SERVE_SIDE: 'serve_side',
  SERVE_DIRECTION: 'serve_direction',
  SERVE_PLUS_ONE: 'serve_plus_one',
  RETURN_TYPE: 'return_type',
  RETURN_SHOT_TYPE: 'return_shot_type',
  RETURN_SPIN: 'return_spin',
  RETURN_DIRECTION: 'return_direction',
  RETURN_POSITION: 'return_position',
  RALLY_PLAYER_HIT: 'rally_player_hit',
  RALLY_SHOT_TYPE: 'rally_shot_type',
  RALLY_SPIN: 'rally_spin',
  RALLY_DIRECTION: 'rally_direction',
  RALLY_ADVANCED: 'rally_advanced',
  RALLY_STROKE_TYPE: 'rally_stroke_type',
  CONVERSION_AND_STEAL: 'conversion_and_steal'
};

export const videoPlaylistCategories = {
  SCORE: 'score',
  POINT_END: 'point_end',
  SERVE: 'serve',
  RETURN: 'return',
  RALLY: 'rally',
  CONVERSION_AND_STEAL: 'conversion_and_steal'
};

export const fieldMappings = {
  [videoPlaylistCategories.SCORE]: [
    videoPlaylistFields.SETS,
    videoPlaylistFields.FROM_GAME,
    videoPlaylistFields.TO_GAME,
    videoPlaylistFields.POINT_TYPE
  ],
  [videoPlaylistCategories.POINT_END]: [
    videoPlaylistFields.LAST_SHOT,
    videoPlaylistFields.OUTCOME,
    videoPlaylistFields.SITUATION
  ],
  [videoPlaylistCategories.SERVE]: [
    videoPlaylistFields.SERVE_TYPE,
    videoPlaylistFields.SERVE_SIDE,
    videoPlaylistFields.SERVE_DIRECTION,
    videoPlaylistFields.SERVE_PLUS_ONE
  ],
  [videoPlaylistCategories.RETURN]: [
    videoPlaylistFields.RETURN_TYPE,
    videoPlaylistFields.RETURN_SHOT_TYPE,
    videoPlaylistFields.RETURN_SPIN,
    videoPlaylistFields.RETURN_DIRECTION,
    videoPlaylistFields.RETURN_POSITION
  ],
  [videoPlaylistCategories.RALLY]: [
    videoPlaylistFields.RALLY_PLAYER_HIT,
    videoPlaylistFields.RALLY_SHOT_TYPE,
    videoPlaylistFields.RALLY_STROKE_TYPE,
    videoPlaylistFields.RALLY_SPIN,
    videoPlaylistFields.RALLY_DIRECTION,
    videoPlaylistFields.RALLY_ADVANCED
  ],
  [videoPlaylistCategories.CONVERSION_AND_STEAL]: [videoPlaylistFields.CONVERSION_AND_STEAL]
};

export const categoriesOrder = [
  videoPlaylistCategories.SCORE,
  videoPlaylistCategories.POINT_END,
  videoPlaylistCategories.SERVE,
  videoPlaylistCategories.RETURN,
  videoPlaylistCategories.RALLY,
  videoPlaylistCategories.CONVERSION_AND_STEAL
];

export const fullMatchVideoType = {
  FULL_MATCH: 'full_match',
  COMPRESSED_MATCH: 'compressed_match'
};

export const videoStatusType = {
  MATCH_INCOMPLETE: 'match_incomplete',
  PROCESSING: 'processing',
  READY: 'ready',
  UNAVAILABLE: 'unavailable'
};

export const videoTexts = {
  MATCH_INCOMPLETE: 'Video will be available after the match is completed',
  PROCESSING: 'Video is currently processing, please try again later',
  UNAVAILABLE: 'Video is unavailable for this match',
  API_ERROR: 'Something has gone wrong in fetching the data, please try refreshing the page',
  FLAGGED: 'You may notice issues with the timings of some video clips'
};

export const statusAlertMap = {
  [videoStatusType.PROCESSING]: { status: 'info', message: videoTexts.PROCESSING },
  [videoStatusType.MATCH_INCOMPLETE]: { status: 'info', message: videoTexts.MATCH_INCOMPLETE },
  [videoStatusType.UNAVAILABLE]: { status: 'info', message: videoTexts.UNAVAILABLE }
};

export const videoPlayerWidthBreakpoints = {
  base: '100%',
  sm: '100%',
  md: '731px',
  lg: '656px',
  xl: '720px',
  '2xl': '720px'
};

export const videoPlayerHeightBreakpoints = {
  base: '100%',
  sm: '100%',
  md: '411px',
  lg: '369px',
  xl: '410px',
  '2xl': '410px'
};
