import axios from 'axios';

import { getReportLambdaUrl } from '_shared/dataFetching/urlConfig';

export const matchReportService = {
  async createMatchReport({ matchId, company }) {
    const url = `${getReportLambdaUrl('match')}${matchId}?random_num=${Math.floor(
      100000 + Math.random() * 900000
    )}&company=${company}`;
    const res = await axios({
      method: 'get',
      url
    });
    const data = res.data;
    return data;
  }
};
