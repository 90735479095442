import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { Flex, ButtonGroupSingle } from '_shared/designSystem/components';

/**
 *
 * getButtonDataFunction is an optional prop. If given, it will manipulate the data using that function,
 * otherwise just use the data provided
 *
 * selectedItemMultiple is an optional array which has multiple selections.
 */

export default function ButtonGroup(props) {
  const { data, getButtonDataFunction, paramName, handleButtonClick, selectedItem, selectedItemMultiple } = props;
  const buttonData = getButtonDataFunction ? getButtonDataFunction(data) : data;

  if (isEmpty(buttonData)) return null;

  const getIsSelectedItem = (value) => {
    if (!isEmpty(selectedItemMultiple)) return selectedItemMultiple.includes(value);
    else return selectedItem === value;
  };

  return (
    <Flex mr="20px">
      {buttonData.map((item, index) => (
        <ButtonGroupSingle
          key={item.value}
          onClick={() => handleButtonClick(paramName, item.value)}
          label={item.label}
          position={getPosition(buttonData, index)}
          isSelected={getIsSelectedItem(item.value)}
        />
      ))}
    </Flex>
  );
}

ButtonGroup.propTypes = {
  getButtonDataFunction: PropTypes.func,
  handleButtonClick: PropTypes.func,
  data: PropTypes.any,
  paramName: PropTypes.string,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedItemMultiple: PropTypes.array
};

function getPosition(buttonData, index) {
  if (buttonData.length === 1) return 'singleButton';
  else return index === 0 ? 'left' : index === buttonData.length - 1 ? 'right' : 'middle';
}
