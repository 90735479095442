import isEmpty from 'lodash/isEmpty';
import { getSearchParamUrl } from 'scout/util/scoutUtil';

import { apiClient } from '_shared/dataFetching/apiConfig';
import { getSiteParam } from '_shared/dataFetching/urlConfig';
import { convertBooleanToYesNo } from '_shared/utils/stringUtil';

export const scoutService = {
  async getPlayerDetails({ playerId }) {
    const url = `scout/${playerId}/player_details?${getSiteParam()}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getPlayerDetailsWinLoss({ playerId, scoutFormValues }) {
    const url = `scout/${playerId}/player_details/win_loss?${getSiteParam()}&${getSearchParamUrl(scoutFormValues)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getFormPopulation({ playerId, scoutFormValues }) {
    const url = `scout/${playerId}/match_selector/form_population?${getSiteParam()}&seasons=${
      scoutFormValues.seasons
    }&last_52_weeks=${scoutFormValues.last52Weeks}&last_no_matches=${scoutFormValues.lastNoMatches}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getMatchSelectorData({ playerId, scoutFormValues }) {
    const url = `scout/${playerId}/match_selector/matches?${getSiteParam()}&${getSearchParamUrl(scoutFormValues)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getInsights({ playerId, scoutFormValues, averages }) {
    const url = `scout/${playerId}/insights?${getSiteParam()}&averages=${convertBooleanToYesNo(
      averages
    )}&${getSearchParamUrl(scoutFormValues)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getInsightsPerformanceRating({ playerId, scoutFormValues, averages }) {
    const url = `scout/${playerId}/insights/performance_rating?${getSiteParam()}&averages=${convertBooleanToYesNo(
      averages
    )}&${getSearchParamUrl(scoutFormValues)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getCourtGraphics({ playerId, graphicType, courtGraphicsParams, averages }) {
    const url = `scout/${playerId}/court_graphics?${getSiteParam()}&graphic_type=${graphicType}&averages=${convertBooleanToYesNo(
      averages
    )}&${courtGraphicsParams}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getCourtStats({ playerId, graphicType, courtGraphicsParams, averages }) {
    const url = `scout/${playerId}/court_stats?${getSiteParam()}&graphic_type=${graphicType}&averages=${convertBooleanToYesNo(
      averages
    )}&${courtGraphicsParams}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getWinningAndLosingPlays({ playerId, playType, scoutFormValues }) {
    const url = `scout/${playerId}/winning_and_losing_plays?${getSiteParam()}&play_type=${playType}&${getSearchParamUrl(
      scoutFormValues
    )}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getWinningAndLosingPlaysV2({ playerId, playerType, scoutFormValues, averages }) {
    const url = `scout/${playerId}/winning_and_losing_plays_v2?${getSiteParam()}&player_type=${playerType}&averages=${convertBooleanToYesNo(
      averages
    )}&${getSearchParamUrl(scoutFormValues)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getPatternsOfPlay({ playerId, patternsOfPlayParams, scoutFetchingValues, averages }) {
    let url = `scout/${playerId}/patterns_of_play?${getSiteParam()}`;
    if (!isEmpty(patternsOfPlayParams)) url = url += `&${patternsOfPlayParams}`;
    if (!isEmpty(scoutFetchingValues)) url = url += `&${getSearchParamUrl(scoutFetchingValues)}`;
    url = url += `&averages=${convertBooleanToYesNo(averages)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  }
};
