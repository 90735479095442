import React from 'react';

import { matchHeaderHeight } from '_layouts/margins';
import { Averages } from 'match/components/_shared/Filtering';
import { getPlayersButtons } from 'match/utils/matchUtils';
import { getSetButtonData } from 'match/utils/performanceUtil';
import { getCourtLegend, getVolumeTypeButtons } from 'match/utils/winningAndLosingPlaysUtil';
import PropTypes from 'prop-types';
import { getScoutPlayerOpponentsButtons } from 'scout/util/scoutUtil';
import { getScoutCourtLegend } from 'scout/util/scoutWinningAndLosingPlaysUtil';

import { Box, ButtonGroup, Flex, Legend, Text, useBreakpointValue } from '_shared/designSystem/components';

const PlaysFilters = ({
  data,
  player,
  set,
  volumeType,
  showAverages,
  handleButtonClick,
  handleAveragesToggle,
  isScout,
  playerType
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      w="100%"
      zIndex={2}
      px={{ base: 2, lg: 0 }}
      bg="white"
      top={{ base: null, md: 0, lg: `${matchHeaderHeight}px` }}
    >
      <Flex rowGap="16px" flexWrap="wrap" mb={5} align="center" py={2}>
        {isScout ? (
          <ButtonGroup
            paramName="playerType"
            getButtonDataFunction={getScoutPlayerOpponentsButtons}
            handleButtonClick={handleButtonClick}
            selectedItem={playerType}
          />
        ) : (
          <ButtonGroup
            paramName="player"
            data={data?.data}
            getButtonDataFunction={getPlayersButtons}
            handleButtonClick={handleButtonClick}
            selectedItem={player}
          />
        )}
        {!isScout && (
          <Flex alignItems="center">
            <Text fontSize="sm" fontWeight="medium" color="grey.700" mr={2}>
              Set
            </Text>
            <ButtonGroup
              paramName="set"
              data={data?.data?.match_score?.set_scores.length}
              getButtonDataFunction={getSetButtonData}
              handleButtonClick={handleButtonClick}
              selectedItem={set}
            />
          </Flex>
        )}
        <ButtonGroup
          paramName="volumeType"
          data={data?.data}
          getButtonDataFunction={getVolumeTypeButtons}
          handleButtonClick={handleButtonClick}
          selectedItem={volumeType}
        />
        <Flex maxW={400} align="center" mx={2}>
          <Averages showAverages={showAverages} setShowAverages={handleAveragesToggle} data-testid="averages-toggle" />
        </Flex>
        {!isMobile && !showAverages && !isScout && (
          <Legend direction="horizontal" fields={isScout ? getScoutCourtLegend('all') : getCourtLegend('all')} />
        )}
      </Flex>
    </Box>
  );
};

PlaysFilters.propTypes = {
  data: PropTypes.object,
  player: PropTypes.string,
  set: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['all'])]),
  volumeType: PropTypes.oneOf(['volume', 'effectiveness']),
  showAverages: PropTypes.bool,
  handleButtonClick: PropTypes.func,
  handleAveragesToggle: PropTypes.func,
  isScout: PropTypes.bool,
  playerType: PropTypes.oneOf(['player', 'opponents'])
};

export default PlaysFilters;
