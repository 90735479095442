import './fullScreenErrorAtptour.css';

export default function FullScreenErrorAtptour() {
  return (
    <div className="atptour-error-container">
      <div className="atptour-error-content">
        <div className="atptour-error-logo-container"></div>
        <h1>ATP TennisIQ</h1>
        <h2>We are sorry</h2>
        <h2>An error has occured</h2>
        <p>Please try refreshing the page.</p>
        <a href={`${window.location.protocol}//${window.location.hostname}`}>
          <button className="atptour-error-home-button">Home</button>
        </a>
      </div>
    </div>
  );
}
