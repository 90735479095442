import isEmpty from 'lodash/isEmpty';

export function checkOptionIsValid(formOption, fieldValue) {
  let result = false;
  if (formOption.type === 'select') {
    if (fieldValue === 'all' && !formOption.no_all_option) return true;
    formOption.options.forEach((item) => {
      if (item.value === fieldValue) result = true;
    });
  } else if (formOption.type === 'checkbox') {
    const acceptableValues = [true, false, 'true', 'false', 'yes', 'no'];
    return acceptableValues.includes(fieldValue);
  } else if (formOption.type === 'number') {
    if (Number.isInteger(fieldValue)) return true;
  }
  return result;
}

export function getFormFieldValue(formOption) {
  let value = null;
  if (formOption.type === 'select' || !formOption.type) {
    if (formOption.default) {
      value = formOption.default;
    } else {
      value = 'all';
    }
  } else if (formOption.type === 'checkbox') {
    value = formOption.default;
  } else {
    if (formOption.default) {
      value = formOption.default;
    } else {
      value = '';
    }
  }
  return value;
}

export function getFormValueFromUrlParam(formOption, value) {
  if (formOption.type === 'checkbox' && checkOptionIsValid(formOption, value)) {
    return value === 'true' || value === true;
  }
  if (!checkOptionIsValid(formOption, value)) {
    return getFormFieldValue(formOption);
  }
  return value;
}

export function getInitialFormValues(formOptions, urlParams) {
  let initialFormValues = {};
  formOptions.forEach((item) => {
    const urlParam = urlParams[item?.field_value];
    const initialValue = getFormValueFromUrlParam(item, urlParam);
    initialFormValues[item?.field_value] = initialValue;
  });
  return initialFormValues;
}

export function getUrlParamErrors(formOptions, urlParams) {
  let urlParamErrors = [];
  Object.keys(urlParams).forEach((fieldName) => {
    const formOptionArray = formOptions.filter((item) => item.field_value === fieldName);
    const urlParamValue = urlParams[`${fieldName}`];
    if (!isEmpty(formOptionArray)) {
      const formOption = formOptionArray[0];
      if (!checkOptionIsValid(formOption, urlParamValue)) {
        urlParamErrors.push({
          fieldName: formOption.field_name,
          incorrectValue: urlParamValue,
          defaultValue: getFormFieldValue(formOption)
        });
      }
    }
  });
  return urlParamErrors;
}

export const getInitialSearchFormValues = (formOptions, params) => {
  return formOptions.reduce((values, option) => {
    const { field_value, default: _default, options, no_all_option } = option;

    let defaultValue;
    if (_default) defaultValue = _default;
    else if (!no_all_option) defaultValue = 'all';
    else defaultValue = options?.[0]?.value;

    values[field_value] = params[field_value] ?? defaultValue;
    return values;
  }, {});
};
