import { courtGraphicTypes } from 'match/_shared/matchConstants';
import CourtContainer from 'match/components/court-graphics/court/CourtContainer';
import PageHeading from 'matchReport/components/_shared/PageHeading';
import SubHeading from 'matchReport/components/_shared/SubHeading';
import PropTypes from 'prop-types';
import PageLayout from 'scoutReport/components/PageLayout';

import { Box } from '_shared/designSystem/components';

export default function GroundstrokesCourtPage(props) {
  const {
    playerName,
    courtGraphicsDataRallyForehand,
    courtGraphicsDataRallyBackhand,
    surface,
    pageHeading,
    firstSubHeading,
    secondSubHeading
  } = props;
  return (
    <PageLayout playerName={playerName}>
      <PageHeading>{pageHeading}</PageHeading>
      <SubHeading>{firstSubHeading}</SubHeading>
      <Box w={1000} mb="75px">
        <CourtContainer
          graphicType={courtGraphicTypes.RALLY_PLACEMENT}
          shots={courtGraphicsDataRallyForehand?.shots}
          dataNoAverages={courtGraphicsDataRallyForehand?.averages?.placement}
          dataWithAverages={null}
          showAverages={false}
          averagesLoading={false}
          surface={surface}
          orientation="vertical"
        />
      </Box>
      <SubHeading>{secondSubHeading}</SubHeading>
      <Box w={1000}>
        <CourtContainer
          graphicType={courtGraphicTypes.RALLY_PLACEMENT}
          shots={courtGraphicsDataRallyBackhand?.shots}
          dataNoAverages={courtGraphicsDataRallyBackhand?.averages?.placement}
          dataWithAverages={null}
          showAverages={false}
          averagesLoading={false}
          surface={surface}
          orientation="vertical"
        />
      </Box>
    </PageLayout>
  );
}

GroundstrokesCourtPage.propTypes = {
  playerName: PropTypes.string,
  surface: PropTypes.string,
  courtGraphicsDataRallyForehand: PropTypes.object,
  courtGraphicsDataRallyBackhand: PropTypes.object
};
