import {
  VIEW_OPTION_VALUES,
  HEAD_TO_HEAD_MATCHES_AVERAGES_TOOLTIP_NO_TRACKING_DATA
} from 'headToHead/constants/headToHeadConstants';
import PropTypes from 'prop-types';

import InfoIconWithTooltip from '_shared/components/InfoIconWithTooltip';
import { Box, Center, Flex, Legend, Text } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import { getTourAverageLabel } from '_shared/utils/metricsUtil';

const FilterButtonGroup = ({
  trackedMatchesCount,
  player1Name,
  player2Name,
  player1Matches,
  player2Matches,
  headToHeadMatchesCount,
  setView,
  view,
  showAverages
}) => {
  const legendFields = [{ label: getTourAverageLabel(), color: 'tourAverage' }];
  const legendFieldsWithPlayerAverage = [
    { label: getTourAverageLabel(), color: 'tourAverage' },
    {
      label: 'Player Avg',
      color: 'playerAverage'
    }
  ];
  const legendFieldsToShow =
    view === VIEW_OPTION_VALUES.FIFTY_TWO_WEEK_AVERAGES ? legendFields : legendFieldsWithPlayerAverage;
  const headToHeadMatchAveragesTooltip = `The head-to-head match averages are based on ${trackedMatchesCount} tracked ${
    trackedMatchesCount > 1 ? 'matches' : 'match'
  }. See the player cards below to see which are included`;

  const headToHeadDisabled = trackedMatchesCount === 0;

  const TooltipStatsBox = () => (
    <Box>
      <Text mb={3}>The 52-week averages are based on the following number of tracked matches:</Text>
      <Text>{`${player1Name} = ${player1Matches}`}</Text>
      <Text>{`${player2Name} = ${player2Matches}`}</Text>
      <Text mt={3}>See the Match Selector in the Scout Area to see which matches are included</Text>
    </Box>
  );

  const TooltipStatsBoxWimb = () => (
    <Box>
      <Text mb={3}>
        The Championship Averages are based on matches from the current championship. Click on the players' images for a
        deep dive into the player in the Scout Area
      </Text>
    </Box>
  );

  const buttonFontSize = { base: 'xs', sm: 'sm', md: 'lg' };

  if (!player1Name) return null;

  return (
    <>
      <Flex w="100%" p="1px">
        <Center
          borderLeftRadius="xl"
          w="50%"
          h="50px"
          justify="center"
          bg={view === VIEW_OPTION_VALUES.FIFTY_TWO_WEEK_AVERAGES ? 'primary.500' : 'secondary.800'}
          onClick={() => setView(VIEW_OPTION_VALUES.FIFTY_TWO_WEEK_AVERAGES)}
          cursor="pointer"
        >
          <Text fontSize={buttonFontSize} fontWeight="semibold" mr={2} color="white" justify="center" align="center">
            {isWimbPortal() ? 'Championships Average' : '52-week Averages'}
          </Text>
          <InfoIconWithTooltip
            tooltipText={isWimbPortal() ? <TooltipStatsBoxWimb /> : <TooltipStatsBox />}
            color="white"
          />
        </Center>
        <Center
          borderRightRadius="xl"
          w="50%"
          h="50px"
          justify="center"
          bg={
            headToHeadDisabled
              ? 'grey.300'
              : view === VIEW_OPTION_VALUES.HEAD_TO_HEAD_MATCHES_AVERAGES
              ? 'primary.500'
              : 'secondary.800'
          }
          onClick={headToHeadDisabled ? null : () => setView(VIEW_OPTION_VALUES.HEAD_TO_HEAD_MATCHES_AVERAGES)}
          cursor={headToHeadDisabled ? 'not-allowed' : 'pointer'}
        >
          <Text
            fontSize={buttonFontSize}
            fontWeight="semibold"
            mr={2}
            color={headToHeadDisabled ? 'grey.400' : 'white'}
          >
            Head-to-head Averages
          </Text>
          {headToHeadMatchesCount > 0 && (
            <Box data-testid="headToHeadTooltip">
              <InfoIconWithTooltip
                tooltipText={
                  headToHeadDisabled
                    ? HEAD_TO_HEAD_MATCHES_AVERAGES_TOOLTIP_NO_TRACKING_DATA
                    : headToHeadMatchAveragesTooltip
                }
                color={headToHeadDisabled ? 'grey.400' : 'white'}
              />
            </Box>
          )}
        </Center>
      </Flex>
      {showAverages && (
        <Flex mt={5} gap={2} justify="center" h="16px">
          <Legend fields={legendFieldsToShow} />
        </Flex>
      )}
    </>
  );
};

FilterButtonGroup.propTypes = {
  trackedMatchesCount: PropTypes.number,
  player1Name: PropTypes.string,
  player2Name: PropTypes.string,
  player1Matches: PropTypes.number,
  player2Matches: PropTypes.number,
  headToHeadMatchesCount: PropTypes.number,
  setView: PropTypes.func,
  view: PropTypes.string,
  showAverages: PropTypes.bool
};

export default FilterButtonGroup;
