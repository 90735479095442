import { useQuery } from 'react-query';

import { apiClient } from '_shared/dataFetching/apiConfig';
import { getSiteParam } from '_shared/dataFetching/urlConfig';
// axios functions
export const headToHeadService = {
  async getMatchup({ player1, player2 }) {
    const url = `head_to_head/matchup?player1=${player1}&player2=${player2}&${getSiteParam()}`;

    const res = await apiClient.get(url);

    const data = res.data;
    return data;
  },

  async getInsights({ player1, player2 }) {
    const nonAveragesUrl = `head_to_head/insights?player1=${player1}&player2=${player2}&${getSiteParam()}&averages=no`;

    const res = await apiClient.get(nonAveragesUrl);

    return res.data;
  },

  async getInsightsAverages({ player1, player2 }) {
    const averagesUrl = `head_to_head/insights?player1=${player1}&player2=${player2}&${getSiteParam()}&averages=yes`;

    const res = await apiClient.get(averagesUrl);

    return res.data;
  },

  async getStats({ player1, player2 }) {
    const nonAveragesUrl = `head_to_head/match_stats?player1=${player1}&player2=${player2}&${getSiteParam()}&averages=no`;

    const res = await apiClient.get(nonAveragesUrl);

    return res.data;
  },

  async getStatsAverages({ player1, player2 }) {
    const averagesUrl = `head_to_head/match_stats?player1=${player1}&player2=${player2}&${getSiteParam()}&averages=yes`;

    const res = await apiClient.get(averagesUrl);

    return res.data;
  },

  async getHeadToHeadMatches({ matchIds }) {
    const url = `/match/multiple?&${getSiteParam()}&match_ids=${matchIds}`;

    const res = await apiClient.get(url);

    return res.data;
  }
};

// react-query hooks
export const useGetHeadToHeadData = ({ player1, player2 }) => {
  return useQuery(
    ['headToHeadService_get_head_to_head_data'],
    () => headToHeadService.getMatchup({ player1, player2 }),
    {
      enabled: player1 > 0 && player2 > 0,
      staleTime: Infinity
    }
  );
};

export const useGetHeadToHeadInsights = ({ player1, player2 }) => {
  return useQuery(
    ['headToHeadService_get_head_to_head_insights_data'],
    () => headToHeadService.getInsights({ player1, player2 }),
    {
      enabled: player1 > 0 && player2 > 0,
      staleTime: Infinity
    }
  );
};

export const useGetHeadToHeadInsightsAverages = ({ player1, player2 }) => {
  return useQuery(
    ['headToHeadService_get_head_to_head_insights_data_averages'],
    () => headToHeadService.getInsightsAverages({ player1, player2 }),
    {
      enabled: player1 > 0 && player2 > 0,
      staleTime: Infinity
    }
  );
};

export const useGetHeadToHeadStats = ({ player1, player2 }) => {
  return useQuery(
    ['headToHeadService_get_head_to_head_stats_data'],
    () => headToHeadService.getStats({ player1, player2 }),
    {
      enabled: player1 > 0 && player2 > 0,
      staleTime: Infinity
    }
  );
};

export const useGetHeadToHeadStatsAverages = ({ player1, player2 }) => {
  return useQuery(
    ['headToHeadService_get_head_to_head_stats_data_averages'],
    () => headToHeadService.getStatsAverages({ player1, player2 }),
    {
      enabled: player1 > 0 && player2 > 0,
      staleTime: Infinity
    }
  );
};

export const useGetTrackedMatches = ({ matchIds }) => {
  return useQuery(
    ['headToHeadService_get_tracked_matches', matchIds],
    () => headToHeadService.getHeadToHeadMatches({ matchIds }),
    {
      enabled: matchIds?.length > 0,
      staleTime: Infinity
    }
  );
};
