import { convertScoreToPercentage, displayScore, zeroOrMore } from 'match/utils/transformations';
import PropTypes from 'prop-types';

import { metricScoreType } from '_shared/constants/metricTypes';
import { Box, Flex, Text } from '_shared/designSystem/components';

export const StatisticsBar = ({
  data,
  player,
  position,
  averageType,
  showAverages,
  opacity,
  overrideBarColor,
  hasLargerText
}) => {
  let barColor;

  switch (averageType) {
    case 'player_average':
      barColor = 'playerAverage';
      break;
    case 'tour_average':
      barColor = 'tourAverage';
      break;
    default:
      barColor = 'primary.500';
  }

  return (
    <Flex w="49.5%" gap={3} minH="14px">
      {position === 'left' && (
        <StatisticsLabel
          data={data}
          player={player}
          position={position}
          averageType={averageType}
          showAverages={showAverages}
          hasLargerText={hasLargerText}
        />
      )}
      <Flex
        justify={position === 'left' ? 'flex-end' : 'flex-start'}
        height={averageType === 'score' || overrideBarColor ? '8px' : '5px'}
        width="100%"
        bg="grey.100"
        borderLeftRadius={position === 'left' ? 4 : null}
        borderRightRadius={position === 'left' ? null : 4}
      >
        <Box
          height={averageType === 'score' || overrideBarColor ? '8px' : '5px'}
          width={`${getPercentage(data, `${player}`, averageType)}%`}
          bg={overrideBarColor ?? barColor}
          opacity={opacity ?? ''}
          borderLeftRadius={position === 'left' ? 4 : null}
          borderRightRadius={position === 'left' ? null : 4}
        />
      </Flex>
      {position === 'right' && (
        <StatisticsLabel
          data={data}
          player={player}
          position={position}
          averageType={averageType}
          showAverages={showAverages}
          hasLargerText={hasLargerText}
        />
      )}
    </Flex>
  );
};

StatisticsBar.propTypes = {
  data: PropTypes.object.isRequired,
  player: PropTypes.string,
  position: PropTypes.string,
  averageType: PropTypes.string,
  showAverages: PropTypes.bool
};

const StatisticsLabel = ({ data, player, position, averageType, showAverages, hasLargerText }) => (
  <Flex direction="column" w="119px">
    <Text
      textAlign={position === 'left' ? 'right' : 'left'}
      fontSize={hasLargerText ? { base: 'sm', md: 'md' } : 'xs'}
      lineHeight="12px"
      fontWeight="normal"
      color="grey.700"
    >
      {displayScore(data?.[`${player}`]?.[`${averageType}`], data?.[`${player}`]?.score_type)}
    </Text>
    {zeroOrMore(data?.[`${player}`]?.frequency) && averageType === 'score' && !showAverages && (
      <Text textAlign={position === 'left' ? 'right' : 'left'} fontSize="xs" fontWeight="normal" color="grey.500">
        ({data?.[`${player}`].frequency}/{data?.[`${player}`].frequency_total})
      </Text>
    )}
  </Flex>
);

export function getPercentage(data, player, averageType) {
  const scoreValue = data?.[`${player}`]?.[`${averageType}`];
  const scoreType = data?.[`${player}`]?.score_type;

  // handle nulls and zeros
  if (!zeroOrMore(scoreValue)) return null;
  if (scoreValue === 0) return 0;

  // for when we have fixed max/min scores to know how long to make the bar
  if (zeroOrMore(data?.[`${player}`]?.bar_max_value) && zeroOrMore(data?.[`${player}`]?.bar_min_value)) {
    return getPercentageFromBarMaxMin(scoreValue, data?.[`${player}`].bar_min_value, data?.[`${player}`].bar_max_value);
  }

  switch (scoreType) {
    // for percentage, just return the value
    case metricScoreType.PERCENTAGE:
      return scoreValue;

    // for out of ten score, convert to percentage
    case metricScoreType.OUT_OF_TEN:
      return convertScoreToPercentage(scoreValue, scoreType);

    case metricScoreType.DECIMAL_TWO_PLACES:
      return convertScoreToPercentage(scoreValue, scoreType);

    // for a count, show percentage from total of both players
    case metricScoreType.COUNT:
      const total = data?.player1?.[`${averageType}`] + data?.player2?.[`${averageType}`];
      return (scoreValue / total) * 100;

    default:
      return null;
  }
}

export function getPercentageFromBarMaxMin(value, min, max) {
  return ((value - min) / (max - min)) * 100;
}
