import isEmpty from 'lodash/isEmpty';
import { matchService } from 'match/services/matchService';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export function useHeaderQuery(matchId, fetchParams) {
  return useQuery(['matchService_header', matchId], () => matchService.getMatchInformation({ matchId }), {
    ...fetchParams,
    refetchOnWindowFocus: true
  });
}

export function usePerformanceWithAveragesQuery(matchId, currentSetFilter, currentScoreRangeFilter, fetchParams) {
  return useQuery(
    ['matchService_performanceWithAverages', matchId, currentSetFilter, currentScoreRangeFilter],
    () => matchService.getPerformance({ matchId, currentSetFilter, currentScoreRangeFilter, averages: true }),
    { ...fetchParams }
  );
}

export function usePerformanceNoAveragesQuery(matchId, currentSetFilter, currentScoreRangeFilter, fetchParams) {
  return useQuery(
    ['matchService_performanceNoAverages', matchId, currentSetFilter, currentScoreRangeFilter],
    () => matchService.getPerformance({ matchId, currentSetFilter, currentScoreRangeFilter, averages: false }),
    { ...fetchParams, refetchOnWindowFocus: true }
  );
}

export function usePerformanceForMatch(matchId) {
  return useQuery(['matchService_performance_for_match', matchId], () => matchService.getPerformance({ matchId }), {
    staleTime: Infinity
  });
}

export function usePerformanceRatingGame(matchId, fetchParams) {
  return useQuery(
    ['matchService_usePerformanceRatingGame', matchId],
    () => matchService.getPerformanceRatingGame({ matchId }),
    { ...fetchParams, refetchOnWindowFocus: true }
  );
}

export function usePerformanceRatingPoint(matchId, fetchParams) {
  return useQuery(
    ['matchService_usePerformanceRatingPoint', matchId],
    () => matchService.getPerformanceRatingPoint({ matchId }),
    { ...fetchParams }
  );
}

export function useCourtGraphicsQuery(matchId, queryParams, fetchParams) {
  return useQuery(
    ['matchService_court-graphics', matchId, queryParams],
    () => matchService.getCourtGraphics({ matchId, queryParams }),
    { ...fetchParams, refetchOnWindowFocus: true }
  );
}

export function useCourtGraphicsAveragesQuery(matchId, queryParams, fetchParams) {
  return useQuery(
    ['matchService_court-graphics-averages', matchId, queryParams],
    () => matchService.getCourtGraphicsAverages({ matchId, queryParams }),
    { ...fetchParams }
  );
}

export function useWinningAndLosingPlaysQuery(matchId, queryParams, fetchParams) {
  return useQuery(
    ['matchService_winning-and-losing-plays', matchId, queryParams],
    () => matchService.getWinningAndLosingPlays({ matchId, queryParams }),
    { ...fetchParams }
  );
}

export function useWinningAndLosingPlaysV2NoAveragesQuery(matchId, queryParams, fetchParams) {
  return useQuery(
    ['matchService_winning-and-losing-plays-v2', matchId, queryParams],
    () => matchService.getWinningAndLosingPlaysV2({ matchId, queryParams, averages: false }),
    { ...fetchParams }
  );
}

export function useWinningAndLosingPlaysV2WithAveragesQuery(matchId, queryParams, fetchParams) {
  return useQuery(
    ['matchService_winning-and-losing-plays-v2-with-averages', matchId, queryParams],
    () => matchService.getWinningAndLosingPlaysV2({ matchId, queryParams, averages: true }),
    { ...fetchParams }
  );
}

export function usePatternsOfPlayNoAveragesQuery(matchId, queryParams) {
  return useQuery(
    ['matchService_patterns-of-play-no-averages', matchId, queryParams],
    () => matchService.getPatternsOfPlay({ matchId, queryParams, averages: false }),
    { enabled: !isEmpty(queryParams) }
  );
}

export function usePatternsOfPlayWithAveragesQuery(matchId, queryParams) {
  return useQuery(
    ['matchService_patterns-of-play-with-averages', matchId, queryParams],
    () => matchService.getPatternsOfPlay({ matchId, queryParams, averages: true }),
    { enabled: !isEmpty(queryParams) }
  );
}

export const useDataQualityFlagQuery = (matchId) => {
  return useQuery(['matchService_data-quality-flag', matchId], () => matchService.getDataQualityFlag({ matchId }));
};

export const useUpdateDataQualityFlag = (matchId, dataQualityFlag) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(() => matchService.updateDataQualityFlag({ matchId, dataQualityFlag }));

  const toggleDataQualityFlag = () => {
    mutate(
      { matchId, dataQualityFlag },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['matchService_data-quality-flag', matchId]);
        },
        onError: (error) => {
          console.error(error);
        }
      }
    );
  };

  return {
    toggleDataQualityFlag
  };
};

export const useGetCsvReportFiles = (matchId, formValues) => {
  return useQuery(['matchService_csv-report-files', matchId], () =>
    matchService.getCsvReportFiles({ matchId, formValues })
  );
};
