//  PLEASE USE TV_PORTAL_ASSETS_PATH as we are moving away from ASSETS_PATH
export const ASSETS_PATH = 'https://s3.eu-central-1.amazonaws.com/portal.tennisviz.com/assets/';
export const TV_PORTAL_ASSETS_PATH = 'https://assets.portal.tennisviz.com/';

export const IMAGE_LOGO_USTA = `${ASSETS_PATH}usta-logo.png`;
export const IMAGE_LOGO_LTA = `${ASSETS_PATH}lta-logo.png`;

export const TV_LOGO_LANDSCAPE_PURPLE = `${TV_PORTAL_ASSETS_PATH}tvportal/logos/tv-logo-landscape-purple.svg`;
export const TV_LOGO_LANDSCAPE_WHITE = `${TV_PORTAL_ASSETS_PATH}tvportal/logos/tv-logo-landscape-white.svg`;
export const TV_LOGO_ICON_GREEN_WHITE = `${TV_PORTAL_ASSETS_PATH}tvportal/logos/tv-logo-icon-green-white.svg`;

export const TIER_MAP_IMAGE = {
  ATP_MASTERS_1000: 'ATP Masters 1000',
  ATP_500: 'ATP 500',
  ATP_250: 'ATP 250',
  GRAND_SLAM: 'Grand Slam'
};

export const HERO_IMAGE_PLACEHOLDER_DIRECTION = { LEFT: 'left', RIGHT: 'right' };

export const IMAGE_TV_PORTAL = `${TV_PORTAL_ASSETS_PATH}tvportal/images/login_page.jpeg`;
export const IMAGE_ATP_PORTALS = `${TV_PORTAL_ASSETS_PATH}atptour/images/atptourtrophy.jpeg`;
