import CircularMetricsPanel from 'match/components/performance/circularMetrics/CircularMetricsPanel';
import { matchStatsOrder } from 'match/components/performance/matchStatistics/matchStatsOrder';
import StatisticsRow from 'match/components/performance/matchStatistics/StatisticsRow';
import { convertLabelName, displayScore } from 'match/utils/transformations';
import PageHeading from 'matchReport/components/_shared/PageHeading';

import { Box, Flex, Text } from '_shared/designSystem/components';
import { getTourAverageLabel } from '_shared/utils/metricsUtil';

import { Definition } from './_shared/Definition';

const ServeReturn = ({ headerData, performanceData, type, player1Name, player2Name }) => {
  const isServe = type === 'serve';

  const serveDefinition = `Serve quality shows the aggregated score of all the serves (1st & 2nd combined) played in this match. Serve quality is calculated by analysing each serve's speed, spin, depth, width, and the impact it has on the opponent`;
  const returnDefinition = `Return quality shows the aggregated score of all the returns (1st & 2nd combined) played in this match. Return quality is calculated by analysing each return's speed, spin, depth, width, and the impact it has on the opponent`;
  const serviceGamesDefinition =
    'Serve game performance shows the aggregated score of all shots played in the service games for this match';
  const returnGamesDefinition =
    'Return game performance shows the aggregated score of all shots played in the return games for this match';

  const showServeAndReturnGamePerformance = false; // make true when these figures are aligned with shot quality

  return (
    <Box>
      <PageHeading>{convertLabelName(type)} Information</PageHeading>
      <Box h="50px" w={1}></Box>
      <Box mb={20}>
        <Flex>
          <CircularMetricsPanel
            heading={isServe ? 'Serve Quality' : 'Return Quality'}
            player1Name={player1Name}
            player2Name={player2Name}
            data={performanceData?.performance?.comparison_metrics?.shot_quality}
            type="light"
            tooltipText={''}
            showAverages={false}
            orderOfMetrics={[type]}
          />
          <Box p={5} w={400}>
            <PlayerAverages
              definition={isServe ? serveDefinition : returnDefinition}
              performanceData={performanceData}
              headerData={headerData}
              metricType="shot_quality"
              type={type}
              player1Name={player1Name}
              player2Name={player2Name}
            />
          </Box>
        </Flex>
        {showServeAndReturnGamePerformance && (
          <Box>
            <Flex>
              <CircularMetricsPanel
                heading={isServe ? 'Serve Game Performance' : 'Return Game Performance'}
                player1Name={player1Name}
                player2Name={player2Name}
                data={performanceData?.performance?.comparison_metrics?.performance_in_games}
                type="dark"
                tooltipText={''}
                showAverages={false}
                orderOfMetrics={isServe ? ['service_games'] : ['return_games']}
              />
              <Box p={5} w={400}>
                <PlayerAverages
                  definition={isServe ? serviceGamesDefinition : returnGamesDefinition}
                  performanceData={performanceData}
                  headerData={headerData}
                  metricType="performance_in_games"
                  type={isServe ? 'service_games' : 'return_games'}
                />
              </Box>
            </Flex>
          </Box>
        )}
      </Box>
      <Flex justify="center">
        <Box w="493px">
          <MatchStats
            performanceData={performanceData}
            type={type}
            player1Name={player1Name}
            player2Name={player2Name}
          />
        </Box>
      </Flex>
    </Box>
  );
};

const PlayerAverages = ({ performanceData, definition, type, metricType, player1Name, player2Name }) => (
  <Box>
    <Definition definition={definition} width={400} />
    <table variant="unstyled" size="sm">
      <tbody>
        <tr>
          <td>
            <Text fontWeight="semibold">{getTourAverageLabel()}</Text>
          </td>
          <td>
            <Text px={5}>
              {performanceData?.performance?.comparison_metrics?.[`${metricType}`]?.[`${type}`]?.player1?.tour_average}
            </Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text fontWeight="semibold">{player1Name} Avg</Text>
          </td>
          <td>
            <Text px={5}>
              {displayScore(
                performanceData?.performance?.comparison_metrics?.[`${metricType}`]?.[`${type}`]?.player1
                  ?.player_average,
                performanceData?.performance?.comparison_metrics?.[`${metricType}`]?.[`${type}`]?.player1?.score_type
              )}
            </Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text fontWeight="semibold">{player2Name} Avg</Text>
          </td>
          <td>
            <Text px={5}>
              {displayScore(
                performanceData?.performance?.comparison_metrics?.[`${metricType}`]?.[`${type}`]?.player2
                  ?.player_average,
                performanceData?.performance?.comparison_metrics?.[`${metricType}`]?.[`${type}`]?.player2?.score_type
              )}
            </Text>
          </td>
        </tr>
      </tbody>
    </table>
  </Box>
);

const MatchStats = ({ performanceData, type, player1Name, player2Name }) => {
  return (
    <Box>
      <Flex justify="space-between" w="100%">
        <Text fontSize="sm" fontWeight="semibold">
          {player1Name}
        </Text>
        <Text fontSize="sm" fontWeight="semibold">
          {player2Name}
        </Text>
      </Flex>
      {matchStatsOrder?.[`${type}`].map((statType) => {
        return performanceData?.performance?.match_statistics?.[`${type}`]?.[statType] ? (
          <StatisticsRow
            data={performanceData?.performance?.match_statistics?.[`${type}`]?.[statType]}
            label={convertLabelName(statType)}
            key={statType}
            showAverages={false}
          />
        ) : null;
      })}
    </Box>
  );
};

export default ServeReturn;
