export const resultName = {
  WON: 'won',
  LOST: 'lost',
  WALKOVER: 'walkover',
  RETIRED: 'retired',
  DEFAULTED: 'defaulted',
  ABANDONED: 'abandoned',
  CANCELLED: 'cancelled'
};

export const matchStatus = {
  FORTHCOMING: 'forthcoming',
  LIVE: 'live',
  COMPLETE: 'complete',
  SUSPENDED: 'suspended'
};

export const tournamentStatus = {
  FORTHCOMING: 'forthcoming',
  CURRENT: 'current',
  COMPLETE: 'complete'
};

export const surfaceTypes = {
  CLAY: 'clay',
  GRASS: 'grass',
  INDOOR_HARD: 'indoor_hard',
  INDOOR_HARD_WITH_SPACE: 'indoor hard',
  OUTDOOR_HARD: 'outdoor_hard',
  OUTDOOR_HARD_WITH_SPACE: 'outdoor hard'
};

export const drawTypes = {
  MENS_SINGLES: 'MS',
  MENS_DOUBLES: 'MD',
  MENS_QUALIFYING_SINGLES: 'QS',
  MENS_QUALIFYING_DOUBLES: 'MD',
  LADIES_SINGLES: 'LS',
  LADIES_DOUBLES: 'LD',
  LADIES_QUALIFYING_SINGLES: 'RS',
  LADIES_QUALIFYING_DOUBLES: 'RD'
};

export const dataCompletenessStatus = {
  INCOMPLETE: 'incomplete',
  COMPLETE: 'complete'
};
