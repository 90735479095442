import { useFormik } from 'formik';

export const useHeadToHeadForm = () => {
  const initialValues = {
    player1: 0,
    player2: 0,
    showAverages: false
  };

  const formik = useFormik({
    initialValues
  });

  return {
    formik
  };
};
