import './fullScreenErrorWimb.css';

export default function FullScreenErrorWimb() {
  return (
    <div className="wimb-error-container">
      <div className="wimb-error-content">
        <div className="wimb-error-logo-container">
          <div className="wimb-error-logo">
            <img
              alt="logo"
              className="wimb-error-img"
              width="100px"
              height="100px"
              src="https://www.wimbledon.com/assets/images/logos/Wimbledon_logo@2x.png"
            />
          </div>
        </div>
        <h2>We are sorry</h2>
        <h1>An error has occured</h1>
        <p>Please try refreshing the page.</p>
        <a href={`${window.location.protocol}//${window.location.hostname}`}>
          <button className="wimb-error-home-button">Home</button>
        </a>
      </div>
    </div>
  );
}
