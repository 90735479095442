import { useMemo } from 'react';

import BalanceOfPowerWithAverages from 'match/components/performance/battles/BalanceOfPowerWithAverages';
import { BaselineBattles } from 'match/components/performance/battles/BaselineBattles';
import CircularMetricsPanel from 'match/components/performance/circularMetrics/CircularMetricsPanel';
import PerformanceGraph from 'match/components/performance/performanceGraph/PerformanceGraph';
import { usePerformanceRatingGame } from 'match/hooks/matchHooks';
import { filterBySet } from 'match/utils/performanceUtil';
import PageHeading from 'matchReport/components/_shared/PageHeading';
import { useMatchReportPerformanceQuery, useMatchReportSetsQuery } from 'matchReport/hooks/matchReportHooks';

import { Box, Flex } from '_shared/designSystem/components';

const MatchSummary = ({ headerData, performanceData, matchId }) => {
  const { performance, dataWithAverages, averagesLoading, showAverages } = performanceData || {};
  const { player1, player2 } = headerData?.player_details || {};
  const player1Name = player1?.member1?.last_name;
  const player2Name = player2?.member1?.last_name;

  const { data: graphData, error: graphError, isLoading: graphLoading } = useMatchReportPerformanceQuery(matchId);

  const { data: performanceRatingData, isLoading: performanceLoading } = useMatchReportSetsQuery(matchId, 'all', null);

  const { data: performanceRatingGameData, isLoading: performanceRatingGameDataLoading } = usePerformanceRatingGame(
    matchId,
    null,
    false
  );

  const filteredPerformanceRatingGameBySet = useMemo(
    () => filterBySet(performanceRatingGameData, 'all'),
    [performanceRatingGameData]
  );

  if (graphError) throw new Error('Unable to retrieve graph data');

  if (graphLoading || performanceLoading || performanceRatingGameDataLoading) return null;

  if (graphData && performanceRatingData && performanceRatingGameData) {
    return (
      <Box>
        <PageHeading>Match Summary</PageHeading>
        <Flex gap="70px">
          <Box pt={5}>
            <Flex direction="column" gap={20} align="center">
              <Box w="600px" p={3}>
                <PerformanceGraph
                  players={headerData?.player_details}
                  performanceRatingGame={filteredPerformanceRatingGameBySet}
                  gamesLoading={graphLoading}
                  performanceRatingAverages={performanceData?.performance?.comparison_metrics?.performance_rating}
                />
              </Box>
              <Box w="450px">
                <BalanceOfPowerWithAverages
                  data={performance?.battles}
                  showAverages={false}
                  player1Name={player1Name}
                  player2Name={player2Name}
                />
                <Box mt={10}>
                  <BaselineBattles
                    data={performance?.battles}
                    dataWithAverages={dataWithAverages?.performance?.battles}
                    averagesLoading={averagesLoading}
                    showAverages={showAverages}
                    player1Name={player1Name}
                    player2Name={player2Name}
                  />
                </Box>
              </Box>
            </Flex>
          </Box>
          <Box>
            <Box mb={6}>
              <CircularMetricsPanel
                heading="Performance Rating"
                tooltipText={null}
                player1Name={player1Name}
                player2Name={player2Name}
                data={performanceRatingData?.performance?.comparison_metrics}
                dataWithAverages={dataWithAverages?.performance?.comparison_metrics}
                type="medium"
                showAverages={showAverages}
                averagesLoading={averagesLoading}
                orderOfMetrics={['performance_rating']}
                noTopLabel
                AISelected={false}
                isAISelected={null}
              />
            </Box>
            <CircularMetricsPanel
              heading="Attack and Defence"
              tooltipText="How often a player has won the point when attacking (Conversion) or defending (Steal)"
              player1Name={player1Name}
              player2Name={player2Name}
              data={performance?.comparison_metrics?.capitalisation}
              type="medium"
              showAverages={false}
              orderOfMetrics={['conversion', 'steal']}
            />
            <Box mt={10}>
              <CircularMetricsPanel
                heading="Shot Quality"
                tooltipText="The quality of the player's four main shots on a 0-10 scale"
                player1Name={player1Name}
                player2Name={player2Name}
                data={performance?.comparison_metrics?.shot_quality}
                type="light"
                showAverages={false}
                orderOfMetrics={['serve', 'return', 'forehand', 'backhand']}
              />
            </Box>
          </Box>
        </Flex>
      </Box>
    );
  }
  return null;
};

export default MatchSummary;
