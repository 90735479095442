import { axisLeft } from 'd3';

import colors from '_shared/designSystem/theme/colors';

export function drawYAxis(svg, width, yMargin, marginLeftInner, yScale) {
  // y-axis formatting
  svg
    .append('g') // add this as a group
    .attr('class', 'y-axis')
    .attr('transform', `translate(${yMargin}, 0)`)
    .call(
      axisLeft(yScale)
        .ticks(5) // how many tick lines we want, which is 5 because we are showing from 5 to 10
        .tickSizeInner(-width + marginLeftInner) // size of the horizontal lines for each tick
    );

  // vertical line
  svg.select('.y-axis').select('.domain').style('display', 'none'); // we dont want a vertical axis line

  // horizontal grid lines
  svg.select('.y-axis').selectAll('.tick line').attr('stroke', colors.grey[200]); // colouring the the horizontal lines for each tick

  // y-axis labels
  svg
    .select('.y-axis')
    .selectAll('.tick text')
    .attr('font-size', '12')
    .attr('fill', colors.grey[500])
    .attr('transform', `translate(-6, 0)`);
}
