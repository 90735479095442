import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { Badge, Box, Card, Flex, Image, Text } from '_shared/designSystem/components';
import { formatDateNoYear } from '_shared/utils/dateUtil';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import { getTournamentTierImage } from '_shared/utils/imageUtil';
import { capitaliseString } from '_shared/utils/stringUtil';

export default function TournamentCard({ data, onClick }) {
  if (isEmpty(data)) return null;

  const { match_live, comp_status, name, tier, start_date, end_date, surface } = data;

  return (
    <Card onClick={onClick} status={comp_status}>
      <Flex direction="column" gap={3}>
        <Flex justify="space-between" h="40px">
          <Flex align="center">
            <Text fontSize={isWimbPortal() ? 'md' : 'xl'} fontWeight="semibold">
              {data.name}
            </Text>
          </Flex>
          <Flex gap={2} align="center">
            {!!match_live && (
              <Box h="30px">
                <Badge
                  text="Live"
                  bgColour="secondary.50"
                  iconColour="secondary.800"
                  textColour="secondary.800"
                  withLeftCircle
                />
              </Box>
            )}
            <TierImage tier={tier} tournamentName={name} />
          </Flex>
        </Flex>
        <Flex justify="space-between">
          <Text>{`${formatDateNoYear(start_date)} - ${formatDateNoYear(end_date)}`}</Text>
          {!isWimbPortal() && <Text fontSize="sm">{capitaliseString(surface)}</Text>}
        </Flex>
      </Flex>
    </Card>
  );
}

TournamentCard.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func
};

export const TierImage = ({ tier, tournamentName }) => {
  const tierUrl = getTournamentTierImage(tier, tournamentName, isWimbPortal());
  return tierUrl ? <Image src={tierUrl} h="40px" /> : <Text>{tier}</Text>;
};
