import { apiClient } from '_shared/dataFetching/apiConfig';
import { getSiteParam } from '_shared/dataFetching/urlConfig';

export const tournamentsService = {
  async getTournaments() {
    const url = `tournaments?${getSiteParam()}&unique_param=${Math.floor(100000 + Math.random() * 900000)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getTournament({ tournamentId }) {
    const url = `tournament/${tournamentId}?${getSiteParam()}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  }
};
