import { useEffect, useState } from 'react';

import { useNavigate, useSearch } from '@tanstack/react-location';
import { authenticationService } from 'authentication/services/authenticationService';
import { useMutation } from 'react-query';
import { useSetRecoilState } from 'recoil';

import { Alert, Text, Flex, Image, Button, Box } from '_shared/designSystem/components';
import { userPermissionsState } from '_shared/globalState/atoms';
import { removeLocalStorageAuthTokens } from '_shared/utils/localStorage';

import { WIMB_REDIRECT_URL } from '../constants/wimbAuthConsts';
import { getMyWRedirectPath, removeMyWRedirectPath, setMyWAuthTokens } from '../services/wimbLocalStorage';
import { wimbService } from '../services/wimbService';
import { wimbledonImageUrls } from '../util/imageUtil';

export default function OidcResponse() {
  const queryParams = useSearch();
  const [displayError, setDisplayError] = useState(null);
  const navigate = useNavigate();
  const setUserPermissionsState = useSetRecoilState(userPermissionsState);

  const errorMessage =
    'Sorry, we have encountered an error logging you in, please try again. In the problem persists, please contact support.';

  const { mutate: permissionsMutate } = useMutation(() => authenticationService.getUserPermissions(), {
    onSuccess: (data) => {
      setUserPermissionsState(data);
    }
  });

  const { mutate: validateAuthorisationCodeMutate } = useMutation(
    (code) => wimbService.validateAuthorisationCode({ authorisationCode: code }),
    {
      onSuccess: (data) => {
        if (data?.e_access_token && data?.w_refresh_token) {
          setMyWAuthTokens(data?.e_access_token, data?.w_refresh_token);
          const redirectPath = getMyWRedirectPath();
          permissionsMutate();
          if (redirectPath) {
            navigate({ to: redirectPath });
            removeMyWRedirectPath();
          } else navigate({ to: '/' });
        } else {
          setDisplayError(errorMessage);
        }
      },
      onError: (error) => {
        setDisplayError(errorMessage);
      }
    }
  );

  useEffect(() => {
    if (!queryParams.code) {
      // we haven't arrived from the myWimbledon redirection, so exit
      removeLocalStorageAuthTokens();
      navigate({ to: '/login' });
    } else {
      const code = queryParams.code;
      validateAuthorisationCodeMutate(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get code from
  return displayError ? (
    <Flex justify="center" mt="100px">
      <Flex direction="column" align="center" gap={6}>
        <Image src={wimbledonImageUrls.LOGO} w="100px" h="100px" />
        <Text fontSize="2xl" textAlign="center" color="grey.500">
          Performance Analytics
        </Text>
        <Box px={4}>
          <Alert status="error" message={displayError} />
        </Box>
        <Button mt={5} onClick={() => window.location.replace(WIMB_REDIRECT_URL)}>
          Retry
        </Button>
      </Flex>
    </Flex>
  ) : null;
}
