import isEmpty from 'lodash/isEmpty';
import { isContactPoint, isReturnOrRallyPlacement, isServeDirectionOrAccuracy } from 'match/utils/courtGraphicsUtil';
import { BeatLoader } from 'react-spinners';

import { Flex, Box, Text, Divider } from '_shared/designSystem/components';

import FrequencyBadge from './_shared/FrequencyBadge';
import { horizontalOrientationOrder, serveDirectionOrder, verticalOrientationOrder } from './AveragesBadges';
import { CustomBadge } from './CustomBadge';
import { getAverage } from './util';

export function getMobileAveragesDisplay(
  graphicType,
  orientation,
  _dataNoAverages,
  _dataWithAverages,
  showAverages,
  averagesLoading
) {
  let dataNoAverages;
  let dataWithAverages;

  if (isReturnOrRallyPlacement(graphicType) && orientation === 'horizontal') {
    dataNoAverages = _dataNoAverages?.horizontal;
    dataWithAverages = _dataWithAverages?.horizontal;
  } else if (isReturnOrRallyPlacement(graphicType) && orientation === 'vertical') {
    dataNoAverages = _dataNoAverages?.vertical;
    dataWithAverages = _dataWithAverages?.vertical;
  } else {
    dataNoAverages = _dataNoAverages;
    dataWithAverages = _dataWithAverages;
  }

  const isColumn =
    isContactPoint(graphicType) || (isReturnOrRallyPlacement(graphicType) && orientation === 'horizontal');
  if (isColumn) {
    return getMobileAveragesDisplayColumns(dataNoAverages, dataWithAverages, showAverages, averagesLoading);
  } else {
    return getMobileAveragesDisplayRows(graphicType, dataNoAverages, dataWithAverages, showAverages, averagesLoading);
  }
}

export function getMobileAveragesDisplayRows(
  graphicType,
  dataNoAverages,
  dataWithAverages,
  showAverages,
  averagesLoading
) {
  const ordering = isServeDirectionOrAccuracy(graphicType) ? serveDirectionOrder : verticalOrientationOrder;
  return (
    <Flex p={3} width="100%" direction="column" gap={2}>
      <Flex width="100%" justify="space-between">
        {!isEmpty(dataNoAverages) &&
          Object.keys(dataNoAverages)?.map((position) => {
            const averageFigure = getAverage(dataNoAverages, position, 'match');
            return (
              <Box key={position}>
                <Flex direction="column" gap={1}>
                  <CustomBadge key={position} text={averageFigure} type="matchAverage" />
                  {!showAverages && <FrequencyBadge frequency={dataNoAverages?.[position]?.frequency} />}
                </Flex>
              </Box>
            );
          })}
      </Flex>
      {showAverages && averagesLoading && (
        <Flex justify="center">
          <BeatLoader size={3} color="gray" />
        </Flex>
      )}
      {showAverages &&
        dataWithAverages &&
        ['player', 'tour'].map((item) => (
          <Flex justify="space-between" key={item}>
            {ordering.map((position) => {
              const averageFigure = getAverage(dataWithAverages, position, item);
              return (
                <CustomBadge loading={averagesLoading} key={position} text={averageFigure} type={`${item}Average`} />
              );
            })}
          </Flex>
        ))}
    </Flex>
  );
}

export function getMobileAveragesDisplayColumns(dataNoAverages, dataWithAverages, showAverages, averagesLoading) {
  return (
    <Box p={3} width="100%">
      <Flex direction="column" gap={3}>
        {horizontalOrientationOrder.map((position) => {
          const averageFigure = getAverage(dataNoAverages, position, 'match');
          return (
            <Box key={position}>
              <Flex gap={1} justify="center">
                <CustomBadge key={position} text={averageFigure} type="matchAverage" />
                {!showAverages && dataNoAverages?.[position]?.frequency !== null && (
                  <Text fontSize="xs" fontWeight="medium" color="grey.500">
                    ({dataNoAverages?.[position]?.frequency})
                  </Text>
                )}
                {showAverages && averagesLoading && (
                  <Flex justify="center">
                    <CustomBadge loading={averagesLoading} type={`playerAverage`} />
                  </Flex>
                )}
                {showAverages &&
                  dataWithAverages &&
                  ['player', 'tour'].map((item) => (
                    <Flex key={item}>
                      <CustomBadge
                        key={position}
                        text={getAverage(dataWithAverages, position, item)}
                        type={`${item}Average`}
                        loading={averagesLoading}
                      />
                    </Flex>
                  ))}
              </Flex>
              <Divider mt={5} variant="dashed" />
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
}
