import { useGetTrackedMatches } from 'headToHead/services/headToHeadService';
import MatchCard from 'schedule/components/MatchCard';

import { Flex } from '_shared/designSystem/components';

export const MatchCards = ({ trackedMatches }) => {
  const { data: matches, isFetching: isFetchingMatches } = useGetTrackedMatches({
    matchIds: trackedMatches
  });

  return (
    <Flex
      width="100%"
      gap="8px"
      rowGap="30px"
      alignContent="center"
      alignItems="center"
      flexWrap="wrap"
      justify="center"
    >
      {!isFetchingMatches &&
        matches?.map((match) => (
          <Flex key={match?.match_id}>
            <MatchCard key={match?.match_id} data={match} showTournamentInfo={true} h2hCard={true} />
          </Flex>
        ))}
    </Flex>
  );
};
