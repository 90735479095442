import PropTypes from 'prop-types';
import { getScoutHeaderForm, getScoutSeasonsOnlyForm } from 'scout/util/scoutHeaderUtil';
import { getInitialMatchSelectorChoices, getScoutMatchSelectorForm } from 'scout/util/scoutMatchSelectorUtil';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Box, FormStandard } from '_shared/designSystem/components';
export default function ScoutMatchSelectorForm(props) {
  const {
    playerDetailsData,
    formPopulationData,
    scoutFormValues,
    handleMatchSelectorFormSubmit,
    handleMatchSelectorFormUpdate
  } = props;

  let formFieldsData;

  if (customSitesConfig.scoutFiltersOnlySeason) {
    formFieldsData = getScoutSeasonsOnlyForm(playerDetailsData?.seasons_filter);
  } else {
    const basicFields = getScoutHeaderForm(playerDetailsData?.seasons_filter, false);
    const detailedfields = getScoutMatchSelectorForm(
      formPopulationData?.tournaments,
      formPopulationData?.opponents,
      formPopulationData?.rounds
    );
    formFieldsData = basicFields.concat(detailedfields);
  }

  const handleFormUpdated = (values) => {
    handleMatchSelectorFormUpdate(values);
  };

  const handleFormSubmit = (values) => {
    handleMatchSelectorFormSubmit(values);
  };

  return (
    <Box>
      <FormStandard
        formFieldsData={formFieldsData}
        formInitialValues={getInitialMatchSelectorChoices(scoutFormValues)}
        handleFormUpdated={handleFormUpdated}
        handleFormSubmit={handleFormSubmit}
        enableReinitialize={false}
      />
    </Box>
  );
}

ScoutMatchSelectorForm.propTypes = {
  playerDetailsData: PropTypes.object,
  formPopulationData: PropTypes.object,
  scoutFormValues: PropTypes.object,
  handleMatchSelectorFormSubmit: PropTypes.func,
  handleMatchSelectorFormUpdate: PropTypes.func
};
