import { matchStatus, tournamentStatus } from '_shared/constants/matchTypes';

export const determineBorderColor = ({ status, disabled }) => {
  if (disabled) return 'grey.500';

  const colorMap = {
    [matchStatus.FORTHCOMING]: 'primary.500',
    [matchStatus.LIVE]: 'secondary.800',
    [tournamentStatus.CURRENT]: 'secondary.800',
    [matchStatus.COMPLETE]: 'primary.500'
  };

  return colorMap[status] || 'primary.500';
};

export const determineHoverColor = ({ status, disabled }) => {
  if (disabled) return null;

  const colorMap = {
    [matchStatus.FORTHCOMING]: 'primary.25',
    [matchStatus.LIVE]: 'secondary.25',
    [tournamentStatus.CURRENT]: 'secondary.25',
    [matchStatus.COMPLETE]: 'primary.25'
  };

  return colorMap[status] || 'primary.25';
};
