import isEmpty from 'lodash/isEmpty';

import colors from '_shared/designSystem/theme/colors';

export function drawSetLinesAndLabels(
  svg,
  height,
  yMargin,
  performanceRatingGame,
  xScale,
  createXAxisIdentifier,
  lineColors
) {
  const setLabelYOffset = 23;
  const setsArray = performanceRatingGame.map((item) => item.set); // all set numbers in one array
  const uniqueSets = [...new Set(setsArray)];
  if (!isEmpty(uniqueSets)) {
    uniqueSets.forEach((set, index) => {
      // get the indexes of the start, middle and end, so we know where to draw the lines and labels
      const startOfSet = setsArray?.indexOf(set);
      const endOfSet = setsArray?.lastIndexOf(set);
      const middleOfSet = Math.floor(startOfSet + (endOfSet - startOfSet) / 2);
      if (index !== uniqueSets?.length - 1) {
        // we don't want a set line at the end of the graph
        svg
          .append('line')
          .attr('x1', xScale(createXAxisIdentifier(performanceRatingGame?.[endOfSet]))) // draw the set line at the end of the set
          .attr('x2', xScale(createXAxisIdentifier(performanceRatingGame?.[endOfSet])))
          .attr('y1', yMargin)
          .attr('y2', height - yMargin)
          .style('stroke-dasharray', '2,4')
          .style('stroke', colors.grey[200])
          .style('stroke-width', 2);
      }
      const gamesPlayer1 = performanceRatingGame?.[endOfSet]?.['score_p1'];
      const gamesPlayer2 = performanceRatingGame?.[endOfSet]?.['score_p2'];
      svg
        .append('text')
        .attr('x', xScale(createXAxisIdentifier(performanceRatingGame?.[middleOfSet]))) // draw the label in the middle of the set
        .attr('y', height - yMargin + setLabelYOffset)
        .text(`Set ${set} ${gamesPlayer1}-${gamesPlayer2}`)
        .style('fill', gamesPlayer1 > gamesPlayer2 ? lineColors?.player1Line : lineColors?.player2Line)
        .style('text-anchor', 'middle')
        .style('font-size', '12px');
    });
  }
}
