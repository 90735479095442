import { courtArrowTypes } from '_shared/designSystem/components/court/courtConstants';

export function getArrowType(id, handedness) {
  const isLeft = handedness === 'left';
  switch (id) {
    case 'forehand_crosscourt':
    case 'backhand_inside_out':
      return isLeft ? courtArrowTypes.DIAGONAL_LEFT_TO_RIGHT : courtArrowTypes.DIAGONAL_RIGHT_TO_LEFT;
    case 'backhand_line':
    case 'forehand_inside_in':
      return isLeft ? courtArrowTypes.STRAIGHT_RIGHT : courtArrowTypes.STRAIGHT_LEFT;
    case 'forehand_line':
    case 'backhand_inside_in':
      return isLeft ? courtArrowTypes.STRAIGHT_LEFT : courtArrowTypes.STRAIGHT_RIGHT;
    case 'backhand_crosscourt':
    case 'forehand_inside_out':
      return isLeft ? courtArrowTypes.DIAGONAL_RIGHT_TO_LEFT : courtArrowTypes.DIAGONAL_LEFT_TO_RIGHT;
    case 'forehand_control_the_middle':
    case 'forehand_from_the_middle':
    case 'backhand_control_the_middle':
      return courtArrowTypes.V_SHAPE_FROM_BASELINE;
    case 'backhand_from_the_middle':
      return courtArrowTypes.V_SHAPE_FROM_BASELINE;
    case 'forehand_through_middle':
      return isLeft ? courtArrowTypes.LEFT_TO_MIDDLE_FROM_BASELINE : courtArrowTypes.RIGHT_TO_MIDDLE_FROM_BASELINE;
    case 'backhand_through_middle':
      return isLeft ? courtArrowTypes.RIGHT_TO_MIDDLE_FROM_BASELINE : courtArrowTypes.LEFT_TO_MIDDLE_FROM_BASELINE;
    case 'forehand_volley':
      return isLeft ? courtArrowTypes.VOLLEY_STRAIGHT_LEFT : courtArrowTypes.VOLLEY_STRAIGHT_RIGHT;
    case 'backhand_volley':
      return isLeft ? courtArrowTypes.VOLLEY_STRAIGHT_RIGHT : courtArrowTypes.VOLLEY_STRAIGHT_LEFT;
    case 'volley_control_the_middle':
      return courtArrowTypes.VOLLEY_FROM_NET;
    case 'drop_shots_and_short_slices':
      return courtArrowTypes.BETWEEN_DROP_SHOT_AND_CENTER;
    case 'volleys':
      return courtArrowTypes.VOLLEY_FROM_NET;
    case 'drop_shots':
      return courtArrowTypes.VOLLEYS;
    case 'forehand_inside_in_out':
      return isLeft
        ? courtArrowTypes.STRAIGHT_RIGHT_AND_DIAGONAL_LEFT
        : courtArrowTypes.STRAIGHT_LEFT_AND_DIAGONAL_RIGHT;
    default:
      return null;
  }
}

export function isForehandOrBackhand(id) {
  if (id === null) return null;
  if (id.includes('forehand')) return 'forehand';
  if (id.includes('backhand')) return 'backhand';
  return 'both';
}

export function getScoutCourtLegend(playType) {
  switch (playType) {
    case 'all':
      return [
        {
          label: 'Winning Play',
          color: 'success.300'
        },
        {
          label: 'Losing Play',
          color: 'error.300'
        },
        {
          label: 'Neutral Play',
          color: 'grey.500'
        }
      ];
    case 'winning':
      return [
        {
          label: 'Winning Play',
          color: 'success.300'
        }
      ];
    case 'losing':
      return [
        {
          label: 'Losing Play',
          color: 'error.400'
        }
      ];
    default:
      return [];
  }
}
