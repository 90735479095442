import { parseISO, format } from 'date-fns';

export function formatDate(date) {
  if (!date) return '';

  try {
    const formattedDate = format(parseISO(date.replace(' ', 'T') + 'Z'), 'd MMM yyyy');
    return formattedDate;
  } catch (error) {
    console.error('Invalid date format:', error);
    return '';
  }
}

export function formatDateNoYear(date) {
  if (!date) return '';

  try {
    const formattedDate = format(parseISO(date?.replace(' ', 'T') + 'Z'), 'd MMM');
    return formattedDate;
  } catch (error) {
    console.error('Invalid date format:', error);
    return '';
  }
}

export const formatDateYearOnly = (date) => {
  if (!date) return '';

  try {
    const formattedDate = format(parseISO(date?.replace(' ', 'T') + 'Z'), 'yyyy');
    return formattedDate;
  } catch (error) {
    console.error('Invalid date format:', error);
    return '';
  }
};

export function formatTime(date) {
  if (!date) return '';

  try {
    const formattedTime = format(parseISO(date?.replace(' ', 'T') + 'Z'), 'HH:mm');
    return formattedTime;
  } catch (error) {
    console.error('Invalid date format:', error);
    return '';
  }
}

export function convertMinToHourMin(totalMinutes) {
  if (!totalMinutes) return '';
  try {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes.toString().padStart(2, '0')}m`;
  } catch (error) {
    console.error('Invalid duration format:', error);
    return '';
  }
}
