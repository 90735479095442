import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

export function movePlayerAverageToScore(data) {
  if (isEmpty(data)) return [];
  const newData = cloneDeep(data);
  return newData.map((item) => {
    return {
      ...item,
      metrics: item?.metrics.map((metric) => {
        metric.player1.score = metric.player1.player_average;
        metric.player1.player_average = null;
        metric.player2.score = metric.player2.player_average;
        metric.player2.player_average = null;
        return metric;
      })
    };
  });
}
