import { Stack, Skeleton } from '@ellipsedata/tennis';

const PlaylistSkeleton = () => {
  const startColor = 'grey.100';
  const endColor = 'grey.300';
  return (
    <Stack padding={1}>
      {[...Array(11)].map((x, i) => (
        <Skeleton key={i} height="50px" mt={0.5} startColor={startColor} endColor={endColor} />
      ))}
    </Stack>
  );
};

export default PlaylistSkeleton;

export const PlaylistSkeletonMobile = () => {
  const startColor = 'grey.100';
  const endColor = 'grey.300';
  return <Skeleton height={{ base: '36px', sm: '28px', md: '36px' }} startColor={startColor} endColor={endColor} />;
};
