import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useMeasure } from 'react-use';

import { Flex, CourtSurface2dFull, ShotLine } from '_shared/designSystem/components';

export default function CourtWithShots({ surface, shots }) {
  const [ref, { width, height }] = useMeasure();

  const backgroundColor = `court.background.${surface}`;
  return (
    <Flex justify="center" bg={backgroundColor}>
      <Flex data-testid="courtWithShots" w="100%" position="relative" ref={ref}>
        {!isEmpty(shots) &&
          shots.map((item, index) => {
            if (item.to_x === null || item.to_y === null) return null;
            const convertedFromCoords = convertCoordinates(width, height, item.from_x, item.from_y);
            const convertedToCoords = convertCoordinates(width, height, item.to_x, item.to_y);
            return (
              <ShotLine
                key={index}
                surface={surface}
                shotType={item.shot_type}
                fromX={convertedFromCoords.x}
                fromY={convertedFromCoords.y}
                toX={convertedToCoords.x}
                toY={convertedToCoords.y}
                canvasWidth={width}
                canvasHeight={height}
                volley={item.volley}
              />
            );
          })}
        <CourtSurface2dFull surface={surface} />
      </Flex>
    </Flex>
  );
}

CourtWithShots.propTypes = {
  surface: PropTypes.oneOf(['hard', 'grass', 'clay']),
  shots: PropTypes.array
};

export function convertCoordinates(viewBoxWidth, viewBoxHeight, pointX, pointY) {
  const startingX = 0.5 * viewBoxWidth;
  const startingY = 0.5 * viewBoxHeight;
  const pxFor1m = 0.03305 * viewBoxHeight; // from using Figma

  return {
    x: Math.round(startingX + pointX * pxFor1m),
    y: Math.round(startingY - pointY * pxFor1m)
  };
}
