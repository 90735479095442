import { isWimbPortal } from './environment/currentEnv';

export function getTourAverageLabel() {
  return isWimbPortal() ? 'Draw Avg' : 'Tour Avg';
}

export const determineWinOpacity = (value1, value2, reverseOpacity) => {
  value1 = Math.round(value1 * 100) / 100;
  value2 = Math.round(value2 * 100) / 100;

  return reverseOpacity ? (value1 >= value2 ? 0.5 : 1) : value1 >= value2 ? 1 : 0.5;
};
