import { courtGraphicTypes } from 'match/_shared/matchConstants';
import { getCourtGraphic3d2d } from 'match/utils/courtGraphicsUtil';
import { useRecoilValue } from 'recoil';

import { Flex, Box, Legend } from '_shared/designSystem/components';
import { isMatchReportState } from '_shared/globalState/atoms';

export const CourtGraphicsLegend = ({ graphicType, surface, width, height, outShots }) => {
  const isMatchReport = useRecoilValue(isMatchReportState);

  const fields = [
    {
      label: 'Won',
      color: 'warning.200'
    },
    {
      label: 'Lost',
      color: surface === 'clay' ? 'rose.800' : 'rose.500'
    },
    {
      label: graphicType === courtGraphicTypes.SERVE_DIRECTION ? 'Ace' : 'Winner',
      color: surface === 'grass' ? 'success.600' : 'success.500'
    }
  ];

  if (outShots === true) {
    fields.push({
      label: 'Errors',
      color: 'white',
      borderColor: 'black'
    });
  }

  return (
    <Box
      display={
        isMatchReport
          ? 'initial'
          : {
              base: 'none',
              md: 'initial'
            }
      }
    >
      {getCourtGraphic3d2d(graphicType) === '3d' && width > 0 && height > 0 && (
        <Box position="absolute" left={width * 0.04} top={height * 0.3} justify="center">
          <Legend fields={fields} direction="vertical" />
        </Box>
      )}
      {getCourtGraphic3d2d(graphicType) === '2d' && (
        <Flex position="absolute" left={width * 0.04} top={height * 0.4} justify="center" pt={0} pb={2}>
          <Legend
            direction="vertical"
            fields={[
              {
                label: 'Won',
                color: 'warning.300'
              },
              {
                label: 'Lost',
                color: surface === 'clay' ? 'rose.800' : 'rose.500'
              },
              {
                label: graphicType === courtGraphicTypes.SERVE_DIRECTION ? 'Ace' : 'Winner',
                color: surface === 'grass' ? 'success.600' : 'success.500'
              },
              {
                label: 'Errors',
                color: 'white',
                border: '1px solid black'
              }
            ]}
          />
        </Flex>
      )}
    </Box>
  );
};

export const CourtGraphicsLegendMobile = ({ graphicType, surface, outShots }) => {
  const fields = [
    {
      label: 'Won',
      color: 'warning.300'
    },
    {
      label: 'Lost',
      color: surface === 'clay' ? 'rose.800' : 'rose.500'
    },
    {
      label: graphicType === courtGraphicTypes.SERVE_DIRECTION ? 'Ace' : 'Winner',
      color: 'success.500'
    },
    {
      label: 'Errors',
      color: 'white',
      border: '1px solid black'
    }
  ];
  return (
    <Flex justify="center" pt={3}>
      <Legend fields={fields} />
    </Flex>
  );
};
