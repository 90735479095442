import isEmpty from 'lodash/isEmpty';
import { displayScore } from 'match/utils/transformations';
import PropTypes from 'prop-types';
import {
  patternsOfPlayGraphicType,
  patternsOfPlayPattern,
  patternsOfPlayShotTypes
} from 'scout/_shared/scoutConstants';

import { Text, Flex } from '_shared/designSystem/components';
import { pluralize } from '_shared/utils/pluralize';
import { capitaliseString } from '_shared/utils/stringUtil';

const CourtHeader = ({ data, graphicType, pattern, shotType }) => {
  if (isEmpty(data)) return null;

  const { score, frequency, frequency_total, score_type } = data?.total;

  return (
    <Flex direction="column" gap={1} alignItems="center" data-testid="courtHeader">
      <Text fontSize="md" fontWeight="semibold" align="center" mb={1} color="secondary.800">
        {capitaliseString(pattern)}
      </Text>
      <Flex direction="row" gap={1} alignItems="center">
        <Text fontSize="sm" fontWeight="semibold" align="center" color="secondary.800">
          {`${frequency_total === null || score === null ? 0 : frequency_total || score} ${pluralize(
            capitaliseString(shotType),
            frequency_total || score
          )}`}
        </Text>
      </Flex>
      {!!frequency_total && (
        <Flex direction="row" gap={1} alignItems="center">
          <Text fontSize="xs" fontWeight="semibold" align="center" color="secondary.800">
            {`${displayScore(score, score_type)} (${frequency}/${frequency_total}) Total ${capitaliseString(
              shotType
            )} ${capitaliseString(graphicType)}`}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

CourtHeader.propTypes = {
  pattern: PropTypes.oneOf(Object.values(patternsOfPlayPattern)).isRequired,
  shotType: PropTypes.oneOf(Object.values(patternsOfPlayShotTypes)).isRequired,
  graphicType: PropTypes.oneOf(Object.values(patternsOfPlayGraphicType)).isRequired
};

export default CourtHeader;
