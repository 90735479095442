import { BackToWimbledonLink } from '_customSites/wimb/components/BackToWimbledonLink';
import { isWimbMyWSession } from '_customSites/wimb/services/wimbLocalStorage';
import Logout from 'authentication/components/Logout';

import { Flex, Link, Text, Center } from '_shared/designSystem/components';
import { isAtpTourPortal, isWimbPortal } from '_shared/utils/environment/currentEnv';
import useFeature from '_shared/utils/hooks/useFeature';

const AdditionalOptions = ({ iconOnly, isTopMenu }) => {
  const iconSize = isWimbPortal() ? '18px' : '20px';
  const textColor = isWimbPortal() ? 'secondary.800' : 'white';
  const fontSize = isWimbPortal() ? '15px' : 'sm';

  const showWearablesLink = useFeature('wearablesLink');

  return (
    <Flex
      justify={showWearablesLink && isAtpTourPortal() ? 'space-between' : 'flex-end'}
      align="center"
      h="100%"
      display={!isTopMenu ? 'column' : 'flex'}
    >
      {showWearablesLink && (
        <>
          {isAtpTourPortal() && (
            <Text fontSize={fontSize} fontWeight="sm" color={textColor} cursor="pointer">
              <Link href="https://atptennisiqwearables.com/" isExternal _hover={{ textDecoration: 'none' }}>
                Wearables
              </Link>
            </Text>
          )}
        </>
      )}
      {isWimbMyWSession() && !isTopMenu && (
        <Center mr={3}>
          <BackToWimbledonLink />
        </Center>
      )}
      {!isWimbMyWSession() && (
        <Logout
          iconOnly={iconOnly}
          isTopMenu={isTopMenu}
          iconSize={iconSize}
          textColor={textColor}
          fontSize={fontSize}
        />
      )}
    </Flex>
  );
};

export default AdditionalOptions;
