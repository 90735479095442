import { atptourFontsGuidelines } from './fontsSpec';

export function convertFontSizeToAtpTourTextGuidelines(chakraProps) {
  switch (chakraProps.fontSize) {
    case 'xs':
      return atptourFontsGuidelines.normal;
    case 'sm':
      return atptourFontsGuidelines.h5;
    case 'md':
      return atptourFontsGuidelines.h4;
    case 'lg':
      return atptourFontsGuidelines.h3;
    case 'xl':
      return atptourFontsGuidelines.h2;
    case '2xl':
    case '3xl':
    case '4xl':
    case '5xl':
    case '6xl':
      return atptourFontsGuidelines.h2;
    case 'paragraphText':
      return atptourFontsGuidelines.paragraphText;
    default:
      return atptourFontsGuidelines.h4;
  }
}
