import { Text as ChakraText } from '@chakra-ui/react';
import { convertFontSizeToAtpTourTextGuidelines } from '_customSites/atptour/theme/fonts/fontsUtil';
import { convertFontSizeToWimbTextGuidelines } from '_customSites/wimb/theme/fonts/fontsUtil';

import { isAtpMediaPortal, isAtpTourPortal, isWimbPortal } from '_shared/utils/environment/currentEnv';

export default function Text(props) {
  let customProps = {};
  if (isWimbPortal()) {
    customProps = convertFontSizeToWimbTextGuidelines(props);
  } else if (isAtpTourPortal() || isAtpMediaPortal()) {
    customProps = convertFontSizeToAtpTourTextGuidelines(props);
  }

  return <ChakraText {...props} {...customProps} />;
}
