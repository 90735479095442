const patternsOfPayCourtArrowTypes = {
  CONTROL_THE_MIDDLE: 'control_the_middle',
  FROM_THE_DEUCE_SIDE: 'from_the_deuce_side',
  FROM_THE_ADVANTAGE_SIDE: 'from_the_advantage_side',
  VOLLEYS: 'volleys'
};

export const ARROW_PATTERN_TYPES = {
  [patternsOfPayCourtArrowTypes.CONTROL_THE_MIDDLE]: [
    { top: 145, left: 16, angle: -11, numberSlots: 23 },
    { top: 80, left: 185, angle: 11, numberSlots: 23 }
  ],
  [patternsOfPayCourtArrowTypes.FROM_THE_DEUCE_SIDE]: [
    { top: 125, left: 150, angle: 0, numberSlots: 25 },
    { top: 170, left: -10, angle: -18, numberSlots: 25 }
  ],
  [patternsOfPayCourtArrowTypes.FROM_THE_ADVANTAGE_SIDE]: [
    { top: 125, left: 55, angle: 0, numberSlots: 25 },
    { top: 60, left: 198, angle: 18, numberSlots: 25 }
  ],
  [patternsOfPayCourtArrowTypes.VOLLEYS]: [
    { top: 185, left: -20, angle: -20, numberSlots: 13 },
    { top: 65, left: 210, angle: 20, numberSlots: 13 }
  ]
};

export const ARROW_PATTERN_TYPES_WIDE_COURT = {
  [patternsOfPayCourtArrowTypes.CONTROL_THE_MIDDLE]: [
    { top: 130, left: -33, angle: -16, numberSlots: 20, height: '200' },
    { top: 32, left: 235, angle: 16, numberSlots: 20, height: '200' }
  ],
  [patternsOfPayCourtArrowTypes.FROM_THE_DEUCE_SIDE]: [
    { top: 88, left: 190, angle: 0, numberSlots: 20, height: '200' },
    { top: 123, left: -78, angle: -30, numberSlots: 22, height: '200' }
  ],
  [patternsOfPayCourtArrowTypes.FROM_THE_ADVANTAGE_SIDE]: [
    { top: 88, left: 14, angle: 0, numberSlots: 20, height: '200' },
    { top: -56, left: 232, angle: 30, numberSlots: 22, height: '200' }
  ],
  [patternsOfPayCourtArrowTypes.VOLLEYS]: [
    { top: 120, left: -30, angle: -20, numberSlots: 14, height: '200' },
    { top: 0, left: 220, angle: 20, numberSlots: 14, height: '200' }
  ]
};
