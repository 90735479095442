import usePageTitle from '_shared/utils/hooks/usePageTitle';

import { useHeaderQuery } from './matchHooks';

export function useMatchPageTitle(matchId: string, section: string): void {
  const { data: matchData } = useHeaderQuery(matchId);

  const player1Name = matchData?.player_details?.player1?.member1?.last_name;
  const player2Name = matchData?.player_details?.player2?.member1?.last_name;

  const tabTitle = `${player1Name} vs ${player2Name} - ${section} - ${matchData?.competition_name}`;

  usePageTitle(tabTitle);
}
