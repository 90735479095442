import { useNavigate } from '@tanstack/react-location';
import PropTypes from 'prop-types';

import { Image, Icon, Center } from '_shared/designSystem/components';
import { navigateTo } from '_shared/utils/navigationUtils';

const PlayerImageHeadshot = ({ imageUrl, playerName, w, h, playerId }) => {
  const navigate = useNavigate();

  return (
    <Image
      borderRadius="full"
      border="1px solid"
      borderColor="grey.300"
      _hover={{ borderColor: 'primary.700', cursor: 'pointer' }}
      bg="white"
      w={w}
      h={h}
      minW={w}
      minH={h}
      src={imageUrl}
      alt={playerName}
      onClick={(e) => {
        if (!playerId) return;
        e.stopPropagation();
        navigateTo({
          url: `/scout/${playerId}`,
          navigate
        });
      }}
      fallback={
        <Center overflow="hidden" h={h} w={w} border="1px solid" borderColor="grey.300" borderRadius="full">
          <Icon mt={2} color="grey.300" w={h} h={h} name="profileImagePlaceholder" />
        </Center>
      }
    />
  );
};

export default PlayerImageHeadshot;

PlayerImageHeadshot.propTypes = {
  imageUrl: PropTypes.string,
  playerName: PropTypes.string,
  w: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  h: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
