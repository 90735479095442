import { courtGraphicTypes } from 'match/_shared/matchConstants';
import { serveDirectionOrder } from 'match/components/court-graphics/court/averages/AveragesBadges';
import CourtContainer from 'match/components/court-graphics/court/CourtContainer';
import { displayScore } from 'match/utils/transformations';
import PageHeading from 'matchReport/components/_shared/PageHeading';
import { useMatchReportCourtGraphicsQuery } from 'matchReport/hooks/matchReportHooks';

import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from '_shared/designSystem/components';

const ServeDirectionPage = ({ headerData, heading, matchId, player, serve }) => {
  const graphicType = courtGraphicTypes.SERVE_DIRECTION;
  const surface = headerData?.surface;
  const queryParams = {
    graphicType,
    player,
    serve,
    set: 'all',
    servePlusOne: false,
    pressureBreak: null,
    forehandBackhand: 'all',
    showAverages: false
  };
  const queryParamsPressure = { ...queryParams, pressureBreak: 'pressure' };
  const queryParamsBreak = { ...queryParams, pressureBreak: 'break' };

  const fetchParams = { enabled: !!headerData };

  const courtGraphicsRes = useMatchReportCourtGraphicsQuery(matchId, queryParams, fetchParams);
  const pressureRes = useMatchReportCourtGraphicsQuery(matchId, queryParamsPressure, fetchParams);
  const breakRes = useMatchReportCourtGraphicsQuery(matchId, queryParamsBreak, fetchParams);

  if (courtGraphicsRes.error || pressureRes.error || breakRes.error)
    throw new Error('Unable to retrieve court graphics data');
  if (courtGraphicsRes.isLoading || pressureRes.isLoading || breakRes.isLoading) return null;

  const courtGraphicsData = courtGraphicsRes?.data;
  const pressureData = pressureRes?.data;
  const breakData = breakRes?.data;

  if (courtGraphicsData && pressureData && breakData) {
    return (
      <Box>
        <PageHeading>{heading}</PageHeading>
        <Box w={1000} mb={50}>
          <CourtContainer
            graphicType={graphicType}
            surface={surface}
            shots={courtGraphicsData?.shots}
            dataNoAverages={courtGraphicsData?.averages?.placement}
            showAverages={false}
          />
        </Box>
        <Table w="750px" ml="20px">
          <Thead>
            <Tr>
              <ColumnHeading></ColumnHeading>
              <ColumnHeading>Wide Ad</ColumnHeading>
              <ColumnHeading>Body Ad</ColumnHeading>
              <ColumnHeading>Tee Ad</ColumnHeading>
              <ColumnHeading>Tee Deuce</ColumnHeading>
              <ColumnHeading>Body Deuce</ColumnHeading>
              <ColumnHeading>Wide Deuce</ColumnHeading>
            </Tr>
          </Thead>
          <Tbody>
            <ServeDirectionTableRow
              heading={tableLeftColumnNames.serve_direction}
              data={courtGraphicsData?.averages?.placement}
              showZero
            />
            <ServeDirectionTableRow heading={tableLeftColumnNames.win} data={courtGraphicsData?.averages?.win} />
            <ServeDirectionTableRow heading={tableLeftColumnNames.pressure} data={pressureData?.averages?.placement} />
            <ServeDirectionTableRow heading={tableLeftColumnNames.break} data={breakData?.averages?.placement} />
            <ServeDirectionTableRow
              heading={tableLeftColumnNames.quality}
              data={courtGraphicsData?.averages?.quality}
            />
            <ServeDirectionTableRow
              heading={tableLeftColumnNames.unreturned}
              data={courtGraphicsData?.averages?.unreturned}
            />
          </Tbody>
        </Table>
      </Box>
    );
  }
  return null;
};

export const ServeDirectionTableRow = ({ heading, data, showZero }) => {
  return (
    <Tr>
      <Td whiteSpace="wrap" w="220px">
        {heading}
      </Td>
      {serveDirectionOrder.map((position) => (
        <Td key={position} w="85px" px={0}>
          <Text>
            {data?.[position]?.match_average === 0 && !showZero ? (
              ''
            ) : (
              <span>
                {displayScore(data?.[position]?.match_average, data?.[position]?.score_type)}
                {displayFrequency(data?.[position]?.frequency)}
              </span>
            )}
          </Text>
        </Td>
      ))}
    </Tr>
  );
};

function displayFrequency(frequency) {
  if (frequency === null) return null;
  return ` (${frequency})`;
}

const tableLeftColumnNames = {
  serve_direction: 'Serve Direction',
  win: 'Win %',
  pressure: 'Pressure Point Direction %',
  break: 'Break Point Direction %',
  quality: 'Quality',
  unreturned: 'Unreturned %'
};

const ColumnHeading = ({ children }) => (
  <Th whiteSpace="wrap" px={0}>
    {children}
  </Th>
);

export default ServeDirectionPage;
