import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { drawTypes } from '_shared/constants/matchTypes';
import { searchByString } from '_shared/utils/stringUtil';

export function getRoundsData(data) {
  if (isEmpty(data)) return [];
  if (isEmpty(data.matches)) return [];
  const result = [];
  data.matches.forEach((element) => {
    result.push(element.round);
  });
  return uniq(result);
}

export function convertArrayToButtonData(data, showSingle) {
  if (isEmpty(data)) return [];
  if (data.length === 1) {
    return showSingle ? [{ value: data[0], label: String(data[0]), position: 'singleButton' }] : [];
  }
  if (data.length === 2) {
    return [
      { value: data[0], label: String(data[0]), position: 'left' },
      { value: data[1], label: String(data[1]), position: 'right' }
    ];
  }
  const result = [];
  result.push({ value: data[0], label: String(data[0]), position: 'left' });
  for (let i = 1; i < data.length - 1; i++) {
    result.push({ value: data[i], label: String(data[i]), position: 'middle' });
  }
  result.push({ value: data[data.length - 1], label: String(data[data.length - 1]), position: 'right' });
  return result;
}

export function performAllTournamentSearch(data, round, player) {
  const filteredByRound = filterByRound(data, round);
  const filteredByPlayerSearch = searchByString(filteredByRound, player, 'players');
  return filteredByPlayerSearch;
}

export function filterByRound(data, round) {
  if (round === 'all' || round === 'All' || !round) return data;
  if (!data || isEmpty(data)) return [];
  let result = [];
  result = data.filter((item) => item.round === round);
  return result;
}

// this is quite fiddly due to dealing with empty params
export function getNewFormState(formState, search) {
  if (!formState && !search) return null;
  const roundExists = search.round || search.round === '';
  if (roundExists && search.playerName) {
    // both round and name set
    return { ...formState, round: search.round, playerName: search.playerName };
  } else if (roundExists && !search.playerName) {
    // only round set
    return { ...formState, round: search.round };
  } else if (!roundExists && search.playerName) {
    // only name  set
    return { ...formState, playerName: search.playerName };
  }
  return formState;
}

export function getIsDoubles(drawType) {
  return [
    drawTypes.MENS_DOUBLES,
    drawTypes.MENS_QUALIFYING_DOUBLES,
    drawTypes.LADIES_DOUBLES,
    drawTypes.LADIES_QUALIFYING_DOUBLES
  ].includes(drawType);
}
