import { useEffect, useState } from 'react';

import { useLocation } from '@tanstack/react-location';

// paths is an array of the root paths we could be on e.g. match, scout etc.
// returns true if the current page we are on is one of those paths
export function useDoesCurrentRootPathContain(paths) {
  const [pathContains, setPathContains] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const pathname = location.current.pathname;
    const pathRoot = pathname?.split('/')?.[1];
    const contains = paths.includes(pathRoot);
    setPathContains(contains);
    // eslint-disable-next-line
  }, [location.current.pathname, paths]);

  return pathContains;
}
