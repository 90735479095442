import { useNavigate } from '@tanstack/react-location';
import PropTypes from 'prop-types';

import PlayerImageHeadshot from '_shared/components/PlayerImageHeadshot';
import { Flex, Text, Box, useBreakpointValue } from '_shared/designSystem/components';
import { determineWinOpacity } from '_shared/utils/metricsUtil';
import { navigateTo } from '_shared/utils/navigationUtils';

const HeadlineWins = ({
  player1FirstName,
  player1LastName,
  player2FirstName,
  player2LastName,
  player1Wins,
  player2Wins,
  player1ThumbnailUrl,
  player2ThumbnailUrl,
  player1Id,
  player2Id
}) => {
  const windowIsLg = useBreakpointValue({
    base: false,
    lg: true
  });

  const playerImageSize = { base: '80px', md: '110px' };
  const playerNameWidth = '200px';
  const vsWinsWidth = ['50px', '60px', '70px', '80px', '90px', '100px'];

  if (!player1LastName) return null;

  return (
    <>
      {!windowIsLg && (
        <Flex justify="center" mb={5}>
          <PlayerImageHeadshot
            w={playerImageSize}
            h={playerImageSize}
            imageUrl={player1ThumbnailUrl}
            playerName={player1LastName}
            playerId={player1Id}
          />
          <Box w="60px" />
          <PlayerImageHeadshot
            w={playerImageSize}
            h={playerImageSize}
            imageUrl={player2ThumbnailUrl}
            playerName={player2LastName}
            playerId={player2Id}
          />
        </Flex>
      )}
      <Flex justify="center">
        <Flex direction="column">
          <Flex alignItems="center">
            <Box w={playerNameWidth}>
              <PlayerName
                firstName={player1FirstName}
                lastName={player1LastName}
                position="left"
                playerId={player1Id}
              />
            </Box>
            <Text
              textAlign="center"
              fontSize={{ base: 'md', sm: 'lg', md: 'xl', lg: '2xl' }}
              fontWeight="bold"
              w={vsWinsWidth}
            >
              VS
            </Text>
            <Box w={playerNameWidth}>
              <PlayerName
                firstName={player2FirstName}
                lastName={player2LastName}
                position="right"
                playerId={player2Id}
              />
            </Box>
          </Flex>
          <Flex alignItems="center">
            <Text
              textAlign="right"
              fontSize={{ base: '7xl', md: '8xl' }}
              fontWeight="bold"
              opacity={determineWinOpacity(player1Wins, player2Wins)}
              color="primary.500"
              w={playerNameWidth}
            >
              {player1Wins}
            </Text>
            <Text textAlign="center" fontSize={{ base: 'xs', sm: 'sm', md: 'sm', lg: 'xl' }} w={vsWinsWidth}>
              WINS
            </Text>
            <Text
              textAlign="left"
              fontSize={{ base: '7xl', md: '8xl' }}
              fontWeight="bold"
              opacity={determineWinOpacity(player2Wins, player1Wins)}
              color="primary.500"
              w={playerNameWidth}
            >
              {player2Wins}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

HeadlineWins.propTypes = {
  player1FirstName: PropTypes.string,
  player1LastName: PropTypes.string,
  player2FirstName: PropTypes.string,
  player2LastName: PropTypes.string,
  player1Wins: PropTypes.number,
  player2Wins: PropTypes.number,
  player1ThumbnailUrl: PropTypes.string,
  player2ThumbnailUrl: PropTypes.string
};

export default HeadlineWins;

const PlayerName = ({ firstName, lastName, position, playerId }) => {
  const navigate = useNavigate();
  const longPlayerName = lastName.length > 12;
  return (
    <Flex
      direction="column"
      alignItems={position === 'left' ? 'flex-end' : 'flex-start'}
      _hover={{
        color: 'primary.500',
        cursor: 'pointer'
      }}
      onClick={() => {
        navigateTo({
          url: `/scout/${playerId}`,
          navigate
        });
      }}
    >
      <Text fontSize={{ base: 'sm', md: 'xl' }} fontWeight="bold">
        {firstName}
      </Text>
      <Text
        fontSize={{
          base: '3xl',
          sm: '4xl',
          md: '5xl',
          lg: '3xl',
          xl: '5xl'
        }}
        fontWeight="bold"
        lineHeight={{
          base: longPlayerName ? '30px' : 7,
          sm: longPlayerName ? '30px' : 7,
          md: longPlayerName ? '45px' : 9
        }}
      >
        {lastName}
      </Text>
    </Flex>
  );
};

PlayerName.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  position: PropTypes.string,
  playerId: PropTypes.number
};
