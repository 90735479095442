import React, { useEffect, useState } from 'react';

import { generateFaqContent } from 'insightsExplained/generateFaqContent';
import { generateInsightsExplainedBody } from 'insightsExplained/generateInsightsExplainedBody';
import { SubHeading } from 'insightsExplained/helperComponents';
import { useInsightsExplained } from 'insightsExplained/hooks/useInsightsExplained';
import { useRecoilValue } from 'recoil';

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Accordion as ChakraAccordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Icon,
  Text,
  Center,
  Flex
} from '_shared/designSystem/components';
import { userPermissionsState } from '_shared/globalState/atoms';

const InsightsExplained = () => {
  const { isOpen, closeDrawer, activeAccordionTitle } = useInsightsExplained();

  const [insightsOpenIndex, setInsightsOpenIndex] = useState([]);
  const [faqsOpenIndex, setFaqsOpenIndex] = useState([]);

  const { permissions } = useRecoilValue(userPermissionsState);

  const insightsExplainedBody = generateInsightsExplainedBody(permissions);

  useEffect(() => {
    if (activeAccordionTitle) {
      const index = insightsExplainedBody.findIndex((item) => item.title === activeAccordionTitle);
      setInsightsOpenIndex(index !== -1 ? [index] : []);
    } else {
      setInsightsOpenIndex([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccordionTitle]);

  const handleInsightsAccordionChange = (index) => {
    setInsightsOpenIndex(index);
  };

  const handleFaqsAccordionChange = (index) => {
    setFaqsOpenIndex(index);
  };

  const Accordion = ({ data, openIndex, onChange }) => {
    return (
      <ChakraAccordion index={openIndex} onChange={onChange} allowToggle>
        {data?.map((item, index) => (
          <AccordionItem
            key={index}
            sx={{
              borderTop: 'none',
              borderBottom: 'none',
              '&:first-of-type': {
                borderTop: 'none'
              },
              '&:last-of-type': {
                borderBottom: 'none'
              }
            }}
          >
            <h2>
              <AccordionButton bgColor="grey.100" borderRadius={8} my={2}>
                <Box flex="1" textAlign="left" color="secondary.500" fontWeight="semi-bold">
                  {item.title}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>{item.content}</AccordionPanel>
          </AccordionItem>
        ))}
      </ChakraAccordion>
    );
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={closeDrawer} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Flex gap={2}>
            <Center>
              <Icon width="20px" height="20px" name="info" color="primary.500" />
            </Center>
            <Text color="secondary.500">Insights Explained</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Accordion
            data={insightsExplainedBody}
            openIndex={insightsOpenIndex}
            onChange={handleInsightsAccordionChange}
          />
          <Box mt={8}>
            <SubHeading>FAQs</SubHeading>
            <Accordion data={generateFaqContent()} openIndex={faqsOpenIndex} onChange={handleFaqsAccordionChange} />
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default InsightsExplained;
