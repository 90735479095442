import { apiClient } from '_shared/dataFetching/apiConfig';
import { getSiteParam } from '_shared/dataFetching/urlConfig';

export const matchListService = {
  async getMatches() {
    const url = `matches?${getSiteParam()}&unique_param=${Math.floor(100000 + Math.random() * 900000)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  }
};
