export const wimbFontsGuidelines = {
  normal: {
    fontFamily: 'GothamBook',
    casing: 'none',
    letterSpacing: '0em',
    fontWeight: 'normal'
  },
  h5: {
    fontFamily: 'GothamMedium',
    casing: 'uppercase',
    letterSpacing: '0em',
    fontWeight: 'normal'
  },
  h4: {
    fontFamily: 'GothamMedium',
    casing: 'none',
    letterSpacing: '0em',
    fontWeight: 'normal'
  },
  h3: {
    fontFamily: 'GothamMedium',
    casing: 'uppercase',
    letterSpacing: '0em',
    fontWeight: 'normal'
  },
  h2: {
    fontFamily: 'GothamMedium',
    casing: 'uppercase',
    letterSpacing: '.05em',
    fontWeight: 'normal'
  },
  h1: {
    fontFamily: 'GothamMedium',
    casing: 'none',
    letterSpacing: '0em',
    fontWeight: 'normal'
  },
  paragraphText: {
    fontFamily: 'GothamBook',
    casing: 'none',
    letterSpacing: '0em',
    fontWeight: 'normal',
    fontSize: '14px'
  },
  mainMenuItem: {
    fontFamily: 'GothamBook',
    casing: 'none',
    letterSpacing: '0em',
    fontWeight: 'semibold',
    fontSize: '13px'
  },
  matchCardPlayerNames: {
    fontFamily: 'GothamBook',
    casing: 'uppercase',
    letterSpacing: '0em',
    fontWeight: 'semibold',
    fontSize: '12px'
  }
};
