import { useState } from 'react';

import PropTypes from 'prop-types';
import { useToggle } from 'react-use';

import { Alert, Button, Flex, Text, useDisclosure } from '_shared/designSystem/components';
import PdfViewModal from '_shared/designSystem/components/document/PdfViewModal';

import AcceptanceRow from './AcceptanceRow';

export default function TermsAndConditionsForm(props) {
  const { termsAndConditionsTexts, submitAccepted, submitError } = props;

  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useToggle(false);
  const [acceptedPrivacyNotice, setAcceptedPrivacyNotice] = useToggle(false);
  const [error, setError] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const { isOpen, onClose, onOpen } = useDisclosure();

  function getVersion(id) {
    const item = termsAndConditionsTexts.find((item) => item.id === id);
    return item.version;
  }

  const handleContinue = () => {
    if (acceptedTermsAndConditions && acceptedPrivacyNotice) {
      submitAccepted({
        terms_and_conditions_version: getVersion('terms_and_conditions'),
        privacy_notice_version: getVersion('privacy_notice')
      });
    } else {
      setError(true);
    }
  };

  const handleTick = (id) => {
    setError(false);
    if (id === 'terms_and_conditions') setAcceptedTermsAndConditions();
    else if (id === 'privacy_notice') setAcceptedPrivacyNotice();
  };

  const getPdfUrl = (id) => {
    const item = termsAndConditionsTexts.find((item) => item.id === id);
    return item.document_url;
  };

  const handleDocsClick = (id) => {
    setPdfUrl(getPdfUrl(id));
    onOpen();
  };

  return (
    <Flex direction="column" gap={10} maxW="350px">
      <Flex direction="column" gap={2}>
        <Text textAlign="center" fontSize="xl" fontWeight="semibold">
          Terms and Conditions
        </Text>
        <Text textAlign="center" fontSize="xl" fontWeight="semibold">
          Privacy Notice
        </Text>
      </Flex>
      {submitError && <Alert status="error" message={submitError} />}
      {error && <Alert status="warning" message="You must agree in order to continue" />}
      <Flex justify="center">
        <Flex direction="column" gap={4}>
          <AcceptanceRow
            tickValue={acceptedTermsAndConditions}
            handleTick={handleTick}
            openModalLinkText="Terms and Conditions"
            id="terms_and_conditions"
            handleDocsClick={handleDocsClick}
          />
          <AcceptanceRow
            tickValue={acceptedPrivacyNotice}
            handleTick={handleTick}
            openModalLinkText="Privacy Notice"
            id="privacy_notice"
            handleDocsClick={handleDocsClick}
          />
        </Flex>
      </Flex>
      <Flex justify="center">
        <Button w="200px" onClick={handleContinue}>
          Continue
        </Button>
      </Flex>
      <PdfViewModal isOpen={isOpen} onClose={onClose} pdfUrl={pdfUrl} />
    </Flex>
  );
}

TermsAndConditionsForm.propTypes = {
  termsAndConditionsTexts: PropTypes.array,
  submitAccepted: PropTypes.func,
  submitError: PropTypes.string
};
