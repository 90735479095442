import { useState } from 'react';

import PropTypes from 'prop-types';

import { Text, Flex, Box, VStack, ErrorMessage, Checkbox } from '_shared/designSystem/components';
import { capitaliseFirstLetterOfString } from '_shared/utils/stringUtil';

export const MatchReportCsvModalContent = ({
  selectedSections,
  setSelectedSections,
  failedFiles,
  allFilesFailed,
  showDownloadMessage
}) => {
  const [noSectionsError, setNoSectionsError] = useState(false);

  const tickBox = (id) => {
    const newSections = structuredClone(selectedSections);
    setNoSectionsError(false);
    let numberChecked = 0;
    newSections.forEach((item) => {
      if (item.id === id) item.isChecked = !item.isChecked;
      if (item.isChecked === true) numberChecked++;
    });
    if (numberChecked > 0) {
      setSelectedSections(newSections);
    } else setNoSectionsError(true);
  };

  return (
    <Box>
      <Box p={6}>
        <VStack gap={10}>
          <Text fontSize="xl" fontWeight="semibold" color="grey.700">
            CSV Report
          </Text>
          <Flex direction="column" gap={2}>
            {selectedSections?.map((item) => (
              <Row key={item.id} label={item.label} id={item.id} isChecked={item.isChecked} tickBox={tickBox} />
            ))}
          </Flex>
          {noSectionsError && <ErrorMessage message="You must have at least one section selected" />}
          {!!failedFiles &&
            !allFilesFailed &&
            failedFiles.map((file) => ( 
              <ErrorMessage
                key={file}
                message={`${capitaliseFirstLetterOfString(file.csv_type)} CSV report generation was not successful.`}
              />
            ))}
          {allFilesFailed && <ErrorMessage message="All CSV report files failed to download, please try again" />}
          <Box h="70px">{showDownloadMessage()}</Box>
        </VStack>
      </Box>
    </Box>
  );
};

MatchReportCsvModalContent.propTypes = {
  setSelectedSections: PropTypes.func,
  selectedSections: PropTypes.array,
  failedFiles: PropTypes.array,
  allFilesFailed: PropTypes.bool,
  showDownloadMessage: PropTypes.func
};

export const Row = ({ label, id, isChecked, tickBox }) => {
  return (
    <Flex>
      <Checkbox colorScheme="green" isChecked={isChecked} onChange={() => tickBox(id)}>
        {label}
      </Checkbox>
    </Flex>
  );
};
