import React from 'react';

import { Player } from '_mockData/apiResponses/players/playersTypes';

import { Flex } from '_shared/designSystem/components';

import PlayersGridCard from './PlayersGridCard';

type PlayersGridProps = {
  data: Player[];
  openScoutPage: (player_id: number) => void;
};

const PlayersGrid = ({ data, openScoutPage }: PlayersGridProps) => {
  return (
    <Flex flexWrap="wrap" gap="20px" justify={{ base: 'center', md: 'normal' }}>
      {data.map((item) => (
        <PlayersGridCard data={item} key={item.player_id} openScoutPage={openScoutPage} />
      ))}
    </Flex>
  );
};

export default PlayersGrid;
