import { borderRadius } from '_shared/designSystem/theme/borderRadius';
import { breakpoints } from '_shared/designSystem/theme/breakpoints';
import { fontSizes, fonts } from '_shared/designSystem/theme/typography';

import { wimbButtonStyles } from './css/wimbButtonStyles';
import colors from './wimbColors';

export const wimbThemeOverrides = {
  colors,
  fonts,
  fontSizes,
  ...borderRadius,
  breakpoints,
  components: {
    Button: wimbButtonStyles,
    Divider: {
      baseStyle: {
        bg: 'gray.500'
      },
      variants: {
        menuSelected: {
          bg: 'primary.700',
          height: '2px'
        }
      }
    }
  }
};
