import PropTypes from 'prop-types';

import { surfaceTypes } from '_shared/constants/matchTypes';
import { Flex, Text } from '_shared/designSystem/components';
import { capitaliseString } from '_shared/utils/stringUtil';

export default function SurfaceBadge(props) {
  const { surface } = props;

  let backgroundColor, textColor;

  switch (surface) {
    case surfaceTypes.CLAY:
      backgroundColor = `court.background.clay`;
      textColor = `white`;
      break;
    case surfaceTypes.GRASS:
      backgroundColor = 'court.surface.grass.hex';
      textColor = 'white';
      break;
    case surfaceTypes.INDOOR_HARD:
    case surfaceTypes.INDOOR_HARD_WITH_SPACE:
      backgroundColor = `blue.400`;
      textColor = 'white';
      break;
    case surfaceTypes.OUTDOOR_HARD:
    case surfaceTypes.OUTDOOR_HARD_WITH_SPACE:
      backgroundColor = `blue.500`;
      textColor = 'white';
      break;
    default:
      backgroundColor = null;
      textColor = null;
  }

  return (
    <Flex bg={backgroundColor} px={2} display="inline-block" borderRadius="md">
      <Flex gap={2}>
        <Text fontSize="xs" fontWeight="medium" color={textColor}>
          {capitaliseString(surface)}
        </Text>
      </Flex>
    </Flex>
  );
}

SurfaceBadge.propTypes = {
  surface: PropTypes.oneOf(['indoor_hard', 'indoor hard', 'outdoor_hard', 'outdoor hard', 'clay', 'grass'])
};
