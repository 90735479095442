import { useState } from 'react';

import { Tooltip as ChakraTooltip, IconButton } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Tooltip = (props) => {
  const { tooltipText, elementToHover, bg, textColour, placement } = props;
  const [isLabelOpen, setIsLabelOpen] = useState(false);
  return (
    <ChakraTooltip
      hasArrow
      label={tooltipText}
      bg={bg ? bg : 'grey.900'}
      color={textColour ? textColour : 'white'}
      borderRadius="sm"
      placement={placement || 'auto'}
      fontSize="xs"
      p={3}
      isOpen={isLabelOpen}
    >
      <IconButton
        variant="unstyled"
        minW="none"
        minHeight="auto"
        height="auto"
        aria-label="more info"
        onMouseEnter={() => setIsLabelOpen(true)}
        onMouseLeave={() => setIsLabelOpen(false)}
        onClick={() => setIsLabelOpen(!isLabelOpen)}
        icon={elementToHover}
      />
    </ChakraTooltip>
  );
};

export default Tooltip;

Tooltip.propTypes = {
  tooltipText: PropTypes.node.isRequired,
  elementToHover: PropTypes.node.isRequired,
  bg: PropTypes.string,
  textColour: PropTypes.string
};
