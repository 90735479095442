import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import { patternsOfPlayPattern } from 'scout/_shared/scoutConstants';

import { convertTextParam, convertMultiSelectParam, stringToArr } from './scoutUtil';

export function getNewFilterValue(param, value, filterValues) {
  if (filterValues === null) return null;
  if (['shotType', 'spinType'].includes(param)) return value === filterValues[`${param}`] ? 'all' : value;
  if (param === 'situation') {
    let situation = filterValues.situation;
    if (situation.includes(value)) {
      remove(situation, (elem) => elem === value);
      return situation;
    } else {
      situation.push(value);
      return situation;
    }
  }
  return value;
}

export function getPatternsOfPlayParams(pattern, filterValues) {
  if (isEmpty(pattern) && isEmpty(filterValues)) return '';
  let url = '';
  url = url.concat(`player=${convertTextParam(filterValues.player)}`);
  url = url.concat(`&pattern=${convertTextParam(pattern)}`);
  url = url.concat(`&graphic_type=${convertTextParam(filterValues.graphicType)}`);
  url = url.concat(`&shot_type=${convertTextParam(filterValues.shotType)}`);
  url = url.concat(`&spin_type=${convertTextParam(filterValues.spinType)}`);
  url = url.concat(`&situation=${convertMultiSelectParam(filterValues.situation)}`);
  return url;
}

export const patternsOfPlayInitialPattern = patternsOfPlayPattern.CONTROL_THE_MIDDLE;

export function getPatternFromSearchParams(params) {
  if (isEmpty(params) || isEmpty(params.pattern)) return patternsOfPlayInitialPattern;
  return params.pattern;
}

export const patternsOfPlayInitialFilterValues = {
  graphicType: 'direction',
  shotType: 'all',
  spinType: 'all',
  situation: [],
  player: 'player1'
};

export function getFilterValuesFromSearchParams(params) {
  if (isEmpty(params)) return patternsOfPlayInitialFilterValues;
  return {
    player: params.player ?? patternsOfPlayInitialFilterValues.player,
    graphicType: params.graphicType ?? patternsOfPlayInitialFilterValues.graphicType,
    shotType: params.shotType ?? patternsOfPlayInitialFilterValues.shotType,
    spinType: params.spinType ?? patternsOfPlayInitialFilterValues.spinType,
    situation: params.situation ?? stringToArr(patternsOfPlayInitialFilterValues.situation)
  };
}

export function updateFilterValuesBasedOnPattern(pattern, filterValues) {
  if (isEmpty(pattern)) return filterValues;
  if (pattern === patternsOfPlayPattern.VOLLEYS) {
    if (filterValues.graphicType === 'quality' || filterValues.graphicType === 'accuracy') {
      const newfilterValues = { ...filterValues, graphicType: 'direction' };
      return newfilterValues;
    }
  }
  return filterValues;
}
