import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  patternsOfPlayGraphicType,
  patternsOfPlayPattern,
  patternsOfPlayShotTypes
} from 'scout/_shared/scoutConstants';

import { Flex, Box } from '_shared/designSystem/components';

import BadgeMobilePositions from '../badges/BadgeMobilePositions';
import {
  DirectionAndAccuracyBadgePositions,
  InBadgePositions,
  QualityBadgePositions,
  DepthBadgePositions
} from '../badges/BadgePositions';
import CourtHeader from '../CourtHeader';

import PatternsOfPlayCourtWithArrows from './CourtWithArrows';

const PatternsOfPlayCourt = ({
  dataWithAverages,
  dataNoAverages,
  surface,
  pattern,
  graphicType,
  shotType,
  isWideCourt,
  showAverages,
  averagesLoading
}) => {
  const [courtSide, setCourtSide] = useState('to_deuce');

  const renderBadges = () => {
    if (graphicType === patternsOfPlayGraphicType.DIRECTION || graphicType === patternsOfPlayGraphicType.ACCURACY) {
      return (
        <DirectionAndAccuracyBadgePositions
          deuceDataWithAverages={dataWithAverages?.to_deuce}
          deuceDataNoAverages={dataNoAverages?.to_deuce}
          advantageDataWithAverages={dataWithAverages?.to_advantage}
          advantageDataNoAverages={dataNoAverages?.to_advantage}
          isWideCourt={isWideCourt}
          showAverages={showAverages}
          averagesLoading={averagesLoading}
        />
      );
    }

    if (graphicType === patternsOfPlayGraphicType.IN) {
      return (
        <InBadgePositions
          dataWithAverages={dataWithAverages}
          dataNoAverages={dataNoAverages}
          isWideCourt={isWideCourt}
          showAverages={showAverages}
          averagesLoading={averagesLoading}
        />
      );
    }

    if (graphicType === patternsOfPlayGraphicType.QUALITY) {
      return (
        <QualityBadgePositions
          dataWithAverages={dataWithAverages}
          dataNoAverages={dataNoAverages}
          isWideCourt={isWideCourt}
          showAverages={showAverages}
          averagesLoading={averagesLoading}
        />
      );
    }

    if (graphicType === patternsOfPlayGraphicType.DEPTH) {
      return (
        <DepthBadgePositions
          dataWithAverages={dataWithAverages}
          dataNoAverages={dataNoAverages}
          isWideCourt={isWideCourt}
          showAverages={showAverages}
          averagesLoading={averagesLoading}
        />
      );
    }
  };

  const renderCourt = () => (
    <Box style={{ transform: graphicType === patternsOfPlayGraphicType.DEPTH && 'scale(1.25)' }} mb={5}>
      <PatternsOfPlayCourtWithArrows
        pattern={pattern}
        surface={surface}
        isWideCourt={isWideCourt}
        graphicType={graphicType}
      />
      <AccuracyCourtStrip position={{ top: '69px', left: isWideCourt ? '117px' : '95px' }} />
      <AccuracyCourtStrip position={{ top: '69px', right: isWideCourt ? '113px' : '95px' }} />
    </Box>
  );

  const AccuracyCourtStrip = ({ position }) => {
    if (graphicType === patternsOfPlayGraphicType.ACCURACY) {
      return (
        <Box
          position="absolute"
          top={position.top}
          left={position.left}
          right={position.right}
          h="231px"
          w="26px"
          bg={surface === 'clay' ? 'grey.500' : 'error.500'}
          opacity={0.5}
        />
      );
    }
    return null;
  };

  return (
    <Flex direction="column" alignItems="center" mb={5} gap={5} style={{ overflowY: 'hidden', width: '100%' }}>
      <CourtHeader data={dataNoAverages} graphicType={graphicType} pattern={pattern} shotType={shotType} />
      <Flex direction="column" alignItems="center" gap={5} mt={graphicType === 'depth' && '70px'}>
        <Flex data-testid="courtWithArrows" position="relative">
          {renderBadges()}
          {renderCourt()}
        </Flex>
        {!isWideCourt && (
          <BadgeMobilePositions
            dataNoAverages={dataNoAverages}
            dataWithAverages={dataWithAverages}
            courtSide={courtSide}
            setCourtSide={setCourtSide}
            graphicType={graphicType}
            showAverages={showAverages}
            averagesLoading={averagesLoading}
          />
        )}
      </Flex>
    </Flex>
  );
};

PatternsOfPlayCourt.propTypes = {
  dataWithAverages: PropTypes.object,
  dataNoAverages: PropTypes.object,
  pattern: PropTypes.oneOf(Object.values(patternsOfPlayPattern)).isRequired,
  graphicType: PropTypes.oneOf(Object.values(patternsOfPlayGraphicType)).isRequired,
  shotType: PropTypes.oneOf(Object.values(patternsOfPlayShotTypes)).isRequired,
  surface: PropTypes.string,
  isWideCourt: PropTypes.bool,
  showAverages: PropTypes.bool,
  averagesLoading: PropTypes.bool
};

PatternsOfPlayCourt.defaultProps = {
  surface: 'hard',
  isWideCourt: false,
  showAverages: false,
  averagesLoading: false
};

export default PatternsOfPlayCourt;
