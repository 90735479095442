import customSitesConfig from '_shared/config/customSitesConfig';
import { Flex, Text } from '_shared/designSystem/components';
import { getComponentWithExtraProps } from '_shared/utils/componentUtils';

import { leftColumnContentWidth } from '../performanceMargins';

export const BaselineBattles = ({
  data,
  dataWithAverages,
  averagesLoading,
  showAverages,
  player1Name,
  player2Name
}) => {
  return (
    <Flex direction="column" gap={4} w={{ base: '100%', sm: `${leftColumnContentWidth}px` }}>
      <Flex justify="space-between" w="100%">
        <Text fontSize="sm" fontWeight="semibold">
          {player1Name}
        </Text>
        <Text fontSize="sm" fontWeight="semibold">
          {player2Name}
        </Text>
      </Flex>
      <Flex direction="column" gap={4}>
        {getComponentWithExtraProps(customSitesConfig.battlesMetricSection, {
          showAverages,
          averagesLoading,
          data,
          dataWithAverages,
          label: 'Baseline Battle',
          fieldName: 'baseline'
        })}
        {getComponentWithExtraProps(customSitesConfig.battlesMetricSection, {
          showAverages,
          averagesLoading,
          data,
          dataWithAverages,
          label: `${player1Name} at Net`,
          fieldName: 'player1_at_net'
        })}
        {getComponentWithExtraProps(customSitesConfig.battlesMetricSection, {
          showAverages,
          averagesLoading,
          data,
          dataWithAverages,
          label: `${player2Name} at Net`,
          fieldName: 'player2_at_net'
        })}
      </Flex>
    </Flex>
  );
};
