import BalanceOfPower from 'match/components/performance/battles/BalanceOfPower';
import BattlesMetricSection from 'match/components/performance/battles/BattlesMetricSection';
import Momentum from 'match/components/performance/battles/Momentum';

export const AtpTourBalanceOfPower = (props) => (
  <BalanceOfPower
    {...props}
    bgColor={null}
    bgBorderColor="secondary.800"
    winningColor="primary.500"
    losingColor="primary.100"
    neutralColor="grey.100"
    winningTextColor="white"
    losingTextColor="secondary.800"
    neutralTextColor="secondary.800"
    headingColor="secondary.800"
    headingText="In Attack"
  />
);

export const AtpTourMomemtum = (props) => (
  <Momentum
    {...props}
    bgColor={null}
    bgBorderColor="secondary.800"
    labelColor="secondary.800"
    greaterColor="primary.500"
    lessColor="primary.100"
  />
);

export const AtpTourBattlesMetricSection = (props) => (
  <BattlesMetricSection {...props} greaterColor="primary.500" lessColor="primary.100" />
);
