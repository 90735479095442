import { geWimbPlayerId } from '_customSites/wimb/services/wimbLocalStorage';
import isEmpty from 'lodash/isEmpty';

import { apiClient } from '_shared/dataFetching/apiConfig';
import { getCurrentEnvironment } from '_shared/utils/environment/currentEnv';
import { getLocalStorageAuthTokens } from '_shared/utils/localStorage';

const userData = getLocalStorageAuthTokens();
const userName = userData.userName;

let attributes = {};

const currentEnv = getCurrentEnvironment();

export const log = async (inputAttributes) => {
  const playerId = geWimbPlayerId();
  if (currentEnv === 'dev') {
    console.dir(`DEVLOG:  ${inputAttributes?.message}`);
  } else {
    if (isEmpty(inputAttributes)) {
      attributes = {
        path: window.location.href,
        userAgent: navigator.userAgent,
        userName,
        level: 'ERROR',
        message: 'Could not send error log message, attributes object empty'
      };
    } else {
      attributes = {
        path: window.location.href,
        userAgent: navigator.userAgent,
        userName,
        level: inputAttributes?.level,
        message: trimLogMessage(inputAttributes?.message)
      };
      // for wimbledon, log the player who is using the site
      if (playerId) attributes['playerId'] = playerId;
    }
    const postObject = { attributes };
    await apiClient.post('log', postObject);
  }
};

export const logBasicMessage = (level, message) => {
  log({
    level,
    message
  });
};

export function trimLogMessage(message) {
  if (message.length > 350) {
    return message.slice(0, 350);
  }
  return message;
}
