import PropTypes from 'prop-types';

import { Box, Flex, Text } from '_shared/designSystem/components';

const Confidence = ({ data }) => {
  const { confidence, confidence_rating } = data;

  const RatingEnum = {
    EXCELLENT: 'Excellent',
    VERY_GOOD: 'Very Good',
    GOOD: 'Good',
    BAD: 'Below Average',
    VERY_BAD: 'Poor'
  };

  const confidenceRatingColors = {
    EXCELLENT: 'primary.800',
    VERY_GOOD: 'primary.500',
    GOOD: 'success.400',
    BAD: 'warning.400',
    VERY_BAD: 'error.500',
    default: 'grey.400'
  };

  const checkConfidenceRating = () => {
    const enumKey = Object.keys(RatingEnum).find((key) => RatingEnum[key] === confidence_rating);
    return confidenceRatingColors[enumKey] || confidenceRatingColors.default;
  };

  const getConfidenceRatingText = () => {
    return Object.keys(RatingEnum).find((key) => RatingEnum[key] === confidence_rating) || 'default';
  };

  return (
    <Box>
      <Text fontSize="xs" fontWeight="normal" color="grey.400">
        Data Confidence
      </Text>
      <Flex>
        <Text fontSize="xs" fontWeight="medium" color={checkConfidenceRating()} w={50}>
          {confidence?.toFixed(1)}%
        </Text>
        <Text fontSize="xs" fontWeight="normal" color={checkConfidenceRating()}>
          {RatingEnum[getConfidenceRatingText()]}
        </Text>
      </Flex>
    </Box>
  );
};

Confidence.propTypes = {
  data: PropTypes.object
};

export default Confidence;
