import { Button as ChakraButton, useStyleConfig } from '@chakra-ui/react';

import { isTvPortal } from '_shared/utils/environment/currentEnv';

export const ButtonStyles = {
  baseStyle: {
    borderRadius: 'md',
    outline: 'none'
  },
  sizes: {
    sm: {
      fontSize: 'sm'
    },
    md: {
      fontSize: 'md'
    }
  },
  variants: {
    outline: {
      border: '2px solid',
      borderColor: 'primary.500',
      color: 'primary.500',
      _hover: { bg: 'primary.50' }
    },
    solid: {
      bg: 'primary.500',
      color: isTvPortal() ? 'charcoal.500' : 'white',
      _hover: { bg: 'primary.700' }
    },
    form: {
      bg: 'primary.500',
      color: isTvPortal() ? 'charcoal.500' : 'white',
      _hover: { bg: 'primary.700' },
      height: '32px',
      borderRadius: 'md'
    }
  },
  defaultProps: {
    size: 'md',
    variant: 'solid'
  }
};

function Button(props) {
  const { variant, size, ...rest } = props;

  const styles = useStyleConfig('Button', { variant, size });

  return <ChakraButton __css={styles} {...rest} />;
}

export default Button;
