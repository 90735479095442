export function hasWonTheMatch(data, playerId) {
  const matchWinner = data?.score?.match_winner || data?.match_result?.match_winner;

  if (!matchWinner) return false;

  const matchWinnerId =
    matchWinner === 'player1'
      ? data.player_details?.player1?.member1?.player_id
      : data.player_details?.player2?.member1?.player_id;

  return playerId === matchWinnerId;
}
