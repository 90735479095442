import isEmpty from 'lodash/isEmpty';
import { displayScore } from 'match/utils/transformations';

export const coordinatesPlacementHorizontal = {
  top: {
    top: 0.35,
    left: 0.73
  },
  middle: {
    top: 0.6,
    left: 0.79
  },
  bottom: {
    top: 0.74,
    left: 0.83
  }
};
export const coordinatesReturnContactPoint = {
  top: {
    top: 0.2,
    left: 0.83
  },
  middle: {
    top: 0.47,
    left: 0.83
  },
  bottom: {
    top: 0.71,
    left: 0.83
  }
};
export const coordinatesRallyContactPoint = {
  top: {
    top: 0.35,
    left: 0.73
  },
  middle: {
    top: 0.71,
    left: 0.73
  },
  bottom: {
    top: 0.86,
    left: 0.73
  }
};

export function getAverage(data, position, type) {
  if (isEmpty(data)) return null;
  return displayScore(data[position]?.[`${type}_average`], data[position]?.score_type);
}
