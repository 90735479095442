import { Averages } from 'match/components/_shared/Filtering';
import { getPlayersButtons } from 'match/utils/matchUtils';
import PropTypes from 'prop-types';
import { patternsOfPlayPattern } from 'scout/_shared/scoutConstants';

import { Box, ButtonGroup, Flex } from '_shared/designSystem/components';

const PatternsOfPlayFilters = ({
  pattern,
  filterValues,
  handleFilterButtonClick,
  showAverages,
  setShowAverages,
  hasPlayerFilter,
  playersData
}) => {
  return (
    <Flex gap="16px" flexWrap="wrap" mb={5} align="center">
      {hasPlayerFilter && (
        <ButtonGroup
          key="player"
          paramName="player"
          data={playersData}
          getButtonDataFunction={getPlayersButtons}
          handleButtonClick={handleFilterButtonClick}
          selectedItem={filterValues.player}
        />
      )}
      {['graphicType', 'shotType', 'spinType'].map((field) => (
        <ButtonGroup
          key={field}
          paramName={field}
          data={
            pattern === patternsOfPlayPattern.VOLLEYS && field === 'graphicType'
              ? filterButtonsData.volleyGraphicTypeButtons
              : filterButtonsData[`${field}Buttons`]
          }
          handleButtonClick={handleFilterButtonClick}
          selectedItem={filterValues[`${field}`]}
        />
      ))}
      <ButtonGroup
        key="situation"
        paramName="situation"
        data={filterButtonsData[`situationButtons`]}
        handleButtonClick={handleFilterButtonClick}
        selectedItemMultiple={filterValues.situation}
      />
      <Box minW="350px">
        <Averages showAverages={showAverages} setShowAverages={setShowAverages} />
      </Box>
    </Flex>
  );
};

PatternsOfPlayFilters.propTypes = {
  pattern: PropTypes.string,
  filterValues: PropTypes.object,
  handleFilterButtonClick: PropTypes.func,
  showAverages: PropTypes.bool,
  setShowAverages: PropTypes.func,
  hasPlayerFilter: PropTypes.bool,
  playersData: PropTypes.object
};

const filterButtonsData = {
  volleyGraphicTypeButtons: [
    {
      label: 'Direction',
      value: 'direction',
      position: 'left'
    },
    {
      label: 'In',
      value: 'in',
      position: 'middle'
    },
    {
      label: 'Depth',
      value: 'depth',
      position: 'right'
    }
  ],
  graphicTypeButtons: [
    {
      label: 'Direction',
      value: 'direction',
      position: 'left'
    },
    {
      label: 'In',
      value: 'in',
      position: 'middle'
    },
    {
      label: 'Quality',
      value: 'quality',
      position: 'middle'
    },
    {
      label: 'Depth',
      value: 'depth',
      position: 'middle'
    },
    {
      label: 'Accuracy',
      value: 'accuracy',
      position: 'right'
    }
  ],
  shotTypeButtons: [
    {
      label: 'Forehand',
      value: 'forehand',
      position: 'left'
    },
    {
      label: 'Backhand',
      value: 'backhand',
      position: 'right'
    }
  ],
  spinTypeButtons: [
    {
      label: 'Topspin',
      value: 'topspin',
      position: 'left'
    },
    {
      label: 'Slice',
      value: 'slice',
      position: 'right'
    }
  ],
  situationButtons: [
    {
      label: 'Attack',
      value: 'attack',
      position: 'left'
    },
    {
      label: 'Neutral',
      value: 'neutral',
      position: 'middle'
    },
    {
      label: 'Defence',
      value: 'defence',
      position: 'right'
    }
  ]
};

export default PatternsOfPlayFilters;
