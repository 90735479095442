import { useState } from 'react';

import PropTypes from 'prop-types';
import { BarLoader } from 'react-spinners';
import { scoutTabTypes } from 'scout/_shared/scoutConstants';
import { scoutReportService } from 'scoutReport/services/scoutReportService';
import { getQueryParamsWithSection } from 'scoutReport/util/scoutReportUtil';

import { getReportBucketUrl } from '_shared/dataFetching/urlConfig';
import {
  Text,
  VStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton
} from '_shared/designSystem/components';
import { logBasicMessage } from '_shared/errors/log';

import ScoutReportModalContent from './ScoutReportModalContent';

export default function ScoutReportModal(props) {
  const { isOpen, onClose, playerId, queryParams, playerName, company } = props;
  const [isDownloading, setIsDownloading] = useState(false);
  const [createReportError, setCreateReportError] = useState(null);
  const [reportCreated, setReportCreated] = useState(false);
  const [selectedSections, setSelectedSections] = useState(initialSelectedSections);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [fileName, setFileName] = useState(null);

  async function handleReportFlow() {
    setCreateReportError(false);
    logBasicMessage('INFO', `User started to download scout report for player_id ${playerId}`);
    let retry = false;
    try {
      await runReportGeneration();
    } catch (error) {
      retry = true;
    }
    if (retry) {
      logBasicMessage('INFO', `First attempt at scout report generation failed for player_id ${playerId}, retrying`);
      try {
        await runReportGeneration();
      } catch (error) {
        setIsDownloading(false);
        setCreateReportError(true);
        let sendingError = `Error downloading scout report: ${error} `;
        if (error.request) sendingError += ` | Request: ${JSON.stringify(error.request)} `;
        if (error.response) sendingError += ` | Response: ${JSON.stringify(error.response)} `;
        logBasicMessage('ERROR', sendingError);
      }
    }
  }

  const runReportGeneration = async () => {
    const queryParamsWithSection = getQueryParamsWithSection(queryParams, selectedSections);
    setIsDownloading(true);
    const res = await scoutReportService.createScoutReport({
      playerName,
      queryParams: queryParamsWithSection,
      company
    });
    const _fileName = res?.fileName;
    const _downloadUrl = `${getReportBucketUrl('scout')}${_fileName}`;
    setDownloadUrl(_downloadUrl);
    setFileName(_fileName);
    setIsDownloading(false);
    setReportCreated(true);
    openReport(_downloadUrl, _fileName);
  };

  const openReport = (_downloadUrl, _fileName) => {
    const url = _downloadUrl || downloadUrl;
    const file = _fileName || fileName;
    setCreateReportError(false);
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.setAttribute('download', file);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    logBasicMessage('INFO', `User successfully downloaded scout report ${file}`);
  };

  const showDownloadMessage = () => {
    if (reportCreated) return <Button onClick={() => openReport()}>Download Report</Button>;
    else if (isDownloading) {
      return (
        <VStack justify="center">
          <Text mb={3} fontSize="sm" fontWeight="normal" color="grey.700">
            Generating Scout Report
          </Text>
          <BarLoader height="8px" width="300px" color="#96BF32" />
        </VStack>
      );
    } else return <Button onClick={() => handleReportFlow()}>Report</Button>;
  };

  const closeModal = () => {
    setCreateReportError(null);
    setIsDownloading(false);
    setReportCreated(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} size="lg" onClose={() => closeModal()}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ScoutReportModalContent
          createReportError={createReportError}
          showDownloadMessage={showDownloadMessage}
          selectedSections={selectedSections}
          setSelectedSections={setSelectedSections}
        />
      </ModalContent>
    </Modal>
  );
}

ScoutReportModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  playerId: PropTypes.number,
  queryParams: PropTypes.string,
  playerName: PropTypes.string,
  company: PropTypes.string
};

const initialSelectedSections = [
  {
    id: scoutTabTypes.INSIGHTS,
    label: 'Insights',
    isChecked: true
  },
  {
    id: scoutTabTypes.SERVE,
    label: 'Serve',
    isChecked: true
  },
  {
    id: scoutTabTypes.RETURN,
    label: 'Return',
    isChecked: true
  },
  {
    id: scoutTabTypes.GROUNDSTROKES,
    label: 'Groundstrokes',
    isChecked: true
  },
  {
    id: scoutTabTypes.WINNING_AND_LOSING_PLAYS,
    label: 'Winning and Losing Plays',
    isChecked: true
  }
];
