import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import './swiperCustom.css';

import { useMemo } from 'react';
import { useRef } from 'react';

import { mainAreaMaxWidth, mobileHeaderHeight } from '_layouts/margins';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { getOrderOfPlay } from 'schedule/util/courtViewUtil';
import { Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Box, Flex, Icon, Text, VStack, useBreakpointValue } from '_shared/designSystem/components';
import ScrollToTopArrow from '_shared/designSystem/components/icon/ScrollToTopArrow';

import MatchCard from './MatchCard';

export default function ScheduleCourtView({ data, activeDay }) {
  const orderedCourtData = useMemo(() => {
    return getOrderOfPlay(data, activeDay);
  }, [data, activeDay]);

  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false
    },
    { ssr: false }
  );

  const swiperWidth = useBreakpointValue(
    {
      base: '100%',
      sm: '100%',
      md: '90vw',
      '2xl': `${mainAreaMaxWidth - 100}px`
    },
    { ssr: false }
  );

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const paginationRef = useRef(null);

  if (isEmpty(orderedCourtData)) return null;

  if (isMobile)
    return (
      <>
        <VStack direction="column" gap="50px">
          {orderedCourtData.map((item, index) => (
            <MatchesColumn key={index} columnData={item} isMobile />
          ))}
        </VStack>
        <ScrollToTopArrow />
      </>
    );

  return (
    <Flex>
      <Flex align="center">
        <Box ref={navigationPrevRef} data-testid="swiperLeftArrow">
          <Icon cursor="pointer" name="chevronLeft" width={8} height={8} color="primary.500" />
        </Box>
      </Flex>
      <Box>
        <Flex justify="center" mb={3}>
          <Box ref={paginationRef} data-testid="swiperPaginationBullets" />
        </Flex>
        <Swiper
          modules={[Navigation, Pagination, Mousewheel]}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current
          }}
          pagination={{
            clickable: true,
            el: paginationRef.current
          }}
          mousewheel={{
            enabled: true,
            forceToAxis: true
          }}
          style={{
            width: swiperWidth,
            paddingBottom: '30px',
            swiperPaginationBulletSize: '30px'
          }}
          breakpoints={{
            576: {
              slidesPerView: 1,
              spaceBetween: 30
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            992: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            1190: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          }}
          grabCursor={true}
        >
          {orderedCourtData.map((item, index) => (
            <SwiperSlide key={index}>
              <Flex justify="center" h="100%">
                <MatchesColumn key={index} columnData={item} />
              </Flex>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Flex align="center">
        <Box ref={navigationNextRef} data-testid="swiperRightArrow">
          <Icon cursor="pointer" name="chevronRight" width={8} height={8} color="primary.500" />
        </Box>
      </Flex>
    </Flex>
  );
}

ScheduleCourtView.propTypes = {
  data: PropTypes.array,
  activeDay: PropTypes.number,
  openMatchPage: PropTypes.func
};

const MatchesColumn = ({ columnData, isMobile }) => (
  <Flex minH={{ md: '520px' }} direction="column" w="100%" align={{ base: 'center', md: 'initial' }}>
    <Flex
      position={isMobile ? 'sticky' : null}
      top={isMobile ? `${mobileHeaderHeight}px` : null}
      justify="center"
      align="center"
      bg="white"
      minW="360px"
      minH="43px"
    >
      <Text mb={4} fontWeight="semibold" fontSize="lg" bg="white">
        {columnData[0].court_name}
      </Text>
    </Flex>
    <Flex direction="column" gap={4}>
      {columnData.map((item, index) => (
        <MatchCard key={index} data={item} showTournamentInfo={false} hideDate />
      ))}
    </Flex>
  </Flex>
);
